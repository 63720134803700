import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import Card from "@mui/material/Card";
import DataTable from "examples/Tables/DataTable";
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import AddIcon from "@mui/icons-material/Add";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { Edit, Delete, PendingActions } from "@mui/icons-material";
import MDTypography from "components/MDTypography";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ApiCalls from "services/flick_erp_api_call";
import moment from "moment";
import MDButton from "components/MDButton";

function ApproveLeave() {
  const [approvalDialog, setApprovalDialog] = useState(false);
  const [leavePayload, setLeavePayload] = useState({});
  const [remark, setRemark] = useState("");
  // const [employee, setEmployee] = useState({});

  useEffect(() => {
    getLeaveApproval();
  }, []);

  async function getLeaveApproval() {
    var res = await ApiCalls.getLeaveApproval();
    if (res.success && res.code == 200) {
      leaveData.rows = res.data;
      setLeaveData({ ...leaveData });
    }
  }
  // approve Leave
  async function approveLeave(id, status, managerId, tlId) {
    var user = JSON.parse(localStorage.getItem("user"));
    let tl_data = {
      tl_decision: status,
      tl_reason: remark,
    };
    let manager_data = {
      manager_decision: status,
      manager_reason: remark,
    };
    if(managerId == tlId ) {

      await ApiCalls.updateLeaveApprovalAsManager(
        id,
        manager_data,
        false
      );
    }
   else if (user.employee_id == managerId) {

      let res = await ApiCalls.updateLeaveApprovalAsManager(
        id,
        manager_data,
        false
      );
      if (res.success) {

      }
    }
   else  if (user.employee_id == tlId) {

      let res = await ApiCalls.updateLeaveApprovalAsTL(id, tl_data, false);
      if (res.success) {
       
      }
    }
    setApprovalDialog(false)
    setLeavePayload({})
    getLeaveApproval();
  }
  const [leaveData, setLeaveData] = useState({
    columns: [
      {
        Header: "Apply Date",
        accessor: "apply_date",
        Cell: ({ row }) =>
          moment(row.original.leave.create_at).format("YYYY-MM-DD"),
      },
      {
        Header: "Employee",
        accessor: "employee",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={row.original.profilePhoto} alt="Profile Photo" />
            <span style={{ marginLeft: "0.5rem" }}>
              {row.original?.leave?.employee?.user?.firstname}
            </span>
            <span style={{ marginLeft: "0.2rem" }}>
              {row.original?.leave?.employee?.user?.lastname}
            </span>
          </div>
        ),
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        Cell: ({ row }) => row.original.leave.start_date,
      },
      {
        Header: "End Date",
        accessor: "end_date",
        Cell: ({ row }) => row.original.leave.end_date,
      },
      {
        Header: "Number of Hours",
        accessor: "number_of_hours",
        Cell: ({ row }) => row.original.leave.number_of_hours,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => row.original.leave.status,
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Action">
              <IconButton
                aria-label="Action"
                size="small"
                onClick={() => {
                  setLeavePayload(row?.original);
                  setApprovalDialog(true);
                }}
              >
                <PendingActions></PendingActions>
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
    ],
    rows: [],
  });
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card>
          <MDBox pt={3} pl={3} pb={4}>
            <MDTypography variant="h4">Approve/Reject Leave</MDTypography>
          </MDBox>
          <MDBox>
            <DataTable table={leaveData} entriesPerPage={false}></DataTable>
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
      <Dialog
        open={approvalDialog}
        onClose={() => {
          setApprovalDialog(false);
        }}
      >
        <DialogTitle>Leave Approval Action</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            placeholder="Add Remark here"
            onChange={(event) => {
              setRemark(event.target.value);
            }}
            InputProps={{
              inputComponent: TextareaAutosize,
              rows: 3,
            }}
          ></TextField>
        </DialogContent>
        <DialogActions>
          <MDButton
            color="info"
            onClick={() => {
              approveLeave(
                leavePayload.id,
                "approved",
                leavePayload.manager,
                leavePayload.tl
              );
            }}
          >
            Approve
          </MDButton>
          <MDButton
            color="red"
            onClick={() => {
              approveLeave(
                leavePayload.id,
                "rejected",
                leavePayload.manager,
                leavePayload.tl
              );
            }}
          >
            Reject
          </MDButton>
          <MDButton
            onClick={() => {
              setApprovalDialog(false);
            }}
          >
            Cancel
          </MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}
export default ApproveLeave;
