/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import video from "../../../../assets/launch.gif";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Material Dashboard 2 PRO React page layout routes
import pageRoutes from "page.routes";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { Box } from "@mui/material";

function IllustrationLayout({
  header,
  title,
  description,
  illustration,
  children,
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <PageLayout background="white">
      {/* <DefaultNavbar
        routes={pageRoutes}
        action={{
          type: "external",
          route: "https://creative-tim.com/product/material-dashboard-pro-react",
          label: "buy now",
        }}
      /> */}
      {/* <MDBox
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        alignSelf="center"
        alignItems="center"
        alignContent="center"
        pt={15} pr={5}
      >
        <img
          component="img"
          width={"60%"}
          style={{ minWidth:"370px" }}
          src={video}
        /> */}
      <Grid py={15} container spacing={2}  alignItems={"center"} justifyContent={"space-between"}>
        <Grid item xs={12} lg={6} >
         <MDBox bgColor="info">
         <img
          style={{height: "100%",
            maxWidth: "100%",
            display: "block",
            margin: "auto"}}
          src={video}
        />
         </MDBox>
        </Grid>
        <Grid item xs={12} lg={4.5}   align="center" alignItems={"center"} >
          <Grid container  justifyContent={"center"}>
          <MDBox   pt={3} >{
          children
          }</MDBox>
          </Grid>
        </Grid>
      </Grid>


      {/* </MDBox> */}
      {/* <Grid
        container
        sx={{
          backgroundColor: ({ palette: { background, white } }) =>
            darkMode ? background.default : white.main,
        }}
      >
        <Grid item >
        <img src={video} width="80%" style={{paddingTop:140}}></img>
        
          
        </Grid>
        <Grid item xs={11} sm={8} md={6} lg={4} xl={3} sx={{ mx: "auto" }} alignSelf={"center"}>
          <MDBox display="flex" flexDirection="column" justifyContent="center">
           
            <MDBox p={3}>{children}</MDBox>
          </MDBox>
        </Grid>
      </Grid> */}
    </PageLayout>
  );
}

// Setting default values for the props of IllustrationLayout
IllustrationLayout.defaultProps = {
  header: "",
  title: "",
  description: "",
  illustration: "",
};

// Typechecking props for the IllustrationLayout
IllustrationLayout.propTypes = {
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
  illustration: PropTypes.string,
};

export default IllustrationLayout;
