/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import ApiCalls from "services/flick_erp_api_call";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
function ChangePassword(props) {

const navigate = useNavigate() ;
  // const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [cnfNewpassword, setCnfNewPassword] = useState('')
  const [passwordValid, setPasswordValid] = useState(null);
  const [password, setPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState('');
  const [cnfNewpasswordError, setCnfNewPasswordError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const user = JSON.parse(localStorage.getItem("user"));

  const passwordRequirements = [
    "One special characters",
    "Min 6 characters",
    "One number (2 are recommended)",
    "Change it often",
  ];

  const validatePassword = (password) => {
    // const hasSpecialCharacters = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    // const hasMinimumLength = password.length >= 6;
    // const hasNumber = /\d/.test(password);
  
    let errors = '';

    if (!password) {
      errors = 'Required';
    } else if (password.length < 6) {
      errors = 'Password must be at least 6 characters long';
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors = 'Password must contain at least one special character';
    }  else if (!/\d/.test(password)) {
      errors = 'Password must contain at least one number';
    }

    setPasswordValid(errors === '');

    return errors;
  };
  
  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
        <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
          {item}
        </MDTypography>
      </MDBox>
    );
  });
  const changePassword = async () => {

    setNewPasswordError('');
    setCnfNewPasswordError('');

    const newPasswordErrors = validatePassword(newPassword);
    const cnfNewpasswordErrors = validatePassword(cnfNewpassword);

    if (newPasswordErrors) {
      setNewPasswordError(newPasswordErrors);
    }

    if (cnfNewpasswordErrors) {
      setCnfNewPasswordError(cnfNewpasswordErrors);
    }

    if (!newPasswordErrors && !cnfNewpasswordErrors) {
      if (newPassword !== '' && cnfNewpassword !== '') {
        if (cnfNewpassword === newPassword) {
          try {
            const passwordData = {
              "pk": props.data.user.id,
              "new_password": newPassword,
              "cnfNewpassword": cnfNewpassword,
            };
            const res = await ApiCalls.changePassword(passwordData);

            if (res.success && res.code === 200) {
              navigate("/employee/employees");
            }
          } catch (error) {
            console.error(error);
          }
        } else {
          setNewPasswordError('Please enter the same password');
          setCnfNewPasswordError('Please enter the same password');
        }
      }
    }
  };

  return (
    <Card id="change-password">
      <MDBox p={3}>
        <MDTypography variant="h5">Change Password</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {/* <MDInput
              fullWidth
              label="Current Password"
              inputProps={{ type: "password", autoComplete: "" }}
              onChange={(e) => {
                setCurrentPassword(e.target.value);
              }}
            /> */}
          </Grid>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              label="New Password"
              inputProps={{ type: "password", autoComplete: "" }}
              onChange={(e) => {
                setNewPassword(e.target.value);

                setNewPasswordError('');
              }}
              required
              error={!!newPasswordError}
              helperText={
                <span style={{ color: newPasswordError ? 'red' : 'initial' }}>
                  {newPasswordError}
                </span>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              fullWidth
              label="Confirm New Password"
              inputProps={{ type: "password", autoComplete: "" }}
              onChange={(e) => {
                setCnfNewPassword(e.target.value);
                setCnfNewPasswordError(''); 
              }}
              required 
              error={cnfNewpasswordError !== ""} 
              helperText={
                <span style={{ color: cnfNewpasswordError ? 'red' : 'initial' }}>
                  {cnfNewpasswordError}
                </span>
              }
            />
          </Grid>
        </Grid>
        <MDBox mt={6} mb={1}>
          <MDTypography variant="h5">Password requirements</MDTypography>
        </MDBox>
        <MDBox mb={1}>
          <MDTypography variant="body2" color="text">
            Please follow this guide for a strong password
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
          <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
            {renderPasswordRequirements}
          </MDBox>
          <MDBox ml="auto">
            <MDButton variant="gradient" color="info" size="small"
              onClick={() => {
                changePassword();
              }}
            // disabled={!passwordValid}
            >
              update password
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default ChangePassword;
