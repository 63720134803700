import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import {
  Person,
  Lock,
  Badge,
  Comment,
  Upload,
  FileUpload,
  SystemSecurityUpdate,
} from "@mui/icons-material";

import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

import SettingsEmp from "layouts/employees/settings";
import { UploadByMachine } from "./upload_by_machine";
import { UploadBySoftware } from "./upload_by_software";
import { FileDrop } from "components/DragNDrop.js/drag_n_drop";
import ApiCalls from "services/flick_erp_api_call";
import { UploadFile } from "./upload_file";

function UploadAttendance() {
  const [pickedFile, setFile] = useState();
  const [tabComponents, setTabComponents] = useState([]);
  
 
  // useEffect(() => {
  //   setTabComponents([
  //     {
  //       label: "File Upload",
  //       component: (
  //        <UploadFile></UploadFile>
  //       ),
  //       icon: <FileUpload />,
  //     },
  //     {
  //       label: "Upload By Machine",
  //       component: <UploadByMachine></UploadByMachine>,
  //       icon: <SystemSecurityUpdate />,
  //     },
  //     {
  //       label: "Upload By Software",
  //       component: <UploadBySoftware></UploadBySoftware>,
  //       icon: <Upload />,
  //     },
  //   ]);
  // }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar/>
    {/* <BaseLayout tabComponents={tabComponents}> */}
    <br/>
    <Card style={{ display: "block"}}>
 
   <UploadFile
    ></UploadFile>
   
    </Card>
  
      <Footer />
    {/* </BaseLayout> */}
    </DashboardLayout>
  );
}
export default UploadAttendance;
