import { createSlice } from "@reduxjs/toolkit";

const promptSlice = createSlice({
    name : 'prompt',
    initialState : {
        isOpen : false,
        value: undefined,
        message: "",
        func:undefined
      
    },
    reducers : {
        setPromptOpen : (state,action) => {
            state.isOpen = true
            state.value= undefined
            state.message = action.payload.message
            state.func = action.payload.func
        },
        setPromptClose:(state,) => {
            state.isOpen= false;
        },
        setPromptValue:(state,action) => {
            state.value= action.payload;
        }
       
    }
});

export const {setPromptOpen,setPromptClose,setPromptValue} = promptSlice.actions;
export default promptSlice.reducer;