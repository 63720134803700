import { Card, IconButton, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import ApiCalls from "services/flick_erp_api_call";
import Info from "@mui/icons-material/Info";
import { ApprovalRounded, CrisisAlert } from "@mui/icons-material";
export function ApproveMissPunch({permissions,modulePermissions}) {
  useEffect(() => {
    getAttendanceConsiderList();
  }, []);
  const [attendanceConsiderData, setAttendanceConsiderData] = useState({
    columns: [
     
      {
        Header: "NAME",
        accessor: "employee_name",
      },
      {
        Header: "Duration",
        accessor: "duration",
        Cell: ({row}) => (<span>
            {row.original?.attendance_list?.duration}
        </span>)
      },
      {
        Header: "COMMENT",
        accessor: "comment",
      },

      {
        Header: "Action",
        accessor: "inout",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Approve">
              <IconButton
                aria-label="info"
                size="small"
                onClick={() => {
                    debugger
                    handleApproval(row.original?.id)
                }}
              >
                <ApprovalRounded/>
              </IconButton>
            </Tooltip>
          
            {/* <Tooltip title="Add Status">
              <IconButton
                aria-label="Add Status"
                size="small"
                onClick={() => {
                  setStatusDialog(true);
                }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip> */}
          </div>
        ),
      },
    ],
    rows: [],
  });
  async function handleApproval(id) {
    debugger
    var res = await ApiCalls.patchAttendanceCorrection(id,{status:"approved"});
    if(res.success){
        getAttendanceConsiderList();
    }
  }
  async function getAttendanceConsiderList() {
    var res =  await ApiCalls.getattendanceConsiderRequestList();

    if (res.success) {
        attendanceConsiderData.rows = res.data;
        setAttendanceConsiderData({...attendanceConsiderData})

    }
  }
  return (
    <DashboardLayout>
     <DashboardNavbar />
      <MDBox>
        <Card>
          <MDBox pt={3} pl={3} pb={4}>
            <MDTypography variant="h4">Approve MissPunch/Break</MDTypography>
          </MDBox>
          <MDBox>
            <DataTable table={attendanceConsiderData} entriesPerPage={false}></DataTable>
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
