import {
  AccountBalance,
  AccountCircle,
  Dialpad,
  Email,
  Inbox,
  Person,
  PhoneAndroid,
} from "@mui/icons-material";
import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MasterCard from "examples/Cards/MasterCard";
import { useEffect, useState } from "react";
import BankAccountCard from "./components/BankCard";
import ApiCalls from "services/flick_erp_api_call";
import {useForm} from 'react-hook-form';
import SalesTable from "examples/Tables/SalesTable";

export function BankDetails({ setCreateBankDialog }) {
  const { register, formState: { errors }, setValue , clearErrors  } = useForm();
  const [bankDetailPayload, setBankDetailPayload] = useState({});
  const [bankDetailsList, setBankDetailsList] = useState([]);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const loggedUser = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    // getEmployeeBankDetials();
  }, []);

  useEffect(() => {
    const fields = [
      'name_in_account',
      'bank_name',
      'bank_account',
      'ifsc_code',
      'email',
      'mobile',
    ];
    fields.forEach((field) => {
      register(field, { required: "required" });
    });
  }, [register]);

  const validation = (payload) => {
    const requiredFields = [
      'name_in_account',
      'bank_name',
      'bank_account',
      'ifsc_code',
      'email',
      'mobile',
    ];
  
    const errors = {};
  
    requiredFields.forEach((field) => {
      if (!payload[field]) {
        errors[field] = "This field is required";
      }
    });
    Object.keys(errors).forEach((field) => {
      setValue(field, '', { shouldValidate: true });
    });
  
    if (Object.keys(errors).length > 0) {
      return false;
    } else {
      return true;
    }
  }
  
  const clearAllFieldErrors = () => {
    const fieldsToClear = [
      'name_in_account',
      'bank_name',
      'bank_account',
      'ifsc_code',
      'email',
      'mobile',
    ];
  
    fieldsToClear.forEach((field) => {
      clearErrors(field);
    });
  };
  

  // async function getEmployeeBankDetials() {
  //   var res = await ApiCalls.getEmployeeBankDetails();
  //   if (res.success && res.code == 200) {
  //     setBankDetailsList(res?.data);
  //   }
  // }
  async function createBankDetail() {
    var payload = {
      ...bankDetailPayload,
      is_primary: true,
      org: loggedUser.org_id,
      // employee: data?.id,
    };

    const valid = validation(payload);

    if(valid){
    var res = await ApiCalls.createBankDetail(payload, false);
    if (res.status && res.code == 201) {
    }
    setCreateBankDialog(false);
    setBankDetailPayload({});
  }
  }
  return (
    <MDBox sx={{ padding: "20px" }}>
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                value={bankDetailPayload?.name_in_account ?? ""}
                fullWidth
                placeholder="Account Holder Name"
                onChange={(event) => {
                  setBankDetailPayload((state) => ({
                    ...state,
                    name_in_account: event.target.value,
                  }));
                  setValue('name_in_account', event.target.value, { shouldValidate: true });
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person />
                    </InputAdornment>
                  ),
                }}
                error={errors.name_in_account}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                //  variant="standard"
                value={bankDetailPayload?.bank_name ?? ""}
                placeholder="Bank Account Name"
                onChange={(event) => {
                  setBankDetailPayload((state) => ({
                    ...state,
                    bank_name: event.target.value,
                  }));
                  setValue('bank_name', event.target.value, { shouldValidate: true });
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountBalance />
                    </InputAdornment>
                  ),
                }}
                error={errors.bank_name}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="number"
                fullWidth
                //  variant="standard"
                value={bankDetailPayload?.bank_account ?? ""}
                onChange={(event) => {
                  setBankDetailPayload((state) => ({
                    ...state,
                    bank_account: event.target.value,
                  }));
                  setValue('bank_account' , event.target.value , {shouldValidate: true});
                }}
                placeholder="Account Number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Dialpad />
                    </InputAdornment>
                  ),
                }}
                error={errors.bank_account}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                //  variant="standard"
                value={bankDetailPayload?.ifsc_code ?? ""}
                onChange={(event) => {
                  setBankDetailPayload((state) => ({
                    ...state,
                    ifsc_code: event.target.value,
                  }));
                  setValue('ifsc_code' , event.target.value , {shouldValidate: true});
                }}
                placeholder="IFSC Code"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountBalance />
                    </InputAdornment>
                  ),
                }}
                error={errors.ifsc_code}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                //  variant="standard"
                value={bankDetailPayload?.email ?? ""}
                onChange={(event) => {
                  setBankDetailPayload((state) => ({
                    ...state,
                    email: event.target.value,
                  }));
                  setValue('email' , event.target.value , {shouldValidate: true});
                }}
                placeholder="Email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email />
                    </InputAdornment>
                  ),
                }}
                error={errors.email}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="number"
                fullWidth
                //  variant="standard"
                value={bankDetailPayload?.mobile ?? ""}
                onChange={(event) => {
                  setBankDetailPayload((state) => ({
                    ...state,
                    mobile: event.target.value,
                  }));
                  setValue('mobile' , event.target.value , {shouldValidate: true});
                }}
                placeholder="Phone Number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneAndroid />
                    </InputAdornment>
                  ),
                }}
                error={errors.mobile}
              ></TextField>
            </Grid>
          </Grid>

<MDBox display="flex" p={2} justifyContent="center">

<MDButton
            color="info"
            onClick={() => {
              createBankDetail();
            }}
          >
            Create
          </MDButton>
          <MDButton onClick={()=>{
            setCreateBankDialog(false)
          }}>Cancel</MDButton>


</MDBox>
        </>
  


    </MDBox>
  );
}
