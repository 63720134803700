import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
  Typography,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

function Booking(props) {
  const {
    defaultQue,
    setDefaultQue,
    questions,
    setQuestions,
    eventLink,
    setEventLink,
  } = props;
  // const initialQuestions = [
  //   {
  //     question: "Name,Email",
  //     answerType: "One Line",
  //     required: true,
  //     status: true,
  //   },
  //   {
  //     question: "Allow invitees to add guests",
  //     required: true,
  //     status: false,
  //     answerType: null,
  //   },
  // ];
  const [isAllowGuest, setIsAllowGuest] = useState(false);
  // const [defaultQue, setDefaultQue] = useState(initialQuestions);

  // const [questions, setQuestions] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [newQuestion, setNewQuestion] = useState({
    questionText: "",
    required: false,
    answerType: "",
    status: false,
  });

  const [triedSubmit, setTriedSubmit] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);

  const handleSwitchChange = () => {
    setDefaultQue((prevDefaultQue) => {
      return prevDefaultQue?.map((item) => {
        if (item.questionText === "Allow invitees to add guests") {
          return { ...item, status: !item.status };
        }
        return item;
      });
    });
  };

  const handleEditQuestion = async (index) => {
    setIsEditing(true);
    setSelectedQuestionIndex(index);
    // const newQue = await transformPaylaodToQuestions(questions);

    setNewQuestion(questions[index]);
    setOpen(true);
  };

  const handleDeleteQuestion = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };

  const MyUI = () => {
    return (
      <Box
        borderRadius={10}
        bgcolor="white"
        p={1}
        sx={{
          marginTop: 8,
          border: "1px solid",
          width: "380px",
          marginBottom: 8,
        }}
      >
        <List>
          <ListItem
            alignItems="center"
            sx={{
              backgroundColor: "#f3f6f9",
              borderRadius: 2,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "35px",
                marginTop: "4px",
              }}
            >
              <IconButton color="primary">
                <LockOutlinedIcon color="dark" />
              </IconButton>
            </ListItemIcon>
            <ListItemText primary="Name" sx={{ maxWidth: "300px" }} />
            {/* <ListItemIcon
              sx={{
                minWidth: "35px",
                marginTop: "4px",
              }}
            >
              <IconButton>
                <EditIcon color="dark" />
              </IconButton>
              <IconButton color="secondary">
                <DeleteIcon />
              </IconButton>
            </ListItemIcon> */}
          </ListItem>
          <Divider variant="inset" component="li" sx={{ margin: "4px" }} />
          <ListItem
            alignItems="center"
            sx={{
              backgroundColor: "#f3f6f9",
              borderRadius: 2,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "35px",
                marginTop: "4px",
              }}
            >
              <IconButton color="primary">
                <LockOutlinedIcon color="dark" />
              </IconButton>
            </ListItemIcon>
            <ListItemText primary="Email" sx={{ maxWidth: "300px" }} />
          </ListItem>
          <Divider variant="inset" component="li" sx={{ margin: "4px" }} />
          <ListItem
            alignItems="center"
            sx={{
              backgroundColor: "#f3f6f9",
              borderRadius: 2,
            }}
          >
            <ListItemIcon
              alignItems="flex-start"
              sx={{
                minWidth: "35px",
                marginTop: "4px",
              }}
            >
              <IconButton>
                <LockOutlinedIcon color="dark" />
              </IconButton>
            </ListItemIcon>
            <ListItemText
              primary="Allow invitees to add guests"
              sx={{ maxWidth: "300px" }}
            />
            <ListItemIcon
              sx={{
                minWidth: "35px",
                marginTop: "4px",
              }}
            >
              <Switch
                checked={
                  defaultQue?.find(
                    (item) =>
                      item?.questionText === "Allow invitees to add guests"
                  ).status
                }
                onChange={handleSwitchChange}
              />
            </ListItemIcon>
          </ListItem>
          {questions?.length > 0 && (
            <Divider variant="inset" component="li" sx={{ margin: "4px" }} />
          )}

          {questions?.map((item, index) => {
            return (
              <>
                <ListItem
                  alignItems="center"
                  sx={{
                    backgroundColor: "#f3f6f9",
                    borderRadius: 2,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "35px",
                      padding: 1,
                    }}
                  >
                    {`Q.${index + 1}`}
                  </ListItemIcon>
                  <ListItemText
                    primary={item?.questionText}
                    secondary={item?.answerType}
                    sx={{ maxWidth: "300px" }}
                  />
                  <ListItemIcon
                    sx={{
                      minWidth: "35px",
                    }}
                  >
                    <IconButton onClick={() => handleEditQuestion(index)}>
                      <EditIcon color="dark" />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteQuestion(index)}>
                      <DeleteIcon color="dark" />
                    </IconButton>
                  </ListItemIcon>
                </ListItem>
                <Divider
                  variant="inset"
                  component="li"
                  sx={{ margin: "4px" }}
                />
              </>
            );
          })}
        </List>
      </Box>
    );
  };

  // const checkOptionPresent = () => {
  //   const requiresOptions = ["Checkboxes", "Radio Button", "Dropdown"].includes(
  //     newQuestion?.answerType
  //   );
  //   return requiresOptions ?? false;
  // };

  // const optionError =
  //   triedSubmit &&
  //   checkOptionPresent() &&
  //   Array.isArray(newQuestion.options) &&
  //   (newQuestion?.options?.length === 0 ||
  //     newQuestion?.options?.some(
  //       (option) => option?.optionText?.trim() === ""
  //     ));

  // function transformQuestionToPayload(oldQuestion) {
  //   const { questionText, required, answerType, status, options } = oldQuestion;

  //   if (
  //     answerType === "Radio Button" ||
  //     answerType === "Checkboxes" ||
  //     answerType === "Dropdown"
  //   ) {
  //     const selectedOption = options.find((option) => option.selected);

  //     return {
  //       status,
  //       required,
  //       answerType,
  //       questionText,
  //       options: {
  //         optionsValue: options.map((option) => option.optionText),
  //         selected: selectedOption
  //           ? selectedOption?.optionText
  //           : options[0].optionText,
  //       },
  //     };
  //   } else {
  //     return {
  //       status,
  //       required,
  //       answerType: "One Line",
  //       questionText,
  //       options: [],
  //     };
  //   }
  // }

  // function transformPaylaodToQuestions(questionsArray) {
  //   return questionsArray?.map((question) => {
  //     if (
  //       question.answerType === "Checkboxes" ||
  //       question.answerType === "Radio Button" ||
  //       question.answerType === "Dropdown"
  //     ) {
  //       if (question.options) {
  //         return {
  //           status: question.status,
  //           required: question.required,
  //           answerType: question.answerType,
  //           questionText: question.questionText,
  //           options: question?.options?.optionsValue?.map((option) => ({
  //             optionText: option,
  //             selected: option === question.options.selected || false,
  //           })),
  //         };
  //       } else {
  //         return {
  //           status: question.status,
  //           required: question.required,
  //           answerType: "One Line",
  //           questionText: question.questionText,
  //           options: [],
  //         };
  //       }
  //     }
  //   });
  // }

  const handleAddQuestion = () => {
    setIsEditing(false);
    setOpen(true);
    setNewQuestion({
      questionText: "",
      required: false,
      answerType: "",
      status: false,
    });

    setTriedSubmit(false);
  };

  const handleSaveQuestion = async () => {
    setTriedSubmit(true);

    if (
      answerTypeError ||
      newQuestion.questionText.trim().length === 0 ||
      newQuestion.questionText.trim().toLowerCase() === "email"
    ) {
      return;
    }

    if (isEditing && selectedQuestionIndex !== null) {
      // Check for duplicates in edit mode
      const updatedQuestions = [...questions];
      const questionText = newQuestion.questionText.trim();
      const existingQuestion = updatedQuestions.find(
        (question, index) =>
          index !== selectedQuestionIndex &&
          question.questionText.trim() === questionText
      );

      if (existingQuestion) {
        // Show error for duplicate question text
        setNewQuestion((prevQuestion) => ({
          ...prevQuestion,
          questionText: questionText,
        }));
        return;
      }

      updatedQuestions[selectedQuestionIndex] = newQuestion;
      // transformQuestionToPayload(newQuestion);
      setQuestions(updatedQuestions);
    } else {
      const questionText = newQuestion.questionText.trim();
      if (
        questions?.some(
          (question) => question.questionText.trim() === questionText
        )
      ) {
        setNewQuestion((prevQuestion) => ({
          ...prevQuestion,
          questionText: questionText,
        }));
        return;
      }
      if (Array.isArray(questions)) {
        setQuestions([...questions, newQuestion]);
      } else {
        setQuestions([newQuestion]);
      }
    }

    setOpen(false);
    setIsEditing(false);
    setSelectedQuestionIndex(null);
    setNewQuestion({
      questionText: "",
      required: false,
      answerType: "",
      status: false,
    });
  };

  // const handleAddOption = () => {
  //   setNewQuestion((prevQuestion) => {
  //     if (
  //       prevQuestion.answerType === "Checkboxes" ||
  //       prevQuestion.answerType === "Radio Button" ||
  //       prevQuestion.answerType === "Dropdown"
  //     ) {
  //       const updatedOptions = [
  //         ...prevQuestion.options,
  //         { optionText: "", selected: false },
  //       ];
  //       return { ...prevQuestion, options: updatedOptions };
  //     }
  //     return prevQuestion;
  //   });
  // };

  // const handleRemoveOption = (index) => {
  //   setNewQuestion((prevQuestion) => {
  //     if (
  //       prevQuestion.answerType === "Checkboxes" ||
  //       prevQuestion.answerType === "Radio Button" ||
  //       prevQuestion.answerType === "Dropdown"
  //     ) {
  //       const updatedOptions = [...prevQuestion.options];
  //       updatedOptions.splice(index, 1);
  //       return { ...prevQuestion, options: updatedOptions };
  //     }
  //     return prevQuestion;
  //   });
  // };

  // const handleToggleOption = (index) => {
  //   setNewQuestion((prevQuestion) => {
  //     if (
  //       prevQuestion.answerType === "Checkboxes" ||
  //       prevQuestion.answerType === "Radio Button" ||
  //       prevQuestion.answerType === "Dropdown"
  //     ) {
  //       const updatedOptions = prevQuestion.options.map((option, i) => ({
  //         ...option,
  //         selected: i === index,
  //       }));
  //       return { ...prevQuestion, options: updatedOptions };
  //     }
  //     return prevQuestion;
  //   });
  // };

  // const renderOptions = () => {
  //   return (
  //     Array.isArray(newQuestion.options) &&
  //     newQuestion?.options?.map((option, index) => (
  //       <div key={index} style={{ display: "flex", alignItems: "center" }}>
  //         <TextField
  //           label="Option"
  //           fullWidth
  //           margin="normal"
  //           value={option.optionText}
  //           onChange={(e) => handleOptionTextChange(index, e.target.value)}
  //           sx={{
  //             marginY: 2,
  //             flex: 1, // Expand to fill available space
  //           }}
  //         />
  //         <FormControlLabel
  //           control={
  //             <Checkbox
  //               checked={option.selected}
  //               onChange={() => handleToggleOption(index)}
  //             />
  //           }
  //           label="Selected"
  //           sx={{
  //             marginY: 2,
  //             marginX: 2,
  //           }}
  //         />
  //         {newQuestion.options.length > 1 && (
  //           <IconButton
  //             onClick={() => handleRemoveOption(index)}
  //             color="secondary"
  //             sx={{
  //               alignSelf: "center", // Align to center vertically
  //             }}
  //           >
  //             <DeleteIcon />
  //           </IconButton>
  //         )}
  //       </div>
  //     ))
  //   );
  // };

  // const handleOptionTextChange = (index, value) => {
  //   setNewQuestion((prevQuestion) => {
  //     const updatedOptions = [...prevQuestion.options];
  //     updatedOptions[index].optionText = value;
  //     return { ...prevQuestion, options: updatedOptions };
  //   });
  // };

  const answerTypeError =
    triedSubmit && newQuestion?.answerType?.toString()?.trim().length === 0;

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Booking form
      </MDTypography>

      <MDInput
        label="Event Link"
        value={eventLink}
        variant="standard"
        onChange={(e) => setEventLink(e.target.value)}
        sx={{
          marginY: 2,
        }}
      />
      <MDTypography variant="h6">Invitee questions</MDTypography>

      {/* {questions.map((question) => (
        <Box
          key={question.id}
          display="flex"
          alignItems="center"
          borderRadius={4}
          p={2}
          mb={1}
        >
          <div>{question.question}</div>
          {question.required ? " (Required)" : null}
        </Box>
      ))} */}
      <MyUI />
      <MDButton
        variant="gradient"
        color="dark"
        size="small"
        onClick={handleAddQuestion}
      >
        Add Question
      </MDButton>

      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "aliceBlue",
            borderRadius: "8px",
            width: "450px",
            maxWidth: "none",
            padding: "20px",
          },
        }}
        open={isOpen}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>Edit Form</DialogTitle>
        <DialogContent>
          <TextField
            label="Question"
            fullWidth
            margin="normal"
            value={newQuestion.questionText}
            onChange={(e) =>
              setNewQuestion({ ...newQuestion, questionText: e.target.value })
            }
            error={
              triedSubmit &&
              newQuestion?.questionText?.toString()?.trim().length === 0
            }
            helperText={
              triedSubmit &&
              newQuestion?.questionText?.toString()?.trim().length === 0
                ? "Question cannot be empty"
                : ""
            }
            sx={{
              marginY: 2,
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={newQuestion.required}
                onChange={(e) =>
                  setNewQuestion({
                    ...newQuestion,
                    required: e.target.checked,
                  })
                }
              />
            }
            label="Required"
            sx={{
              marginY: 2,
            }}
          />
          <Autocomplete
            id="autocomplete-options"
            options={[
              {
                lable: "Text",
                value: "text",
              },
              {
                lable: "Text Area",
                value: "textarea",
              },
              {
                lable: "Number",
                value: "number",
              },
            ]}
            getOptionLabel={(option) => option.lable}
            disableClearable={true}
            onChange={(_, newValue) => {
              setNewQuestion({
                ...newQuestion,
                answerType: newValue.value,
              });
            }}
            sx={{
              marginY: 2,
            }}
            renderInput={(params) => (
              <>
                <TextField {...params} label="Answer Type" />
                {answerTypeError && (
                  <FormHelperText
                    sx={{
                      color: "#f44336!important",
                    }}
                  >
                    Answer type can't be empty.
                  </FormHelperText>
                )}
              </>
            )}
          />
          {/* {["Checkboxes", "Radio Button", "Dropdown"].includes(
            newQuestion.answerType
          ) && (
            <>
              <MDButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={handleAddOption}
              >
                Add Option
              </MDButton>
              {renderOptions()}
            </>
          )} */}
          <FormControlLabel
            sx={{
              marginY: 2,
            }}
            control={
              <Switch
                checked={newQuestion.status}
                onChange={(e) =>
                  setNewQuestion({ ...newQuestion, status: e.target.checked })
                }
              />
            }
            label="Status"
          />
        </DialogContent>

        <DialogActions>
          <MDButton
            variant="gradient"
            color="dark"
            size="small"
            onClick={handleSaveQuestion}
          >
            Save and close
          </MDButton>
        </DialogActions>
      </Dialog>
    </MDBox>
  );
}

export default Booking;
