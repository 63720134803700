/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

import { Box, Button, IconButton, TextField, Typography } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import WeeklyTimeRangePicker from "../weeklyTimepicker";

function Schedulling({
  formData,
  duration,
  setDuration,
  custom,
  setCustom,
  scheduleSetting,
  setScheduleSetting,
  daysOfWeek,
  defaultTimeRange,
  defaultUnavailableTime,
  timeRanges,
  setTimeRanges,
  initialData,
  isEventEdit,
}) {
  const { formField, values, errors, touched } = formData;
  const { address1, address2, city, zip } = formField;
  const {
    address1: address1V,
    address2: address2V,
    city: cityV,
    zip: zipV,
  } = values;

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Scheduling settings
      </MDTypography>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <MDTypography variant="h6">Duration</MDTypography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Autocomplete
                size="small"
                options={["15", "30", "45", "Custom"]}
                value={duration}
                onChange={(event, newValue) => {
                  setDuration(newValue);
                }}
                sx={{
                  width: "110px",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // size="small"
                  />
                )}
              />
              {duration === "Custom" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",

                    alignItems: "center",
                  }}
                >
                  <TextField
                    variant="outlined"
                    type="number"
                    size="small"
                    sx={{
                      width: 50,
                      marginRight: 2,
                      marginLeft: 2,
                      padding: 0,
                    }}
                    value={custom.customDuration}
                    onChange={(event) => {
                      setCustom({
                        ...custom,
                        customDuration: event.target.value,
                      });
                    }}
                    InputProps={{
                      inputProps: { min: 0 }, // Set a minimum value if needed
                    }}
                  />
                  <Autocomplete
                    options={["min", "hrs"]}
                    size="small"
                    value={custom.customTimeIn}
                    onChange={(event, newValue) => {
                      setCustom({
                        ...custom,
                        customTimeIn: newValue,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}

                        // fullWidth
                        // error={!!sourceError}
                        // helperText={sourceError}
                      />
                    )}
                  />
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDTypography variant="h6">Date Range</MDTypography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <TextField
                variant="outlined"
                type="number"
                size="small"
                sx={{
                  width: 50,
                  marginRight: 2,
                }}
                value={scheduleSetting.dateRange}
                onChange={(event) => {
                  setScheduleSetting({
                    ...scheduleSetting,
                    dateRange: event.target.value,
                  });
                }}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
              <Autocomplete
                options={["calendar days", "weekdays"]}
                value={scheduleSetting.days}
                size="small"
                onChange={(event, newValue) => {
                  setScheduleSetting({
                    ...scheduleSetting,
                    days: newValue,
                  });
                }}
                sx={{
                  width: "150px",
                  marginRight: 1,
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      height: 30,
                    }}
                  />
                )}
              />
              <MDTypography variant="subtitle2">into the future</MDTypography>
            </div>
          </Grid>
        </Grid>
        <div style={{ marginTop: "10px" }}>
          <MDTypography variant="h5" fontWeight="bold">
            Availablity Hours
          </MDTypography>
          <WeeklyTimeRangePicker
            daysOfWeek={daysOfWeek}
            defaultTimeRange={defaultTimeRange}
            defaultUnavailableTime={defaultUnavailableTime}
            timeRanges={timeRanges}
            setTimeRanges={setTimeRanges}
            initialData={initialData}
            isEventEdit={isEventEdit}
          />
        </div>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for Address
Scheduling.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Schedulling;
