import { createSlice } from "@reduxjs/toolkit";

const screeningSlice = createSlice({
  name: "screening",
  initialState: {
    screening: [],
    options : []
},
  reducers: {
    setScreeningLists: (state, action) => {
      state.screening = action.payload.screening;
    }, 
    optionValues : (state,action) => {
      state.options = action.payload;
    }   
  },
});

export const { setScreeningLists,optionValues } = screeningSlice.actions;
export default screeningSlice.reducer;
