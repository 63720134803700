import {
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import ApiCalls from "services/flick_erp_api_call";
import Header from "layouts/employees/employee_view/components/Header";
import MDButton from "components/MDButton";
import { SelectEmployee } from "components/SelectEmployee";
import { Delete, DeleteOutline } from "@mui/icons-material";

export function ReportingManager({ data, permissions, modulePermissions, reloadCallback}) {

const [manager,setManager] = useState();
const [hr,setHr] = useState();

  const [selectedManagerId, setManagerId] = useState(null);
  const [selectedHrManagerId, setHrManagerId] = useState(null);

  const [addManagerDialog, setAddManagerDialog] = useState(false);
  const [addManagerError, setAddManagerError] = useState("");
  const [addHrManagerError, setHrAddManagerError] = useState("");

  useEffect(() => {
    getManager();
    getHr()
  }, [data]);
  // get employee Manager
  async function getManager() {
   if(data?.manager){
    var res = await ApiCalls.getEmployee(data?.manager);
    if (res.success && res.code == 200) {
      setManager(res.data);
    } else {
    }
   }else {
    setManager();
   }
  }
  // get Hr
  async function getHr() {

   if(data?.hr_manager) {
    var res = await ApiCalls.getEmployee(data?.hr_manager);
    if (res.success && res.code == 200) {
      setHr(res.data);
    } else {
    }
   }else {
    setHr()
   }
  }
  // post manager
  async function addManager(isHr) {
    if ( isHr ? selectedHrManagerId != null :  selectedManagerId != null ) {
    isHr ? setHrAddManagerError("") :  setAddManagerError("");
      var managerObj = {
        manager: selectedManagerId ,
      };
      var hrObj = {
        hr_manager: selectedHrManagerId ,
      };
      var res = await ApiCalls.editEmployee(data.id, isHr ?  hrObj : managerObj, false);
      if (res.success) {
        setAddManagerDialog(false);
        reloadCallback("")
      }
    } else {
      isHr ? setHrAddManagerError("Please select a Hr manager") :
      setAddManagerError("Please select a manager");
    }
  }
  // get all Employees

  async function deleteManager(isHr) {
    var managerObj = {
      manager:  null,
    };
    var hrManagerObj = {
      hr_manager:  null,
    };
    var res = await ApiCalls.editEmployee(data.id, isHr ? hrManagerObj :  managerObj, false);
    if (res.success) {
      reloadCallback("")
      // getManagers();
    }
  }
  const managerComponent = (manager, isManager, isHr) => {
    return (<MDBox p={2} width="80%">
    <Grid
      container
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Grid item>
        <Header
          data={manager}
          is_manager={isManager}
          is_hr={isHr}
        ></Header>
      </Grid>
      <Grid item>
        
        <Delete
          style={{ cursor: "pointer" }}
          onClick={() => {
             deleteManager( isHr);
          }}
        ></Delete>
      </Grid>
    </Grid>
  </MDBox>)
  }
  return (
    <Card>
      <MDBox p={3} display="flex" justifyContent="space-between">
        <MDTypography variant="h4">Managers</MDTypography>
        {(permissions.includes(
          modulePermissions.reporting_structure_create_permission 
        
        )   && (data?.manager == null || data?.hr_manager == null)) && (
          <Fab
            color="info"
            onClick={() => {
              setAddManagerDialog(true);
            }}
          >
            <MDTypography color="white">+</MDTypography>
          </Fab>
        )}
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        {/* {managers?.map(function (e) {
          return (
            <>
              { */}
             {hr &&( managerComponent(hr,false,true))}
              {manager &&( managerComponent(manager,true,false))}
              {/* }
            </>
          );
        })} */}
      </MDBox>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "white",
            boxShadow: "none",
            width: "500px",
            // height: "250px", 
            maxWidth: "none",
            borderRadius: "10px",
            paddingBottom:"20px",
            // alignContent: "center",
            // justifyContent: "center",
            // alignItems: "center",

            // bgColor :"info"
          },
        }}
        open={addManagerDialog}
        onClose={() => {
          setAddManagerDialog(false);
          setAddManagerError("");
        }}
      >
        <DialogTitle
          style={{
            width: "100%",
            padding: "20px",
            borderBottom: "10px solid ",
            backgroundColor: "#007bed",
            color: "white",
          }}
        >
          <Typography sx={{ color: "white", fontWeight: "Medium" }}>
            Add Manager
          </Typography>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "20px",
            paddingTop:"0px"
            //  alignContent: "center",
            // justifyContent: "center",
            // padding: "40px",
          }}
        >
         { data?.manager == null &&( <>
            <Typography textAlign={"left"}>Manager</Typography>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item>
                <SelectEmployee
                  label="Select Manager"
                  onSelected={(e) => {
                    setManagerId(e);
                    setAddManagerError("");
                  }}
                ></SelectEmployee>
              </Grid>
              <Grid item>
                <MDButton
                  variant="gradient"
                  color="info"
                  sx={{ height: "40px", width: "0px" }}
                  onClick={() => {
                    addManager(false);
                    // setAddManager(true)
                    // setCreateDialog(true);
                  }}
                >
                  <MDTypography variant="h6" color="white">
                    Add
                  </MDTypography>
                </MDButton>
              </Grid>
            </Grid>
            {addManagerError && (
              <MDBox mt={1} color="error">
                {addManagerError}
              </MDBox>
            )}</>)}
          {data?.hr_manager == null &&(  <>
          <Typography textAlign={"left"}>Hr Manager</Typography>
          <Grid container spacing={1} alignItems="flex-end">
              <Grid item>
                <SelectEmployee
                  label="Select HR Manager"
                  onSelected={(e) => {
                    setHrManagerId(e);
                    setHrAddManagerError("");
                    // filterByUser(e);
                  }}
                ></SelectEmployee>
              </Grid>
              <Grid item>
                <MDButton
                  variant="gradient"
                  color="info"
                  sx={{ height: "40px", width: "0px" }}
                  onClick={() => {
                    addManager(true);
                    // setAddManager(true)
                    // setCreateDialog(true);
                  }}
                >
                  <MDTypography variant="h6" color="white">
                    Add
                  </MDTypography>
                </MDButton>
              </Grid>
            </Grid>    {addHrManagerError && (
              <MDBox mt={1} color="error">
                {addHrManagerError}
              </MDBox>
            )}</>)}
        </DialogContent>
      </Dialog>
    </Card>
  );
 
}
