import { Card, Dialog, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import ApiCalls from "services/flick_erp_api_call";
import InfoIcon from "@mui/icons-material/Info";
import MDBox from "components/MDBox";
import SalesTable from "examples/Tables/SalesTable";
import MDTypography from "components/MDTypography";

export function Salary() {
    const [salaryDetailDialog, setSalaryDetailDialog] = useState(false);
    const [salaryHeadDetails, setSalaryHeadDetails] = useState([]);

    useEffect(()=>{
        getSalary();
      },[])
      async function getSalary() {
        var res = await ApiCalls.getSalary();
        if (res.success && res.code == 200) {
            salaryDetails.rows = res.data
            setSalaryDetails({...salaryDetails})
        }
      }
      const [salaryDetails, setSalaryDetails] = useState({
        columns: [
          {
            Header: "NAME",
            accessor: "name",
            Cell: ({ row }) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{}}>{row.original.employee.user.firstname}</span>
                <span style={{ marginLeft: "0.2rem" }}>
                  {row.original.employee.user.lastname}
                </span>
              </div>
            ),
          },
          {
            Header: "Total Working Days",
            accessor: "total_days",
          },
          {
            Header: "Paid Leave",
            accessor: "paid_leave",
          },
          {
            Header: "Unpaid Leave",
            accessor: "unpaid_leave",
          },
          {
            Header: "Total Deductions",
            accessor: "total_deductions",
          },
          {
            Header: "Net Pay",
            accessor: "net_pay",
          },    
          {
            Header: "Action",
            accessor: "inout",
            Cell: ({ row }) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Tooltip title="Info">
                  <IconButton
                    aria-label="info"
                    size="small"
                    onClick={() => {
                        handleView(row.original.salary_lines);
                    }}
                  >
                    <InfoIcon></InfoIcon>
                  </IconButton>
                </Tooltip>
              </div>
            ),
          },
        ],
        rows: [],
      });
      const handleView = (data) => {
        setSalaryHeadDetails(
            data?.map((e) => ({
                description : e?.description,
              amount:e?.amount,
            
              }))
        );
        setSalaryDetailDialog(true);
      };

    return (<DashboardLayout>
        <Card>
        <MDBox pt={3} pl={3} pb={4}>
            <MDTypography variant="h4">Salary</MDTypography>
          </MDBox>
          <MDBox>
          <DataTable
              table={salaryDetails}
              entriesPerPage={{
                defaultValue: 100,
                entries: [5, 10, 15, 20, 25, 30],
              }}
              showTotalEntries={true}
              isSorted={false}
            ></DataTable>
          </MDBox>
        </Card>
                <Dialog
        PaperProps={{
          style: {
            backgroundColor: "white",
            boxShadow: "none",
            width: "auto",
            maxWidth: "none",
            borderRadius: "10px",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        open={salaryDetailDialog}
        onClose={() => setSalaryDetailDialog(false)}
      >
        <DialogTitle
          style={{
            width: "100%",
            padding: "20px",
            borderBottom: "10px solid ",
            backgroundColor: "#007bed",
            color: "white",
          }}
        >
          <Typography sx={{ color: "white", fontWeight: "Medium" }}>
            Salary Details
          </Typography>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "4px",
          }}
        >
          <MDBox align="center" p={2}>
            <SalesTable title="" rows={salaryHeadDetails} />
          </MDBox>
        </DialogContent>
      </Dialog>
    </DashboardLayout>);
}