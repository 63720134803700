import {
    Autocomplete,
    Card,
    Button,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import { Radio } from "@mui/material";
import { Radio, RadioGroup, FormControlLabel } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TextEditor from './Editor/index';
import { useDispatch, useSelector } from "react-redux";
import { dataList } from "./formBuilderSlice";

function Options(props) {
  
    const dispatch = useDispatch();
    const data = useSelector((state) => state.FormBuilderData.form_data);
    const [selectedOption, setSelectedOption] = useState(""); // State to track the selected radio option
    const [externalLink, setExternalLink] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedPage, setSelectedPage] = useState(null);
    const [editorValue, setEditorValue] = useState('');
    const [selectedType, setSelectedType] = useState("");
    const [title, setTitle] = useState(""); // State variable to store the title value

    const handleTitleChange = (event) => {
        const newValue = event.target.value;
        setTitle(newValue);
    };

    const typeList = [
        { name: "yogesh@geitpl.com" },
        { name: "dharmendra@geitpl.com" },
        { name: "hr@geitpl.com" },
        { name: "Shweta@geitpl.com" },
        { name: "chandan@geitpl.com" },
        { name: "nikita@geitpl.com" },
    ];

    const pageOptions = [
        { name: "Page 1" },
        { name: "Page 2" },
        { name: "Page 3" },
        { name: "Page 4" },
        // Add more pages as needed
    ];

    useEffect(() => {
    }, [data])


    useEffect(() =>{
     if(props.response !== null){
        setTitle(props.response?.name);
        setSelectedType(props.response?.config?.form_data?.notification_to);
        setExternalLink(props.response?.config?.form_data?.data?.link);
        setSelectedPage(props.response?.config?.form_data?.data?.selected_page);
        setEditorValue(props.response?.config?.form_data?.data?.message);
        setSelectedOption(props.response?.config?.form_data?.type);
     }else{
        setTitle("");
        setSelectedType("");
        setExternalLink("");
        setSelectedPage("");
        setEditorValue("");
        setSelectedOption("");
     }
    },[props])

    useEffect(() => {
        const form_data = {
            form_title: title || '',
            selected_submission_type: selectedOption || '',
            notification_to: selectedType || '',
            type: selectedOption === 'message' ? 'message' : 'redirect',
            data: selectedOption === 'message' ? { message: editorValue || '' } : { link: externalLink || '', selected_page: selectedPage || '' },
          };
          dispatch(dataList({ form_data: form_data}));
    }, [selectedOption, editorValue, selectedType, externalLink , title , selectedPage])

    const filterOptions = (options, state) => {
        return options.filter((option) =>
            option.name.toLowerCase().includes(state.inputValue.toLowerCase())
        );
    };

    const handleTypeChange = (event, newValue) => {
        if (newValue) {
            setSelectedType(newValue.name);
        } else {
            setSelectedType(null); // No selection
        }
    };
    return (
        <Card>
            <MDBox component="form" pb={3} px={3}>
                <MDBox mt={6} mb={1}>
                    <MDTypography variant="h5">What should happen after someone submits this form?</MDTypography>
                </MDBox>
                <Grid item xs={12} md={6} sx={{ m: 3, minWidth: "5rem" }}>
                    <MDBox
                        borderRadius="lg"
                        p={3}
                        sx={{
                            border: ({ borders: { borderWidth, borderColor } }) =>
                                `${borderWidth[1]} solid ${borderColor}`,
                        }}
                    >
                        <MDTypography variant="body2" fontWeight="regular">
                            What should happen after visitors submits this form?
                        </MDTypography>

                        <Grid item>
                            <RadioGroup
                                value={selectedOption}
                                onChange={(e) => setSelectedOption(e.target.value)}
                            >
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <FormControlLabel
                                            value="message"
                                            control={<Radio color="primary" />}
                                            label="Display a thank you message"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            value="redirect"
                                            control={<Radio color="primary" />}
                                            label="Redirect to another page"
                                        />
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </Grid>
                        {/* </MDBox> */}
                        {selectedOption === "message" && (
                            <Grid item xs={12} sx={{ p: 2 }}>
                                <Typography variant="h6" fontWeight="regular">
                                    Enter Thank You Message:
                                </Typography>
                                <TextEditor
                                    value={editorValue}
                                    onChange={(value) => {
                                        setEditorValue(value)
                                    }}
                                />
                            </Grid>
                        )}

                        {selectedOption === "redirect" && (
                            <Grid item xs={12} sx={{ p: 2 }}>

                                <Autocomplete
                                    id="page-select"
                                    options={pageOptions}
                                    value={selectedPage}
                                    onChange={(event, newValue) => setSelectedPage(newValue)}
                                    inputValue={searchTerm}
                                    onInputChange={(event, newInputValue) =>
                                        setSearchTerm(newInputValue)
                                    }
                                    filterOptions={filterOptions}
                                    getOptionLabel={(option) => {
                                        if (typeof option === 'string') {
                                            return option;
                                        } else if (option.name) {
                                            return option.name;
                                        } else {
                                            return '';
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            name="page-select"
                                            label="Select a Page"
                                            variant="standard"
                                            required
                                        />
                                    )}
                                />
                                <TextField
                                    label="Add External Link"
                                    variant="standard"
                                    fullWidth
                                    value={externalLink}
                                    onChange={(e) => setExternalLink(e.target.value)}
                                    margin="normal"
                                />
                                <Button variant="outlined" style={{ color: "black" }}>
                                    Add Link
                                </Button>
                            </Grid>
                        )}

                    </MDBox>
                </Grid>
                <MDBox mt={6} mb={1}>
                    <MDTypography variant="h5">Send submission notifications to</MDTypography>
                </MDBox>
                <Grid item xs={4}>
                    <Autocomplete
                        id="Type"
                        options={typeList}
                        value={selectedType} 
                        getOptionLabel={(option) => {
                            if (typeof option === 'string') {
                                return option;
                            } else if (option.name) {
                                return option.name;
                            } else {
                                return '';
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                name="Type"
                                label="Select Type"
                                variant="standard"
                                required
                            />
                        )}
                        onChange={handleTypeChange}
                    />
                </Grid>
                <MDBox mt={6} mb={1}>
                    <MDTypography variant="h5">Title of form</MDTypography>
                </MDBox>
                <Grid item xs={4}>
                    <TextField
                        id="outlined-basic"
                        label="Title"
                        variant="outlined"
                        style={{ marginTop: "8px", width: "50%" }}
                        value={title} 
                        onChange={handleTitleChange} 
                    />
                </Grid>
            </MDBox>
        </Card>
    );
}
export default Options;
