import { createSlice } from "@reduxjs/toolkit";

const formFieldsSlice = createSlice({
    name : 'fieldsArray',
    initialState : {
        fieldsArray : [],
        FormData:[],
        isFlagged: false,
    },
    reducers : {
        fieldsList : (state,action) => {
            state.fieldsArray = action.payload.fieldsArray;
        },
        DataList : (state,action) => {
            state.FormData = action.payload.FormData;
        },
        setFlag: (state, action) => {
            state.isFlagged = action.payload;
        },
    }
});

export const {fieldsList , DataList , setFlag} = formFieldsSlice.actions;
// export const {DataList} = formFieldsSlice.actions;

export default formFieldsSlice.reducer;