import {
  configureStore,
  //  getDefaultMiddleware
} from "@reduxjs/toolkit";
import orgRegSliceReducer from "./layouts/authentication/org-sign-up/orgRegSlice";
import userReducer from "./layouts/authentication/sign-in/illustration/signInSlice";
import employeeReducer from "./layouts/employees/employeesSlice";
import jobAdvertisementReducer from "./layouts/recruitement/currentOpenings/jobAdvertisementSlice";
import screeningReducer from "./layouts/recruitement/screening/screeningSlice";
import candidatesReducer from "./layouts/recruitement/candidates/candidatesSlice";
import interviewsReducer from "./layouts/recruitement/interview/interviewsSlice";
import textViewBoxReducer from "./components/TextViewEditorBox/textViewEditorBoxSlice";
import formFieldsReducer from "./layouts/marketing/FormBuilder/createForm/formFieldsSlice";
import FormBuilderReducer from "./layouts/marketing/FormBuilder/options/formBuilderSlice";
import styleReducer from "./layouts/marketing/FormBuilder/styleAndPreview/StyleSlice";
import thunk from "redux-thunk";
import toastAlertReducer from "./components/Toast/toastAlertSlice";
import loaderReducer from "./components/Loader/loaderSlice";
import promptReducer from "./components/Prompt/promptSlice";

import newEvents from "./layouts/marketing/newEvent/newEventSlice";

export const store = configureStore({
  reducer: {
    orgRegistration: orgRegSliceReducer,
    user: userReducer,
    employee: employeeReducer,
    jobAdvertisement: jobAdvertisementReducer,
    screening: screeningReducer,
    candidates: candidatesReducer,
    interviews: interviewsReducer,
    textViewBox: textViewBoxReducer,
    toastAlert: toastAlertReducer,
    loader: loaderReducer,
    fieldsArray: formFieldsReducer,
    FormBuilderData: FormBuilderReducer,
    styleData: styleReducer,
    newEvents: newEvents,
    prompt: promptReducer
  },
  // middleware: [...getDefaultMiddleware(), thunk],
});
