import { createSlice } from "@reduxjs/toolkit";

const loaderSlice = createSlice({
    name : 'loader',
    initialState : {
        isOpen : false,
      
    },
    reducers : {
        setLoaderOpen : (state,) => {
            state.isOpen = true
        },
        setLoaderClose:(state,) => {
            state.isOpen= false;
        }
       
    }
});

export const {setLoaderOpen,setLoaderClose} = loaderSlice.actions;
export default loaderSlice.reducer;