/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
// import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import { Card } from "@mui/material";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import bgImage from "assets/images/illustrations/illustration-reset.jpg";
import Footer from "examples/Footer";
import { Link, useNavigate } from "react-router-dom";
import ApiCalls from "services/flick_erp_api_call";
import { useDispatch } from "react-redux";
import {selectSignIn,signIn, setEmail, setPassword,userDataAfterSignIn} from "./signInSlice";
import { useSelector } from "react-redux";
import { setToastOpen } from "components/Toast/toastAlertSlice";

function Illustration() {
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [token, settoken] = useState("");
  const [permissions, setPermissions] = useState("");
  const [emailValid, setEmailValid] = useState(null);
  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(null);
  const [showAlert, setShowAlert] = useState(null);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const navigate = useNavigate();
  // const selectSignIn = useSelector(selectSignIn)


  const [currentUser, setCurrentUser] = useState(false);


  const dispatch = useDispatch();
  async function submit() {
    if (validateEmail(email) && validatePassword(password)) {
      var res = await ApiCalls.logIn({
        email: email,
        password: password
      });
      if (res.success && res.code == 201) {
      

        setCurrentUser(true);
        localStorage.setItem("user", JSON.stringify(res.data));
         dispatch(userDataAfterSignIn(res.data));

        dispatch(signIn(res.data));

        navigate("/dashboards/dashboard",{ replace: true })
        // navigate("/invoicing/pricing_page")
      } else if (res.success == false && res.code == 400) {


      } else {
        
       
      }
    } else {
      setShowAlert("Please enter a Valid Email & Password");
    }
  }
  
  const validateEmail = (_email) => {
    if (
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(_email) &&
      _email !== ""
    ) {
      setEmailValid(true);
      return true;
    }
    setEmailValid(false);
    return false;
  };

  const validatePassword = (password) => {
    if (password !== "" && password.length >= 3) {
      setPasswordValid(true);
      return true;
    }
    setPasswordValid(false);
    return false;
  };
  const alertContent = (name) => (
    <MDTypography variant="body2" color="white">
      {name}
    </MDTypography>
  );

  // const selectSignIn_ = useSelector(selectSignIn)

  const emp = useSelector((state)=>state.user);


  return (
    <div>
      <IllustrationLayout >
        <Card >
          {showAlert && (
            <MDAlert
              color="primary"
              dismissible
              onClick={() => {
                setShowAlert(null);
              }}
            >
              {alertContent(showAlert)}
            </MDAlert>
          )}
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"

            coloredShadow="success"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Sign In
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              Enter your email and password to Sign in
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput
                  error={emailValid == null ? false : !emailValid}
                  data-testid="email"
                  type="email"
                  id="email"
                  label="Email"
                  variant="standard"
                  value={email}
                  onChange={({ currentTarget }) => {
                    setEmail(currentTarget.value);
                    validateEmail(currentTarget.value);
                  }}
                  fullWidth
                  placeholder="user@gmail.com"
                  InputLabelProps={{ shrink: true }}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  error={passwordValid == null ? false : !passwordValid}
                  type="password"
                  id="password"
                  label="Password"
                  variant="standard"
                  value={password}
                  onChange={({ currentTarget }) => {

                    setPassword(currentTarget.value);
                    validatePassword(currentTarget.value);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      submit()
                    }
                  }}
                  fullWidth
                  placeholder="*****"
                  InputLabelProps={{ shrink: true }}
                />
              </MDBox>
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;Remember me
                </MDTypography>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  id="submit"
                  name="submit"
                  variant="gradient"
                  color="info"
                  size="large"
                  fullWidth
                  onClick={submit}
                >
                  sign in
                </MDButton>
              </MDBox>
              <MDBox mt={3} textAlign="center">
                <MDTypography variant="button" color="text">
                  Don&apos;t have an account?{" "}
                  <MDTypography
                    component={Link}
                    to="/authentication/sign-up/organisation"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign up
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </IllustrationLayout>
      <Footer />
    </div>
  );
}

export default Illustration;
