import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setPromptClose, setPromptOpen, setPromptValue } from "./promptSlice";
import MDTypography from "components/MDTypography";

export function PromptBox() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const isOpen = useSelector((state) => state?.prompt?.isOpen) ?? false;
  const message = useSelector((state) => state?.prompt?.message) ?? "";
  const promptFunction = useSelector((state)=> state?.prompt.func )


  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog open={open}
    PaperProps={{
        style:{
            width:"320px"
        }
    }}
    >
      <DialogTitle>Prompt Box

        <MDTypography>{message}</MDTypography>
      </DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions>
        <MDButton
          onClick={() => {
            dispatch(setPromptValue(true));
            dispatch(setPromptClose());
          }}
        >
          Confirm
        </MDButton>
        <MDButton
          onClick={() => {
            dispatch(setPromptValue(false));
            dispatch(setPromptClose());
          }}
        >
          Cancel
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
// export function promptConfirmation() {
//   var isOpen = useSelector((state) => state?.loader?.isOpen) ?? false;
//   var promptVal = useSelector((state) => state?.prompt.value);
//   useEffect(() => {
//     dispatch(setPromptOpen());
//   }, []);

//   useEffect(() => {
//   if(promptVal != undefined) {
//     return promptVal;
//   }
//   }, [promptVal]);
// }
