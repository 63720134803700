import {
    Card,
    Grid,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    Button,
    Stack,
    Box
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { styled } from '@mui/system';
import { options } from "dropzone";
import '../styleAndPreview/style.css';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from "react-redux";
import { styleList } from "./StyleSlice";
import StyleComponent from "./styleComponent";
import ApiCalls from "services/flick_erp_api_call";

const useStyles = styled((theme) => ({
    accordion: {
        '&:hover': {
            '& .MuiAccordionSummary-content': {
                color: 'green',
            },
            '& .MuiAccordionSummary-expandIcon': {
                color: 'green',
                transform: 'rotate(90deg)',
            },
        },
    },
    accordionContent: {
        display: 'flex',
        alignItems: 'center',
    }
}));
function StyleAndPreview(props) {
    const dispatch = useDispatch();
    const formFields = useSelector((state) => state.fieldsArray.fieldsArray);
    const styles = useSelector((state) => state.styleData.style);
    const data = useSelector((state) => state.FormBuilderData.form_data);
    const fieldStyles = useSelector((state) => state.styleData.fieldStyles);
    const classes = useStyles();
    const [expandedItem, setExpandedItem] = useState(null);
    const [clickedItem, setClickedItem] = useState(null);
    const [fieldWidth, setFieldWidth] = useState("100%");
    const columnsPerRow = 3;
    const [isRowLayout, setIsRowLayout] = useState(true);
    const [selectedBoxProperties, setSelectedBoxProperties] = useState([]);
    const [flag, setFlag] = useState(true);
    const [id, setId] = useState(null);
    const [formTitle, setFormTitle] = useState("Form-preview");
    const [labelColor, setLabelColor] = useState("");
    const [labelSize, setLabelSize] = useState("");
    const [unit, setUnit] = useState("%");
    const [buttonAlignment, setAlignment] = useState("");
    const [font, setFont] = useState("Arial");
    const [buttonColor, setbuttonColor] = useState("");

    const boxes = [
        {
            BoxId: 0,
            TextField: {
                variant: "outlined"
            },
            buttonStyle: {
                margin: "10px",
                color: 'white',
            }
        },
        {
            BoxId: 1,
            TextField: {
                variant: "standard"
            },
            buttonStyle: {
                margin: "10px",
                color: 'white',
            }
        },
        {
            BoxId: 2,
            TextField: {
                variant: "outlined"
            },
            buttonStyle: {
                margin: "10px",
                color: 'white',
                borderRadius: "50px"
            }
        },
        {
            BoxId: 3,
            TextField: {
                variant: "outlined"
            },
            buttonStyle: {
                margin: "10px",
                color: 'white',
                borderRadius: "0px"
            }
        },

    ];

    useEffect(() => {

        setLabelColor(fieldStyles?.labelColor || "");
        setLabelSize(fieldStyles?.labelSize || "");
        setFieldWidth(fieldStyles?.width || "");
        setUnit(fieldStyles?.unit || "");
        setAlignment(fieldStyles?.buttonAlignment || "");
        setFont(fieldStyles?.font || "");
        setbuttonColor(fieldStyles?.buttonColor || "");
    }, [fieldStyles])

    useEffect(() => {
        if (props.response !== null) {
            setFlag(false);
            setSelectedBoxProperties(props.response?.config?.style);
            setId(props.response?.id);
            setFormTitle(props.response.name);
        }
    }, [props])

    useEffect(() => {
    }, [selectedBoxProperties])

    useEffect(() => {
        const style = {
            text_field: {
                'variant': selectedBoxProperties?.text_field?.variant,
            },
            button_style: {
                margin: selectedBoxProperties?.button_style?.margin || "10px"
                , color: selectedBoxProperties?.button_style?.color || "white",
                borderRadius: selectedBoxProperties?.button_style?.borderRadius,
                backgroundColor: buttonColor,
            }
        }
        dispatch(styleList({ style: style }));
    }, [selectedBoxProperties])

    const toggleAccordion = (itemId) => {
        if (expandedItem === itemId) {
            setExpandedItem(null);
        } else {
            setExpandedItem(itemId);
        }
    };

    const handleSubmitForm = async () => {

        const payload = {
            name: data?.form_title,
            org: JSON.parse(localStorage.getItem("user")).org_id,
        //     config:{'field_keys': formFields,
        //     'form_data': data,
        //     'style': styles}
        // }


        // const res = await ApiCalls.postFormData(payload, false);
        // if (res.success && res.code === 201) {

        //     alert("successfully saved !!")
        // } else {
        //   alert("network error");
        // }
            config: {
                'field_keys': formFields,
                'form_data': data,
                'style': styles,
                'fieldStyles': fieldStyles
            }
        }
        if (flag === true) {
            const res = await ApiCalls.postFormData(payload, false);
            if (res.success) {
                props.setShowList(true);
                props.setShowTab(false);
            } else {
            }
        } else {
            const res = await ApiCalls.updateFormData(id, payload, false);
            if (res.success) {
                props.setShowList(true);
                props.setShowTab(false);
            } else {
            }
        }

    }

    const handleGridItemClick = (itemId) => {

        const selectedBox = boxes.find((box) => box.BoxId === itemId);
        if (selectedBox) {
            setClickedItem(itemId);
            setSelectedBoxProperties(selectedBox);
        }
    };

    const renderField = (fieldObj) => {
        const { field, type } = fieldObj;
        switch (type) {
            case "text":
                return <div>
                    <label style={{ color: labelColor, fontSize: labelSize + "px", fontFamily: font }}><b>{field}</b></label>
                    <br />
                    <TextField id="outlined-basic" label={field}
                        variant={
                            selectedBoxProperties
                                ? selectedBoxProperties.text_field?.variant || "outlined"
                                : "outlined"
                        }
                        style={{ marginTop: "8px", width: fieldWidth + unit }} />
                </div>;
            case "number":
                return <div>
                    <label style={{ color: labelColor, fontSize: labelSize + "px", fontFamily: font }}><b>{field}</b></label>
                    <br />
                    <TextField id="outlined-basic" label={field} type="number"
                        variant={
                            selectedBoxProperties
                                ? selectedBoxProperties.text_field?.variant || "outlined"
                                : "outlined"
                        }
                        style={{ marginTop: "8px", width: fieldWidth + unit }} />
                </div>;
            default:
                return field;
        }
    };

    const [options, setOptions] = useState([
        {
            title: 'Input options',
            content: (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {/* First Card */}
                        <Box
                            p={2}
                            border={1}
                            className={`gridItem ${clickedItem === 0 ? 'clicked' : ''}`}
                            onClick={() => handleGridItemClick(0)}
                        >
                            <div>
                                <label><b>Label</b></label>
                                <br />
                                <TextField id="outlined-basic" label="label" variant="outlined" value="" style={{ marginTop: "8px", pointerEvents: "none" }} />
                                <br />
                                <Button variant="contained" style={{ margin: "10px", color: "white", pointerEvents: "none" }}>Submit</Button>
                            </div>
                            <div style={{ textAlign: "center", fontWeight: "bold" }}>Default</div>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        {/* Second Card */}
                        <Box
                            p={2}
                            border={1}
                            className={`gridItem ${clickedItem === 1 ? 'clicked' : ''}`}
                            onClick={() => handleGridItemClick(1)}
                        >
                            <div>
                                <label><b>Label</b></label>
                                <br />
                                <TextField id="outlined-basic" label="label" variant="standard" value="" style={{ marginTop: "8px", pointerEvents: "none" }} />
                                <br />
                                <Button variant="contained" style={{ margin: "10px", color: "white", pointerEvents: "none" }}>Submit</Button>
                            </div>
                            <div style={{ textAlign: "center", fontWeight: "bold" }}>Linear</div>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        {/* Third Card */}
                        <Box
                            p={2}
                            border={1}
                            className={`gridItem ${clickedItem === 2 ? 'clicked' : ''}`}
                            onClick={() => handleGridItemClick(2)}
                        >
                            <div>
                                <label><b>Label</b></label>
                                <br />
                                <TextField id="outlined-basic" label="label" variant="outlined" value="" style={{ marginTop: "8px", pointerEvents: "none" }} />
                                <br />
                                <Button variant="contained" style={{ margin: "10px", color: "white", pointerEvents: "none", borderRadius: "50px" }}>Submit</Button>
                            </div>
                            <div style={{ textAlign: "center", fontWeight: "bold" }}>Round</div>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        {/* Fourth Card */}
                        <Box
                            p={2}
                            border={1}
                            className={`gridItem ${clickedItem === 3 ? 'clicked' : ''}`}
                            onClick={() => handleGridItemClick(3)}
                        >
                            <div>
                                <label><b>Label</b></label>
                                <br />
                                <TextField id="outlined-basic" label="label" variant="outlined" value="" style={{ marginTop: "8px", pointerEvents: "none" }} />
                                <br />
                                <Button variant="contained" style={{ margin: "10px", color: "white", pointerEvents: "none", borderRadius: "0px" }}>Submit</Button>
                            </div>
                            <div style={{ textAlign: "center", fontWeight: "bold" }}>Sharp</div>
                        </Box>
                    </Grid>
                </Grid>
            )
        }
        , {
            title: 'Styles',
            content: (
                <StyleComponent props={props} />
            )
        }
    ])

    return (<div>
        <Grid container spacing={3} padding={0}>
            <Grid item xs={12} md={6} padding={0} overflow={"scroll"}>
                {/* <div style={{ borderRadius: '5px', boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)" }}> */}
                    <Stack spacing={0.5} padding={0.4}>
                        <div style={{ marginBottom: "2%" }}>
                            {options.map((item, idx) => {
                                return (<Accordion
                                    key={item.id}
                                    className={classes.accordion}
                                    expanded={expandedItem === idx}
                                    onChange={() => toggleAccordion(idx)}
                                >
                                    <AccordionSummary className={classes.accordionContent} >
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                                            {
                                                expandedItem === idx ? (
                                                    <KeyboardArrowDownIcon style={{ color: 'green' }} />
                                                ) : (
                                                    <KeyboardArrowRightIcon style={{ color: 'green' }} />
                                                )
                                            }
                                            <Typography align="center">{item.title}</Typography>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            {item.content}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>)
                            })}
                        </div>
                    </Stack>
                    <div>
                        <Button variant="contained" style={{ margin: "10px", color: 'white' }} onClick={handleSubmitForm}>{flag === true ? "Submit Form" : "Update Form"}</Button>
                    </div>
                {/* </div> */}
            </Grid>
            <Grid item xs={12} md={6} padding={0}>
                <div style={{ boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)" }}>
                    <h3 style={{ padding: "15px" }}>{formTitle}</h3>
                    <div className="form-preview" style={{ minHeight: "500px" }}>
                        <div style={{ display: isRowLayout ? 'flex' : 'block', flexWrap: isRowLayout ? 'wrap' : 'nowrap', padding: "8px" }}>
                            {formFields.map((fieldObj, index) => (
                                <div key={index} style={{ width: isRowLayout ? `${100 / columnsPerRow}%` : '100%', marginBottom: "8px" }}>
                                    {renderField(fieldObj)}
                                </div>
                            ))}
                        </div>
                        <div style={{ display: "flex", justifyContent: buttonAlignment }}>
                            <Button
                                variant="contained"
                                style={
                                    selectedBoxProperties
                                        ? { ...selectedBoxProperties.button_style, backgroundColor: buttonColor || "", color: "white" } || { margin: "10px", color: 'white', backgroundColor: buttonColor || "" }
                                        : { margin: "10px", color: 'white', backgroundColor: buttonColor || "" }
                                }
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>
    </div >
    );
}
export default StyleAndPreview;