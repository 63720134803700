import React from "react";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { useMaterialUIController } from "context";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
const NotFound = () => {
  // const [controller] = useMaterialUIController();
  // const { darkMode } = controller;
  return (
    <PageLayout background="white">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#F5F5F5",
        }}
      >
        <h1
          style={{
            fontSize: "5rem",
            marginBottom: "2rem",
            color: "#333",
          }}
        >
          404 Error: Page Not Found
        </h1>
        <p
          style={{
            fontSize: "2rem",
            color: "#777",
            textAlign: "center",
            maxWidth: "80%",
          }}
        >
          We're sorry, the page you are looking for cannot be found.
        </p>
      </div>
    </PageLayout>
  );
};
export default NotFound;
