import { createSlice } from "@reduxjs/toolkit";

    const styleSlice = createSlice({
        name: "styleData",
        initialState: {
                style:{},
                fieldStyles:{}
        },
    reducers : {
        styleList : (state,action) => {
            state.style = action.payload.style;
        },
        fieldStylesList : (state,action) => {
            state.fieldStyles = action.payload.fieldStyles;
        }
    }
});

export const {styleList} = styleSlice.actions;
export const {fieldStylesList} = styleSlice.actions;
export default styleSlice.reducer;
