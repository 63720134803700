import { LibraryAddCheck } from "@mui/icons-material";
import {
  Autocomplete,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import ApiCalls from "services/flick_erp_api_call";

export function SelectEmployee({
  onSelected,
  multiple,
  label,
  selectAll,
  fullWidth,
  placeholder
}) {
  const [employees, setEmployees] = useState([]);
  const [selected, setSelected] = useState(multiple ? [] : null);

  useEffect(() => {
    debugger
    getEmployee();
  }, []);
  async function getEmployee() {
    var res = await ApiCalls.getEmployeesList("?status=active");
    if (res.success && res.code === 200) {
      var data = JSON.parse(JSON.stringify(res.data));
    //  var d = flattenHierarchy(data) ;
    // debugger
      setEmployees(data);
    } else {
    }
  }
  const flattenHierarchy = (node, result = []) => {
    result.push(node);
    if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        flattenHierarchy(child, result);
      }
    }
    return result;
  };
  function handleSelectAll() {
    setSelected(employees.map((e) => e.id));
  }

  return (
    <MDBox display="flex" flexDirection="row" alignItems="center">
      <Autocomplete
        id={label}
        value={
          multiple
            ? selected?.map((e) => employees?.find((v) => v?.id == e))
            : employees.find((v) => v.id == selected)
        }
        options={employees}
        dense
        multiple={multiple}
        componentsProps={{ popper: { style: { width: "fit-content" } } }}
        fullWidth={fullWidth}
        getOptionLabel={(option) =>
          Array.isArray(option)
            ? option.map(
                (item) => item?.user?.firstname + " " + item?.user?.lastname
              )
            : option?.user?.firstname + " " + option?.user?.lastname
        }
        renderInput={(params) => (
          <TextField
            {...params}
            name="Employee"
            label={label}
            placeholder={placeholder}
            variant="standard"
            InputLabelProps={{ shrink: true }}
            sx={{ width: fullWidth ? "100%" : 200}}
          />
        )}
        onChange={(event, newValue) => {
          var selectedValues;
          if (multiple) {
            selectedValues = Array.isArray(newValue)
              ? newValue.map((v) => v?.id)
              : [newValue?.id];
          } else {
            selectedValues = newValue?.id;
          }
          setSelected(selectedValues);
          onSelected(selectedValues);
        }}
      />
      {selectAll && (
        <Tooltip title="Select All">
          <LibraryAddCheck
            sx={{ cursor: "pointer", mt: 2 }}
            onClick={() => {
              handleSelectAll();
            }}
          />
        </Tooltip>
      )}
    </MDBox>
  );
}
