import { Backdrop, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import loading from "assets/loading.json";
import Lottie from "react-lottie";
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };


export function Loader () {
    const [open, setOpen] = useState(false);
    const isOpen = useSelector((state) => state?.loader?.isOpen) ?? false;

    useEffect(() => {
      setOpen(isOpen);
    }, [isOpen]);

  

    return <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={open}
    
    >
        <Lottie
        options={defaultOptions}
        height="15%"
        width="15%"
        >

        </Lottie>
  

    

    </Backdrop> ;
}