import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Checkbox, Icon, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DataTable from "examples/Tables/DataTable";
import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FormField from "layouts/pages/account/components/FormField";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ApiCalls from "services/flick_erp_api_call";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { employeeList } from "layouts/employees/employeesSlice";
import MDTypography from "components/MDTypography";
import { SelectEmployee } from "components/SelectEmployee";
import moment from "moment";
import { getNextDateFromCurrentDate } from "helpers/DateTime";
import { getNextDayFromCurrentDate } from "helpers/DateTime";
import { getNextDay } from "helpers/DateTime";
import { getNextDate } from "helpers/DateTime";
function Shifts() {
  const loggedUser = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();

  const [officeShifts, setOfficeShifts] = useState([]);
  const [ShiftError, setShiftError] = useState("");
  const [ShiftHoursError, setShiftHoursError] = useState("");
  const [StartTimeError, setStartTimeError] = useState("");
  const [StartDateError, setStartDateError] = useState("");
  const [EndDateError, setEndDateError] = useState("");
  const [SelectEmployeError, setSelectEmployeError] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [SelectWeeklyOffError, setSelectWeeklyOffError] = useState("");
  const [editPayloadData, setEditPayloadData] = useState({});
  const [multiDialogOpen, setMultiDialogOpen] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [dateColumn, setDateColumn] = useState({
    days: Array.from(Array(7).keys()),
    date: new Date().getDate(),
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });

  const [selectedMonth, setSelectedMonth] = useState(
    `${new Date().getFullYear()}-${new Date().toLocaleString("en-GB", {
      // you can use undefined as first argument

      month: "2-digit",
    })}`
  );

  const handleSubmit = async () => {
    if (shiftPayload.shift == undefined) {
      setShiftError("Please select the shift");
    } else {
      setShiftError("");
    }
    if (shiftPayload.start_date == undefined) {
      setStartDateError("Please select the start date");
    } else {
      setStartDateError("");
    }
    if (shiftPayload.end_date == undefined) {
      setEndDateError("Please select the end date");
    } else {
      setEndDateError("");
    }
    if (
      shiftPayload.user_ids == undefined ||
      shiftPayload.user_ids.length == 0
    ) {
      setSelectEmployeError("Please select the Employee");
    } else {
      setSelectEmployeError("");
    }
    if (shiftPayload.shift_hours == undefined) {
      setShiftHoursError("please enter hours");
    } else {
      setShiftHoursError("");
    }
    if (shiftPayload.time_from == undefined) {
      setStartTimeError("please enter start time");
    } else {
      setStartTimeError("");
    }
    if (
      shiftPayload.week_off == undefined ||
      shiftPayload.week_off.length == 0
    ) {
      setSelectWeeklyOffError("Please select atleast one Week Day");
    } else {
      setSelectWeeklyOffError("");
      createShift();
    }
  };

  const date = new Date();

  var weekList = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [shiftPayload, setShiftPayload] = useState({
    request: "create",
    start_date: new Date()
      .toLocaleDateString("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .split("/")
      .reverse()
      .join("-"),
  });

  const [employeeShiftsData, setEmployeeShiftsData] = useState({
    columns: [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ color: "black" }}>
              {row?.original?.user?.firstname +
                " " +
                row?.original?.user?.lastname}
            </span>
          </div>
        ),
      },
    ],
    rows: [],
  });
  const filterShiftDate = (shift, date) => {
    let data = shift?.find((e) => e?.date == date);
    let timeFrom = data?.time_from;
    let timeTo = data?.time_to;

    return (timeFrom != undefined && timeTo != undefined) || data?.week_off
      ? data?.week_off
        ? "Week Off"
        : moment(timeFrom, "HHmmss").format("LT") +
          " - " +
          moment(timeTo, "HHmmss").format("LT")
      : "";
  };
  const employees = useSelector((state) => state.employee.employee);
  useEffect(() => {
    getEmployee();
    getOfficeShifts();
  }, []);
  useEffect(() => {}, []);

  async function getEmployee() {
    const user = JSON.parse(localStorage.getItem("user"));
    var res = await ApiCalls.getEmployeesList(`?status=active`);
    if (res.success && res.code === 200) {
      var data = JSON.parse(JSON.stringify(res.data));

      dispatch(employeeList({ employee: data }));
    } else {
    }
  }

  async function getEmployeeShifts(queryParams) {
    const res = await ApiCalls.getEmployeeShifts(queryParams);
    if (res.success && res.code === 200) {
      if (employees) {
        let empShift = [];
        empShift =
          selectedEmployee != ""
            ? [
                {
                  ...employees.find((e) => e.id == selectedEmployee),
                  shift: res.data.filter((f) => f.employee == selectedEmployee),
                },
              ]
            : employees.map((e) => ({
                ...e,
                shift: res.data.filter((f) => f.employee == e.id),
              }));
        employeeShiftsData.rows = empShift;
        employeeShiftsData.columns = [
          {
            Header: "Name",
            accessor: "name",
            Cell: ({ row }) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ color: "black" }}>
                  {row?.original?.user?.firstname +
                    " " +
                    row?.original?.user?.lastname}
                </span>
              </div>
            ),
          },
          ,
          ...dateColumn.days.map((e, index) => ({
            Header: getNextDate(
              index,
              dateColumn.date,
              dateColumn.month,
              dateColumn.year
            ),
            sub_header: getNextDay(
              index,
              dateColumn.date,
              dateColumn.month,
              dateColumn.year
            ),
            Cell: ({ column, row }) => (
              <div
                style={{ display: "flex", alignItems: "center" }}
                onClick={() => {
                  setEditDialog(true);
                  setEditPayloadData(
                    row?.original?.shift?.find((e) => e?.date == column.Header)
                  );
                }}
              >
                <span>
                  {filterShiftDate(row?.original?.shift, column.Header) ?? "-"}
                </span>
              </div>
            ),
          })),
        ];

        setEmployeeShiftsData({ ...employeeShiftsData });
      }
    } else {
    }
  }
  async function getOfficeShifts() {
    const res = await ApiCalls.getOfficeShifts();
    if (res.success && res.code === 200) {
      setOfficeShifts(res.data);
    } else {
    }
  }
  async function createShift() {
    var data = {
      shift: shiftPayload.shift,
      shift_hours: shiftPayload.shift_hours,
      time_from: shiftPayload.time_from,
      start_date: shiftPayload.start_date,
      end_date: shiftPayload.end_date,
      week_off: shiftPayload.week_off,
      user_ids: shiftPayload.user_ids,
    };
    var res = await ApiCalls.createEmployeeShift(data, false);
    if (res.success && res.code === 201) {
      setMultiDialogOpen(false);
      setShiftPayload({});
      getEmployeeShifts(
        `?${
          selectedEmployee != "" ? "employee_id=" + selectedEmployee : ""
        }&month=${dateColumn.month + 1}&year=${dateColumn.year}&status=active`
      );
    } else {
      setMultiDialogOpen(false);
      setShiftPayload({});
    }
  }

  async function updateEmployeeShift() {
    var data = {
      shift: editPayloadData.shift,
      shift_hours: editPayloadData.shift_hours,
      time_from: editPayloadData.time_from,
      is_wfh: editPayloadData.is_wfh,
    };
    var res = await ApiCalls.updateShift(editPayloadData.id, data, false);
    if (res.success && res.code === 200) {
     
    } else {
    
    }  setEditDialog(false);
      setEditPayloadData({});
    getEmployeeShifts(
      `?${
        selectedEmployee != "" ? "employee_id=" + selectedEmployee : ""
      }&month=${dateColumn.month + 1}&year=${dateColumn.year}`
    );
  }

  function handleFilterSubmit() {
    setDateColumn({
      days: Array.from(
        Array(
          new Date(
            selectedMonth.split("-")[0],
            selectedMonth.split("-")[1],
            0
          ).getDate()
        ).keys()
      ),
      date: 1,
      month: selectedMonth.split("-")[1] - 1,
      year: selectedMonth.split("-")[0],
    });
  }
  useEffect(() => {
    employees.length != 0
      ? getEmployeeShifts(
          `?${
            selectedEmployee != "" ? "employee_id=" + selectedEmployee : ""
          }&month=${dateColumn.month + 1}&year=${dateColumn.year}`
        )
      : null;
  }, [dateColumn, employees]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox
              mt={-3}
              p={3}
              lineHeight={1}
              bgColor="#007bed"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                color: " white",
                borderRadius: 2,
                marginLeft: "0.9rem",
                marginRight: "0.9rem",
              }}
            >
              Shift Details
            </MDBox>

            <Grid
              container
              p={5}
              align="flex-end"
              alignContent={"center"}
              justifyContent={"space-between"}
            >
              <Grid item pr={2}>
                <MDButton
                  onClick={() => {
                    setMultiDialogOpen(true);
                    setShiftPayload({
                      request: "create",
                    });
                  }}
                  sx={{
                    border: "1.2px solid black",
                    "&:hover": { backgroundColor: " black", color: " white" },
                  }}
                >
                  Create Shifts
                </MDButton>
              </Grid>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    <TextField
                      variant="standard"
                      InputLabelProps={{}}
                      id="month"
                      type="month"
                      value={selectedMonth}
                      defaultValue={selectedMonth}
                      name="Date"
                      label="Select Month"
                      onChange={(event) => {
                        setSelectedMonth(event.target.value);
                      }}
                      InputProps={{}}
                    />
                  </Grid>
                  <Grid item>
                    <SelectEmployee
                      label="Select Employee"
                      onSelected={(e) => {

                        setSelectedEmployee(e ?? "");
                      }}
                    ></SelectEmployee>
                  </Grid>
                  <Grid item>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      onClick={() => {
                        handleFilterSubmit();
                      }}
                    >
                      Submit
                    </MDButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <DataTable
              table={employeeShiftsData}
              isShiftsTable
              // canSearch
              callableFunction={(row, index) => {}}
            ></DataTable>
          </Card>
        </MDBox>
      </MDBox>

      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "white",
            boxShadow: "none",
            width: "600px",
            height: "auto",
            maxWidth: "none",
            borderRadius: "10px",
            cursor: "pointer",
          },
        }}
        open={multiDialogOpen}
        onClose={() => {
          setMultiDialogOpen(false);
          setShiftPayload({});
        }}
      >
        <DialogTitle
          style={{
            width: "100%",
            padding: "20px",
            backgroundColor: "#007bed",
            color: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "Medium" }}>
              Create Shifts
            </Typography>
            <Icon
              fontSize="medium"
              onClick={() => {
                setMultiDialogOpen(false);
                setShiftPayload({});
              }}
            >
              close
            </Icon>
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "4px",
          }}
        >
          <Card id="basic-info" sx={{ overflow: "visible" }}>
            <MDBox component="form" pb={3} px={3} pt={3}>
              <Grid container spacing={3} justifyContent={"center"}>
                <Grid item xs={12} sm={6} alignSelf={"end"}>
                  <Autocomplete
                    id="shifts"
                    options={officeShifts}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        name="Office Shifts"
                        label="Select Office Shifts"
                        variant="standard"
                        required
                        error={!!ShiftError}
                        helperText={ShiftError}
                      />
                    )}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setShiftPayload((state) => ({
                          ...state,
                          shift: newValue.id,
                          shift_hours: newValue.shift_hours,
                          time_from: newValue.time_from,
                        }));
                      }
                      setShiftError("");
                      setShiftHoursError("");
                      setStartTimeError("");
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    type="number"
                    label="Shift Hours"
                    value={shiftPayload.shift_hours || ""}
                    variant="standard"
                    onChange={(event) => {
                      setShiftHoursError("");
                    }}
                    required
                    fullWidth
                    error={!!ShiftHoursError}
                    helperText={ShiftHoursError}
                  ></TextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Start Time"
                    // type="number"
                    value={shiftPayload.time_from || ""}
                    variant="standard"
                    onChange={(event) => {}}
                    fullWidth
                    required
                    error={!!StartTimeError}
                    helperText={StartTimeError}
                  ></TextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Start Date"
                        InputLabelProps={{ shrink: true }}
                        variant="standard"
                        id="start_date"
                        fullWidth
                        type="date"
                        value={shiftPayload.start_date}
                        name="start_date"
                        onChange={(event) => {
                          setShiftPayload((state) => ({
                            ...state,
                            start_date: event.target.value,
                          }));
                          setStartDateError("");
                        }}
                        required
                        error={!!StartDateError}
                        helperText={StartDateError}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="End Date"
                        InputLabelProps={{ shrink: true }}
                        variant="standard"
                        id="end_date"
                        fullWidth
                        type="date"
                        value={shiftPayload.end_date}
                        name="end_date"
                        onChange={(event) => {
                          setShiftPayload((state) => ({
                            ...state,
                            end_date: event.target.value,
                          }));
                          setEndDateError("");
                        }}
                        required
                        error={!!EndDateError}
                        helperText={EndDateError}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    id="employees"
                    options={employees}
                    multiple
                    getOptionLabel={(option) =>
                      Array.isArray(option)
                        ? option.map(
                            (item) =>
                              item.user.firstname + " " + item.user.lastname
                          )
                        : option.user.firstname + " " + option.user.lastname
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        name="Employee"
                        label="Select Employee"
                        variant="standard"
                        required
                        error={!!SelectEmployeError}
                        helperText={SelectEmployeError}
                      />
                    )}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        const selectedValues = Array.isArray(newValue)
                          ? newValue.map((v) => v.id)
                          : [newValue.id];

                        // setNewRecord((state) => ({
                        //   ...state,
                        //   recruiter: selectedValues,
                        // }));
                        setShiftPayload((state) => ({
                          ...state,
                          user_ids: selectedValues,
                        }));
                      }
                      setSelectEmployeError("");
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    id="Weekly Off"
                    options={weekList}
                    multiple
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        name="Weekly Off"
                        label="Select Weekly Off"
                        variant="standard"
                        required
                        error={!!SelectWeeklyOffError}
                        helperText={SelectWeeklyOffError}
                      />
                    )}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        const selectedValues = Array.isArray(newValue)
                          ? newValue.map((v) => v)
                          : [newValue];

                        // setNewRecord((state) => ({
                        //   ...state,
                        //   recruiter: selectedValues,
                        // }));
                        setShiftPayload((state) => ({
                          ...state,
                          week_off: selectedValues,
                        }));
                      }
                      setSelectWeeklyOffError("");
                    }}
                  />
                </Grid>
              </Grid>
              <MDBox p={2} align="center">
                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </MDButton>
              </MDBox>
            </MDBox>
          </Card>
        </DialogContent>
      </Dialog>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "white",
            boxShadow: "none",
            width: "600px",
            height: "auto",
            maxWidth: "none",
            borderRadius: "10px",
            cursor: "pointer",
          },
        }}
        open={editDialog}
        onClose={() => {
          setEditDialog(false);
        }}
      >
        <DialogTitle
          style={{
            width: "100%",
            padding: "20px",
            backgroundColor: "#007bed",
            color: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "Medium" }}>
              Edit Shift
            </Typography>
            <Icon
              fontSize="medium"
              onClick={() => {
                setEditDialog(false);
              }}
            >
              close
            </Icon>
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "4px",
          }}
        >
          <Card id="basic-info" sx={{ overflow: "visible" }}>
            <MDBox component="form" pb={3} px={3} pt={3}>
              <Grid
                container
                spacing={3}
                justifyContent={"center"}
                alignItems={"flex-end"}
              >
                <Grid item xs={12} sm={6} alignSelf={"end"}>
                  <Autocomplete
                    id="shifts"
                    options={officeShifts}
                    value={officeShifts.find(
                      (e) => e.id == editPayloadData.shift
                    )}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        name="Office Shifts"
                        label="Select Office Shifts"
                        variant="standard"
                        required
                        error={!!ShiftError}
                        helperText={ShiftError}
                      />
                    )}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setEditPayloadData((state) => ({
                          ...state,
                          shift: newValue.id,
                          shift_hours: newValue.shift_hours,
                          time_from: newValue.time_from,
                        }));
                      }
                      setShiftError("");
                      setShiftHoursError("");
                      setStartTimeError("");
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    type="number"
                    label="Shift Hours"
                    value={
                      officeShifts.find((e) => e.id == editPayloadData.shift)
                        ?.shift_hours || ""
                    }
                    variant="standard"
                    onChange={(event) => {
                      setShiftHoursError("");
                    }}
                    required
                    fullWidth
                    error={!!ShiftHoursError}
                    helperText={ShiftHoursError}
                  ></TextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Start Time"
                    // type="number"
                    value={
                      officeShifts.find((e) => e.id == editPayloadData.shift)
                        ?.time_from || ""
                    }
                    variant="standard"
                    onChange={(event) => {}}
                    fullWidth
                    required
                    error={!!StartTimeError}
                    helperText={StartTimeError}
                  ></TextField>
                </Grid>

                {/* <Grid item xs={12} sm={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Start Date"
                        InputLabelProps={{ shrink: true }}
                        variant="standard"
                        id="start_date"
                        fullWidth
                        type="date"
                        value={shiftPayload.start_date}
                        name="start_date"
                        onChange={(event) => {

                          setShiftPayload((state) => ({
                            ...state,
                            start_date: event.target.value,
                          }));
                          setStartDateError("");
                        }}
                        required
                        error={!!StartDateError}
                        helperText={StartDateError}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="End Date"
                        InputLabelProps={{ shrink: true }}
                        variant="standard"
                        id="end_date"
                        fullWidth
                        type="date"
                        value={shiftPayload.end_date}
                        name="end_date"
                        onChange={(event) => {
                          setShiftPayload((state) => ({
                            ...state,
                            end_date: event.target.value,
                          }));
                          setEndDateError("");
                        }}
                        required
                        error={!!EndDateError}
                        helperText={EndDateError}
                      />
                    </Grid>
                  </Grid>
                </Grid> */}

                <Grid item xs={12} sm={6}>
                  <MDBox>
                    <Checkbox
                      checked={editPayloadData?.is_wfh}
                      size="small"
                      style={{ width: "20px", padding: 0, paddingRight: 20 }}
                      onChange={(e) => {
                        setEditPayloadData((state) => ({
                          ...state,
                          is_wfh: e.target.checked,
                        }));
                      }}
                    ></Checkbox>
                    Is WFH
                  </MDBox>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <Autocomplete
                    id="Weekly Off"
                    options={weekList}
                    multiple
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        name="Weekly Off"
                        label="Select Weekly Off"
                        variant="standard"
                        required
                        error={!!SelectWeeklyOffError}
                        helperText={SelectWeeklyOffError}
                      />
                    )}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        const selectedValues = Array.isArray(newValue)
                          ? newValue.map((v) => v)
                          : [newValue];

                        // setNewRecord((state) => ({
                        //   ...state,
                        //   recruiter: selectedValues,
                        // }));
                        setShiftPayload((state) => ({
                          ...state,
                          week_off: selectedValues,
                        }));
                      }
                      setSelectWeeklyOffError("");
                    }}
                  />
                </Grid> */}
              </Grid>
              <MDBox p={2} align="center">
                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={() => updateEmployeeShift()}
                >
                  Submit
                </MDButton>
              </MDBox>
            </MDBox>
          </Card>
        </DialogContent>
      </Dialog>

      <Footer />
    </DashboardLayout>
  );
}
export default Shifts;
