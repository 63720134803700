/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Grid } from "@mui/material";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { useEffect, useState } from "react";
import ApiCalls from "services/flick_erp_api_call";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  selectOrgRegistration,
  setOrg,
  setFirstName,
  setLastName,
  setPhone,
  setEmail,
  setPassword,
  orgRegistration,
} from "./orgRegSlice";
import { setToastOpen } from "components/Toast/toastAlertSlice";

function OrganisationSignUp() {
  const [orgPayload, setOrgPayload] = useState({});
  const navigate = useNavigate();
  const [org, setOrg] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmitClick = () => {
    navigate("/authentication/sign-in/illustration");
  };

  const dispatch = useDispatch();

  function submit() {
    var hasError = false ;
    if(orgPayload.orgError  == undefined || orgPayload.orgError){
      setOrgPayload((state)=>({...state,orgError:true}))
      hasError = true; 
    }
    if(orgPayload.firstNameError  == undefined || orgPayload.firstNameError){
      setOrgPayload((state)=>({...state,firstNameError:true}))
      hasError = true; 
    }
    if(orgPayload.lastNameError  == undefined || orgPayload.lastNameError){
      setOrgPayload((state)=>({...state,lastNameError:true}))
      hasError = true; 
    }
    if(orgPayload.emailError  == undefined || orgPayload.emailError){
      setOrgPayload((state)=>({...state,emailError:true}))
      hasError = true; 
    }
    if(orgPayload.phoneError  == undefined || orgPayload.phoneError){
      setOrgPayload((state)=>({...state,phoneError:true}))
      hasError = true; 
    }
    if(orgPayload.passwordError  == undefined || orgPayload.passwordError){
      setOrgPayload((state)=>({...state,passwordError:true}))
        hasError = true; 
    }

    if(!hasError) {
 var data = {
      org: {
        name: orgPayload.org,
        category: 1,
      },
      user: {
        firstname: orgPayload.firstName,
        lastname: orgPayload.lastName,
        email: orgPayload.email,
        phone: orgPayload.phone,
        password: orgPayload.password,
      },
    };

    ApiCalls.createOrg(data)
      .then((response) => {
        if (response.success && response.code === 201) {
          navigate("/authentication/sign-in/illustration");
        } 
      })
      .catch((error) => {
      });
    }
   
  }

  const orgREG = useSelector(selectOrgRegistration);

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography
            variant="h4"
            fontWeight="medium"
            color="white"
            mt={1}
            align="left"
          >
            Register Organisation
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Organisation Name"
                variant="standard"
                fullWidth
                required
                value={orgPayload.org}
                error={orgPayload?.orgError}
                onChange={(e) => {
                  setOrgPayload((state) => ({
                    ...state,
                    org: e.target.value,
                    orgError: e.target.value == "",
                  }));
                  setOrg(e.target.value);
                }}
              />
            </MDBox>
            <MDBox display="flex" pb={2}>
              <MDInput
                type="firstName"
                label="First Name"
                variant="standard"
                fullWidth
                required
                value={orgPayload.firstName}
                error={orgPayload?.firstNameError}
                onChange={(e) => {
                  setOrgPayload((state) => ({
                    ...state,
                    firstName: e.target.value,
                    firstNameError: e.target.value == "",
                  }));
                  setFirstName(e.target.value);
                }}
                sx={{ paddingRight: "10px" }}
              />

              <MDInput
                type="lastName"
                label="Last Name"
                variant="standard"
                fullWidth
                required
                value={orgPayload.lastName}
                error={orgPayload?.lastNameError}
                onChange={(e) => {
                  setOrgPayload((state) => ({
                    ...state,
                    lastName: e.target.value,
                    lastNameError: e.target.value == "",
                  }));
                  setLastName(e.target.value);
                }}
                // sx={{paddingLeft:"5px"}}
              />
            </MDBox>
            {/* <Grid container spacing={2} justifyContent="start">
           <Grid item>
         
           </Grid>
           <Grid item> </Grid>
            </Grid> */}
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                required
                error={orgPayload?.emailError}
                value={orgPayload.email}
                onChange={(e) => {
                  setOrgPayload((state) => ({
                    ...state,
                    email: e.target.value,
                    emailError: e.target.value == "",
                  }));
                  setEmail(e.target.value);
                }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="phone"
                label="Phone"
                variant="standard"
                fullWidth
                required
                value={orgPayload.phone}
                error={orgPayload?.phoneError}
                onChange={(e) => {
                  setOrgPayload((state) => ({
                    ...state,
                    phone: e.target.value,
                    phoneError: e.target.value == "",
                  }));
                  setPhone(e.target.value);
                }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                variant="standard"
                fullWidth
                error={orgPayload?.passwordError}
                value={orgPayload.password}
                required
                onChange={(e) => {
                  setOrgPayload((state) => ({
                    ...state,
                    password: e.target.value,
                    passwordError: e.target.value == "",
                  }));
                  setPassword(e.target.value);
                }}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox
                value={orgPayload?.terms}
                onChange={(e) => {
                  setOrgPayload((state) => ({
                    ...state,
                    terms: e.target.checked,
                  }));
                }}
              />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              {/* <MDButton variant="gradient" color="info" fullWidth onClick={()=> handleSubmitClick()} > */}
              <MDButton
                disabled={!orgPayload?.terms}
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => submit()}
              >
                Register
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default OrganisationSignUp;
