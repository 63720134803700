import checkout from "layouts/marketing/newEvent/schemas/form";

const {
  formField: {
    eventName, // Add event name
    eventColor, // Add event color
    eventDuration, // Add event duration
    // Remove the following fields
    email,
    address1,
    address2,
    city,
    zip,
    twitter,
    facebook,
    instagram,
    publicEmail,
    bio,
  },
} = checkout;

// const initialValues = {
//   [eventName.name]: "", // Initialize event name
//   [eventColor.name]: "#2679DF", // Initialize event color
//   [eventDuration.name]: "", // Initialize event duration
//   [email.name]: "",
//   [address1.name]: "",
//   [address2.name]: "",
//   [city.name]: "",
//   [zip.name]: "",
//   [twitter.name]: "",
//   [facebook.name]: "",
//   [instagram.name]: "",
//   [publicEmail.name]: "",
//   [bio.name]: "",
// };

// export default initialValues;

export const getInitialValues = (isEdit, event) => {
  return {
    [eventName.name]: isEdit ? event?.event_name : "",
    [eventColor.name]: isEdit ? event?.background_color : "#2679DF",
    [email.name]: isEdit ? event?.invitee_email : "",
  };
};

// Function to get initial values for auth code form
export const getInitialValuesForAuthCode = () => {
  const storedEvent = localStorage.getItem("editedEvent");

  // Check if there's a stored event
  if (storedEvent) {
    const parsedEvent = JSON.parse(storedEvent);
    // Do something with the parsedEvent
    return {
      [eventName.name]: true ? parsedEvent?.event_name : "",
      [eventColor.name]: true ? parsedEvent?.background_color : "#2679DF",
      [email.name]: true ? parsedEvent?.invitee_email : "",
    };
  }
};

// Export both functionsexport { getInitialValues, getInitialValuesForAuthCode };
