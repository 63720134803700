import React from 'react';
import PageLayout from "examples/LayoutContainers/PageLayout";

const PermissionDenied = () => {
  return ( 
    <PageLayout background="white">

    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#F5F5F5'
    }}>
      <h1 style={{
        fontSize: '5rem',
        marginBottom: '2rem',
        color: '#333'
      }}>Error: Permission Denied</h1>
      <p style={{
        fontSize: '2rem',
        color: '#777',
        textAlign: 'center',
        maxWidth: '80%'
      }}>
        We're sorry, the page you are looking for can't be access.
      </p>
    </div>
    </PageLayout>
  );
};
export default PermissionDenied;