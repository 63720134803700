import { createSlice } from '@reduxjs/toolkit';

const orgRegSlice = createSlice({
  name: 'orgRegistration',
  initialState: {
    org: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    password: '',
  },
  reducers: {
    setOrg: (state, action) => {
      state.org = action.payload;
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setPhone: (state, action) => {
      state.phone = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
  //   orgRegistration : (state,actions) => {
  //     state.org = actions.payload.org;
  //     state.firstName = actions.payload.firstName;
  //     state.lastName = actions.payload;
  //     state.phone = actions.payload;
  //     state.email = actions.payload;
  //     state.password = actions.payload;
  // }
  },
});

export const {
  setOrg,
  setFirstName,
  setLastName,
  setPhone,
  setEmail,
  setPassword,
  // orgRegistration
} = orgRegSlice.actions;

export default orgRegSlice.reducer;
export const selectOrgRegistration = (state) => state.orgRegistration;
