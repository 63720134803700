/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useRef, useState } from "react";

// porp-types is a library for typechecking of props
import PropTypes, { element } from "prop-types";

// react-chartjs-2 components
import { Bar, getElementAtEvent } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { Grid } from "@mui/material";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";

// HorizontalBarChart configurations
import configs from "examples/Charts/BarCharts/HorizontalBarChart/configs";
import NotificationItem from "examples/Items/NotificationItem";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React base styles
import colors from "assets/theme/base/colors";
import { useNavigate } from "react-router-dom";
import MDBadge from "components/MDBadge";
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";


function HorizontalBarChart({ icon, title, description, height, chart,dropdown, absolute, light, mini }) {
  const navigate = useNavigate(); 
  // const chartRef = useRef();
  // const onClick = (event) => {

  // }
  

  // const [navbarType, setNavbarType] = useState();
  // const [controller, dispatch] = useMaterialUIController();
  // const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  // const [openMenu, setOpenMenu] = useState(false);
  // const [openProfileMenu, setOpenProfileMenu] = useState(false);
  // const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  // const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  // const handleOpenProfileMenu = (event) => setOpenProfileMenu(event.currentTarget);
  // const handleCloseMenu = () => setOpenMenu(false);
  // const handleCloseProfileMenu = () => setOpenProfileMenu(false);

  // const renderProfileMenu = () => (
  //   <Menu
  //     anchorEl={openProfileMenu}
  //     anchorReference={null}
  //     anchorOrigin={{
  //       vertical: "bottom",
  //       horizontal: "left",
  //     }}
  //     open={Boolean(openProfileMenu)}
  //     onClose={handleCloseProfileMenu}
  //     sx={{ mt: 2 }}
  //   >
  //     <NotificationItem onClick={handleEditProfile} icon={<Icon>account_box</Icon>} title="Edit Profile" />
  //     <NotificationItem onClick={handleProfile} icon={<Icon>workspace</Icon>} title="Workspace" />
  //     <NotificationItem onClick= {handleConfiguratorOpen} icon={<Icon>settings</Icon>} title="Settings" />
  //     <NotificationItem onClick= {handleLogout} icon={<Icon>logout</Icon>} title="Logout" />
  //     {/* <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" /> */}
  //   </Menu>
  // );

  // const handleLogout = () => {
  //   navigate("/authentication/sign-in/illustration")
  // }
  // const handleProfile = () => {
  //   navigate("/pages/profile/profile-overview")
  // }
  // const handleEditProfile = () => {
  //   navigate("/pages/account/settings")
  // }
  // const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
  //   color: () => {
  //     let colorValue = light || darkMode ? white.main : dark.main;

  //     if (transparentNavbar && !light) {
  //       colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
  //     }

  //     return colorValue;
  //   },
  // });



  const chartDatasets = chart.datasets
    ? chart.datasets.map((dataset) => ({
        ...dataset,
        weight: 5,
        borderWidth: 0,
        borderRadius: 4,
        backgroundColor: colors[dataset.color]
          ? colors[dataset.color || "dark"].main
          : colors.dark.main,
        fill: false,
        maxBarThickness: 35,
      }))
    : [];

  const { data, options } = configs(
    chart.labels.map((e) => e.label) || [],
    chartDatasets
  );
  // const data = configs(chart.labels || [], chartDatasets);
  // const options = {
  //   responsive : true,
  //   onClick : (e,element)=> {

  //   }
  // }
  // const navigate = useNavigate()
  // const handleClick = () => {
  //     navigate("dashboards/attendance")
  // }
  const renderChart = (
    <MDBox py={2} pr={2} pl={icon.component ? 1 : 2}>
      {title || description ? (
        <MDBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
          
          {icon.component && (
            <MDBox
              
              width="4rem"
              height="4rem"
              bgColor={icon.color || "info"}
              variant="gradient"
              coloredShadow={icon.color || "info"}
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="info"
              mt={-5}
              mr={2}
              
            >
              
              <Icon fontSize="medium">{icon.component}</Icon>
              
            </MDBox>
          )}
          
          <MDBox width="100%" mt={icon.component ? -2 : 0}>
            {title && <MDTypography variant="h6">{title}</MDTypography>}
            <MDBox mb={2}>
              <MDTypography component="div" variant="button" color="text">
                {description}
              </MDTypography>
              
            </MDBox>
          </MDBox>
          <Grid item xs={9}>
            {dropdown && (
              <MDBox width="100%" justifyContent="right" textAlign="right" lineHeight={1}>
                <MDTypography
                  variant="button"
                  color="secondary"
                  fontWeight="regular"
                  sx={{ cursor: "pointer" }}
                  onClick={dropdown.action}
                >
                  {dropdown.value}
                </MDTypography>
                {dropdown.menu}
              </MDBox>
            )}
          </Grid>

          {/* <Grid item xs={15}>
            <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="profile-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenProfileMenu}
              >
                <MDBadge  color="error" size="xs" circular>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </MDBadge>
              </IconButton>
              {renderProfileMenu()}
          </Grid>
           */}
        </MDBox>
        
      ) : null}
      {useMemo(
        () => (
          <MDBox height={height}>
            <Bar
              data={data}
              options={options}
              getElementAtEvent={(elements, event) => {
                if (event.type === "click" && elements.length) {

                  // navigate(`/dashboards/recruitement/${chart.labels[elements[0].index].label.toLowerCase()}`)
                }
              }}
            />
          </MDBox>
        ),
        [chart, height]
      )}
        {/* <Grid item xs={5}>
              {dropdown && (
                <MDBox width="100%" textAlign="right" lineHeight={1}>
                  <MDTypography
                    variant="caption"
                    color="secondary"
                    fontWeight="regular"
                    sx={{ cursor: "pointer" }}
                    onClick={dropdown.action}
                  >
                    {dropdown.value}
                  </MDTypography>
                  {dropdown.menu}
                </MDBox>
              )}
            </Grid> */}
    </MDBox>
    
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Setting default values for the props of HorizontalBarChart
HorizontalBarChart.defaultProps = {
  icon: { color: "info", component: "" },
  title: "",
  description: "",
  height: "19.125rem",
};

// Typechecking props for the HorizontalBarChart
HorizontalBarChart.propTypes = {
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]),
    component: PropTypes.node,
  }),
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default HorizontalBarChart;
