import React, { useState, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './createForm.css';
// import { fieldsList } from './formFieldsSlice';
import { useDispatch, useSelector } from "react-redux";

const DraggableField = ({ fieldObj }) => {
    const [, ref] = useDrag({
        type: "FIELD",
        item: fieldObj,
    });

    return (
        <div ref={ref} style={{ border: "1px dashed gray", padding: "8px", marginBottom: "8px" }}>
            {fieldObj.field}
        </div>
    );
};

const CreateForm = ({ selectedData }) => {
    const dispatch = useDispatch();

    const list = useSelector((state) => state.fieldsArray.fieldsArray);
    const [formFields, setFormFields] = useState([]);
    const [responseData, setResponseData] = useState([]);
    const [labelColor, setLabelColor] = useState("");
    const [labelSize, setLabelSize] = useState("");
    const [unit, setUnit] = useState("%");
    const [buttonAlignment, setAlignment] = useState("");
    const [font, setFont] = useState("Arial");
    const [buttonColor, setbuttonColor] = useState("");
    const [fieldWidth, setFieldWidth] = useState("100%");
    const [selectedBoxProperties, setSelectedBoxProperties] = useState([]);

    useEffect(() => {

        if (formFields?.length === 0) {

            list.forEach((fieldObj) => {
                addField(fieldObj);
            });
        }
    }, [list]);

    useEffect(() => {
        setResponseData(selectedData);
        setLabelColor(selectedData?.config?.fieldStyles?.labelColor || "");
        setLabelSize(selectedData?.config?.fieldStyles?.labelSize || "");
        setFieldWidth(selectedData?.config?.fieldStyles?.width || "");
        setUnit(selectedData?.config?.fieldStyles?.unit || "");
        setAlignment(selectedData?.config?.fieldStyles?.buttonAlignment || "");
        setFont(selectedData?.config?.fieldStyles?.font || "");
        setbuttonColor(selectedData?.config?.fieldStyles?.buttonColor || "");
        setSelectedBoxProperties(selectedData?.config?.style || "");
    }, [selectedData])

    useEffect(() => {

        if (selectedData) {
            const fields = selectedData.config.field_keys;
            setFormFields(fields);
        } else if (list.length === 0){
            setFormFields([]);
        }
    }, [responseData])

    const [, drop] = useDrop({
        accept: "FIELD",
        drop: (item) => {
            addField(item);
        },
    });

    const addField = (fieldObj) => {
        setFormFields((prevFormFields) => [...prevFormFields, fieldObj]);
    };


    useEffect(() => {
      if(list.length >= 0 && formFields.length !== 0){
        formFields;
        dispatch(fieldsList({ fieldsArray: formFields }));
      }
    }, [formFields])

    const renderField = (fieldObj) => {
        const { field, type } = fieldObj;
        switch (type) {
            case "text":
                return <div>
                    <label style={{ color: labelColor, fontSize: labelSize + "px", fontFamily: font }}><b>{field}</b></label>
                    <br />
                    <TextField id="outlined-basic" label={field} variant={
                        selectedBoxProperties
                            ? selectedBoxProperties.text_field?.variant || "outlined"
                            : "outlined"
                    }
                        style={{ marginTop: "8px", width: fieldWidth + unit }} />
                </div>;
            case "number":
                return <div>
                    <label style={{ color: labelColor, fontSize: labelSize + "px", fontFamily: font }}><b>{field}</b></label>
                    <br />
                    <TextField id="outlined-basic" label={field} type="number" variant={
                        selectedBoxProperties
                            ? selectedBoxProperties.text_field?.variant || "outlined"
                            : "outlined"
                    }
                        style={{ marginTop: "8px", width: fieldWidth + unit }} />
                </div>;
            default:
                return field;
        }
    };

    const [isRowLayout, setIsRowLayout] = useState(true);

    const toggleLayout = () => {
        setIsRowLayout(!isRowLayout);
    };
    const columnsPerRow = 3;

    return (
        <div>
            <div>
                <div className="form-builder-container">
                    <div className="available-fields">
                        <h3>Available Fields</h3>
                        <div style={{ padding: "8px" }}>
                            <DraggableField fieldObj={{ field: "First Name", type: "text" }} />
                            <DraggableField fieldObj={{ field: "Last Name", type: "text" }} />
                            <DraggableField fieldObj={{ field: "Email", type: "text" }} />
                            <DraggableField fieldObj={{ field: "Phone number", type: "number" }} />
                            <DraggableField fieldObj={{ field: "Street Address", type: "text" }} />
                            <DraggableField fieldObj={{ field: "City", type: "text" }} />
                            <DraggableField fieldObj={{ field: "State/Region", type: "text" }} />
                            <DraggableField fieldObj={{ field: "Country/Region", type: "text" }} />
                            <DraggableField fieldObj={{ field: "Mobile Phone Number", type: "number" }} />
                            <DraggableField fieldObj={{ field: "Company name", type: "text" }} />
                        </div>
                        <div>
                            <Button variant="contained" onClick={toggleLayout} style={{ marginTop: "8px", color: "white" }}>
                                {isRowLayout ? "Switch to Column Layout" : "Switch to Row Layout"}
                            </Button>
                        </div>
                    </div>
                    <div className="form-preview" ref={drop} >
                        {formFields?.length === 0 ? <div className="dragDrop">Drag and drop a form field here</div>
                            : null}

                        <div style={{ display: isRowLayout ? 'flex' : 'block', flexWrap: isRowLayout ? 'wrap' : 'nowrap', padding: "8px" }}>
                            {formFields?.map((fieldObj, index) => (
                                <div key={index} style={{ width: isRowLayout ? `${100 / columnsPerRow}%` : '100%', marginBottom: "8px" }}>
                                    {renderField(fieldObj)}
                                </div>
                            ))}
                        </div>
                        <div style={{ display: "flex", justifyContent: buttonAlignment }}>
                            <Button variant="contained"
                                style={
                                    selectedBoxProperties
                                        ? { ...selectedBoxProperties.button_style, backgroundColor: buttonColor || "" } || { margin: "10px", color: 'white', backgroundColor: buttonColor || "" }
                                        : { margin: "10px", color: 'white', backgroundColor: buttonColor || "" }
                                }
                            >Submit</Button>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
};

export default CreateForm;
