import React, { useState } from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

import CardContent from "@mui/material/CardContent";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
function Verification() {
    const checkList1 = ["Email ", "Manual ", "API "];
    const [nascom, setNascom] = useState(true);
    const [association, setAssociation] = useState(true);
    const [blacklist, setBlacklist] = useState(true);

    const handleSetNascom = () => setNascom(!nascom);
    const handleSetAssociation = () => setAssociation(!association);
    const handleSetBlacklist = () => setBlacklist(!blacklist);

    return (
        <Card id='verification'>
            <CardContent><MDTypography variant="h5" fontWeight="medium">
                Verification
            </MDTypography><br />
                <MDTypography variant="body2" color="text.secondary">
                    {/* {checkList1.map((item, index) => (
                        <Card variant="button" color ="text" key={index}>
                            <row>
                                {item}
                                <input value={item} type="checkbox" />
                            </row>
                        </Card>
                    ))} */}
                <Grid container spacing={3}>
                    <Grid item xs={8} sm={5}>
                        
                            <MDBox
                            display="flex"
                            justifyContent="space-between"
                            alignItems={{ xs: "flex-start", sm: "center" }}
                            flexDirection={{ xs: "column", sm: "row" }}
                        >
                            <MDBox display="flex" alignItems="center">
                                {/* <MDAvatar src={logoAtlassian} alt="Slack logo" variant="rounded" /> */}
                                <MDBox ml={2} lineHeight={0}>
                                    <MDTypography variant="h7" fontWeight="small">
                                       NASCOM
                                    </MDTypography>
                                    {/* <MDTypography variant="button" color="text">
                                        Payment vendor
                                    </MDTypography> */}
                                </MDBox>
                            </MDBox>
                            <MDBox
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="center"
                                width={{ xs: "100%", sm: "auto" }}
                                mt={{ xs: 1, sm: 0 }}
                            >
                                <MDBox lineHeight={0} mx={2}>
                                    {/* <MDTypography variant="button" color="text">
                                        {nascom ? "Enabled" : "Disabled"}
                                    </MDTypography> */}
                                </MDBox>
                                <MDBox mr={1}>
                                    <Switch checked={nascom} onChange={handleSetNascom} />
                                </MDBox>
                            </MDBox>
                       </MDBox>
                        
                    </Grid><br />
                   
                    <Grid item xs={8} sm={5}>
                        
                            <MDBox
                            display="flex"
                            justifyContent="space-between"
                            alignItems={{ xs: "flex-start", sm: "center" }}
                            flexDirection={{ xs: "column", sm: "row" }}
                        >
                            <MDBox display="flex" alignItems="center">
                                {/* <MDAvatar src={logoAtlassian} alt="Slack logo" variant="rounded" /> */}
                                <MDBox ml={2} lineHeight={0}>
                                    <MDTypography variant="h7" fontWeight="small">
                                       Association
                                    </MDTypography>
                                    {/* <MDTypography variant="button" color="text">
                                        Payment vendor
                                    </MDTypography> */}
                                </MDBox>
                            </MDBox>
                            <MDBox
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="center"
                                width={{ xs: "100%", sm: "auto" }}
                                mt={{ xs: 1, sm: 0 }}
                            >
                                <MDBox lineHeight={0} mx={2}>
                                    {/* <MDTypography variant="button" color="text">
                                        {association ? "Enabled" : "Disabled"}
                                    </MDTypography> */}
                                </MDBox>
                                <MDBox mr={1}>
                                    <Switch checked={association} onChange={handleSetAssociation} />
                                </MDBox>
                            </MDBox>
                       </MDBox>
                        
                    </Grid><br />
                    <Grid item xs={8} sm={5}>
                        
                            <MDBox
                            display="flex"
                            justifyContent="space-between"
                            alignItems={{ xs: "flex-start", sm: "center" }}
                            flexDirection={{ xs: "column", sm: "row" }}
                        >
                            <MDBox display="flex" alignItems="center">
                                {/* <MDAvatar src={logoAtlassian} alt="Slack logo" variant="rounded" /> */}
                                <MDBox ml={2} lineHeight={0}>
                                    <MDTypography variant="h7" fontWeight="small">
                                       Self Blacklisted
                                    </MDTypography>
                                    {/* <MDTypography variant="button" color="text">
                                        Payment vendor
                                    </MDTypography> */}
                                </MDBox>
                            </MDBox>
                            <MDBox
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="center"
                                width={{ xs: "100%", sm: "auto" }}
                                mt={{ xs: 1, sm: 0 }}
                            >
                                <MDBox lineHeight={0} mx={2}>
                                    {/* <MDTypography variant="button" color="text">
                                        {blacklist ? "Enabled" : "Disabled"}
                                    </MDTypography> */}
                                </MDBox>
                                <MDBox mr={1}>
                                    <Switch checked={blacklist} onChange={handleSetBlacklist} />
                                </MDBox>
                            </MDBox>
                       </MDBox>
                        
                    </Grid><br />
                
               
                </Grid>                      
                
                   
                    
                </MDTypography>
            </CardContent>
        </Card>
    )
}
export default Verification;