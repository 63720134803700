/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewUser page components
import EventInfo from "layouts/marketing/newEvent/components/EventInfo";
import Schedulling from "layouts/marketing/newEvent/components/Schedulling";
import Booking from "layouts/marketing/newEvent/components/Booking";
import Profile from "layouts/marketing/newEvent/components/Profile";

// NewUser layout schemas for form and form feilds
import validations from "layouts/marketing/newEvent/schemas/validations";
import form from "layouts/marketing/newEvent/schemas/form";
import ApiCalls from "services/flick_erp_api_call";
import EditIcon from "@mui/icons-material/Edit";

import {
  Button,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
// import { newMeetingList } from "./newEventSlice";
import { base_url } from "services";

import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { newMeetingList } from "./newEventSlice";
import {
  getInitialValues,
  getInitialValuesForAuthCode,
} from "./schemas/initialValues";
// import newMeetingList from "./newEventSlice";

function getSteps() {
  return ["Event Info", "Schedule Setting", "Booking Form"];
}

function NewEvent() {
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const dispatch = useDispatch();
  const meetinglist = useSelector((state) => state?.newEvents?.newMeetings);

  const [isEventEdit, setIsEventEdit] = useState();
  const [eventEditItem, setEventEditItem] = useState();

  const [isGmeetAuthenticated, setIsGmeetAuthenticated] = useState(true);

  const handleEditEvent = (event) => {
    localStorage.setItem("editedEvent", JSON.stringify(event));
    setEventEditItem(event);
    setIsEventEdit(true);
  };

  const getAuthToken = () => {
    const googleOAuthURL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=938201927476-qdqsjasno8319mui54olm6mj7gn8r75m.apps.googleusercontent.com&redirect_uri=http://${window.location.host}/marketing/newEvent&response_type=code&scope=https://www.googleapis.com/auth/calendar`;
    console.log(googleOAuthURL);
    window.location = googleOAuthURL;
  };

  const getGmeetAuthenticateStatus = async () => {
    var res = await ApiCalls.getStatusOfGoogleAuthenticate();
    if (window.location.href.includes("code")) {
      setIsListing(false);
      const storedEvent = localStorage.getItem("editedEvent");

      // Check if there's a stored event
      if (storedEvent && checkForEdit()) {
        const parsedEvent = JSON.parse(storedEvent);
        // Do something with the parsedEvent
        handleEditEvent(parsedEvent);
      } else {
        setColor("#2679DF");
        setSelectedLocation({
          label: "Meet",
          value: "meet",
          icon: <VideocamOutlinedIcon color="#344767" />,
        });
      }
    }
    setIsGmeetAuthenticated(res.success ? true : false);
  };

  async function postGoogleResponseCode(code) {
    var data = {
      code: code,
    };
    var res = ApiCalls.postResponseCode(data, false);
    setIsListing(false);
    const storedEvent = localStorage.getItem("editedEvent");

    // Check if there's a stored event
    if (storedEvent && checkForEdit()) {
      const parsedEvent = JSON.parse(storedEvent);
      // Do something with the parsedEvent
      handleEditEvent(parsedEvent);
    } else {
      setColor("#2679DF");
      setSelectedLocation({
        label: "Meet",
        value: "meet",
        icon: <VideocamOutlinedIcon color="#344767" />,
      });
    }
  }

  const checkForEdit = () => {
    const editEvent = localStorage.getItem("isEdit");

    // Check if there's a stored event
    if (editEvent) {
      const parsedEvent = JSON.parse(editEvent);
      if (parsedEvent.isEdit) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    getGmeetAuthenticateStatus();

    if (window.location.href.includes("code") && !isGmeetAuthenticated) {
      const query = new URLSearchParams(window.location.search);
      const code = query.get("code");
      postGoogleResponseCode(code);
    }
  }, []);

  const checkIfAlreadyAuthenticateOrNot = (item) => {
    if (!isGmeetAuthenticated) {
      getAuthToken();
    } else {
      setSelectedLocation(item);
    }
  };

  function getStepContent(stepIndex, formData) {
    switch (stepIndex) {
      case 0:
        return (
          <EventInfo
            formData={formData}
            color={color}
            setColor={setColor}
            duration={duration}
            setDuration={setDuration}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            custom={custom}
            setCustom={setCustom}
            selectedLocationError={selectedLocationError}
            getAuthToken={checkIfAlreadyAuthenticateOrNot}
          />
        );
      case 1:
        return (
          <Schedulling
            formData={formData}
            custom={custom}
            setCustom={setCustom}
            duration={duration}
            setDuration={setDuration}
            scheduleSetting={scheduleSetting}
            setScheduleSetting={setScheduleSetting}
            daysOfWeek={daysOfWeek}
            defaultTimeRange={defaultTimeRange}
            defaultUnavailableTime={defaultUnavailableTime}
            timeRanges={timeRanges}
            setTimeRanges={setTimeRanges}
            initialData={
              isEventEdit
                ? eventEditItem?.schedule_setting?.availablityHours
                : {}
            }
            isEventEdit={isEventEdit}
          />
        );
      case 2:
        return (
          <Booking
            formData={formData}
            defaultQue={defaultQue}
            questions={questions}
            setDefaultQue={setDefaultQue}
            setQuestions={setQuestions}
            eventLink={eventLink}
            setEventLink={setEventLink}
          />
        );

      default:
        return null;
    }
  }

  const [color, setColor] = useState("#2679DF");
  const [duration, setDuration] = useState("15");
  const [selectedLocation, setSelectedLocation] = useState(null);

  //booking state
  const initialQuestions = [
    {
      status: true,
      options: null,
      required: true,
      answerType: "text",
      questionText: "Name",
    },
    {
      status: true,
      options: null,
      required: true,
      answerType: "email",
      questionText: "Email",
    },
    {
      status: false,
      options: null,
      required: true,
      answerType: "text",
      questionText: "Allow invitees to add guests",
    },
  ];
  const [defaultQue, setDefaultQue] = useState(initialQuestions);
  const [questions, setQuestions] = useState([]);
  const [eventLink, setEventLink] = useState("");

  const locationTypes = [
    {
      label: "Meet",
      value: "meet",
      icon: <VideocamOutlinedIcon color="#344767" />,
    },
    {
      label: "Phone Call",
      value: "phone_call",
      icon: <PhoneOutlinedIcon color="#344767" />,
    },
    {
      label: "In-person Meeting",
      value: "in_person",
      icon: <LocationOnOutlinedIcon color="#344767" />,
    },
  ];

  //schedule state

  const initialCustom = {
    customDuration: isEventEdit
      ? eventEditItem?.schedule_setting?.customDuration
      : "15",
    customTimeIn: isEventEdit
      ? eventEditItem?.schedule_setting?.customTimeIn
      : "min",
  };
  const [custom, setCustom] = useState(initialCustom);

  const initialSchedule = {
    dateRange: isEventEdit ? eventEditItem?.schedule_setting?.dateRange : "10",
    days: isEventEdit ? eventEditItem?.schedule_setting?.days : "weekdays",
  };
  const [scheduleSetting, setScheduleSetting] = useState(initialSchedule);

  //availablity hours

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const defaultTimeRange = { start: "10:00", end: "18:00" };
  const defaultUnavailableTime = { start: "Unavailable", end: "Unavailable" };
  const [timeRanges, setTimeRanges] = useState(
    daysOfWeek.reduce((acc, day) => {
      if (day === "Sun" || day === "Sat") {
        return {
          ...acc,
          [day]: defaultUnavailableTime,
        };
      } else {
        return { ...acc, [day]: { ...defaultTimeRange } };
      }
    }, {})
  );

  const [triedSubmit, setTriedSubmit] = useState(false);
  const [isListing, setIsListing] = useState(true);

  const selectedLocationError = triedSubmit && selectedLocation === null;

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);

  const resetStateVariables = () => {
    setTriedSubmit(false);
    setColor("#2679DF");
    setDuration("15");
    setSelectedLocation(null);
    setDefaultQue(initialQuestions);
    setQuestions([]);
    setEventLink("");
    setCustom(initialCustom);
    setScheduleSetting(initialSchedule);
    setTimeRanges(
      daysOfWeek.reduce((acc, day) => {
        if (day === "Sun" || day === "Sat") {
          return {
            ...acc,
            [day]: defaultUnavailableTime,
          };
        } else {
          return { ...acc, [day]: { ...defaultTimeRange } };
        }
      }, {})
    );
  };
  const callback = (actions) => {
    actions.setSubmitting(false);
    actions.resetForm();
    setActiveStep(0);
    resetStateVariables();
    setIsEventEdit(false);
    setIsListing(true);
  };

  const getCalenderMeetList = async (data) => {
    const res = await ApiCalls.getEventConfigList(data);
    if (res.success && (res.code == 201 || res.code == 200)) {
      dispatch(newMeetingList(res.data));
    } else if (res.success == false && res.code == 400) {
      const errors = res.data ?? "Opps, Something went wrong";
      alert(errors);
    } else {
    }
  };

  useEffect(() => {
    if (isListing) {
      getCalenderMeetList();
      resetStateVariables();
      setActiveStep(0);
    }
  }, [isListing]);

  const submitForm = async (values, actions) => {
    const handleCreateEventConfig = async (data) => {
      const res = await ApiCalls.createEventConfig(data);
      if (res.success && (res.code == 201 || res.code == 200)) {
        callback(actions);
      } else if (res.success == false && res.code == 400) {
        const errors = res.data ?? "Opps, Something went wrong";
        alert(errors);
      } else {
      }
    };

    const handleUpdateEventConfig = async (data) => {
      const res = await ApiCalls.updateEventConfig(eventEditItem.id, data);
      if (res.success && (res.code == 201 || res.code == 200)) {
        callback(actions);
      } else if (res.success == false && res.code == 400) {
        const errors = res.data ?? "Opps, Something went wrong";
        alert(errors);

      } else {
      }
    };

    const payload = {
      event_name: values?.eventName,
      invitee_email: values?.email,
      background_color: color,
      location_type: selectedLocation?.value,
      schedule_setting: {
        duration: duration,
        customDuration: duration === "Custom" ? custom?.customDuration : null,
        customTimeIn: duration === "Custom" ? custom?.customTimeIn : null,
        dateRange: scheduleSetting.dateRange ?? null,
        days: scheduleSetting.days ?? null,
        availablityHours: timeRanges,
      },
      booking_info: {
        inviteeQuestion: [...defaultQue, ...questions],
        // inviteeQuestion: [
        //   {
        //     status: true,
        //     required: true,
        //     answerType: "One Line",
        //     questionText: "Name,Email",
        //     options: null,
        //   },
        //   {
        //     status: true,
        //     required: true,
        //     answerType: "One Line",
        //     questionText: "Allow invitees to add guests",
        //     options: null,
        //   },
        //   {
        //     status: true,
        //     required: true,
        //     answerType: "Phone number",
        //     questionText: "Phone Number",
        //     options: null,
        //   },
        //   {
        //     status: true,
        //     required: true,
        //     answerType: "Checkboxes",
        //     questionText: "Select your hobbies",
        //     options: {
        //       optionsValue: ["Cricket", "football","Study","Reading"],
        //       selected: "Cricket",
        //     },
        //   },
        //   {
        //     status: true,
        //     required: true,
        //     answerType: "Radio Button",
        //     questionText: "Choose your gender",
        //     options: {
        //       optionsValue: ['Male', "Female","Other"],
        //       selected: "Male",
        //     },
        //   },
        //   {
        //     status: true,
        //     required: true,
        //     answerType: "Dropdown",
        //     questionText: "Select your country",
        //     options: {
        //       optionsValue: ['USA', "Canada","India",'Australia'],
        //       selected: "India",
        //     },
        //   },
        // ],
        eventLink: eventLink,
      },
      org: JSON.parse(localStorage.getItem("user")).org_id,
      employee: JSON.parse(localStorage.getItem("user"))?.employee_id,
    };

    isEventEdit
      ? handleUpdateEventConfig(payload)
      : handleCreateEventConfig(payload);
  };

  const handleSubmit = (values, actions) => {
    setTriedSubmit(true);
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      selectedLocation !== null && setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const toggleView = () => {
    localStorage.setItem(
      "isEdit",
      JSON.stringify({
        isEdit: false,
      })
    );
    setIsListing(!isListing);
    setIsEventEdit(false);
    setColor("#2679DF");
  };

  const [buttonId, setButtonId] = useState(-1);

  const handleCopyLink = (item) => {
    const copyText = `${base_url}${item?.linkText}`;
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        setButtonId(item?.id);
        setTimeout(() => {
          setButtonId(-1);
        }, 1500);
      })
      .catch((error) => {
        console.error("Copy failed:", error);
      });
  };

  const saveEditValue = async () => {
    if (isEventEdit && eventEditItem) {
      const desiredLocation = locationTypes.find(
        (locationType) => locationType.value === eventEditItem?.location_type
      );

      setSelectedLocation(desiredLocation);
      setColor(eventEditItem?.background_color);
      const desiredQuestionTexts = [
        "Name",
        "Email",
        "Allow invitees to add guests",
      ];
      const filteredDefaultQuestions =
        eventEditItem?.booking_info?.inviteeQuestion?.filter((question) =>
          desiredQuestionTexts.includes(question.questionText)
        );
      setDefaultQue(filteredDefaultQuestions);
      const filteredQuestions =
        eventEditItem?.booking_info?.inviteeQuestion?.filter(
          (question) => !desiredQuestionTexts.includes(question.questionText)
        );
      setQuestions(filteredQuestions);
      setCustom(initialCustom);
      setDuration(eventEditItem?.schedule_setting?.duration);
      setScheduleSetting(initialSchedule);
      // setTimeRanges(eventEditItem?.schedule_setting?.availablityHours);
    }
  };

  useEffect(() => {
    if (isEventEdit) {
      setIsListing(false);
      saveEditValue();
    }
  }, [isEventEdit]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDButton
        variant="gradient"
        color="info"
        size="small"
        onClick={toggleView}
      >
        {isListing ? "New Meeting" : "Listing"}
      </MDButton>
      {isListing ? (
        <MDBox
          py={3}
          sx={{
            minHeight: "60vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap", // Enable wrapping to the next line
              justifyContent: "flex-start",
            }}
          >
            {meetinglist?.map((item, index) => {
              return (
                <Card sx={{ width: 300, margin: 2 }}>
                  <CardActionArea>
                    <div
                      style={{
                        backgroundColor: item?.background_color ?? "#2679DF",
                        height: 15,
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    ></div>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {item?.event_name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {item?.schedule_setting?.duration === "Custom"
                          ? ` ${item?.schedule_setting?.customDuration},${item?.schedule_setting?.customTimeIn}`
                          : `${item?.schedule_setting?.duration},min`}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => {
                        handleCopyLink(item);
                      }}
                    >
                      {buttonId === item?.id ? "Copied" : "Copy Link"}
                    </Button>
                    <Tooltip
                      title="Edit"
                      onClick={() => {
                        localStorage.setItem(
                          "isEdit",
                          JSON.stringify({
                            isEdit: true,
                          })
                        );

                        handleEditEvent(item);
                      }}
                    >
                      <EditIcon size="small" color="dark" />
                    </Tooltip>
                  </CardActions>
                </Card>
              );
            })}
          </div>
        </MDBox>
      ) : (
        <MDBox py={3} mb={20} height="65vh">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%", mt: 8 }}
          >
            <Grid item xs={12} lg={8}>
              <Formik
                initialValues={
                  checkForEdit()
                    ? getInitialValuesForAuthCode()
                    : getInitialValues(false, eventEditItem)
                }
                validationSchema={currentValidation}
                onSubmit={handleSubmit}
              >
                {({ values, errors, touched, isSubmitting }) => (
                  <Form id={formId} autoComplete="off">
                    <Card sx={{ height: "100%" }}>
                      <MDBox mx={2} mt={-3}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                          {steps.map((label) => (
                            <Step key={label}>
                              <StepLabel>{label}</StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </MDBox>
                      <MDBox p={3}>
                        <MDBox>
                          {getStepContent(activeStep, {
                            values,
                            touched,
                            formField,
                            errors,
                          })}
                          <MDBox
                            mt={2}
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                          >
                            {activeStep === 0 ? (
                              <MDBox />
                            ) : (
                              <MDButton
                                variant="gradient"
                                color="light"
                                onClick={handleBack}
                              >
                                back
                              </MDButton>
                            )}
                            <MDButton
                              disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              {isLastStep ? "Save" : "next"}
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </MDBox>
      )}

      <Footer />
    </DashboardLayout>
  );
}

export default NewEvent;
