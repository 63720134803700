import { createSlice } from "@reduxjs/toolkit";

const interviewsSlice = createSlice({
    name : 'interviews',
    initialState : {
        interviews : [],
    },
    reducers : {
        interviewsList : (state,action) => {
            state.interviews = action.payload.interviews;
        },
      
    }
});

export const {interviewsList} = interviewsSlice.actions;
export default interviewsSlice.reducer;