import { identity } from "@fullcalendar/react";
import {
  Organisation_Create,
  Login,
  Organisation,
  Employees,
  Global_Permissions,
  Change_Password,
  Job_Advertisement,
  Screening,
  Candidate,
  Interview,
  UploadCV,
  Attendance,
  Shifts,
  Office_Shifts,
  Bulk_Shift,
  Holidays,
  Upload_Attendance,
  GOOGLE_CALLBACK,
  Organisation_Settings,
  Employee_Reporting_Manager,
  Department,
  Designations,
  Job_Offers,
  Document_Type,
  Leave_Settings,
  Leave_Balance,
  Leave_Requests,
  Roles,
  Leave_Approval,
  Notice_Board,
  Notice_Board_Read,
  EmployeeContract,
  forms_Config,
  Create_Event_Config,
  SalaryHeads,
  Bank_Details,
  Recruitement_Dashboard_Counts,
  Recruitment_Pie_Data,
  HierarchyEmployees,
  OrganisationHierarchy,
  Leave_Transactions,
  Validate_Leave_Transaction,
  Employee_Leave_balance,
  TotalShiftHours,
  LeaveRefresh,
  AttendanceList,
  EmployeeEvent,
  UserDashboard,
  RefreshToken,
  Salary,
  ExpensesIncentiveDeductions,
  AttendanceConsiderRequest,
  TempCard,
} from "./apis";
import services from "services";
import { setToastOpen } from "components/Toast/toastAlertSlice";
import { store } from "../store";

const ApiCalls = {
  // Organisation

  getOrganisationSetting: async () => {
    try {
      var response = await services.get(Organisation_Settings, true);
      return response;
    } catch (error) {
      return error;
    }
  },
  createOrg: async (data) => {
    try {
      var response = await services.post(Organisation_Create, data, false);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Organisation Created Successfully",
            })
          )
        : null;
      return response;
    } catch (error) {
      return error;
    }
  },
  logIn: async (data) => {
    try {
      var response = await services.post(Login, data);
      response.success
        ? store.dispatch(
            setToastOpen({ isOpen: true, message: "Login Successfully" })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  refreshToken: async () => {
    // var data = {"refresh":""},
    try {
      var response = await services.post(RefreshToken, data);
      // response.success
      //   ? store.dispatch(
      //       setToastOpen({ isOpen: true, message: "Login Successfully" })
      //     )
      //   : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  getEmployeesList: async (queryParams) => {
    try {
      var response = await services.get(   queryParams ? Employees + queryParams : Employees,  true);

      return response;
    } catch (error) {
      return error;
    }
  },
  getHierarchyEmployeesList: async () => {
    try {
      var response = await services.get(HierarchyEmployees, true);

      return response;
    } catch (error) {
      return error;
    }
  },
  getOrganisationHierarchyList: async () => {
    try {
      var response = await services.get(OrganisationHierarchy, true);
      return response;
    } catch (error) {
      return error;
    }
  },
  
  getEmployee: async (empId) => {
    try {
      var response = await services.get(`${Employees}${empId}`, true);

      return response;
    } catch (error) {
      return error;
    }
  },
  createEmployee: async (data, isFormData) => {
    try {
      var response = await services.post(Employees, data, true, isFormData);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Employee Created Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  editEmployee: async (empId, data, isFormData) => {
    try {
      var response = await services.patch(
        `${Employees}${empId}/`,
        data,
        true,
        isFormData
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Employee Updated Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  deleteEmployee: async (empId) => {
    try {
      var response = await services.delete(`${Employees}${empId}/`, true);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Employee Deleted Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  changePassword: async (data) => {
    try {
      var response = await services.patch(Change_Password, data, true);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Password Changed Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  getGlobalPermissions: async () => {
    try {
      var response = await services.get(`${Global_Permissions}`, true);

      return response;
    } catch (error) {
      return error;
    }
  },
  getReportingManagers: async (employeeId) => {
    try {
      var response = await services.get(
        `${Employee_Reporting_Manager}?employee_id=${employeeId}`
      );

      return response;
    } catch (error) {
      return error;
    }
  },
  postReportingManager: async (data, isFormData) => {
    try {
      var response = await services.post(
        Employee_Reporting_Manager,
        data,
        true,
        isFormData
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Reporting Manager Created Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  deleteReportingManager: async (data, isFormData) => {
    try {
      var response = await services.post(
        Employee_Reporting_Manager,
        data,
        true,
        isFormData
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Reporting Manager Deleted Successfully",
            })
          )
        : null;
      return response;
    } catch (error) {
      return error;
    }
  },
  // Department

  getDepartment: async () => {
    try {
      var response = await services.get(`${Department}`, true);

      return response;
    } catch (error) {
      return error;
    }
  },
  postDepartment: async (data, isFormData) => {
    try {
      var response = await services.post(Department, data, true, isFormData);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Department Created Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  deleteDepartment: async (depId) => {
    try {
      var response = await services.delete(`${Department}${depId}/`, true);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Department Deleted Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  // Designation

  getDesignation: async () => {
    try {
      var response = await services.get(`${Designations}`, true);

      return response;
    } catch (error) {
      return error;
    }
  },
  postDesignation: async (data, isFormData) => {
    try {
      var response = await services.post(Designations, data, true, isFormData);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Designation Created Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  deleteDesignation: async (id) => {
    try {
      var response = await services.delete(`${Designations}${id}/`, true);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Designation Deleted Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  // User Dashboard 
  getUserDashboard: async () => {
    try {
      var response = await services.get(`${UserDashboard}`, true);
      return response;
    } catch (error) {
      return error;
    }
  },
  // Recruitment Dashboard
  getRecruitmentDashboardCounts: async () => {
    try {
      var response = await services.get(`${Recruitement_Dashboard_Counts}/`, true);
      return response;
    } catch (error) {
      return error;
    }
  },
  getRecruitmentDashboardPieData: async () => {
    try {
      var response = await services.get(`${Recruitment_Pie_Data}/`, true);
      return response;
    } catch (error) {
      return error;
    }
  },
  

  // Advertisements
  getAdvertisement: async (id) => {
    try {
      var response = await services.get(`${Job_Advertisement}${id}/`, true);

      return response;
    } catch (error) {
      return error;
    }
  },
  getAdvertisementList: async () => {
    try {
      var response = await services.get(Job_Advertisement, true);

      return response;
    } catch (error) {
      return error;
    }
  },
  createAdvertisement: async (data) => {
    try {
      var response = await services.post(Job_Advertisement, data, true);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Job Advertisement Created Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  updateAdvertisement: async (id, data) => {
    try {
      var response = await services.put(
        `${Job_Advertisement}${id}/`,
        data,
        true
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Job Advertisement Updated Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  updateJobAdvertisement: async (id, data) => {
    try {
      var response = await services.patch(
        `${Job_Advertisement}${id}/`,
        data,
        true
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Job Advertisement Updated Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  deleteAdvertisement: async (id) => {
    try {
      var response = await services.delete(`${Job_Advertisement}${id}/`, true);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Job Advertisement Deleted Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },

  // screening
  getScreening: async (queryParams) => {
    try {
      var response = await services.get(
        queryParams ? Screening + queryParams : Screening,
        true
      );

      return response;
    } catch (error) {
      return error;
    }
  },
  getScreeningStatusAndSource: async () => {
    try {
      var response = await services.get(`${Screening}get_option_value/`, true);

      return response;
    } catch (error) {
      return error;
    }
  },
  screening: async (id) => {
    try {
      var response = await services.get(`${Screening}${id}`, true);

      return response;
    } catch (error) {
      return error;
    }
  },
  postScreening: async (formData) => {
    try {
      var response = await services.post(Screening, formData, true, true);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Screening Created Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },

  updateScreening: async (id, formData) => {
    try {
      var response = await services.put(`${Screening}${id}`, true, formData);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Screening Updated Successfully",
            })
          )
        : null;
      return response;
    } catch (error) {
      return error;
    }
  },
  partialUpdateScreening: async (id, formData) => {
    try {
      var response = await services.patch(
        `${Screening}${id}/`,
        formData,
        true,
        true
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Screening Updated Successfully",
            })
          )
        : null;
      return response;
    } catch (error) {
      return error;
    }
  },
  deleteScreening: async (id) => {
    try {
      var response = await services.delete(`${Screening}${id}/`, true);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Screening Deleted Successfully",
            })
          )
        : null;
      return response;
    } catch (error) {
      return error;
    }
  },
  uploadCV: async (data, formData) => {
    try {
      var response = await services.post(`${UploadCV}`, data, true, formData);
      response.success
        ? store.dispatch(
            setToastOpen({ isOpen: true, message: "Upload CV Successfully" })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  convertToCandidate: async (id, data) => {
    try {
      var response = await services.post(
        `${Screening}${id}/convert_to_candidates/`,
        data,
        true
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Candidate Converted Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },

  // Candidates

  getCandidatesList: async (queryParams) => {
    try {
      var response = await services.get(
        queryParams ? Candidate + queryParams : Candidate,
        true
      );

      return response;
    } catch (error) {
      return error;
    }
  },
  getCandidate: async (id) => {
    try {
      var response = await services.get(`${Candidate}${id}`, true);

      return response;
    } catch (error) {
      return error;
    }
  },
  getCandiateStatusAndSource: async () => {
    try {
      var response = await services.get(`${Candidate}get_option_value/`, true);

      return response;
    } catch (error) {
      return error;
    }
  },

  postCandidate: async (formData) => {
    try {
      var response = await services.post(Candidate, formData, true, true);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Candidate Created Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },

  updateCandidate: async (id, data, formData) => {
    try {
      var response = await services.patch(
        `${Candidate}${id}/`,
        data,
        true,
        formData
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Candidate Updated Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  deleteCandidate: async (id) => {
    try {
      var response = await services.delete(`${Candidate}${id}/`, true);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Candidate Deleted Successfully",
            })
          )
        : null;
      return response;
    } catch (error) {
      return error;
    }
  },
  getInterviewsList: async (queryParams) => {
    try {
      var response = await services.get(
        queryParams ? Interview + queryParams : Interview,
        true
      );

      return response;
    } catch (error) {
      return error;
    }
  },
  getInterviews: async (id) => {
    try {
      var response = await services.get(`${Interview}${id}`, true);

      return response;
    } catch (error) {
      return error;
    }
  },
  postInterview: async (data) => {
    try {
      var response = await services.post(Interview, data, true);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Interview Scheduled Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  patchInterview: async (data, interviewId, formData) => {
    try {
      var response = await services.patch(
        `${Interview}${interviewId}/`,
        data,
        true,
        formData
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Interview Updated Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  deleteInterview: async (id) => {
    try {
      var response = await services.delete(`${Interview}${id}`, true);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Interview Deleted Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },

  // Job Offers

  getJobOffers: async () => {
    try {
      var response = await services.get(`${Job_Offers}`, true);

      return response;
    } catch (error) {
      return error;
    }
  },
  postJobOffer: async (data, isFormData) => {
    try {
      var response = await services.post(Job_Offers, data, true, isFormData);
      response.success
        ? store.dispatch(
            setToastOpen({ isOpen: true, message: "Job Offered Successfully" })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  patchJobOffer: async (jobOfferId, data, isFormData) => {
    try {
      var response = await services.patch(
        `${Job_Offers}${jobOfferId}/`,
        data,
        true,
        isFormData
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Job Offer Updated Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  deleteJobOffer: async (id) => {
    try {
      var response = await services.delete(`${Job_Offers}${id}`, true);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Job Offer Deleted Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },

  // Attendance
  getAttendanceList: async (queryParams) => {
    try {
      var response = await services.get(
        queryParams ? Attendance + queryParams : Attendance,
        true
      );

      return response;
    } catch (error) {
      return error;
    }
  },
  getAttendanceListFromID: async (id) => {
    try {
      var response = await services.get(`${Attendance}${id}`, true);

      return response;
    } catch (error) {
      return error;
    }
  },

  uploadAttendance: async (data, isFormData) => {
    try {
      var response = await services.post(
        Upload_Attendance,
        data,
        true,
        isFormData
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Attendated Created Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  uploadAttendanceCorrection: async (data, isFormData) => {
    try {
      var response = await services.post(
        AttendanceList,
        data,
        true,
        isFormData
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Attendance Correction Added Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  getattendanceConsiderRequestList: async (queryParams) => {
    try {
      var response = await services.get(
        queryParams ? AttendanceConsiderRequest + queryParams : AttendanceConsiderRequest,
        true
      );

      return response;
    } catch (error) {
      return error;
    }
  },
  patchAttendanceCorrection: async (id, data, isFormData) => {
    try {
      var response = await services.patch(
        `${AttendanceConsiderRequest}${id}/`,
        data,
        true,
        isFormData
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Attendance Request Approved Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  attendanceConsiderRequest: async (data, isFormData) => {
    try {
      var response = await services.post(
        AttendanceConsiderRequest,
        data,
        true,
        isFormData
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Attendance Consider Requested Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  updateAttendance: async () => {
    try {
      var response = await services.patch(Attendance, true);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Attendated Updated Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  // Temp Cards 
  postTempCards: async (data, isFormData) => {
    try {
      var response = await services.post(
        TempCard,
        data,
        true,
        isFormData
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Temp Card Created Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  getTempCards: async (queryParams) => {
    try {
      var response = await services.get(
        queryParams ? TempCard + queryParams : TempCard,
        true
      );

      return response;
    } catch (error) {
      return error;
    }
  },
  deleteTempCard: async (id) => {
    try {
      var response = await services.delete(`${TempCard}${id}/`, true);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Temp Card Deleted Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  // Shifts
  getEmployeeShifts: async (queryParams) => {
    try {
      var response = await services.get(
        queryParams ? Shifts + queryParams : Shifts,
        true
      );

      return response;
    } catch (error) {
      return error;
    }
  },
  createEmployeeShift: async (data, isFormData) => {
    try {
      var response = await services.post(Bulk_Shift, data, true, isFormData);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Employee Shift Created Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  updateShift: async (id, data, isFormData) => {
    try {
      var response = await services.patch(
        `${Shifts}${id}/`,
        data,
        true,
        isFormData
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Shift Updated Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  deleteShift: async () => {
    try {
      var response = await services.delete(Shifts, true);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Employee Shift Deleted Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  getOfficeShifts: async () => {
    try {
      var response = await services.get(Office_Shifts, true);

      return response;
    } catch (error) {
      return error;
    }
  },
  createOfficeShifts: async (data, isFormData) => {
    try {
      var response = await services.post(Office_Shifts, data, true, isFormData);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Office Shift Created Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  patchOfficeShifts: async (data, id, isFormData) => {
    try {
      var response = await services.patch(
        Office_Shifts + id + "/",
        data,
        true,
        isFormData
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Office Shift Updated Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  deleteOfficeShift: async (id) => {
    try {
      var response = await services.delete(`${Office_Shifts}${id}/`, true);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Office Shift Deleted Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  // Holidays
  getholidaysList: async () => {
    try {
      var response = await services.get(Holidays, true);

      return response;
    } catch (error) {
      return error;
    }
  },
  createHolidays: async (data, isFormData) => {
    try {
      var response = await services.post(Holidays, data, true, isFormData);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Holiday Created Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  updateHoliday: async (id, data, isFormData) => {
    try {
      var response = await services.put(
        `${Holidays}${id}/`,
        data,
        true,
        isFormData
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Holiday Updated Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  deleteHoliday: async (id) => {
    try {
      var response = await services.delete(`${Holidays}${id}/`, true);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Holiday Deleted Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  // google calendar authenticate

  postResponseCode: async (data, isFormData) => {
    try {
      var response = await services.post(
        GOOGLE_CALLBACK,
        data,
        true,
        isFormData
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Response Code Generated Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  getStatusOfGoogleAuthenticate: async () => {
    try {
      var response = await services.get(GOOGLE_CALLBACK, true);

      return response;
    } catch (error) {
      return error;
    }
  },
  // Document types
  getDocuments: async () => {
    try {
      var response = await services.get(Document_Type, true);

      return response;
    } catch (error) {
      return error;
    }
  },
  postDocumentTypes: async (data, isFormData) => {
    try {
      var response = await services.post(Document_Type, data, true, isFormData);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Document Type Created Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  deleteDocumentType: async (id) => {
    try {
      var response = await services.delete(`${Document_Type}${id}/`, true);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Document Type Deleted Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  // Leave

  getLeavesTypes: async () => {
    try {
      var response = await services.get(Leave_Settings, true);

      return response;
    } catch (error) {
      return error;
    }
  },
  deleteLeaveType: async (id) => {
    try {
      var response = await services.delete(`${Leave_Settings}${id}/`, true);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Leave Type Deleted Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  postLeaveTypes: async (data, isFormData) => {
    try {
      var response = await services.post(
        Leave_Settings,
        data,
        true,
        isFormData
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Leave Type Created Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  postLeaveRefresh: async (data, isFormData) => {
    try {
      var response = await services.post(
        LeaveRefresh,
        data,
        true,
        isFormData
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Leave Refresh Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  getLeaveBalance: async () => {
    try {
      var response = await services.get(Leave_Balance, true);

      return response;
    } catch (error) {
      return error;
    }
  },
  getShiftHours: async (queryParams) => {
    try {
      var response = await services.get(TotalShiftHours+queryParams, true);
      return response;
    } catch (error) {
      return error;
    }
  },
  getEmployeeLeaveBalance: async (id) => {
    try {
      var response = await services.get(Employee_Leave_balance+id, true);

      return response;
    } catch (error) {
      return error;
    }
  },
  postLeaveBalance: async (data, isFormData) => {
    try {
      var response = await services.post(Leave_Balance, data, true, isFormData);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Leave Balance Created Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  patchLeaveBalance: async (data, id, isFormData) => {
    try {
      var response = await services.patch(
        Leave_Balance + id + "/",
        data,
        true,
        isFormData
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Leave Balance Updated Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  getLeaveTransactions: async () => {
    try {
      var response = await services.get(Leave_Transactions, true);

      return response;
    } catch (error) {
      return error;
    }
  },
  postValidateLeaveTransaction: async (data, isFormData) => {
    try {
      var response = await services.post(Validate_Leave_Transaction, data, true, isFormData);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Validate Leave Transaction Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  getLeaveRequest: async () => {
    try {
      var response = await services.get(Leave_Requests, true);

      return response;
    } catch (error) {
      return error;
    }
  },

  postLeaveRequest: async (data, isFormData) => {
    try {
      var response = await services.post(
        Leave_Requests,
        data,
        true,
        isFormData
      );

      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Leave Request Created Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  updateLeaveRequest: async (id, data, isFormData) => {
    try {
      var response = await services.patch(
        `${Leave_Requests}${id}/`,
        data,
        true,
        isFormData
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Leave Request Updated Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  deleteLeaveRequest: async (id) => {
    try {
      var response = await services.delete(`${Leave_Requests}${id}/`, true);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Leave Request Deleted Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  getLeaveApproval: async () => {
    try {
      var response = await services.get(Leave_Approval, true);
      return response;
    } catch (error) {
      return error;
    }
  },
  updateLeaveApprovalAsManager: async (id, data, isFormData) => {
    try {
      var response = await services.patch(
        `${Leave_Approval}${id}/approve_as_manager/`,
        data,
        true,
        isFormData
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Leave Approval Updated Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  updateLeaveApprovalAsTL: async (id, data, isFormData) => {
    try {
      var response = await services.patch(
        `${Leave_Approval}${id}/approve_as_tl/`,
        data,
        true,
        isFormData
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Leave Approval Updated Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  // roles
  postRoles: async (data, isFormData) => {
    try {
      var response = await services.post(Roles, data, true, isFormData);

      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Roles Created Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  getRoles: async () => {
    try {
      var response = await services.get(Roles, true);

      return response;
    } catch (error) {
      return error;
    }
  },
  deleteRole: async (id) => {
    try {
      var response = await services.delete(`${Roles}${id}/`, true);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Roles Deleted Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  updateRoles: async (id, data, isFormData) => {
    try {
      var response = await services.put(
        `${Roles}${id}/`,
        data,
        true,
        isFormData
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Roles Updated Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  // Notice Board
  getNoticeBoards: async () => {
    try {
      var response = await services.get(Notice_Board, true);

      return response;
    } catch (error) {
      return error;
    }
  },
  postNoticeBoard: async (data, isFormData) => {
    try {
      var response = await services.post(Notice_Board, data, true, isFormData);
      return response;
    } catch (error) {
      return error;
    }
  },
  postNoticeBoardRead: async (data, isFormData) => {
    try {
      var response = await services.post(
        Notice_Board_Read,
        data,
        true,
        isFormData
      );
      return response;
    } catch (error) {
      return error;
    }
  },
  // PayRole
  getEmployeeContract: async (queryParams) => {
    try {
      var response = await services.get( queryParams ? EmployeeContract + queryParams : EmployeeContract , true);
      return response;
    } catch (error) {
      return error;
    }
  },
  patchEmployeeContract: async (id, data, isFormData) => {
    try {
      var response = await services.patch(
        `${EmployeeContract}${id}/`,
        data,
        true,
        isFormData
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Contract Updated Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  getEmployeeBankDetails: async (queryParams) => {
    try {
      var response = await services.get( queryParams ? Bank_Details + queryParams :  Bank_Details, true);
      return response;
    } catch (error) {
      return error;
    }
  },
  createBankDetail: async (data, isFormData) => {
    try {
      var response = await services.post(Bank_Details, data, true, isFormData);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Bank Detail Created Successfully",
            })
          )
        : null;
      return response;
    } catch (error) {
      return error;
    }
  },
  getSalaryHead: async () => {
    try {
      var response = await services.get(SalaryHeads, true);
      return response;
    } catch (error) {
      return error;
    }
  },
  deleteSalaryHead: async (id) => {
    try {
      var response = await services.delete(`${SalaryHeads}${id}/`, true);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Salary Head Deleted Successfully",
            })
          )
        : null;
      return response;
    } catch (error) {
      return error;
    }
  },
  patchSalaryHead: async (id, data, isFormData) => {
    try {
      var response = await services.patch(
        SalaryHeads + id + "/",
        data,
        true,
        isFormData
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Salary Head Updated Successfully",
            })
          )
        : null;
      return response;
    } catch (error) {
      return error;
    }
  },
  createSalaryHead: async (data, isFormData) => {
    try {
      var response = await services.post(SalaryHeads, data, true, isFormData);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Salary Head Created Successfully",
            })
          )
        : null;
      return response;
    } catch (error) {
      return error;
    }
  },
  createEmployeeContract: async (data, isFormData) => {
    try {
      var response = await services.post(
        EmployeeContract,
        data,
        true,
        isFormData
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Employee Payrole Created Successfully",
            })
          )
        : null;
      return response;
    } catch (error) {
      return error;
    }
  },
  getSalary: async (queryParams) => {
    try {
      var response = await services.get(queryParams ?  Salary + queryParams  : Salary, true);
      return response;
    } catch (error) {
      return error;
    }
  },
  getExpenseIncentiveDeductions: async () => {
    try {
      var response = await services.get(ExpensesIncentiveDeductions, true);
      return response;
    } catch (error) {
      return error;
    }
  },
  createExpenseIncentiveDeductions: async (data, isFormData) => {
    try {
      var response = await services.post(
        ExpensesIncentiveDeductions,
        data,
        true,
        isFormData
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Expense Created Successfully",
            })
          )
        : null;
      return response;
    } catch (error) {
      return error;
    }
  },
  editExpenseIncentiveDeductions: async (id,data, isFormData) => {
    try {
      var response = await services.patch(
        ExpensesIncentiveDeductions + id + "/",
        data,
        true,
        isFormData
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Expense Edited Successfully",
            })
          )
        : null;
      return response;
    } catch (error) {
      return error;
    }
  },
  // marketing form
  postFormData: async (data, isFormData) => {
    try {
      var response = await services.post(forms_Config, data, true, isFormData);
      response.success
        ? store.dispatch(
            setToastOpen({ isOpen: true, message: "Form Created Successfully" })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },
  getFormData: async () => {
    try {
      var response = await services.get(forms_Config, true);

      return response;
    } catch (error) {
      return error;
    }
  },
  deleteForm: async (id) => {
    try {
      var response = await services.delete(`${forms_Config}${id}/`, true);

      response.success
        ? store.dispatch(
            setToastOpen({ isOpen: true, message: "Form deleted Successfully" })
          )
        : null;
      return response;
    } catch (error) {
      return error;
    }
  },
  updateFormData: async (id, data, isFormData) => {
    try {
      var response = await services.put(
        `${forms_Config}${id}/`,
        data,
        true,
        isFormData
      );
      response.success
        ? store.dispatch(
            setToastOpen({ isOpen: true, message: "Form Updated Successfully" })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },

  //calender meet
  createEventConfig: async (data) => {
    try {
      var response = await services.post(Create_Event_Config, data, true);
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Event has been Created Successfully",
            })
          )
        : null;
      return response;
    } catch (error) {
      return error;
    }
  },
  getEventConfigList: async () => {
    try {
      var response = await services.get(Create_Event_Config, true);
      return response;
    } catch (error) {
      return error;
    }
  },
  updateEventConfig: async (id, data) => {
    try {
      var response = await services.put(
        `${Create_Event_Config}${id}/`,
        data,
        true
      );
      response.success
        ? store.dispatch(
            setToastOpen({
              isOpen: true,
              message: "Event has been Updated Successfully",
            })
          )
        : null;

      return response;
    } catch (error) {
      return error;
    }
  },

  // Employee Events 
  getEmployeeEvents: async () => {
    try {
      var response = await services.get(EmployeeEvent, true);
      return response;
    } catch (error) {
      return error;
    }
  },
};
export default ApiCalls;
