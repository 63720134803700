/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Grid } from "@mui/material";

// Images
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
import logoAsana from "assets/images/small-logos/logo-asana.svg";

// Material Dashboard 2 PRO React components
import { useMaterialUIController } from "context";

function Documents() {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const [slack2FA, setSlack2FA] = useState(true);
    const [spotify2FA, setSpotify2FA] = useState(true);
    const [atlassian2FA, setAtlassian2FA] = useState(true);
    const [asana2FA, setAsana2FA] = useState(false);

    const handleSetSlack2FA = () => setSlack2FA(!slack2FA);
    const handleSetSpotify2FA = () => setSpotify2FA(!spotify2FA);
    const handleSetAtlassian2FA = () => setAtlassian2FA(!atlassian2FA);
    const handleSetAsana2FA = () => setAsana2FA(!asana2FA);
    const [fileValidationMessage, setFileValidationMessage] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
        setFileValidationMessage("");
      };
    
    const handleUpload = () => {
        if (!selectedFile) {
          setFileValidationMessage("Please select a file.");
          return;
        }
        setSelectedFile(null);
      };

    return (
        <Card id="documents">
            <MDBox p={3} lineHeight={1}>
                <MDBox mb={1}>
                    <MDTypography variant="h5">Documents</MDTypography>
                </MDBox>
                <MDBox><MDTypography variant="button" color="text" >
                    Upload the required document here.
                </MDTypography></MDBox><br />

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <MDBox>
                            <MDTypography variant="h6">
                                Add Documents
                            </MDTypography>
                            <input type="file" onChange={handleFileChange} ></input>
                            <div style={{ color: "red" ,marginTop:"5px" ,fontSize:"15px"}}>{fileValidationMessage}</div>
                        </MDBox>
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                        <MDBox>
                            <MDTypography variant="h6">
                                PAN Card
                            </MDTypography>
                            <input type="file"></input>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MDBox>
                            <MDTypography variant="h6">
                                Other
                            </MDTypography>
                            <input type="file"></input>
                        </MDBox>
                    </Grid> */}
                    <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                        <br />
                        <MDButton variant='gradient' color='info' size='small' sx={{ fontSize: '12px',width: '100px', height : '40px' }} onClick={handleUpload}>Upload</MDButton>
                    </Grid>
                </Grid>

            </MDBox>
        </Card>
    );
}

export default Documents;
