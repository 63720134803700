import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDEditor from "components/MDEditor";
import MDTypography from "components/MDTypography";
import { useDispatch } from "react-redux";
import { changeValue, setIsOpen } from "./textViewEditorBoxSlice";
import { useSelector } from "react-redux";

export function TextViewEditorBox({ dialogOpen }) {
  // const selector = useSelector();
  const value = useSelector((state) => state?.textViewBox?.value);
  const isReadOnly = useSelector((state) => state?.textViewBox?.isReadOnly);

  const dispatch = useDispatch();
  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: "white",
          boxShadow: "none",
          maxWidth: "50%",
          borderRadius: "10px",
          alignItems: "center",
          // bgColor :"info"
        },
      }}
      open={dialogOpen}
      onClose={() => {
        dispatch(setIsOpen({ isOpen: false }));
      }}
    >
      <DialogContent
        style={{
          padding: "4px",
        }}
      >
        <MDBox display="flex" flexDirection="column" alignItems="center" pt={4}>
          <CKEditor
            readOnly={true}
            editor={ClassicEditor}
            data={value || ""}
            onReady={(editor) => {
              editor.ui.view.editable.element.style.minHeight = "500px";
            //   editor.ui.view.editable.element.style.width = "50%";
              isReadOnly ? editor.enableReadOnlyMode("") : null;
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              dispatch(changeValue({ value: data }));

            }}
            onBlur={(event, editor) => {

              editor.ui.view.editable.element.style.minHeight = "500px";
            //   editor.ui.view.editable.element.style.width = "50%";



            }}
            onFocus={(event, editor) => {

              editor.ui.view.editable.element.style.minHeight = "500px";
            //   editor.ui.view.editable.element.style.width = "50%";

            }}
          />
        </MDBox>
      </DialogContent>
    </Dialog>
  );
}
