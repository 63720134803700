/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "layouts/marketing/newEvent//schemas/form";

const {
  formField: {
    eventName,
    eventColor,
    email,
    eventDuration,
    repeatPassword,
    address1,
    city,
    zip,
    twitter,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [eventName.name]: Yup.string().required(eventName.errorMsg),
    [eventColor.name]: Yup.string().required(eventColor.errorMsg),
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
  }),
];

export default validations;
