const Development_Base_Url = "http://hr.flickerp.com/";
const Production_Base_Url = "https://pro.com/";
const Staging_Base_Url = "https://stg.com/";

const Organisation_Create = "api/v1/org_onbording/create/";
const Organisation = "api/v1/org/";
export const RefreshToken = "api/token/refresh/";

const Login = "api/v1/login/";
const Employees = "api/v1/employees/";
export const HierarchyEmployees = "api/v1/employee-hierarchy/";
export const OrganisationHierarchy = "api/v1/company-hierarchy/";
const Global_Permissions = "api/v1/permissions/";
const Change_Password = "api/v1/change-password/";
const Job_Advertisement = "api/v1/recruitment/jobadvertisement/";
const Screening = "api/v1/recruitment/screening/";
const UploadCV = "api/v1/recruitment/uploadbycv/";
const Candidate = "api/v1/recruitment/candidates/";
const Interview = "api/v1/recruitment/interview/";
const Job_Offers = "api/v1/recruitment/joboffers/";

// const Attendance = "api/v1/recruitment/interview/";
const Attendance = "api/v1/attendance/attendances/";
export const  AttendanceList = "api/v1/attendance/attendancelists/"
export const AttendanceConsiderRequest = "api/v1/attendance/attendance-consider-requests/" ;
const Upload_Attendance = "api/v1/attendance/upload-csv/";
export const TempCard = "api/v1/attendance/temcards/";
const Shifts = "api/v1/attendance/employeeshifts/";
const Ten_Days_Shift = "employee-shifts-next-10-days"
const Office_Shifts = "api/v1/attendance/officeshifts/";
const Holidays = "api/v1/holiday/holidays/";
const GOOGLE_CALLBACK = "api/v1/recruitment/api/google/callback/";
const Bulk_Shift = "api/v1/attendance/bulk-create-shifts/";
const Organisation_Settings = "api/v1/organization-create-list/";
const Employee_Reporting_Manager = "api/v1/employee-reporting-managers/";
const Department = "api/v1/departments/";
const Designations = "api/v1/designations/";
const Document_Type = "api/v1/document_types/";
const Leave_Settings = "api/v1/leave/leave-settings/";
const Leave_Balance = "api/v1/leave/leave-balances/";
export const Leave_Transactions = "api/v1/leave/leave_transactions/"
export const Validate_Leave_Transaction = "api/v1/leave/validate_leave_transactions/" ;
export const Employee_Leave_balance = "api/v1/leave/employee_leave_balance/"
export const TotalShiftHours = "api/v1/attendance/total_shift_hours/" ;
const Leave_Requests = "api/v1/leave/leave-requests/";
export const LeaveRefresh = "api/v1/leave/update-leave-balance-monthly" ;
const Roles = "api/v1/roles/";
const Leave_Approval = "api/v1/leave/leave-approval/";
const Notice_Board = "api/v1/notifications/notishboards/";
const Notice_Board_Read = "api/v1/notifications/notishboard-reads/";
const EmployeeContract = `api/v1/payrole/employee-contracts/`;
export const ExpensesIncentiveDeductions = "api/v1/payrole/expenses-incentives-deductions/"
const SalaryHeads = `api/v1/payrole/salary-heads/`;
export const Salary  = `api/v1/payrole/salary/`;
const forms_Config = "/api/v1/marketing/formsconfig/";
const Create_Event_Config = "api/v1/marketing/event-configs/";
export const EmployeeEvent = "api/v1/marketing/employee-events";
export const UserDashboard = "api/v1/user/dashboard/" ;
export const Recruitement_Dashboard_Counts = "/api/v1/recruitment/screening-report-counts/";
export const Recruitment_Pie_Data = "api/v1/recruitment/interview-report-pie/" ;
export const Bank_Details = "api/v1/bank-details/";
export const developmentMessage = "This Page is under development, if you have any suggestions regarding any functionality then please provide  us your valuable feedback." ;
export {
  Development_Base_Url,
  Production_Base_Url,
  Staging_Base_Url,
  Organisation_Create,
  Login,
  Employees,
  Organisation,
  Global_Permissions,
  Change_Password,
  Job_Advertisement,
  Screening,
  Candidate,
  Interview,
  UploadCV,
  Attendance,
  Shifts,
  Office_Shifts,
  Bulk_Shift,
  Holidays,
  Upload_Attendance,
  GOOGLE_CALLBACK,
  Organisation_Settings,
  Employee_Reporting_Manager,
  Department,
  Designations,
  Job_Offers,
  Document_Type,
  Leave_Settings,
  Leave_Balance,
  Leave_Requests,
  Roles,
  Leave_Approval,
  Notice_Board,
  Notice_Board_Read,
  EmployeeContract,
  forms_Config,
  Create_Event_Config,
  SalaryHeads
};
