import {
  ArrowDropDown,
  ArrowDropUp,
  ArrowUpward,
  LibraryAddCheck,
} from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Popover,
  Popper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import * as React from "react";

import ApiCalls from "services/flick_erp_api_call";

export function SelectHierarchicalEmployee({
  onSelected,
  multiple,
  label,
  selectAll,
  fullWidth,
}) {
  const [isExpand, setIsExpand] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [selected, setSelected] = useState(multiple ? [] : null);
  const [anchorEl, setAnchorEl] = useState();
  const [isFocused, setFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState();

 

  useEffect(() => {
    getEmployee();
  }, []);
  const id = isExpand ? "simple-popper" : undefined;
  const textFieldRef = React.useRef();

  async function getEmployee() {
    var res = await ApiCalls.getHierarchyEmployeesList();
    if (res.success && res.code === 200) {
      var data = JSON.parse(JSON.stringify(res.data));

const d = flattenHierarchy(data)

      setEmployees(d);
      setFilteredData(data)
    } else {
    }
  }
  function flattenHierarchy(arr) {
    return arr.reduce((acc, obj) => {
        acc.push(obj);
        if (obj.children && obj.children.length > 0) {
            acc = acc.concat(flattenHierarchy(obj.children));
        }
        delete obj.children; 
        return acc;
    }, []);
}
  function handleSelectAll() {
    setSelected(employees.map((e) => e.id));
  }
  const handleFocus = (e) => {
    setAnchorEl(e.currentTarget);
    setFocused(true);
    textFieldRef.current.focus();
  };

  const handleBlur = () => {
    textFieldRef.current.blur();
    setFocused(false);
  };
  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);
    filterHierarchy(employees, newSearchTerm);
  };

  const filterHierarchy = (node, term) => {
  if (!term) {
    setFilteredData(employees);
    return;
  }

  const filterRecursively = (currentNode) => {
    const matches = currentNode.fullname.toLowerCase().includes(term);

    if (currentNode.children && currentNode.children.length > 0) {
      // Filter the children recursively
      currentNode.children = currentNode.children
        .map((child) => ({ ...child, children: [] }))  // Make a copy of each child
        .filter(filterRecursively);
      
      // If any child matches, include the current node in the result
      return matches || currentNode.children.some((child) => !!child);
    }

    return matches;
  };

  // Create a copy of the root node to avoid modifying the original data
  const filteredNodes = { ...node, children: [...node.children] };
  filterRecursively(filteredNodes);
debugger

  // Check if the result has any children, and set the filtered data accordingly
  setFilteredData(filteredNodes? filteredNodes : []);
};


  const EmployeeTree = ({ employee }) => {
    return (
      <List key={employee.id} dense={true}>
        <ListItem>
          <ListItemButton style={{ borderRadius: "5px" }}>
            <ListItemText
              primary={
                <Typography style={{ fontSize: 15 }}>
                  {employee.fullname}
                </Typography>
              }
            ></ListItemText>
          </ListItemButton>
        </ListItem>
        {employee.children && employee.children.length > 0 && (
          <div style={{ marginLeft: "20px" }}>
            {employee.children.map((child) => (
              <EmployeeTree key={child.id} employee={child} />
            ))}
          </div>
        )}
      </List>
    );
  };

  return (
    <MDBox display="flex" flexDirection="row" alignItems="center">
      {/* <TextField
        autoComplete="off"
        variant="standard"
        label={label}
        onFocus={handleFocus}
        onBlur={handleBlur}
      onChange={handleSearchChange}
        aria-describedby={id}
        inputRef={textFieldRef}
        InputProps={{
          endAdornment: (
            <InputAdornment
              style={{ paddingBottom: "15px" }}
              disableTypography
              position="end"
            >
              <IconButton
                onClick={(e) => {
                  isFocused ? handleBlur(e) : handleFocus(e);
                }}
              >
                {isFocused ? <ArrowDropUp /> : <ArrowDropDown />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      ></TextField> */}
      {/* <div>
        <EmployeeTree employee={employees}></EmployeeTree>
      </div> */}

      <Autocomplete
        id={label}
        value={
          multiple
            ? selected?.map((e) => employees?.find((v) => v?.id == e))
            : employees.find((v) => v.id == selected)
        }
        options={employees}
        dense
        multiple={multiple}
        componentsProps={{ popper: { style: { width: "fit-content" } } }}
        fullWidth={fullWidth}
        getOptionLabel={(option) =>
          Array.isArray(option)
            ? option.map(
                (item) => item?.fullname 
              )
            : option?.fullname
        }
        renderInput={(params) => (
          <TextField
            {...params}
            name="Employee"
            label={label}
            variant="standard"
            InputLabelProps={{ shrink: true }}
            sx={{ width: fullWidth ? "100%" : 200}}
          />
        )}
        onChange={(event, newValue) => {
          var selectedValues;
          if (multiple) {
            selectedValues = Array.isArray(newValue)
              ? newValue.map((v) => v?.id)
              : [newValue?.id];
          } else {
            selectedValues = newValue?.id;
          }
          setSelected(selectedValues);
          onSelected(selectedValues);
        }}
      />
      {selectAll && (
        <Tooltip title="Select All">
          <LibraryAddCheck
            sx={{ cursor: "pointer", mt: 2 }}
            onClick={() => {
              handleSelectAll();
            }}
          />
        </Tooltip>
      )}
      <Popper
        id={id}
        open={isFocused}
        anchorEl={anchorEl}
        placement="bottom-start"
        style={{
          width: anchorEl ? anchorEl.clientWidth + 50 : null,
        }}
      >
        <Paper elevation={3} bgColor="white">
          <EmployeeTree employee={ filteredData != undefined  ? filteredData : employees}></EmployeeTree>
        </Paper>
      </Popper>
    </MDBox>
  );
}
