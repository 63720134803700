import { createSlice } from "@reduxjs/toolkit";

const textViewEditorBoxSlice = createSlice({
    name : 'textbox',
    initialState : {
        isOpen : false,
        value  : "",
        isReadOnly:false
    },
    reducers : {
        setIsOpen : (state,action) => {

            state.isOpen = action.payload.isOpen;
            state.value = action.payload.value;
            state.isReadOnly = action.payload.isReadOnly

        },
        changeValue : (state,action) => {
            state.value = action.payload.value;

        },
    }
});

export const {setIsOpen,changeValue} = textViewEditorBoxSlice.actions;
export default textViewEditorBoxSlice.reducer;