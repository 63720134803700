import { createSlice } from "@reduxjs/toolkit";

    const optionSlice = createSlice({
        name: "FormBuilderData",
        initialState: {
                form_data:{},
        },
    reducers : {
        dataList : (state,action) => {
            state.form_data = action.payload.form_data;
        }
    }
});

export const {dataList} = optionSlice.actions;
export default optionSlice.reducer;


// {
//     'field_keys' : [{key:'first_name', type:''}, {key:'last_name', type:''},{key:'city', type:''}],
//     'form_data':{
//       form_title: 'Contact Form',
//       selected_submission_type: 'message/redirect',
//           notification_to:'himanshi@geitpl.com',
//       {type: 'message', data: {'message':''}}, 
//       {type: 'redirect', data: {'link':'', 'selected_page':''}},

//         style:{
//            text_field: {'variant':"outlined"} // whatever property need to add we can add.
//            button_style : {margin:"10px", color:"#fff", border_radius?:"50px"}
//         }
       
// }