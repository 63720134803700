import {createSlice} from '@reduxjs/toolkit';

const employeeSlice = createSlice({
    name : "employee",
    initialState : {
        employee :[],
        clickedEmployee : null,
    },
    reducers : {
        dispatchEmployee : (state,action) => {
            state.firstname = action.payload.firstname;
            state.lastname = action.payload.lastname;
            state.email = action.payload.email;
            state.phone = action.payload.phone;
            state.password = action.payload.password;
        },
        employeeList : (state,action) => {
            state.employee = action.payload.employee;
        }        
    }
});

export const {dispatchEmployee,employeeList} = employeeSlice.actions;
export default employeeSlice.reducer;
export const selectGetEmployee = (state) => state.employee;
export const selectClickedEmployee = (state) => state.employee.clickedEmployee;
