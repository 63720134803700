import { Card, Dialog, DialogContent, DialogTitle, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import MasterCard from "examples/Cards/MasterCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import Invoices from "layouts/invoicing/billing/components/Invoices";
import SalaryComponentChart from "./components/SalaryComponent";
import ApiCalls from "services/flick_erp_api_call";
import { useEffect, useState } from "react";
import { developmentMessage } from "services/apis";
import DataTable from "examples/Tables/DataTable";
import { Download, Info } from "@mui/icons-material";
import SalesTable from "examples/Tables/SalesTable";
export function Payrole() {
  const loggedUser = JSON.parse(localStorage.getItem("user")) ;
  const [employeeContract, setEmployeeContract] = useState({});
  const [accountNumber, setAccountNumber] = useState();
  const [salaryLineDialog, setSalaryLineDialog] = useState(false);
  const [salaryDetails, setSalaryDetails] = useState(false);

  // const [salarySlips, setSalarySlips] = useState([]);


  useEffect(()=>{
    getEmployeeContract();
    getSalarySlips();
  },[])
  function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString([], { month: 'long' });
  }
  const [ salarySlips, setSalarySlips] = useState({
    columns: [
      {
        Header: "NAME",
        accessor: "name",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{}}>{row.original.employee.user.firstname}</span>
            <span style={{ marginLeft: "0.2rem" }}>
              {row.original.employee.user.lastname}
            </span>
          </div>
        ),
      },
      {
        Header: "DATE",
        accessor: "date",
        Cell: ({ row }) => (
          <div
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
            }}
          >
           {getMonthName(row.original?.batch?.month) + " " + row.original?.batch?.year}
          </div>
        ),
      }, 
      {
        Header:"TOTAL WORKING DAYS", 
        accessor: "total_days"
      },
      {
        Header:"WORKING DAYS", 
        accessor: "working_days"
      },
      {
        Header:"Absent", 
        accessor: "absent_days"
      },
      {
        Header:"PAID LEAVE", 
        accessor: "paid_leave"
      },
      {
        Header:"UNPAID LEAVE", 
        accessor: "unpaid_leave"
      },
      {
        Header:"Amount", 
        accessor: "net_pay"
      },
      {
        Header: "Action",
        accessor: "inout",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Info">
              <IconButton
                aria-label="info"
                size="small"
                onClick={() => {
                  setSalaryLineDialog(true)
                  setSalaryDetails(row.original)
                  // handleView(row.original.attendance_lists);
                }}
              >
                <Info ></Info>
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
      
      {
        Header: "Download",
        accessor: "download",
        Cell: ({ row }) => {
          // Define invoiceYear and invoiceMonth before returning the JSX
          const invoiceYear = row.original.batch.year;
          const invoiceMonth = row.original.batch.month;
          const invoiceID = row.original.id;
          let salaryInvoiceapi = "https://www.flickerp.com/api/v1/payrole/salary-slip";
      
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Tooltip title="Download">
                <a href={`${salaryInvoiceapi}/${loggedUser.employee_id}/${invoiceYear}/${invoiceMonth}/${invoiceID}/`}>
                <IconButton
                aria-label="download"
                size="small"
                
              >
                <Download ></Download>
              </IconButton>
                </a>
              </Tooltip>
            </div>
          );
        },
      },
    ],
    rows: [],
  });
  
 
  async function getEmployeeContract() {
    var res = await ApiCalls.getEmployeeContract(`?employee_id=${loggedUser.employee_id}&is_active=true` );
    if (res.success && res.code == 200) {
      setEmployeeContract(res?.data[0]);
      setAccountNumber("XXXXXXXXXXXX"+ (res?.data[0]?.bank?.bank_account?.substr(res?.data[0]?.bank?.bank_account?.length -4)??"XXXX"))
    }
  }
  async function getSalarySlips() {    
      var res = await ApiCalls.getSalary(`?employee=${loggedUser.employee_id}`);
      if (res.success && res.code === 200) {
        salarySlips.rows = res.data;
        setSalarySlips({...salarySlips});
      }
    } 

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar  />
        <br></br>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4} sm={6}>
            <MasterCard
              number={  accountNumber ?? "XXXXXXXXXXXXXXXX" }
              accountHolder= {employeeContract?.bank?.name_in_account}
              bankName={employeeContract?.bank?.bank_name}
            />
          </Grid>

          <Grid item xs={12} lg={2.5} sm={6}>
            <MDBox>
              <DefaultInfoCard
                icon="account_balance"
                title="Next Increament"
                description="Belong Interactive"
                value={employeeContract?.end_date ?? "-"}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={2.5} sm={6}>
            <MDBox>
              <DefaultInfoCard
                icon="account_balance"
                title="CTC"
                description="Belong Interactive"
                value={employeeContract?.ctc ?? "-"}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12} lg={3} sm={6}>
            <SalaryComponentChart salaryDetail={employeeContract?.salary_details} />
          </Grid>
        </Grid>
        <MDBox pt={2}>
         <DataTable table={salarySlips}></DataTable>
        </MDBox>
        <Dialog
          PaperProps={{
          style: {
            backgroundColor: "white",
            boxShadow: "none",
            width: "auto",
            maxWidth: "none",
            borderRadius: "10px",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        open={salaryLineDialog}
        onClose={() => setSalaryLineDialog(false)}
      >
        <DialogTitle
          style={{
            width: "100%",
            padding: "20px",
            borderBottom: "10px solid ",
            backgroundColor: "#007bed",
            color: "white",
          }}
        >
          <Typography sx={{ color: "white", fontWeight: "Medium" }}>
            Salary Details
          </Typography>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "4px",
          }}
        >
          <MDBox align="center" p={2} sx={{width:"100%"}}>
            <SalesTable title="" rows={salaryDetails.salary_lines} ignore={["id","salary_head","employee_salary"]} />
            
          </MDBox>
          <MDBox display="flex" p={2} pt={0} justifyContent="space-between">
            <Typography>
              Total Amount 
            </Typography>
            <Typography>
              {salaryDetails.gross_pay}
            </Typography>
          </MDBox>
        </DialogContent>
      </Dialog>
      </DashboardLayout>
    </>
  );
}
