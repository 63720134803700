import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import FormField from "layouts/pages/account/components/FormField";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import Autocomplete from "@mui/material/Autocomplete";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useForm} from 'react-hook-form';

function Contracts({
  contract,
  setEmployeeContract,
  bankDetails,
  validationErrors,
  setValidationErrors,
}) {
  const { register, formState: { errors }, setValue  } = useForm();



  const contractType = [
    { id: "full_time", name: "Full Time" },
    { id: "part_time", name: "Part Time" },
    { id: "intern", name: "Intern" },
    { id: "contract", name: "Contract" },
    { id: "rent", name: "Rent" },
  ];


  const statusoptions  = [
    { label: "Active", id: 1 },
    { label: "De - active", id: 2 },
  ];


  useEffect(() =>{
validationErrors
  },[])
  useEffect(() => {
    const fields = [
      'ref_number',
      'Term',
      'status',
      'contract_type',
      'Bankoptions',
      'start_date',
      'expiry_date',
      'ctc'
    ];
    fields.forEach((field) => {
      register(field, { required: "required" });
    });
  }, [register]);


  return (
    <MDBox p={3} lineHeight={1}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            fullWidth
            options={bankDetails}
            value={contract?.bank || {}}
            getOptionLabel={(e) =>
              {
                if (e.bank_name) {
                  return (
                    e?.name_in_account +
                    " " +
                    e?.bank_name +
                    " " +
                    e?.bank_account?.substr(e?.bank_account?.length - 4)
                  );
                } else {
                  return ""; // Provide a default label when e is undefined
                }}
            
            }
            onChange={(event, value) => {
              setEmployeeContract((state) => ({ ...state, bank: value }));
              setValidationErrors((prevErrors) => ({
                ...prevErrors,
                bank: undefined, 
              }));
            }}
            renderInput={(params) => (
              <FormField
                label="Select Bank"
                placeholder="Select Bank"
                {...params}
                InputLabelProps={{ shrink: true }}
                error={validationErrors.bank}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            value={
              contractType.find((c) => c.id == contract?.contract_type) || {}
            }
            options={contractType}
            getOptionLabel={(e) =>
              contractType?.length > 0
                ? e?.name ?? "Select Type"
                : "Select Type"
            }
            onChange={(e, value) => {
              setEmployeeContract((state) => ({
                ...state,
                contract_type: value?.id,
              }));
              setValidationErrors((prevErrors) => ({
                ...prevErrors,
                contract_type: undefined, 
              }));
            }}
            renderInput={(params) => (
              <FormField
                label="Type"
                {...params}
                InputLabelProps={{ shrink: true }}
            error={validationErrors.contract_type}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            label="Effective Date"
            value={contract?.start_date || ""}
            onChange={(e) => {
              setEmployeeContract((state) => ({
                ...state,
                start_date: e.target.value,
              }));
              setValidationErrors((prevErrors) => ({
                ...prevErrors,
                start_date: undefined, 
              }));
            }}
            error={validationErrors.start_date}
            inputProps={{ type: "date" }}
            placeholder="DD/MM/YY"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormField
            label="Expiry Date"
            value={contract?.end_date || ""}
            onChange={(e) => {
              setEmployeeContract((state) => ({
                ...state,
                end_date: e.target.value,
              }));
              setValidationErrors((prevErrors) => ({
                ...prevErrors,
                end_date: undefined, 
              }));
            }}
            error={validationErrors.end_date}
            inputProps={{ type: "date" }}
            placeholder="DD/MM/YY"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={["Long Term", "Short Term"]}
            value={contract?.terms || ""}
            onChange={(e, v) => {
              setEmployeeContract((state) => ({ ...state, terms: v }));
              setValidationErrors((prevErrors) => ({
                ...prevErrors,
                terms: undefined, 
              }));
            }}
            renderInput={(params) => (
              <FormField
                label="Term"
                {...params}
                InputLabelProps={{ shrink: true }}
            error={validationErrors.terms}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={statusoptions}
            value={
              contract?.is_active ? statusoptions[0] : statusoptions[1] || ""
            }
            onChange={(e, value) => {
              setEmployeeContract((state) => ({
                ...state,
                is_active: value?.id == 1,
              }));
              setValidationErrors((prevErrors) => ({
                ...prevErrors,
                status: undefined, 
              }));
            }}
            renderInput={(params) => (
              <FormField
                label="Status"
                {...params}
                InputLabelProps={{ shrink: true }}
            error={validationErrors.status}
              />
            )}
          />
        </Grid>

        {/* <Grid item xs={12} sm={6}>
          <FormField
            name={"ref_number"}
            label="Document"
            inputProps={{ type: "file" }}
            onChange={(e) => {
              setEmployeeContract((state)=> ({...state,contract_type : e.target.value }))

              setValidationErrors((prevErrors) => ({
                ...prevErrors,
                Document: undefined, 
              }));
            }}
            error={validationErrors.Document}
          />
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <FormField
            label="CTC"
            value={contract?.ctc || ""}
            onChange={(e) => {
              setEmployeeContract((state)=> ({...state,ctc : e.target.value }))

              setValidationErrors((prevErrors) => ({
                ...prevErrors,
                ctc: undefined, 
              }));
            }}
            error={validationErrors.ctc}
            inputProps={{ type: "number" }}
            // placeholder="DD/MM/YY"
          />
        </Grid>
        {/* {!isContractCreate && (
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <MDButton variant="gradient" color="dark" onClick={() => handleSubmit()}>
              Submit
            </MDButton>
          </Grid>
        )} */}
      </Grid>
    </MDBox>
  );
}

export default Contracts;
