import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    email: null,
    token : null,
    permissions : null
  },
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },

    signIn : (state,action) => {
        state.email = action.payload.email;
        state.password = action.payload.password;
        state.token = action.payload.token;
        state.password = action.payload.password;
    },
    userDataAfterSignIn : (state,action) => {
        state.email= action.payload.email
        state.token = action.payload.token;
        state.permissions = action.payload.permissions;
    }
  },
});

export const { setEmail, setPassword, signIn, userDataAfterSignIn } = userSlice.actions;
export default userSlice.reducer;
export const selectSignIn = (state) => state.user;
