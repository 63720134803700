import { Card, Checkbox, Icon, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { AttendanceSetting } from "./components/AttendanceSetting";
import { ShiftSetting } from "./components/ShiftSetting";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import tab from "assets/theme/components/tabs/tab";

export function AttendanceSettings({permissions, modulePermissions}) {

  const [tabComponents, setTabComponents] = useState([]);
  useEffect(() => {
    setTabComponents([
      // {
      //   label: "Attendance Settings",
      //   component: (
      //    <AttendanceSetting
      //    permissions={permissions}
      //    modulePermissions={modulePermissions}
      //    />
      //   ),
        
      //   icon: <Icon>settings</Icon>,
      // },
      {
        label: "Office Shift Settings",
        component: (
         <ShiftSetting    permissions={permissions}
         modulePermissions={modulePermissions} />
        ),
        visible:permissions?.includes(modulePermissions?.office_shift_list_permission),
        icon: <Icon>settings</Icon>,
      },
    ]);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar></DashboardNavbar>
      <MDBox p={5}>
        <Card sx={{ padding: "10px" }}>
          <MDBox p={2}>
            <MDBox display="flex" justifyContent="center">
              <MDTypography variant="h4"> Settings</MDTypography>
            </MDBox>
           <BaseLayout tabComponents={tabComponents}>
           
           </BaseLayout>

          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
