// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Radio, Switch } from "@mui/material";
import { TextField } from "@mui/material";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// Material Dashboard 2 PRO React examples
import PageLayout from "examples/LayoutContainers/PageLayout";
import MDTypography from "components/MDTypography";
import masterCardLogo from "assets/images/logos/mastercard.png";
import {useNavigate } from "react-router-dom";

function SubscriptionPayment() {
  const navigate = useNavigate();

    const handleIconClick = () =>{
        navigate("/invoicing/pricing_page")
    }
    return (
        <PageLayout>
            <Grid container sx={{ px: 25, my: 30 }}>
                <Grid item xs={10}>
                    <Card sx={{ mt: -16 }}>
                        <MDBox
                            mt={-3}
                            p={3}
                            lineHeight={1}
                            bgColor="#007bed"
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",

                                color: " white",
                                borderRadius: 2,
                                marginLeft: "0.9rem",
                                marginRight: "0.9rem",
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Icon fontSize="medium" sx={{ marginRight: '10px' }} onClick={handleIconClick}>west</Icon>
                                SubscriptionPayment
                            </div>
                        </MDBox>
                        <MDBox minWidth={{ xs: "22rem", md: "25rem" }} m={4} p={4}>

                            <grid item>
                                <MDTypography variant="h3">Payment method</MDTypography>
                            </grid>

                            <Grid item xs={12} md={6} sx={{ m: 3, minWidth: "5rem" }}>
                                <MDBox
                                    borderRadius="lg"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    p={3}
                                    sx={{
                                        border: ({ borders: { borderWidth, borderColor } }) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    }}
                                >

                                    <MDTypography variant="h6" fontWeight="bold">
                                        <Radio color="primary" /> Pix
                                    </MDTypography>
                                    <MDTypography variant="h6" fontWeight="regular">
                                        Scan the QR code with your payment app
                                    </MDTypography>
                                    <MDBox component="img" src={masterCardLogo} alt="master card" width="10%" />

                                </MDBox>

                            </Grid>

                            <Grid item xs={12} md={6} sx={{ m: 3, minWidth: "5rem" }}>
                                <MDBox
                                    borderRadius="lg"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    p={3}
                                    sx={{
                                        border: ({ borders: { borderWidth, borderColor } }) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    }}
                                >
                                    <MDTypography variant="h6" fontWeight="bold">
                                        <Radio color="primary" /> ApplePay
                                    </MDTypography>
                                    <MDTypography variant="h6" fontWeight="regular">
                                        Unlimited users and unlimited individual data
                                    </MDTypography>
                                    <MDBox component="img" src={masterCardLogo} alt="master card" width="10%" />

                                </MDBox>

                            </Grid>

                            <Grid item xs={12} md={6} sx={{ m: 3, minWidth: "5rem" }}>
                                <MDBox
                                    borderRadius="lg"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    p={3}
                                    sx={{
                                        border: ({ borders: { borderWidth, borderColor } }) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    }}
                                >
                                    <MDTypography variant="h6" fontWeight="bold">
                                        <Radio color="primary" />   PayPal
                                    </MDTypography>
                                    <MDTypography variant="h6" fontWeight="regular">
                                        you will be redirecting to paypal website
                                    </MDTypography>
                                    <MDBox component="img" src={masterCardLogo} alt="master card" width="10%" />

                                </MDBox>

                            </Grid>

                            <Grid item xs={12} md={6} sx={{
                                m: 3, minWidth: "5rem",
                                border: ({ borders: { borderWidth, borderColor } }) =>
                                    `${borderWidth[5]} solid ${borderColor}`,
                            }}>
                                <MDBox
                                    borderRadius="lg"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    px={2}
                                >
                                    <MDTypography variant="h6" fontWeight="bold">
                                        <Icon fontSize="small" sx={{ paddingTop: '2px' }}>check_circle</Icon> Pay with Credit Card
                                    </MDTypography>

                                    <MDBox component="img" src={masterCardLogo} alt="master card" width="10%" />

                                </MDBox>
                                <MDBox component="form" pb={3} px={3} pt={3}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField label="Name" variant="outlined" sx={{ width: '100%' }} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField label="Card Number" variant="outlined" sx={{ width: '100%' }} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField label="Card Security Code" variant="outlined" sx={{ width: '100%' }} />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField label="Expiration Date" placeholder="MM/YY" variant="outlined" sx={{ width: '100%' }} />
                                        </Grid>
                                    </Grid>
                                </MDBox>

                            </Grid>

                            <Grid item xs={12} md={6} sx={{ m: 3, minWidth: "5rem", fontSize: '14px' }}>
                                <Icon fontSize="small" sx={{ paddingTop: '2px' }}> gpp_good_outlined</Icon> we protect your payment information using encryption to provide bank-level security.
                            </Grid>

                            <Grid item xs={12} md={6} sx={{ m: 3, minWidth: "5rem" }}>
                                {<Switch />}
                                Save as default card
                            </Grid>
                        </MDBox>
                    </Card>
                </Grid>
            </Grid>
        </PageLayout>
    );
}

export default SubscriptionPayment;