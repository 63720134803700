/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import MDButton from "components/MDButton";
// import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ApiCalls from "services/flick_erp_api_call";
import Header from "../Header";
import {
  FormControl,
  IconButton,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { setToastOpen } from "components/Toast/toastAlertSlice";
import { useDispatch } from "react-redux";
import { useForm, useFormContext } from "react-hook-form";
import TripleToggleSwitch from "components/ThreeWaySwitch";

function BasicInfo({
  data,
  employee,
  handleImageSelect,
  isCreateEmployee,
  Employee,
  setEmployee,
  permissions,
  modulePermissions,
  reloadCallback,
}) {
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const genderData = [
    { name: "Male", id: "m" },
    { name: "Female", id: "f" },
  ];

  const getGenderId = (gender) => {
    return genderData.find((e) => e?.id == gender?.id)?.id;
  };
  const [firstname, setFirstname] = useState("");
  const [empId, setEmpId] = useState("");
  const [lastname, setLastname] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [doj, setDoj] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [designationValue, setDesignationValue] = useState("");
  const [roleValue, setRoleValue] = useState("");
  const [status, setStatus] = useState("active");
  const [attendanceCheck, setAttendanceCheck] = useState("false");

  const [roles, setRoles] = useState([]);
  const [departments, setDepartment] = useState([]);
  // const [img, setImg] = useState("");
  const {
    register,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    setFirstname(data?.user?.firstname || "");
    setStatus(
      data?.status ?? "active"
    );
    setLastname(data?.user?.lastname || "");
    setEmpId(data?.emp_id || "");
    setGender(genderData.find((e) => e.id == data?.gender) || genderData[0]);
    setDob(formatDate(data?.dob) || "");
    setDoj(formatDate(data?.doj) || "");
    setEmail(data?.user?.email || "");
    setPhone(data?.user?.phone || "");
    setRoleValue(data?.role || "");
    setDesignationValue(data?.designation.id || "");
    setAttendanceCheck(data?.attendance_check || false)
  }, [data]);
  useEffect(() => {
    getRoles();
    getDepartment();
  }, []);

  const navigate = useNavigate();
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const formattedDate = date.toISOString().slice(0, 10);
    return formattedDate;
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const user_id = location.state?.userid ?? data?.id;
  const user = JSON.parse(localStorage.getItem("user"));

  async function getDepartment() {
    var res = await ApiCalls.getDepartment();
    if (res.success && res.code == 200) {
      setDepartment(res.data);
    } else {
    }
  }

  useEffect(() => {
    const fields = [
      "firstname",
      "lastname",
      "gender",
      "dob",
      "doj",
      "designationValue",
      "phone",
      "empId",
      "roles",
      "email",
      "password",
    ];
    fields.forEach((field) => {
      register(field, { required: "required" });
    });
  }, [register]);

  // Get Organisation Roles
  async function getRoles() {
    var res = await ApiCalls.getRoles();
    if (res.success && res.code == 200) {
      setRoles(res.data);
    } else {
    }
  }

  const setEmployeeStatus = async (val) => {
    const obj = {
      status: val,
    };
    const res = await ApiCalls.editEmployee(data?.id, obj, false);
    if (res.success && res.code === 200) {
      reloadCallback();
    } else {
    }
  };
  const handleSubmit = async () => {
    try {
      const updatedData = {
        user: {
          id: data.user.id.toString(),
          firstname: firstname,
          lastname: lastname,
          phone: phone,
          email:email
        },

        emp_id: empId,
        gender: getGenderId(gender),
        dob: dob,
        doj: doj,
        role: roleValue,
        designation: designationValue,
      };
      if(email == data.user.email) {
        delete updatedData.user.email
      }
      if(phone == data.user.phone) {
        delete updatedData.user.phone
      }

      const formIsValid = isFormInvalid(updatedData);
      if (formIsValid) {
        const res = await ApiCalls.editEmployee(data?.id, updatedData, false);
        if (res.success && res.code === 200) {
          reloadCallback();
        } else {
        }
      }
    } catch (error) {
      console.error(error);
    }
    return;
  };

  const isFormInvalid = (updatedData) => {
    let isValid = true;

    const fieldsToCheck = [
      "user.firstname",
      "user.lastname",
      "emp_id",
      "gender",
      "dob",
      "doj",
      "role",
      // 'designation',
    ];

    for (const field of fieldsToCheck) {
      const fieldNames = field.split(".");
      let fieldValue = updatedData;

      for (const fieldName of fieldNames) {
        if (
          fieldValue &&
          fieldValue[fieldName] !== "" &&
          fieldValue[fieldName] !== undefined
        ) {
          fieldValue = fieldValue[fieldName];
        } else {
          setValue(field, "", { shouldValidate: true });
          isValid = false;
          break;
        }
      }
    }

    return isValid;
  };

  const handleDelete = async () => {
    try {
      const res = await ApiCalls.deleteEmployee(data?.id);
      if (res.success && res.code === 204) {
        navigate("/employee/employees");
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };
  const labels = {
    left: {
      title: "Deactive",
      value: "deactive",
    },
    right: {
      title: "Notice",
      value: "notish",
    },
    center: {
      title: "Active",
      value: "active",
    },
  };
  function hideKey(object, key) {
    if (object.hasOwnProperty(key)) {
      Object.defineProperty(object, key, { enumerable: false });
    }
  }

  return (
    <div>
      
      {!isCreateEmployee && (
        <MDBox>
          <MDBox bgColor="info">
          
          </MDBox>
          { permissions?.includes(modulePermissions.update_permission) &&(<MDBox display="flex" justifyContent="end" alignItems="center" pr={3}>
          <TripleToggleSwitch value={status} labels={labels} onChange={(value)=> {
            setStatus(value)
            setEmployeeStatus(value)
          }} />
          
          </MDBox>)}
          <Header
            data={data}
            userId={user_id}
            permissions={permissions}
            modulePermissions={modulePermissions}
          />
        </MDBox>
      )}
      <br />
      <Card id="basic-info" sx={{ overflow: "visible" }}>
        <MDBox
          p={3}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <MDTypography variant="h5">Employee Information</MDTypography>
          {permissions?.includes(modulePermissions?.delete_permission) && (
            <IconButton
              onClick={() => {
                handleDelete();
              }}
            >
              <Delete />
            </IconButton>
          )}
        </MDBox>
        <MDBox component="form" pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                label="First Name"
                placeholder="Alex"
                value={firstname}
                onChange={(e) => {
                  setEmployee({ ...Employee, firstName: e.target.value });
                  setFirstname(e.target.value);
                  setValue("firstname", e.target.value, {
                    shouldValidate: true,
                  });
                }}
                error={errors.firstname}
                inputProps={{
                  readOnly: !isCreateEmployee
                    ? !permissions?.includes(
                        modulePermissions.update_permission
                      )
                    : false,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Last Name"
                placeholder="Volkov"
                value={lastname}
                onChange={(e) => {
                  setEmployee({ ...Employee, lastName: e.target.value });
                  setLastname(e.target.value);
                  setValue("lastname", e.target.value, {
                    shouldValidate: true,
                  });
                }}
                error={errors.lastname}
                inputProps={{
                  readOnly: !isCreateEmployee
                    ? !permissions?.includes(
                        modulePermissions.update_permission
                      )
                    : false,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    value={gender}
                    options={genderData}
                    getOptionLabel={(e) => e.name}
                    onChange={(event, values) => {
                      setEmployee({
                        ...Employee,
                        gender: values,
                      });
                      setGender(values);
                      setValue("gender", values, { shouldValidate: true });
                    }}
                    renderInput={(params) => (
                      <FormField
                        label="Gender"
                        {...params}
                        placeholder="M/F"
                        InputLabelProps={{ shrink: true }}
                        value={gender}
                        error={errors.gender}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        label="Date of Birth"
                        placeholder="DD/MM/YY"
                        value={dob}
                        onChange={(e) => {
                          setEmployee({ ...Employee, dob: e.target.value });
                          setDob(e.target.value);
                          setValue("dob", e.target.value, {
                            shouldValidate: true,
                          });
                        }}
                        error={errors.dob}
                        inputProps={{
                          type: "date",
                          readOnly: !isCreateEmployee
                            ? !permissions?.includes(
                                modulePermissions.update_permission
                              )
                            : false,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        label="Date of Joining"
                        placeholder="DD/MM/YY"
                        value={doj}
                        onChange={(e) => {
                          setEmployee({ ...Employee, doj: e.target.value });
                          setDoj(e.target.value);
                          setValue("doj", e.target.value, {
                            shouldValidate: true,
                          });
                        }}
                        error={errors.doj}
                        inputProps={{
                          type: "date",
                          readOnly: !isCreateEmployee
                            ? !permissions?.includes(
                                modulePermissions.update_permission
                              )
                            : false,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Email"
                placeholder="example@email.com"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value) ;
                  setEmployee({ ...Employee, email: e.target.value });
                  setValue("email", e.target.value, { shouldValidate: true });
                }}
                // error={errors.email}
                inputProps={{
                  type: "email",
                  readOnly: !isCreateEmployee
                    ? !permissions?.includes(
                        modulePermissions.update_permission
                      )
                    : false,
                }}
              />
            </Grid>
            {isCreateEmployee && (
              <Grid item xs={12} sm={6}>
                <FormField
                  label="Password"
                  type="password"
                  placeholder="********"
                  value={password}
                  onChange={(e) => {
                    isCreateEmployee ? setPassword(e.target.value) : null;
                    setEmployee({ ...Employee, password: e.target.value });
                    setValue("password", e.target.value, {
                      shouldValidate: true,
                    });
                  }}
                  error={errors.password}
                  inputProps={{
                    readOnly: !isCreateEmployee
                      ? !permissions?.includes(
                          modulePermissions.update_permission
                        )
                      : false,
                  }}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
           <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
            <FormField
                    label="Phone Number"
                    placeholder="+40 735 631 620"
                    value={phone}
                    onChange={(e) => {
                      setEmployee({ ...Employee, phone: e.target.value });
                      setPhone(e.target.value);
                      setValue("phone", e.target.value, {
                        shouldValidate: true,
                      });
                    }}
                    error={errors.phone}
                    inputProps={{
                      type: "number",
                      readOnly: !isCreateEmployee
                        ? !permissions?.includes(
                            modulePermissions.update_permission
                          )
                        : false,
                    }}
                  />
            </Grid>
            <Grid item xs={12} sm={6} alignSelf={"flex-end"}>
            <FormControlLabel
            
            control={
              <Checkbox
              // sx={{marginTop:"15px"}}
              value={attendanceCheck}
              onChange={(e,value)=>{

                setAttendanceCheck(true)
              }} name="attendance_check" />
            }
            sx={{marginTop:"15px"}}
            label="Attendance Check Required"
          />
            </Grid>
           </Grid>
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <Autocomplete
                id={"designations"}
                options={designations}
                value={designations.filter((e) => e.id == designationValue)}
                sx={{}}
                getOptionLabel={(option) =>
                  Array.isArray(option)
                    ? option.map((item) => item.name)
                    : option.name
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="Designations"
                    label={"Select Designations"}
                    variant="standard"
                  />
                )}
                onChange={(event, newValue) => {
                  setDesignationValue(newValue?.id);
                  setEmployee({ ...Employee, designation: newValue?.id });
                }}
              />
            </Grid> */}

            <Grid item xs={12} sm={6} alignSelf={"end"}>
              <FormControl fullWidth sx={{}} variant="standard">
                <InputLabel htmlFor="grouped-native-select">
                  Select Designation
                </InputLabel>
                <Select
                  fullWidth
                  native
                  value={designationValue || 0}
                  id="grouped-native-select"
                  label="Grouping"
                  onChange={(event) => {
                    setDesignationValue(event.target?.value);
                    setEmployee({
                      ...Employee,
                      designation: event.target?.value,
                    });
                    setValue("designationValue", event.target?.value, {
                      shouldValidate: true,
                    });
                  }}
                  error={errors.designationValue}
                >
                  <option aria-label="None" value="" />
                  {departments.map(function (e) {
                    return (
                      <>
                        <optgroup label={e.name}>
                          {e.designations.map(function (designation) {
                            return (
                              <>
                                <option value={designation.id}>
                                  {designation.name}
                                </option>
                              </>
                            );
                          })}
                        </optgroup>
                      </>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  {
                    <Autocomplete
                      id={"roles"}
                      options={roles}
                      value={roles?.filter((e) => e.id == roleValue)[0] || ""}
                      sx={{}}
                      getOptionLabel={(option) => option.name || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="Roles"
                          label={"Select Roles"}
                          variant="standard"
                          error={errors.roles}
                        />
                      )}
                      readOnly={
                        !isCreateEmployee
                          ? !permissions?.includes(
                              modulePermissions.update_permission
                            )
                          : false
                      }
                      onChange={(event, newValue) => {
                        setRoleValue(newValue?.id);
                        setEmployee({ ...Employee, role: newValue?.id });
                        setValue("roles", newValue?.id, {
                          shouldValidate: true,
                        });
                      }}
                    />
                  }
                </Grid>
                <Grid item xs={12} sm={6}>
                  {
                    <FormField
                      label="Employee ID"
                      placeholder="0"
                      value={empId}
                      onChange={(e) => {
                        setEmployee({ ...Employee, emp_id: e.target.value });
                        setEmpId(e.target.value);
                        setValue("empId", e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                      error={errors.empId}
                      inputProps={{
                        readOnly: !isCreateEmployee
                          ? !permissions?.includes(
                              modulePermissions.update_permission
                            )
                          : false,
                      }}
                    />
                  }
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}></Grid>
            {!isCreateEmployee &&
              permissions?.includes(modulePermissions?.update_permission) && (
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => handleSubmit()}
                  >
                    Submit
                  </MDButton>
                </Grid>
              )}
          </Grid>
        </MDBox>
      </Card>
    </div>
  );
}
export default BasicInfo;
