import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { Autocomplete, Fab, Input, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import FormField from "layouts/applications/wizard/components/FormField";
import ApiCalls from "services/flick_erp_api_call";
import MDTypography from "components/MDTypography";
import { Close, Done, Edit, ForkRight, Save } from "@mui/icons-material";
import { useForm } from 'react-hook-form';

function Contractsline({ contract, setEmployeeContract, isContractCreate ,  validationErrors,
  setValidationErrors,}) {
  const { register, formState: { errors }, setValue, clearErrors } = useForm();
  const [salaryHeads, setSalaryHead] = useState([]);
  const loggedUser = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    getSalaryHead();
    validationErrors
  }, []);

  useEffect(() => {
    const fields = [
      'name',
      'method',
      'condition',
      'head_type',
      'code',
    ];
    fields.forEach((field) => {
      register(field, { required: "required" });
    });
  }, [register]);

  async function getSalaryHead() {
    var res = await ApiCalls.getSalaryHead();
    if (res.success && res.code == 200) {
      setSalaryHead(res.data);
    }
  }

  useEffect(() => {

    setContractLines(isContractCreate ? [] : contract?.salary_details || []);
  }, []);

  const [contractLines, setContractLines] = useState([]);

  // useEffect(() => {
  //   setContractPayload((state) => ({ ...state, salary_details: contractLines }))
  // }, [contractLines])

  const [rowErrors, setRowErrors] = useState(new Array(contractLines.length).fill({}));

  const handleAddRow = () => {
    const newRow = {
      amount: 0,
      salary_head: "",
      org: loggedUser.org_id,
    };
    setEmployeeContract((state) => ({
      ...state,
      salary_details: [...contract?.salary_details ?? [], newRow],
    }));
  };

  const handleChange = (value, field, index) => {

    const newData = [...contractLines];
    // newData[index][field] = event?.target?.value;
    newData[index][field] = value;
    setContractLines(newData);

    setRowErrors((state) => {
      const updatedErrors = [...state];
      updatedErrors[index] = { ...updatedErrors[index] };
      delete updatedErrors[index][field];
      return updatedErrors;
    });
  };

  const handleSubmit = async (index) => {
    const formData = contractLines[index];

    const errors = {};

    Object.keys(formData).forEach((field) => {
      if (!formData[field]) {
        errors[field] = "This field is required";
      }
    });

    if (Object.keys(errors).length > 0) {
      setRowErrors((state) => {
        const updatedErrors = [...state];
        updatedErrors[index] = errors;
        return updatedErrors;
      });
    } else {


    }
  };

  return (
    <MDBox  p={3}  lineHeight={1}>
  <Grid container fullWidth>
    <Grid item xs={12} sm={12}>

        <Table aria-label="simple table">
          <TableBody data-testid="tBody">
            <TableRow style={{ backgroundColor: "white" }}>
              {/* <TableCell variant="head" style={{ color: "black" }}>
                Name
              </TableCell> */}
              {/* <TableCell variant="head" style={{ color: "black" }}>
                Method
              </TableCell> */}
              <TableCell variant="head" style={{ color: "black" }}>
                Amount
              </TableCell>
              {/* <TableCell variant="head" style={{ color: "black" }}>
                Type
              </TableCell> */}
              <TableCell variant="head" style={{ color: "black" }}>
                Salary Head
              </TableCell>
              <TableCell width={"110px"}>
                {" "}
                <Tooltip title="Add Contract Line">
                  <Fab color="info" onClick={handleAddRow}>
                    <MDTypography color="whtie">+</MDTypography>
                  </Fab>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            {contract?.salary_details?.map((item, index) => (
              <TableRow key={index}>
                {/* <TableCell>
                  <Input
                    value={
                      isContractCreate ? item.name : item?.salary_head?.name
                    }
                    fullWidth
                    onChange={(event) => {
                      handleChange(event.target.value, "name", index)
                      setValue("name", event.target.value, { shouldValidate: true });
                    }}
                    error={!!rowErrors[index]?.name}
                  />
                </TableCell> */}
                {/* <TableCell>
                  <Autocomplete
                    fullWidth
                    value={isContractCreate ? item.method : "fixed"}
                    options={["fixed"]}
                    getOptionLabel={(e) => e.toUpperCase()}
                    onChange={(event) => {
                      setValue("method", event.target.value, { shouldValidate: true });
                    }}
                    renderInput={(params) => (
                      <FormField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        error={!!rowErrors[index]?.method}
                      />
                    )}
                    margin="dense"
                  />
                </TableCell> */}
                <TableCell>
                  <Input
                    fullWidth
                    value={item?.amount ?? ""}
                    onChange={(event) => {
                      const newData = [...contract?.salary_details];
                      newData[index]["amount"] = event.target.value;
                      setEmployeeContract((state) => ({
                        ...state,
                        salary_details: newData,
                      }));
                      setValidationErrors((prevErrors) => ({
                        ...prevErrors,
                        [`amount-${index}`]: undefined, // Clear the error for this field
                      }));
                    }}
                    error={validationErrors[`amount-${index}` || undefined]}
                  />
                </TableCell>
                {/* <TableCell>
                  <Autocomplete
                    fullWidth
                    options={["component", "deduction", "bonus"]}
                    value={
                      isContractCreate
                        ? item.head_type
                        : item?.head_type || ["component", "deduction", "bonus"].find(
                          (e) => e == item?.salary_head?.head_type
                        )
                    }
                    onChange={(event,value) =>
                   {

                    const newData = [...contractLines];
                    newData[index]["head_type"] = value;
                    setContractLines(newData);

}
                    }
                    renderInput={(params) => (
                      <FormField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        error={!!rowErrors[index]?.head_type}
                      />
                    )}
                    margin="dense"
                  />
                </TableCell> */}
                <TableCell>
                  <Autocomplete
                    fullWidth
                    options={salaryHeads}
                    // style={{minWidth:"250px"}}
                    value={
                      salaryHeads.find((e) => e?.id == item?.salary_head?.id) || "" 
                    }
                    getOptionLabel={(e) => e?.name ?  e?.name + ` (${e.head_type})` : "Select Salary Head" ?? ""}
                    onChange={(event, value) => {
                      const newData = [...contract?.salary_details];
                      newData[index]["salary_head"] = value;
                      setEmployeeContract((state) => ({
                        ...state,
                        salary_details: newData,
                      }));
                      setValidationErrors((prevErrors) => ({
                        ...prevErrors,
                        [`salary_head-${index}`]: undefined,// Clear the error for this field
                      }));
                    }}
                    renderInput={(params) => (
                      <FormField
                        {...params}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        error={validationErrors[`salary_head-${index}` || undefined]}
                      />
                    )}
                    margin="dense"
                  />
                </TableCell>
                <TableCell color="info">
                  <Grid container spacing={0.3} justifyContent={"center"}>
                    <Grid item>
                      <Fab
                        size="small"
                        sx={{ height: "0px", width: "35px" }}
                        onClick={() => {
                          var list = [...contract?.salary_details];
                          list.splice(index, 1);
                          setEmployeeContract((state) => ({
                            ...state,
                            salary_details: list,
                          }));
                        }}
                      >
                        <Tooltip title="Remove">
                          <Close sx={{ cursor: "pointer" }}></Close>
                        </Tooltip>
                      </Fab>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

    </Grid>

  </Grid>
    </MDBox>
  );
}

export default Contractsline;
