/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBadgeDot from "components/MDBadgeDot";
import PieChart from "examples/Charts/PieChart";



// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";

function SalaryComponentChart({salaryDetail}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const channelChartData = {
    labels: salaryDetail?.map((e)=> e?.salary_head?.name),
    datasets: {
      label: "Projects",
      backgroundColors: ["info", "primary", "dark", "error", "primary"],
      data: salaryDetail?.map((e)=> e?.amount),
    },
  };

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h6">Salary</MDTypography>
        <Tooltip title="See Salary Component" placement="bottom" arrow>
          <MDButton variant="outlined" color="secondary" size="small" circular iconOnly>
            <Icon>priority_high</Icon>
          </MDButton>
        </Tooltip>
      </MDBox>
     {salaryDetail ? ( <MDBox >
        <Grid container alignItems="center">
          <Grid item xs={7}>
            <PieChart chart={channelChartData} height="9.0rem" />
          </Grid>
          <Grid item xs={5}>
            <MDBox pr={1}>
          {  salaryDetail?.map((e,index)=> <MDBox mb={1}>
                <MDBadgeDot color={index == 0? "info" : index == 1? "primary": "dark"} size="sm" badgeContent={""} />
              </MDBox>)}
              
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>) : (<MDBox height="100%"  display="flex" justifyContent="center" alignItems="center">
        <MDTypography >Not Found!</MDTypography>
      </MDBox>)}

      {/* <MDBox
        pt={4}
        pb={2}
        px={2}
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        mt="auto"
      >
        <MDBox width={{ xs: "100%", sm: "60%" }} lineHeight={1}>
          <MDTypography variant="button" color="text" fontWeight="light">
            More than <strong>1,200,000</strong> sales are made using referral marketing, and{" "}
            <strong>700,000</strong> are from social media.
          </MDTypography>
        </MDBox>
        <MDBox width={{ xs: "100%", sm: "40%" }} textAlign="right" mt={{ xs: 2, sm: "auto" }}>
          <MDButton color={darkMode ? "white" : "light"}>read more</MDButton>
        </MDBox>
      </MDBox> */}
    </Card>
  );
}

export default SalaryComponentChart;
