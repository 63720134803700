import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import EmployeeDashboard from "./employee_dashboard";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import { Icon } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Recruitement from "layouts/recruitement";
import Sales from "./sales";
import Analytics from "./analytics";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

export function Dashboard ({permissions,modulePermissions}){

    const [tabComponents, setTabComponents] = useState([]);

    useEffect(() => {
        setTabComponents([
          {
            label: "Employee",
            component: (
            <EmployeeDashboard
            permissions={permissions}
            modulePermissions={modulePermissions}
            ></EmployeeDashboard>
            ),
            icon: <Icon>group</Icon>,
            visible: true
          },
          {
            label: "Recruitment",
            component: (
            <Recruitement></Recruitement>
            ),
            icon: <Icon>group</Icon>,
            visible: true
          },
          // {
          //   label: "Sales",
          //   component: (
          //   <Sales></Sales>
          //   ),
          //   icon: <Icon>group</Icon>,
          //   visible: true
          // },
          // {
          //   label: "Analytics",
          //   component: (
          //   <Analytics></Analytics>
          //   ),
          //   icon: <Icon>group</Icon>,
          //   visible: true
          // },
        
        ]);
      }, []);
    return (<DashboardLayout>
        <DashboardNavbar/>
 <BaseLayout tabComponents={tabComponents}></BaseLayout>
    </DashboardLayout>);
}