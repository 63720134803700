/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import MDButton from "components/MDButton";
// import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ApiCalls from "services/flick_erp_api_call";
import Header from "../Header";
import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { setToastOpen } from "components/Toast/toastAlertSlice";
import { useDispatch } from "react-redux";

function BasicInfo({
  data,
  employee,
  handleImageSelect,
  isCreateEmployee,
  Employee,
  setEmployee,
  permissions
}) {


  const dispatch = useDispatch()
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const getGenderLabel = (gender) => {
    return gender === "m" ? "Male" : "Female";
  };
  const setGenderResponse = (gender) => {
    return gender === "Male" ? "m" : "f";
  };
  const [firstname, setFirstname] = useState("");
  const [id, setId] = useState("");
  const [lastname, setLastname] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [doj, setDoj] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  // const [img, setImg] = useState("");

  useEffect(() => {
    // Update the firstname state whenever data or currentUser changes
    setFirstname(data?.user?.firstname || "");
    setLastname(data?.user?.lastname || "");
    setGender(getGenderLabel(data?.gender) || "");
    setDob(formatDate(data?.dob) || "");
    setDoj(formatDate(data?.doj) || "");
    setEmail(data?.user?.email || "");
    setPhone(data?.user?.phone || "");
  }, [data]);
  
  const navigate = useNavigate();
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const formattedDate = date.toISOString().slice(0, 10);
    return formattedDate;
  };

  const location = useLocation();
  const user_id = location.state?.userid;
  const user = JSON.parse(localStorage.getItem("user"));

  const handleSubmit = async () => {
    try {
      const updatedData = {
        user: {
          id: data.user.id.toString(),
          firstname: firstname,
          lastname: lastname,
        },
        gender: setGenderResponse(gender),
        dob: dob,
        doj: doj,
        // img: img,
      };
      const res = await ApiCalls.editEmployee(

        user_id,
        updatedData,
        false
      );

      if (res.success && res.code === 200) {
       
        navigate("/employee/employees");
      } else {
       

      }
    } catch (error) {
      console.error(error);
      
    }
  };
  const handleDelete = async () => {
    try {
      const res = await ApiCalls.deleteEmployee(
        user_id,
      );
      if (res.success && res.code === 204) {
       

        navigate("/employee/employees");
      } else {
        

      }
    } catch (error) {
      console.error(error);
    
    }
  };
  return (
    <div>
      {/* {!isCreateEmployee && (
        <MDBox>
          <Header data={data} userId={user_id} permissions={permissions} />
        </MDBox>
      )}
      <br />
      <Card id="basic-info" sx={{ overflow: "visible" }}>
        <MDBox
          p={3}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <MDTypography variant="h5">Employee Information</MDTypography>
          {permissions?.includes("delete") && (<IconButton
            onClick={() => {
              handleDelete();
            }}
          >
            <Delete />
          </IconButton>)}
        </MDBox>
        <MDBox component="form" pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                label="First Name"
                placeholder="Alex"
                value={firstname}
                onChange={(e) => {
                  setEmployee({ ...Employee, firstName: e.target.value });
                  setFirstname(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Last Name"
                placeholder="Volkov"
                value={lastname}
                onChange={(e) => {
                  setEmployee({ ...Employee, lastName: e.target.value });
                  setLastname(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    value={gender}
                    options={selectData.gender}
                    onChange={(event, values) => {
                      setEmployee({
                        ...Employee,
                        gender: setGenderResponse(values),
                      });
                      setGender(values);
                    }}
                    renderInput={(params) => (
                      <FormField
                        label="Gender"
                        {...params}
                        placeholder="M/F"
                        InputLabelProps={{ shrink: true }}
                        value={gender}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        label="Date of Birth"
                        inputProps={{ type: "date" }}
                        placeholder="DD/MM/YY"
                        value={dob}
                        onChange={(e) => {
                          setEmployee({ ...Employee, dob: e.target.value });
                          setDob(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        label="Date of Joining"
                        inputProps={{ type: "date" }}
                        placeholder="DD/MM/YY"
                        value={doj}
                        onChange={(e) => {
                          setEmployee({ ...Employee, doj: e.target.value });
                          setDoj(e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                label="Email"
                placeholder="example@email.com"
                inputProps={{ type: "email" }}
                value={email}
                onChange={(e) => {
                  isCreateEmployee ? setEmail(e.target.value) : null;
                  setEmployee({ ...Employee, email: e.target.value });
                }}
              />
            </Grid>
            {isCreateEmployee && (
              <Grid item xs={12} sm={6}>
                <FormField
                  label="Password"
                  placeholder="********"
                  value={password}
                  onChange={(e) => {
                    isCreateEmployee ? setPassword(e.target.value) : null;
                    setEmployee({ ...Employee, password: e.target.value });
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <FormField
                label="Phone Number"
                placeholder="+40 735 631 620"
                inputProps={{ type: "number" }}
                value={phone}
                onChange={(e) => {
                  setEmployee({ ...Employee, phone: e.target.value });
                  setPhone(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField label="Designation" placeholder="Developer" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                 {!isCreateEmployee &&( <FormField
                    label="Employee ID"
                    placeholder="0"
                    value={currentUser?.id || data?.user?.id}
                    onChange={(e) => {
                      setEmployee({ ...Employee, empId: e.target.value });
                    }}
                    
                  />)}
                </Grid>
                <Grid item xs={12} sm={6}>
                 {!isCreateEmployee &&( <FormField
                    label="Organization ID"
                    placeholder="0"
                    value={ data?.org}
                  />)}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}></Grid>
            {(!isCreateEmployee && permissions?.includes("update")) && (
              <Grid
                item
                xs={12}
                md={6}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </MDButton>
              </Grid>
            )}
          </Grid>
        </MDBox>
      </Card> */}
    </div>
  );
}

export default BasicInfo;
