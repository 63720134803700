import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components

// EmployeeView page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import { useEffect, useState } from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Contracts from "./ContractGeneral";
import Contractsline from "./Contractlines";
import ApiCalls from "services/flick_erp_api_call";
import MDTypography from "components/MDTypography";
import colors from "assets/theme/base/colors";
import { useForm} from 'react-hook-form'; 
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

function ContractView({ contractDetailsPayload, setContractDetailsPayload,  setCreateContractDialog }) {
  const { register, formState: { errors }, setValue , clearErrors  } = useForm();
  const [tabComponents, setTabComponents] = useState([]);
  const [addContractDialog, setAddContractDialog] = useState(false);
  const [contractPayload, setContractPayload] = useState({});
  const [bankDetails, setBankDetails] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    // getEmployeeContract();
    getBankDetials();
  }, []);
  const loggedUser = JSON.parse(localStorage.getItem("user"));
  // async function getEmployeeContract() {
  //   var res = await ApiCalls.getEmployeeContract(
  //     `?employee_id=${data.id}&is_active=true`
  //   );
  //   if (res.success && res.code == 200) {
  //     if (res?.data[0]) {
  //       setContractDetailsPayload(res?.data[0]);
  //     }
  //   }
  // }
  async function getBankDetials() {
    var res = await ApiCalls.getEmployeeBankDetails(
    );
    if (res.success && res.code == 200) {
      setBankDetails(res?.data);
    }
  }

  const validation = (payload) =>{
    const errors = {};
  
    Object.keys(payload).forEach((field) => {
      if (payload[field] === null || payload[field] === undefined || payload[field] === "") {
        errors[field] = "This field is required";
      }
    });

    if (payload.salary_details && payload.salary_details.length > 0) {
      payload.salary_details.forEach((detail, index) => {
        if (!detail.salary_head) {
          errors[`salary_head-${index}`] = "Salary Head is required";
        }
        if (!detail.amount || isNaN(parseFloat(detail.amount)) || parseFloat(detail.amount) === 0) {
          errors[`amount-${index}`] = "Valid Amount is required";
        }
      });
    }
  
    Object.keys(errors).forEach((field) => {
      setValue(field, '', { shouldValidate: true });
    });
  
    if (Object.keys(errors).length > 0) {

      setValidationErrors(errors);
      return false;
    } else {
      return true;
    }
  }

  async function updateContract() {
    var payload = {
      bank: contractDetailsPayload?.bank?.id,
      end_date: contractDetailsPayload.end_date,
      start_date: contractDetailsPayload.start_date,
      contract_type: contractDetailsPayload.contract_type,
      is_active: contractDetailsPayload.is_active,
      terms: contractDetailsPayload.terms,
      ctc: contractDetailsPayload.ctc,
      salary_details: contractDetailsPayload.salary_details.map((e) => ({
        ...e,
        salary_head: e.salary_head.id,
      })),
    };

    const isValid = validation(payload);
    if(isValid){
      setValidationErrors({});
      var res = await ApiCalls.patchEmployeeContract(
        contractDetailsPayload.id,
        payload,
        false
      );
      if (res.success && res.code == 200) {
        setCreateContractDialog(false);
      }
    }

   
  }
  useEffect(() => {
    setTabComponents([
      {
        label: "General",
        component: (
          <Contracts
            isContractCreate={ contractDetailsPayload?.type == "create"}
            contract={contractDetailsPayload}
            setEmployeeContract={setContractDetailsPayload}
            bankDetails={bankDetails}
            validationErrors={validationErrors} 
      setValidationErrors={setValidationErrors}
          />
        ),
      },
      {
        label: "Contract Lines",
        component: (
          <Contractsline
            isContractCreate={ contractDetailsPayload?.type == "create"}
            contract={contractDetailsPayload}
            setEmployeeContract={setContractDetailsPayload}
            bankDetails={bankDetails}
            validationErrors={validationErrors} 
            setValidationErrors={setValidationErrors}
          />
        ),
      },
    ]);
  }, [contractDetailsPayload, bankDetails , validationErrors]);

  async function createEmployeeContract() {
    var payload = {
      bank: contractDetailsPayload.bank.id,
      salary_details: contractDetailsPayload?.salary_details?.map((e) => ({
        ...e,
        salary_head: e.salary_head.id,
      })) ?? [],
      terms: contractDetailsPayload.terms,
      start_date: contractDetailsPayload.start_date,
      end_date: contractDetailsPayload.end_date,
      contract_type: contractDetailsPayload.contract_type,
      ctc: contractDetailsPayload.ctc,
      is_active: contractDetailsPayload.is_active,
      org: loggedUser.org_id,
      // employee: data?.id,
    };
    const isValid = validation(payload);
    if(isValid){
    var res = await ApiCalls.createEmployeeContract(payload, false);
    if (res.success && res.code == 201) {
      setCreateContractDialog(false);
    }
  }
  }

  return (
    <>
      <MDBox sx={{  width:"100%"}}>
            <BaseLayout tabComponents={tabComponents}></BaseLayout>
            <MDBox align="center">
              <MDButton
                variant="gradient"
                color="info"
                onClick={() => {
                  contractDetailsPayload?.type == "create"
                    ? 
                    createEmployeeContract()
                    : updateContract();
                }}
              >
                Submit
              </MDButton>
            </MDBox>

        
        {/* <Dialog
          PaperProps={{
            style: {
              backgroundColor: "aliceBlue",
              boxShadow: "none",
              width: "100%",
              // height: "500px",
              maxWidth: "none",
              padding: "20px",
            },
          }}
          open={addContractDialog}
          onClose={() => {
            setAddContractDialog(false);
          }}
        >
          <DialogTitle>Add Contract</DialogTitle>
          <DialogContent>
            <Contracts
              isContractCreate={addContractDialog}
              contract={contractDetailsPayload}
              setContractDetailsPayload={setContractDetailsPayload}
              bankDetails={bankDetails}
            />

            <Contractsline
              isContractCreate={addContractDialog}
              contract={contractDetailsPayload}
              setContractDetailsPayload={setContractDetailsPayload}
              bankDetails={bankDetails}
            />
          </DialogContent>
          <DialogActions>
            <MDButton
              onClick={() => {
                createEmployeeContract();
              }}
            >
              Create Contract
            </MDButton>
            <MDButton onClick={() => {}}>Cancel</MDButton>
          </DialogActions>
        </Dialog> */}
      </MDBox>
    </>
  );
}

export default ContractView;
