import { Popover } from "@mui/material";

export function FlickPopover ({open,anchorEl,close,children }) {
    return (<Popover
        id="mouse-over-popover"
        sx={{

          
        }}
        PaperProps={{
            style: {
              backgroundColor: "white",
              marginTop:"-10px"
            },
          }}
        // color="white"

        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        onClose={close}
        disableRestoreFocus
      >
      {children}
      </Popover>);
}