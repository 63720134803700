import { Search } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

export function SearchBox({ width, placeholder, onChange, sort, borderColor, ...rest }) {
  return (
   <MDBox bgColor={borderColor} p={.2}   sx={{ borderRadius: "20px", }} mr={.5}>
     <MDBox
      // p={1}
      // mr={1}
      display="flex"
      alignItems="center"
      px={1}
      height={35}
      width={width}
      bgColor="white"
      sx={{ borderRadius: "17px", }}
    >
      <MDInput
        variant="standard"
        placeholder={placeholder}
        fullWidth
        onChange={(e) => {
          onChange(e.target.value);
        }}
        {...rest}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      ></MDInput>
      {sort}
    </MDBox>
   </MDBox>
  );
}
