import {
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  Tooltip,
  Typography,
  TextField,
  Autocomplete,
  Grid
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import ApiCalls from "services/flick_erp_api_call";
import InfoIcon from "@mui/icons-material/Info";
import MDBox from "components/MDBox";
import SalesTable from "examples/Tables/SalesTable";
import MDTypography from "components/MDTypography";
import moment from "moment";
import ContractView from "./Contract";
import { Add, Edit } from "@mui/icons-material";
import MDButton from "components/MDButton";
import { BankDetails } from "./BankDetails";

export function Contracts() {
  const [salaryDetailDialog, setSalaryDetailDialog] = useState(false);
  const [createContractDialog, setCreateContractDialog] = useState(false);
  const [createBankDialog, setCreateBankDialog] = useState(false);
  const [salaryDetails, setSalaryDetails] = useState([]);
  const [contractDetailsPayload, setContractDetailsPayload] = useState({});
  const [contractStatus, setContractStatus] = useState({name:"Active", value:true});


  useEffect(() => {
    createContractDialog == false
      ? getEmployeeContract(`?is_active=${contractStatus.value}`)
      : null;
  }, [createContractDialog,contractStatus]);
  async function getEmployeeContract(query) {
    var res = await ApiCalls.getEmployeeContract(query);
    if (res.success && res.code == 200) {
      contractDetails.rows = res.data.map((e) => ({
        ...e,
        contractStatus: isContractExpire(e?.end_date)
          ? "red"
          : isContractUpcoming(e?.start_date)
          ? "yellow"
          : "",
      }));

      setContractDetails({ ...contractDetails });
    }
  }
  const isContractExpire = (date) => {
    var currentDate = new Date();
    var end_date = new Date(date);
    return end_date <= currentDate;
  };
  const isContractUpcoming = (date) => {
    var currentDate = new Date();
    var start_date = new Date(date);
    return start_date > currentDate;
  };
  const [contractDetails, setContractDetails] = useState({
    columns: [
      {
        Header: "NAME",
        accessor: "name",
        bgColor: "red",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span
            // style={{
            //   color: isContractExpire(row.original?.end_date)
            //     ? "red"
            //     : isContractUpcoming(row.original?.start_date)
            //     ? "yellow"
            //     : "",
            // }}
            >
              {row.original.bank.name_in_account}
            </span>
          </div>
        ),
      },
      {
        Header: "Start Date",
        accessor: "start_date",
      },
      {
        Header: "End Date",
        accessor: "end_date",
      },
      {
        Header: "Contract Type",
        accessor: "contract_type",
      },
      {
        Header: "CTC",
        accessor: "ctc",
      },

      {
        Header: "Action",
        accessor: "inout",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Info">
              <IconButton
                aria-label="info"
                size="small"
                onClick={() => {
                  handleView(row.original.salary_details);
                }}
              >
                <InfoIcon></InfoIcon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Info">
              <IconButton
                aria-label="info"
                size="small"
                onClick={() => {
                  setContractDetailsPayload({ type: "edit", ...row.original });
                  setCreateContractDialog(true);
                }}
              >
                <Edit></Edit>
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
    ],
    rows: [],
  });
  const handleView = (data) => {
    setSalaryDetails(
      data?.map((e) => ({
        amount: e?.amount,
        salary_head: e?.salary_head?.name,
        head_type: e?.salary_head?.head_type,
      }))
    );
    setSalaryDetailDialog(true);
  };

  return (
    <DashboardLayout>
      <Card>
        <MDBox
          pt={3}
          pl={3}
          pb={4}
          pr={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <MDTypography variant="h4">Contracts</MDTypography>
          <MDBox display="flex">
            <MDButton
              color="info"
              onClick={() => {
                setCreateContractDialog(true);
                setContractDetailsPayload({ type: "create" });
              }}
            >
              Add Contract
            </MDButton>
            <div style={{ paddingLeft: "2px" }}></div>
            <MDButton
              color="info"
              onClick={() => {
                setCreateBankDialog(true);
              }}
            >
              Add Bank
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox>

           <Grid container spacing={2} pr={2} justifyContent="end">
            <Grid item width={160}>
            <Autocomplete
              width={200}
              value={contractStatus}
              options={[
                { name: "Active", value: true },
                { name: "In Active", value: false },
              ]}
              getOptionLabel={(v) => v.name}
              onChange={(event, values) => {
                setContractStatus(values??{})
              }}
              renderInput={(params) => (
                <TextField {...params} label="Status" variant="standard" />
              )}
            ></Autocomplete>
            </Grid>
           </Grid>

          <DataTable
            table={contractDetails}
            entriesPerPage={{
              defaultValue: 100,
              entries: [5, 10, 15, 20, 25, 30],
            }}
            showTotalEntries={true}
            isSorted={false}
          ></DataTable>
        </MDBox>
      </Card>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "white",
            boxShadow: "none",
            width: "auto",
            maxWidth: "none",
            borderRadius: "10px",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        open={salaryDetailDialog}
        onClose={() => setSalaryDetailDialog(false)}
      >
        <DialogTitle
          style={{
            width: "100%",
            padding: "20px",
            borderBottom: "10px solid ",
            backgroundColor: "#007bed",
            color: "white",
          }}
        >
          <Typography sx={{ color: "white", fontWeight: "Medium" }}>
            Salary Details
          </Typography>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "4px",
          }}
        >
          <MDBox align="center" p={2}>
            <SalesTable title="" rows={salaryDetails} />
          </MDBox>
        </DialogContent>
      </Dialog>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "white",
            boxShadow: "none",
            width: "50%",
            maxWidth: "none",
            borderRadius: "10px",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        open={createContractDialog}
        onClose={() => setCreateContractDialog(false)}
      >
        <DialogTitle
          style={{
            width: "100%",
            padding: "20px",
            borderBottom: "10px solid ",
            backgroundColor: "#007bed",
            color: "white",
          }}
        >
          <Typography sx={{ color: "white", fontWeight: "Medium" }}>
            Add Contract
          </Typography>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "4px",
            width: "100%",
          }}
        >
          <MDBox align="center" p={2}>
            <ContractView
              contractDetailsPayload={contractDetailsPayload}
              setContractDetailsPayload={setContractDetailsPayload}
              setCreateContractDialog={setCreateContractDialog}
            />
          </MDBox>
        </DialogContent>
      </Dialog>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "white",
            boxShadow: "none",
            width: "auto",
            maxWidth: "none",
            borderRadius: "10px",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        open={createBankDialog}
        onClose={() => setCreateBankDialog(false)}
      >
        <DialogTitle
          style={{
            width: "100%",
            padding: "20px",
            borderBottom: "10px solid ",
            backgroundColor: "#007bed",
            color: "white",
          }}
        >
          <Typography sx={{ color: "white", fontWeight: "Medium" }}>
            Add Bank
          </Typography>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "4px",
          }}
        >
          <MDBox align="center" p={2}>
            <BankDetails
              setCreateBankDialog={setCreateBankDialog}
            ></BankDetails>
          </MDBox>
        </DialogContent>
      </Dialog>
    </DashboardLayout>
  );
}
