/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import { useSelector, useDispatch } from "react-redux";
import { selectClickedEmployee } from "./employeesSlice";
import setClickedEmployee from "./employeesSlice";
import { useLocation } from "react-router-dom";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";

import ApiCalls from "services/flick_erp_api_call";
import { useEffect, useMemo, useState } from "react";
import { dispatchEmployee, selectGetEmployee } from "./employeesSlice";

import { employeeList } from "./employeesSlice";
import Avatar from "@mui/material/Avatar";
import { AccountCircle } from "@mui/icons-material";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { setToastOpen } from "components/Toast/toastAlertSlice";
import { Autocomplete, TextField } from "@mui/material";

function Employees({ permissions, modulePermissions }) {
  const location = useLocation();
  const [status,setStatus] = useState({val:"active", name:"Active"});

  const [employeesData, setEmployeesData] = useState({
    columns: [
      { Header: "Emp ID", accessor: "emp_id", width: "10%" },
      {
        Header: "Name",
        accessor: "firstname",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar alt="Profile Photo">
              {row.original.profilePhoto.length > 0 ? (
                <img
                  src={row.original.profilePhoto}
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
              ) : (
                row.original.firstname.charAt(0).toUpperCase()
              )}
            </Avatar>
            <span style={{ marginLeft: "0.5rem" }}>
              {row.original.firstname}
            </span>
            <span style={{ marginLeft: "0.2rem" }}>
              {row.original.lastname}
            </span>
          </div>
        ),
      },
      { Header: "Designation", accessor: "designation" },

      { Header: "email", accessor: "email" },
      { Header: "manager", accessor: "manager_name"},
      { Header: "Date of joining", accessor: "doj" },
    ],
    rows: [],
  });
  const employee = useSelector((state) => state.employee.employee);


  const dispatch = useDispatch();
  useEffect(() => {
    getEmployee(`?status=${status.val}`);
  }, [status]);

  async function getEmployee(queryParams) {
    const user = JSON.parse(localStorage.getItem("user"));
    var res = await ApiCalls.getEmployeesList(queryParams);
    if (res.success && res.code === 200) {
      var data = JSON.parse(JSON.stringify(res.data));
      var filterData = data.filter((o) => (o.user.id = o.id));
      employeesData.rows = filterData.map((o) => ({
        ...o.user,
        emp_id: o?.emp_id,
        designation: o?.designation?.name,
        doj: o?.doj,
        profilePhoto: o?.img || AccountCircle,
        manager_name : o?.manager_name
      }));
      // dispatch(employeeList({ employee: employeesData }));
      setEmployeesData({ ...employeesData });
    } else {
    }
  }

  const navigate = useNavigate();

  const handleAddClick = () => {
    navigate("/pages/profile/profile-overview/", {
      state: {
        isCreateEmployee: true,
        permissions: permissions,
      },
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox
              p={3}
              lineHeight={1}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <MDTypography variant="h5" fontWeight="medium">
                Employees
              </MDTypography>
              {permissions?.includes(modulePermissions?.create_permission) && (
                <MDBox>
                  <MDButton
                    variant="gradient"
                    color="dark"
                    sx={{borderRadius:"20px"}}
                    onClick={() => {
                      handleAddClick();
                    }}
                  >
                    Add +
                  </MDButton>
                </MDBox>
              )}
            </MDBox>
            <DataTable
              table={employeesData}
              isEmployeeTable={true}
              canSearch
              permissions={permissions}
              sort={permissions.includes(modulePermissions.update_permission) &&(<Autocomplete
                value={status}
                options={[{val:"active", name:"Active"},{val:"deactive",name:"Deactive"},{val:"notish", name:"In Notice"}]}
                getOptionLabel={(e)=> e?.name}
                onChange={(event, newValue) => {
                  setStatus(newValue ?? {val:"active", name:"Active"});
                }}
             
                sx={{ width: "240px",paddingLeft:"5px", paddingRight: "5px" }}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"   InputProps={{...params.InputProps, disableUnderline: true}}
                  />
                )}
              />)}
            ></DataTable>
          </Card>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Employees;
