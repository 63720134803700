import { Cancel } from "@mui/icons-material";
import {
  Autocomplete,
  Avatar,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import MDBox from "components/MDBox";
import { SearchBox } from "components/SearchBox";
import DataTable from "examples/Tables/DataTable";
import moment from "moment";
import { useEffect, useState } from "react";
import ApiCalls from "services/flick_erp_api_call";

export function LeaveHistoryComponent({
  permissions,
  modulePermissions,
  reload,
  setReload
}) {
  useEffect(() => {
    getLeaveRequests();
  }, [reload]);
  async function getLeaveRequests() {
    var res = await ApiCalls.getLeaveRequest();
    if (res.success && res.code == 200) {
      leaveData.rows = res.data;
      leaveData.rows = leaveData.rows.map((e)=> ({...e,firstname : e.employee.user.firstname,lastname : e.employee.user.lastname}))

      setLeaveData({ ...leaveData });
    }
  }
  async function changeStatus(id, status) {
    var payload = {
      status: status,
    };
    var res = await ApiCalls.updateLeaveRequest(id, payload, false);
    if (res.success) {
      getLeaveRequests();
      setReload(Math.random().toString())
    }
  }
  async function deleteLeaveRequest(id) {
    var res = await ApiCalls.deleteLeaveRequest(id);
    if (res.success && res.code == 204) {
      getLeaveRequests();
      setReload(Math.random().toString())
    }
  }
  const [leaveData, setLeaveData] = useState(
    {
      columns: [
        {
          Header: "Apply Date",
          accessor: "created_at",
          Cell: ({ row }) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              {moment(row.original.created_at).format("YYYY-MM-DD")}
            </div>
          ),
        },
        {
          Header: "Name",
          accessor: "firstname",
          Cell: ({ row }) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                src={row.original?.profilePhoto}
                alt="Profile Photo"
                sizes="s"
              />
              <span style={{ marginLeft: "0.2rem" }}>
                {row.original?.firstname}
              </span>
              <span style={{ marginLeft: "0.2rem" }}>
                {row.original?.lastname}
              </span>
            </div>
          ),
        },
        { Header: "Start Date", accessor: "start_date" },
        { Header: "End Date", accessor: "end_date" },
        {
          Header: "Category",
          accessor: "category",
          Cell: ({ row }) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              {row.original?.leave_category?.name}
            </div>
          ),
        },
        {
          Header: "Hours",
          accessor: "hours",
          Cell: ({ row }) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              {row.original.number_of_hours}
            </div>
          ),
        },
        {
          Header: "Status",
          accessor: "status",
          Cell: ({ row }) =>
            permissions?.includes(modulePermissions?.update_permission) ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Autocomplete
                  blurOnSelect
                  underlineShow={false}
                  value={["pending", "approved", "rejected"].find(
                    (e) => e == row.original.status
                  )}
                  options={["pending", "approved", "rejected"]}
                  onChange={(event, newValue) => {
                    changeStatus(row.original.id, newValue);
                  }}
                  // inputProps={{ disableUnderline: true}}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      sx={{ width: "120px" }}
                    />
                  )}
                ></Autocomplete>
              </div>
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                {row.original.status}
              </div>
            ),
        },
        {
          Header: "Action",
          accessor: "action",
          Cell: ({ row }) =>
            permissions?.includes(modulePermissions?.delete_permission) && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Tooltip title="Cancel">
                  <IconButton
                    aria-label="cancel"
                    size="small"
                    onClick={() => {
                      deleteLeaveRequest(row.original.id);
                    }}
                  >
                    <Cancel />
                  </IconButton>
                </Tooltip>
              </div>
            ),
        },
      ],
      rows: [],
    },
    []
  );
  return (
    <MDBox>
      {/* <MDBox display="flex" justifyContent="flex-end">
        <SearchBox
          //  width={350}
          placeholder={"Search Candidate"}
          borderColor="info"
          onChange={(e) => {
          }}
        />
      </MDBox> */}
      <DataTable table={leaveData}
canSearch
      ></DataTable>
    </MDBox>
  );
}
