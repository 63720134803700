import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
// import Card from "@mui/material/Card";
import DataTable from "examples/Tables/DataTable";
import FormField from "layouts/pages/account/components/FormField";

import {
  Autocomplete,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  Icon,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Edit, Delete } from "@mui/icons-material";
import ApiCalls from "services/flick_erp_api_call";
import MDButton from "components/MDButton";
import { useDispatch } from "react-redux";
import { setToastOpen } from "components/Toast/toastAlertSlice";
import colors from "assets/theme/base/colors";
import MDTypography from "components/MDTypography";
import { randomIntFromInterval } from "helpers/utility";
import moment from "moment";
function Holidays({ permissions, modulePermissions }) {
  const typeList = [
    { name: "National", type: "n" },
    { name: "State", type: "s" },
    { name: "Optional", type: "o" },
  ];
  const handleDeleteClick = (id) => {
    deleteHoliday(id);
  };
  const handleEditClick = (data) => {
    setPostHolidayPayload({
      id: data.id,
      date: data.date,
      name: data.name,
      type: data.type,
      request: "edit",
    });
    setCreateDialogOpen(true);
  };
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [holidayDialogOpen, setHolidayDialogOpen] = useState(false);
  const [openHolidayData, setOpenHolidayData] = useState(false) ;
  const [postHolidayPayload, setPostHolidayPayload] = useState({});
  const [typeError, setTypeError] = useState("");
  const [holidayNameError, setHolidayNameError] = useState("");
  const [dateError, setDateError] = useState("");
  const [holidays, setHolidays] = useState();

  const dispatch = useDispatch();
  const [holidaysRecord, setHolidaysRecord] = useState({
    columns: [
      {
        Header: "TYPE",
        accessor: "type_name",
      },
      {
        Header: "NAME",
        accessor: "name",
        Cell: ({ row }) => (
          <span style={{ color: "black" }}>{row.original.name}</span>
        ),
      },
      {
        Header: "DATE",
        accessor: "date",
      },
      {
        Header: "Action",
        accessor: "edit_delete",
        Cell: ({ row }) =>
          (permissions?.includes(modulePermissions?.update_permission) ||
            permissions?.includes(modulePermissions?.delete_permission)) && (
            <div style={{ display: "flex", alignItems: "center" }}>
              {permissions?.includes(modulePermissions?.delete_permission) && (
                <Tooltip title="Delete">
                  <IconButton
                    size="small"
                    onClick={() => {
                      handleDeleteClick(row.original.id);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              )}
              {permissions?.includes(modulePermissions?.update_permission) && (
                <Tooltip title="Edit">
                  <IconButton
                    size="small"
                    onClick={() => {
                      handleEditClick(row.original);
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          ),
      },
    ],
    rows: [],
  });
  const [months, setMonths] = useState([
    { name: "January", id: "01" },
    { name: "February", id: "02" },
    { name: "March", id: "03" },
    { name: "April", id: "04" },
    { name: "May", id: "05" },
    { name: "June", id: "06" },
    { name: "July", id: "07" },
    { name: "August", id: "08" },
    { name: "September", id: "09" },
    { name: "October", id: "10" },
    { name: "November", id: "11" },
    { name: "December", id: "12" },
  ]);
  useEffect(() => {
    getHolidays();
  }, []);
  async function getHolidays() {
    const res = await ApiCalls.getholidaysList();
    if (res.success && res.code === 200) {
      holidaysRecord.rows = res.data.map((e) => ({
        type_name: getLeaveType(e.type),
        ...e,
      }));
      setHolidays(res.data)

      setHolidaysRecord({ ...holidaysRecord });
    } else {
    }
  }
  async function deleteHoliday(id) {
    const res = await ApiCalls.deleteHoliday(id);

    if (res.success && res.code === 204) {
      getHolidays();
    } else {
    }
  }
  async function createHoliday() {
    let hasError = false;
    let error = "This field may not be blank.";

    if (
      postHolidayPayload.name === undefined ||
      postHolidayPayload.name === ""
    ) {
      setHolidayNameError(error);
      hasError = true;
    } else {
      setHolidayNameError("");
    }
    if (
      postHolidayPayload.date === undefined ||
      postHolidayPayload.date === ""
    ) {
      setDateError(error);
      hasError = true;
    } else {
      setDateError("");
    }
    if (
      postHolidayPayload.type === undefined ||
      postHolidayPayload.type === ""
    ) {
      setTypeError(error);
      hasError = true;
    } else {
      setTypeError("");
    }

    if (!hasError) {
      const res = await ApiCalls.createHolidays(
        {
          date: postHolidayPayload.date,
          name: postHolidayPayload.name,
          type: postHolidayPayload.type,
        },
        false
      );
      if (res.success && res.code === 201) {
        setPostHolidayPayload({});
        setCreateDialogOpen(false);
        getHolidays();
      } else if (res.success == false && res.code == 400) {
        const errors = res.data;
        if (errors.name) {
          alert(errors.name[0]);
        } else if (errors.date) {
          alert(errors.date[0]);
        } else if (errors.type) {
          alert(errors.type[0]);
        }
      } else {
      }
    }
  }
  async function updateHoliday(id) {
    const res = await ApiCalls.updateHoliday(
      id,
      {
        org: JSON.parse(localStorage.getItem("user")).org_id,
        date: postHolidayPayload.date,
        name: postHolidayPayload.name,
        type: postHolidayPayload.type,
      },
      false
    );
    if (res.success && res.code === 200) {
      setPostHolidayPayload({});
      setCreateDialogOpen(false);
      getHolidays();
    } else {
    }
  }
  const getLeaveType = (type) => {
    return type == "o"
      ? "Optional"
      : type == "n"
      ? "National"
      : type == "s"
      ? "State"
      : "";
  };

  const handleCreateSubmit = () => {
    createHoliday();
  };
  const handleEditSubmit = (id) => {
    updateHoliday(id);
  };
  const handleSubmit = (id) => {
    if (postHolidayPayload.request == "create") {
      handleCreateSubmit();
    } else {
      handleEditSubmit(postHolidayPayload.id);
    }
  };
  const backgroundColors = ["info", "primary","secondary","info","primary","secondary"];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={1}>
        {/* <MDBox>
          <Card sx={{ overflow: "visible" }}>
            <MDBox
              mt={-3}
              p={3}
              lineHeight={1}
              bgColor="#007bed"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                color: " white",
                borderRadius: 2,
                marginLeft: "0.9rem",
                marginRight: "0.9rem",
              }}
            >
              Holiday's
            </MDBox>

           { permissions?.includes(modulePermissions?.create_permission) &&( <MDBox
              p={2}
              lineHeight={1}
              sx={{
                display: "flex",
                justifyContent: "end",

                borderRadius: 3,
              }}
            >
              <Grid item>
                <MDButton
                  color="white"
                  onClick={() => {
                    setCreateDialogOpen(true);
                    setPostHolidayPayload({ request: "create" });
                  }}
                  sx={{
                    border: "1px solid black",
                    "&:hover": { backgroundColor: colors.info.main, color: " white" },
                  }}
                >
                  Add Holiday
                </MDButton>
              </Grid>
            </MDBox>)}
            <DataTable
              table={holidaysRecord}
              entriesPerPage={false}
              showTotalEntries={false}
              isSorted={false}
            ></DataTable>
          </Card>
        </MDBox> */}
        <MDBox>
          <Card style={{ padding: "10px", display: "flex" }}>
            <Typography p={2} variant="h5" textAlign={"center"}>
              2024 Holiday Schedule
            </Typography>
            <MDBox
              display="flex"
              justifyContent="center"
              p={1}
              style={{ flexWrap: "wrap" }}
            >
              {months.map((e) => (
                <MDBox p={1}>
                  <Card
                    style={{
                      height: "150px",
                      width: "200px",
                      padding: "30",
                      overflow: "hidden",
                    }}
                  >
                    <MDBox p={0.5} pb={0.6} bgColor="#4285F4">
                      <MDBox
                        bgColor="#4285F4"
                        style={{
                          borderRadius: "15px",
                          borderBottomLeftRadius: "0px",
                          borderBottomRightRadius: "0px",
                          boxShadow:
                            "rgba(17, 17, 26, 0.2) 0px 4px 16px 0px inset, rgba(17, 17, 26, 0.05) 0px 8px 32px 0px",
                        }}
                      >
                        <MDTypography textAlign="center" color="white">
                          {e.name}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox p={1}>
                     {holidays?.map((h)=> (
                      new Date(h.date).getMonth() + 1 == e.id ? (<>
                       <Fab
                        style={{ height: "1px", width: "36px", }}
                        color={backgroundColors[randomIntFromInterval(0, 5)]}
                        onClick={()=>{
                          setOpenHolidayData(h)
                          setHolidayDialogOpen(true)
                        }}
                      >
                        {new Date(h.date).getDate()}
                      </Fab></>) : (<></>)
                     ))}
                     {!(holidays?.some((h)=>  new Date(h.date).getMonth() + 1 == e.id)) && (<Typography textAlign={"center"}>
                      No Holidays!
                     </Typography>)}
                    </MDBox>
                  </Card>
                </MDBox>
              ))}
            </MDBox>
          </Card>
        </MDBox>
      </MDBox>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "white",
            boxShadow: "none",
            width: "600px",
            height: "auto",
            maxWidth: "none",
            borderRadius: "10px",
            cursor: "pointer",
          },
        }}
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
      >
        <DialogTitle
          style={{
            width: "100%",
            padding: "20px",
            backgroundColor: "steelblue",
            color: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "Medium" }}>
              Add Holiday
            </Typography>
            <Icon
              fontSize="medium"
              onClick={() => {
                setCreateDialogOpen(false);
                setPostHolidayPayload({});
              }}
            >
              close
            </Icon>
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "4px",
          }}
        >
          <Card id="basic-info" sx={{ overflow: "visible" }}>
            <MDBox component="form" pb={3} px={3} pt={3}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Holiday Name"
                    variant="standard"
                    value={postHolidayPayload.name}
                    onChange={(event) => {
                      setPostHolidayPayload((state) => ({
                        ...state,
                        name: event.target.value,
                      }));
                      setHolidayNameError("");
                    }}
                    required
                    error={!!holidayNameError}
                    helperText={holidayNameError}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Date"
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    InputProps={{}}
                    id="date"
                    type="date"
                    value={postHolidayPayload.date}
                    name="date"
                    onChange={(event) => {
                      setPostHolidayPayload((state) => ({
                        ...state,
                        date: event.target.value,
                      }));
                      setDateError("");
                    }}
                    required
                    error={!!dateError}
                    helperText={dateError}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    id="Type"
                    options={typeList}
                    value={
                      typeList.filter(
                        (e) => e.type == postHolidayPayload.type
                      )[0]
                    }
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        name="Type"
                        label="Select Type"
                        variant="standard"
                        required
                        error={!!typeError}
                        helperText={typeError}
                      />
                    )}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setPostHolidayPayload((state) => ({
                          ...state,
                          type: newValue.type,
                        }));
                      }
                      setTypeError("");
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                fullWidth
                pt={3}
                // md={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </MDButton>
              </Grid>
            </MDBox>
          </Card>
        </DialogContent>
      </Dialog>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "white",
            boxShadow: "none",
            width: "600px",
            height: "auto",
            maxWidth: "none",
            borderRadius: "10px",
            cursor: "pointer",
          },
        }}
        open={holidayDialogOpen}
        onClose={() => setHolidayDialogOpen(false)}
      >
        <DialogTitle
          style={{
            width: "100%",
            padding: "20px",
            backgroundColor: "steelblue",
            color: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
           {/* <MDBox display="flex" > */}
           <Typography  sx={{ color:"white", paddingRight:"60px",  fontWeight: "Medium" }}>
              {openHolidayData?.name}
            </Typography>
            {/* <MDBox> */}
            <Typography  sx={{ color:"white", paddingRight:"30px",  fontWeight: "Medium" }}>
              {moment(openHolidayData.date).format("ddd")+"/"+ moment(openHolidayData.date).format("MMM")+"/" + moment(openHolidayData.date).year()}
            </Typography>
            {/* </MDBox> */}
           {/* </MDBox> */}
            <Icon
              fontSize="medium"
              onClick={() => {
                setHolidayDialogOpen(false);
                setOpenHolidayData({});
              }}
            >
              close
            </Icon>
          </div>
        </DialogTitle>
        {/* <DialogContent
          style={{
            padding: "4px",
          }}
        >
          <Card id="basic-info" sx={{ overflow: "visible" }}>
          
          </Card>
        </DialogContent> */}
      </Dialog>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}
export default Holidays;
