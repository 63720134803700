import { Avatar } from "@mui/material";
import MDBox from "components/MDBox";
import { SelectEmployee } from "components/SelectEmployee";
import DataTable from "examples/Tables/DataTable";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import ApiCalls from "services/flick_erp_api_call";

export function LeaveTransactionComponent({ employees, reload }) {
  useEffect(() => {
    getLeaveTransaction();
  }, [reload]);
  // get Leave Transaction
  async function getLeaveTransaction() {
    var res = await ApiCalls.getLeaveTransactions();
    if (res.success && res.code == 200) {
      leaveTransactionData.rows = res.data;
      leaveTransactionData.rows = leaveTransactionData.rows.map((e) => ({
        ...e,
        firstname: employees?.find((emp) => emp.id == e?.employee)?.user
          ?.firstname,
      }));
      setLeaveTransactionData({ ...leaveTransactionData });
    }
  }

  // Refresh Transactions
  async function validateLeaveTransaction(e) {
    var obj = { employee_id: e };
    var res = await ApiCalls.postValidateLeaveTransaction(obj, false);
    if (res.success && res.code == 200) {
    }
  }
  const [leaveTransactionData, setLeaveTransactionData] = useState({
    columns: [
      {
        Header: "Added Date",
        accessor: "transaction_date",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {moment(row.original.transaction_date).format("YYYY-MM-DD")}
          </div>
        ),
      },
      {
        Header: "Name",
        accessor: "firstname",
        Cell: ({ row }) => {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar src={row.original.profilePhoto} alt="Profile Photo" />
              <span style={{ marginLeft: "0.5rem" }}>
                {row.original?.firstname}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Transaction",
        accessor: "transaction_type",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {row.original.transaction_type}{" "}
            {row.original.new_balance - row.original.old_balance} hours
          </div>
        ),
      },
      { Header: "Balance", accessor: "balance" },
      {
        Header: "Trc Id",
        accessor: "leave_balance",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {row.original?.leave_balance
              ? row.original?.leave_balance
              : row.original?.leave_request}
          </div>
        ),
      },
    ],
    rows: [],
  });

  return (
    <MDBox>
      <MDBox
        pr={2}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <SelectEmployee
          label="Refresh Transaction"
          placeholder="Select Employee"
          onSelected={(e) => {
            if (e) {
              validateLeaveTransaction(e);
            }
          }}
        />
      </MDBox>
      <DataTable table={leaveTransactionData} canSearch></DataTable>
    </MDBox>
  );
}
