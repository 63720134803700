import { Delete, Edit } from "@mui/icons-material";
import {
  Autocomplete,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Icon,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { setToastOpen } from "components/Toast/toastAlertSlice";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SalesTable from "examples/Tables/SalesTable";
import FormField from "layouts/applications/wizard/components/FormField";
import { useDebugValue, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ApiCalls from "services/flick_erp_api_call";

export function PayroleSettings({ permissions, modulePermissions }) {
  const [automaticOrManualRadioValue, setAutomaticOrManualRadioValue] =
    useState("");
  const [automaticRadioValue, setAutomaticRadioValue] = useState("");

  const loggedUser = JSON.parse(localStorage.getItem("user"));
  const [salaryHeads, setSalaryHeads] = useState([]);
  const [salaryHeadPayload, setSalaryHeadPayload] = useState({});
  const [SalaryHeadDialog, setSalaryHeadDialog] = useState(false);
  const headList = [
    { name: "Basic Salary", head_value: "basic" },
    { name: "House Rent Allowance", head_value: "hra" },
    { name: "Daily Allowance", head_value: "da" },
    { name: "Bonus", head_value: "bonus" },
    { name: "Incentive", head_value: "incentive" },
    { name: "Professional Tax", head_value: "ptax" },
    { name: "Tax Deducted at Source", head_value: "tds" },
    { name: "Medical Allowance", head_value: "medical" },
  ];

  useEffect(() => {
    getSalaryHeads();
  }, []);
  async function getSalaryHeads() {
    var res = await ApiCalls.getSalaryHead();
    if (res.success && res.code == 200) {
      setSalaryHeads(res.data);
    }
  }
  async function postSalaryHead() {
    var res = await ApiCalls.createSalaryHead(salaryHeadPayload, false);
    if (res.success && res.code == 201) {
    }
    getSalaryHeads();
    setSalaryHeadDialog(false);
    setSalaryHeadPayload((state) => ({}));
  }
  async function patchSalaryHead() {
    var res = await ApiCalls.patchSalaryHead(
      salaryHeadPayload.id,
      salaryHeadPayload,
      false
    );
    if (res.success && res.code == 200) {
    }
    getSalaryHeads();
    setSalaryHeadDialog(false);
    setSalaryHeadPayload((state) => ({}));
  }
  async function deleteSalaryHead(id) {
    var res = await ApiCalls.deleteSalaryHead(id);
    getSalaryHeads();
  }
  function handleAction(v) {
    if (v.action == "delete") {
      deleteSalaryHead(v.id);
    } else if (v.action == "edit") {
      setSalaryHeadPayload({
        ...salaryHeads?.find((e) => e.id == v.id),
        request: "edit",
      });
      setSalaryHeadDialog(true);
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar></DashboardNavbar>
      <MDBox p={5}>
        {permissions?.includes(
          modulePermissions?.salary_head_list_permission
        ) ? (
          <Card sx={{ padding: "1px" }}>
            <MDBox p={2}>
              <MDBox pb={3} display="flex" justifyContent="center">
                <MDTypography variant="h4">Payrole Settings</MDTypography>
              </MDBox>
              <MDBox display="flex">
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="automatic-or-manually"
                    name="automatic-or-manually-radio-buttons-group"
                    value={automaticOrManualRadioValue}
                    onChange={(e) => {
                      setAutomaticOrManualRadioValue(e.target.value);
                    }}
                  >
                    <MDBox>
                      <FormControlLabel
                        value="automatic"
                        control={<Radio />}
                        label="Automatic"
                      />
                      {automaticOrManualRadioValue == "automatic" && (
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={automaticRadioValue}
                            onChange={(e) => {
                              setAutomaticRadioValue(e.target.value);
                            }}
                          >
                            <MDBox display="flex">
                              <FormControlLabel
                                value="monthly"
                                control={<Radio />}
                                label="Monthly"
                              />
                              {automaticRadioValue == "monthly" && (
                                <MDBox>
                                  <TextField
                                    label="Start Date"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ type: "date" }}
                                  ></TextField>
                                  {"  "}
                                  <TextField
                                    label="End Date"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ type: "date" }}
                                  ></TextField>
                                </MDBox>
                              )}
                            </MDBox>
                            <MDBox display="flex">
                              <FormControlLabel
                                value="weekly"
                                control={<Radio />}
                                label="By Weekly"
                              />
                              {automaticRadioValue == "weekly" && (
                                <TextField
                                  label="Start Date"
                                  variant="standard"
                                  InputLabelProps={{ shrink: true }}
                                  inputProps={{ type: "date" }}
                                ></TextField>
                              )}
                            </MDBox>
                          </RadioGroup>
                        </FormControl>
                      )}
                    </MDBox>
                    <MDBox>
                      <FormControlLabel
                        value="manually"
                        control={<Radio />}
                        label="Manually"
                      />
                      {automaticOrManualRadioValue == "manually" && (
                        <MDBox display="flex">
                          <DatePicker
                            label={'"month" and "year"'}
                            views={["month", "year"]}
                            sx={{marginRight:"10px"}}
                          />
                          <MDButton color="info">Generate</MDButton>
                        </MDBox>
                      )}
                    </MDBox>
                  </RadioGroup>
                </FormControl>
              </MDBox>
              {permissions?.includes(
                modulePermissions?.salary_head_create_permission
              ) && (
                <MDBox p={0} align="end">
                  <Fab
                    size="small"
                    color="info"
                    onClick={() => {
                      setSalaryHeadPayload({ request: "create" });
                      setSalaryHeadDialog(true);
                    }}
                  >
                    +
                  </Fab>
                </MDBox>
              )}
              <SalesTable
                title="Salary heads"
                rows={salaryHeads}
                ignore={["id"]}
                action={
                  (permissions?.includes(
                    modulePermissions?.salary_head_update_permission
                  ) ||
                    permissions?.includes(
                      modulePermissions?.salary_head_delete_permission
                    )) && {
                    action: [
                      { icon: <Edit />, key: "edit", idKey: "id",presenseKey: ["allowance", "deduction"],presenseTypeKey: "head_type" },
                      { icon: <Delete />, key: "delete", idKey: "id",presenseKey: ["allowance", "deduction"],presenseTypeKey: "head_type"},
                    ],
                  }
                }
                onActionClick={(v) => {
                  handleAction(v);
                }}
              ></SalesTable>
            </MDBox>
          </Card>
        ) : (
          <Card sx={{ padding: "200px" }}>
            <MDTypography align="center">Not Permitted!</MDTypography>
          </Card>
        )}
      </MDBox>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "white",
            boxShadow: "none",
            width: "600px",
            height: "auto",
            maxWidth: "none",
            borderRadius: "10px",
            cursor: "pointer",
          },
        }}
        open={SalaryHeadDialog}
        onClose={() => {
          setSalaryHeadDialog(false);
          setSalaryHeadPayload((state) => ({}));
        }}
      >
        <DialogTitle
          style={{
            width: "100%",
            padding: "20px",
            backgroundColor: "#4FA7F9",
            color: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "Medium" }}>
              {salaryHeadPayload?.request == "create"
                ? "Add"
                : salaryHeadPayload?.request == "edit"
                ? "Edit"
                : ""}{" "}
              Salary Head
            </Typography>
            <Icon
              fontSize="medium"
              onClick={() => {
                setSalaryHeadDialog(false);
              }}
            >
              close
            </Icon>
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "15px",
          }}
        >
          <Grid container spacing={2} alignItems={"flex-end"}>
            <Grid item xs={12} sm={5}>
              <Autocomplete
                fullWidth
                placeholder="Select Head"
                options={headList}
                value={headList.find((e) => e.name == salaryHeadPayload.name)}
                getOptionLabel={(v) => v?.name ?? ""}
                onChange={(event, value) => {
                  setSalaryHeadPayload((state) => ({
                    ...state,
                    name: value?.name,
                    head_value: value?.head_value,
                  }));
                }}
                renderInput={(params) => (
                  <FormField
                    label="Salary Head"
                    placeholder="Select Head"
                    {...params}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <TextField
                fullWidth
                variant="standard"
                placeholder="Code"
                value={salaryHeadPayload.code}
                onChange={(event) => {
                  setSalaryHeadPayload((state) => ({
                    ...state,
                    code: event.target.value,
                  }));
                }}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={3.5}>
              <Autocomplete
                fullWidth
                placeholder="Select Head Type"
                options={["allowance", "deduction"]}
                value={["allowance", "deduction"].find(
                  (e) => e == salaryHeadPayload.head_type
                )}
                onChange={(event, value) => {
                  setSalaryHeadPayload((state) => ({
                    ...state,
                    head_type: value,
                    org: loggedUser.user_id,
                  }));
                }}
                renderInput={(params) => (
                  <FormField
                    label="Head Type"
                    placeholder="Select Head Type"
                    {...params}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton
            onClick={() => {
              salaryHeadPayload?.request == "create"
                ? postSalaryHead()
                : patchSalaryHead();
            }}
          >
            {salaryHeadPayload?.request == "create"
              ? "Create"
              : salaryHeadPayload?.request == "edit"
              ? "Edit"
              : ""}
          </MDButton>
          <MDButton
            onClick={() => {
              setSalaryHeadDialog(false);
              setSalaryHeadPayload({});
            }}
          >
            Cancel
          </MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}
