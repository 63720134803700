import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import {
  Autocomplete,
  Button,
  Card,
  Checkbox,
  Grid,
  IconButton,
  Switch,
  TableFooter,
  TablePagination,
  TextField,
  Tooltip,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Footer from "examples/Footer";
import typography from "assets/theme/base/typography";
// icons
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
  Cancel,
  Check,
  Delete,
  Edit,
  PictureAsPdf,
  Visibility,
} from "@mui/icons-material";
import * as moment from "moment";
//Dialog Box
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Input } from "@mui/material";
import Typography from "@mui/material/Typography";
import ApiCalls from "services/flick_erp_api_call";
import { useSelector } from "react-redux";
import {
  candidatesList,
  candidateDetails,
  optionValues,
} from "./candidatesSlice";
import { employeeList } from "layouts/employees/employeesSlice";
import { useDispatch } from "react-redux";

import { getTimeFromDateTime } from "helpers/DateTime";
import { getDateFromDateTime } from "helpers/DateTime";
import MDAlert from "components/MDAlert";
import { stringify } from "stylis";
import FlickSwitch from "components/FlickSwitch";
import { setToastOpen } from "components/Toast/toastAlertSlice";
import { SearchBox } from "components/SearchBox";
import colors from "assets/theme/base/colors";
import { advertisementLists } from "../currentOpenings/jobAdvertisementSlice";
import verifiedIcon from "../../../assets/verified.png";

function Candidates({ props, permissions, modulePermissions }) {
  const { size } = typography;
  const user = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();

  const [interviewerNameError, setInterviewerNameError] = useState("");
  const [interviewTimeError, setInterviewTimeError] = useState("");
  const [interviewDateError, setInterviewDateError] = useState("");



  useEffect(() => {
    getEmployee();
  }, []);

  // Get Employee List Api

  async function getEmployee() {
    var res = await ApiCalls.getEmployeesList();
    if (res.success && res.code === 200) {
      // var data = JSON.parse(JSON.stringify(res.data));
      dispatch(employeeList({ employee: res.data }));
    } else {
    }
  }

  // Get Candidate Api

  const [candData, setCandData] = useState({});
  const handleOnNameClick = async (index, row) => {
    const can_id = row.id;

    const res = await ApiCalls.getCandidate(can_id, row);
    if (res.success && res.code == 200) {
      dispatch(candidateDetails(res.data));
      setCandData(res.data);
    } else {
    }
    setCandidateNameDialog(true);
  };
  const [searchBy, setSearchBy] = useState("Name");

  const detail = useSelector((state) => state.candidates.details);
  const selectedDocs = useSelector(
    (state) => state.candidates.details.documents_needed
  );
  var advertisements = useSelector((state) =>
    state.jobAdvertisement.jobAdvertisement
  );




  const [documentOpen, setDocumentOpen] = React.useState(false);
  const [documents, setDocuments] = useState([]);
  const [jobOfferDetail, setJobOfferDetail] = useState({
    expected_doj: moment(new Date()).format("YYYY-MM-DD"),
    valid_till: moment(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        new Date().getDate()
      )
    ).format("YYYY-MM-DD"),
  });

  const handleDocumentClose = () => {
    setDocumentOpen(false);
  };
  const handleDocumentOpen = async () => {
    const res = await ApiCalls.getDocuments();
    if (res.success && res.code == 200) {
      setDocuments(res.data);
    }
    setDocumentOpen(true);
  };

  // const [selectedValues, setSelectedValues] = useState(selectedDocs);
  const [selectedValues, setSelectedValues] = useState({});

  useEffect(() => {
    setSelectedValues(selectedDocs?.map((key) => key));
  }, [selectedDocs]);

  const handleDocumentChange = (key) => {
    if (selectedValues.includes(key)) {
      setSelectedValues((prevSelectedValues) =>
        prevSelectedValues.filter((value) => value !== key)
      );
    } else {
      setSelectedValues((prevSelectedValues) => [...prevSelectedValues, key]);
    }
  };

  // Get status and source api
  useEffect(() => {
    getOptionValue();
  }, []);

  // const [option, setOption] = useState({ source: {}, status: {} });
  async function getOptionValue() {
    const res = await ApiCalls.getCandiateStatusAndSource();
    if (res.success && res.code == 200) {
      // setOption(res.data);
      dispatch(optionValues({ candidates: res.data }));
    } else {
    }
  }

  const candidateOptionValues = useSelector(
    (state) => state.candidates.options.candidates
  );

  function convertSource(so) {
    const sourceValue = candidateOptionValues?.source[so] || "";
    return `${sourceValue}`;
  }
  function convertStatus(st) {
    const statusValue = candidateOptionValues?.status[st] || "";

    return `${statusValue}`;
  }

  const [scheduleInterviewRecord, setScheduleInterviewRecord] = useState({
    datetime: "",
    status: 0,
    rating: 0,
    feedback: "",
    evaluated_exp: 0,
    candidate: 0,
    interviewer: [],
  });

  const [editableRowIndex, setEditableRowIndex] = useState(-1);
  const [editedData, setEditedData] = useState([]);
  const [editableInterviewRowIndex, setEditableInterviewRowIndex] =
    useState(-1);
  const [editedInterviewData, setEditedInterviewData] = useState({});
  const [currentCandidateIndex, setCurrentCandidateIndex] = useState(null);
  const [candidateNameDialog, setCandidateNameDialog] = React.useState(false);

  const [interviewDialog, setInterviewDialog] = React.useState(false);
  const [gmeetAuthenticated, setGmeetAuthenticated] = useState(true);

  const [gmeet, setGmeet] = useState(true);
  const [scheduleInterviewOpen, setScheduleInterviewOpen] =
    React.useState(false);
    const handleSetGmeet = () => setGmeet(!gmeet);
// gmeet
useEffect(() => {
  getGmeetAuthenticateStatus();

  if (window.location.href.includes("code")) {
    const query = new URLSearchParams(window.location.search);
    const code = query.get("code");
    postGoogleResponseCode(code);
  }
}, []);
const getGmeetAuthenticateStatus = async () => {
  var res = await ApiCalls.getStatusOfGoogleAuthenticate();
  setGmeetAuthenticated(res.success ? true : false);
};
  const handleOnInterviewOpen = (index) => {
    setCurrentCandidateIndex(index);
    setInterviewDialog(true);
  };
  const handleInterviewClose = () => {
    setCurrentCandidateIndex(null);
    setInterviewDialog(false);
  };

  const handleOnScheduleInterview = (row) => {
    setScheduleInterviewRecord((state) => ({
      ...state,
      candidate: row.id || "",
      evaluated_exp: row.experience || "",
      status: row.status || "",
    }));

    setScheduleInterviewOpen(true);
  };
  const handleScheduleInterviewClose = () => {
    setScheduleInterviewOpen(false);
  };

  const [interviewDate, setInterviewDate] = useState({
    interviewDate: "",
  });
  const [description, setDescription] = useState("");
  const [finalInterview, setFinalInterview] = useState(false);
  const [interviewTime, setInterviewTime] = useState({
    interviewTime: "",
  });
  const handleInputDateTimeInterviewChange = (selectedDate, selectedTime) => {
    if (typeof selectedTime === "string") {
      const [hours, minutes] = selectedTime.split(":");

      const dateObject = new Date(selectedDate);

      dateObject.setHours(hours);
      dateObject.setMinutes(minutes);
      dateObject.setSeconds(0);

      const formattedDateTime = dateObject.toISOString();
      return formattedDateTime;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRecord((prevState) => ({ ...prevState, [name]: value }));

    if (name === "full_name") {
      setFullNameError("");
    } else if (name === "email") {
      setEmailError("");
    } else if (name === "experience") {
      setExperienceError("");
    }
  };

  const [recordsOpen, setRecordsOpen] = React.useState(false);
  const handleRecordsOpen = () => {
    setEditableRowIndex(-1);
    setRecordsOpen(true);
  };
  const handleRecordsClose = () => {
    setRecordsOpen(false);
  };

  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // Api for get Job Advertisement List

  async function jobAdvertisement() {
    const res = await ApiCalls.getAdvertisementList();
    if (res.success && res.code === 200) {
      dispatch(advertisementLists({ jobAdvertisement: res.data }));
    } else {
    }
  }

  // get candidates List api
  useEffect(() => {
    jobAdvertisement()
    getListCandidates();
  }, []);

  async function getListCandidates(queryParams) {
    const res = await ApiCalls.getCandidatesList(queryParams);
    if (res.success && res.code == 200) {
      setData(res.data);
      dispatch(candidatesList({ candidates: res.data }));
    } else {
    }
  }

  const candidate = useSelector((state) => state.candidates.candidates);

  //create candidates api
  const [showAlert, setShowAlert] = useState(null);
  const [newRecord, setNewRecord] = useState({
    full_name: "",
    mobile: "",
    email: "",
    experience: "",
    status: "",
    source: "",
    jd:"",
    recruiter: [],
    cv: "",
  });
  // schedule interview api
  const handleScheduleInterview = async () => {
    let hasError = false;
    let error = "This field may not be blank.";

    var payload = {
      interviewer: scheduleInterviewRecord.interviewer,
      candidate: scheduleInterviewRecord.candidate,
      datetime: handleInputDateTimeInterviewChange(
        interviewDate,
        interviewTime
      ),
      meeting_note: description,
      final_interview: finalInterview,
    };

    if (
      !interviewDate.interviewDate === undefined ||
      interviewDate.interviewDate === ""
    ) {
      setInterviewDateError(error);
      hasError = true;
    } else {
      setInterviewDateError("");
    }
    if (
      !interviewTime.interviewTime === undefined ||
      interviewTime.interviewTime === ""
    ) {
      setInterviewTimeError(error);
      hasError = true;
    } else {
      setInterviewTimeError("");
    }
    if (scheduleInterviewRecord.interviewer.length === 0) {
      setInterviewerNameError(error);
      hasError = true;
    } else {
      setInterviewerNameError("");
    }

    if (!hasError) {
      // if(payload.candidate && payload.datetime && payload.interviewer){
      const res = await ApiCalls.postInterview(payload, true);
      if (res.success && res.code == 201) {
        handleScheduleInterviewClose();
        getListCandidates();
      } else if (res.success == false && res.code == 400) {
      } else {
      }
      // getListCandidates();
    }
  };
  const alertContent = (name) => (
    <MDTypography variant="body2" color="white">
      {name}
    </MDTypography>
  );

  const [fullNameError, setFullNameError] = useState("");
  const [experienceError, setExperienceError] = useState("");
  const [jdError, setJDError] = useState("");
  const [cvError, setCVError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [sourceError, setSourceError] = useState("");
  const [statusError, setStatusError] = useState("");

  const handleAddRecord = async (file) => {
    let hasError = false;
    let error = "This field may not be blank.";

    if (newRecord.full_name === undefined || newRecord.full_name === "") {
      setFullNameError(error);
      hasError = true;
    } else {
      setFullNameError("");
    }
    if (newRecord.experience === undefined || newRecord.experience === "") {
      setExperienceError(error);
      hasError = true;
    } else {
      setExperienceError("");
    }
    if (newRecord.email === undefined || newRecord.email === "") {
      setEmailError(error);
      hasError = true;
    } else {
      setEmailError("");
    }
    if (resume === null) {
      setCVError("please select file");
      hasError = true;
    } else {
      setCVError("");
    }
    if (newRecord.source === undefined || newRecord.source === "") {
      setSourceError(error);
      hasError = true;
    } else {
      setSourceError("");
    }
    if (newRecord.jd === undefined || newRecord.jd === "") {
      setJDError(error);
      hasError = true;
    } else {
      setJDError("");
    }
    if (newRecord.status === undefined || newRecord.status === "") {
      setStatusError(error);
      hasError = true;
    } else {
      setStatusError("");
    }

    if (
      newRecord.id &&
      newRecord.full_name &&
      newRecord.mobile &&
      newRecord.experience &&
      newRecord.email &&
      newRecord.status &&
      newRecord.source &&
      newRecord.recruiter &&
      newRecord.cv &&
      newRecord.jd
    ) {
      const newDataRow = createData(
        newRecord.id,
        newRecord.full_name,
        newRecord.mobile,
        newRecord.experience,
        newRecord.email,
        newRecord.status,
        newRecord.source,
        newRecord.recruiter,
        newRecord.cv,
        newRecord.jd
      );

      setRows([...rows, newDataRow]);

      setNewRecord({
        id: "",
        full_name: "",
        mobile: "",
        email: "",
        experience: "",
        status: "",
        source: "",
        cv: "",
        jd: ''
      });
    }
    const formData = new FormData();
    formData.append("cv", resume);
    formData.append("full_name", newRecord.full_name);
    formData.append("mobile", newRecord.mobile);
    formData.append("email", newRecord.email);
    formData.append("experience", newRecord.experience);
    formData.append("source", newRecord.source);
    formData.append("status", newRecord.status);
    formData.append("job_ad", newRecord.jd?.id);

    formData.append("recruiter", newRecord.recruiter);

    if (!hasError) {
      const res = await ApiCalls.postCandidate(formData, true, true);

      if (res.success && res.code == 201) {
        getListCandidates();
        handleRecordsClose();
        setNewRecord({});
      } else {
        if (res.code === 400) {
          setShowAlert("Please enter the data");
          const errors = res.data;
          if (errors.full_name) {
            alert(errors.full_name);
          } else if (errors.experience) {
            alert(errors.experience);
          } else if (errors.email) {
            alert(errors.email);
          } else if (errors.job_ad) {
            alert(errors.job_ad);
          } else if (errors.cv) {
            alert(errors.cv);
          } else if (errors.source) {
            alert(errors.source);
          }
          else if (errors.jd) {
            alert(errors.jd);
          } else if (errors.status) {
            alert(errors.status);
          }
        }
      }
    }
  };
  const handleEditInterviewRecord = (row, actualIndex) => {
    setEditableInterviewRowIndex(actualIndex);
    setEditedInterviewData(row);
    // var records = employeesList.filter((v) =>
    //   editedInterviewData?.interviewer?.some((a) => v.id == a)
    // );
  };

  // delete interview record
  const handleDeleteInterviewRecord = async (row) => {
    const res = await ApiCalls.deleteInterview(row.id);
    if (res.success && res.code === 204) {
      getListCandidates();
    } else {
    }
  };

  //update interview record

  const handleInterviewSaveClick = async () => {
    var data = {
      datetime: editedInterviewData.datetime,
      status: editedInterviewData.status,
      rating: editedInterviewData.ratings,
      evaluated_exp: editedInterviewData.evaluated_exp,
      feedback: editedInterviewData.feedback,
      candidate: editedInterviewData.candidate,
      interviewer: editedInterviewData.interviewer,
    };
    const res = await ApiCalls.patchInterview(
      data,
      editedInterviewData.id,
      false
    );
    if (res.success && res.code == 200) {
    } else {
    }
    getListCandidates();
    setEditedInterviewData({});
    setEditableInterviewRowIndex(-1);
  };

  // delete candidate api

  const handleDeleteClick = async (row) => {
    const res = await ApiCalls.deleteCandidate(row.id);

    if (res.success && res.code === 204) {
      getListCandidates();
    } else {
    }
  };

  // update candidate api

  const handleEditClick = (row, actualIndex) => {
    setEditableRowIndex(actualIndex);
    setEditedData([...data]);
    setID(row.id);
  };

  const handleFieldChange = (event, fieldName) => {
    const updatedData = [...editedData];
    const editedRecord = { ...updatedData[editableRowIndex] };

    editedRecord[fieldName] = event.target.value;
    updatedData[editableRowIndex] = editedRecord;
    setEditedData(updatedData);
  };

  const [id_, setID] = useState();

  const handleSaveClick = async () => {
    const editedRow = editedData?.find((row) => row.id === id_);
    const formData = new FormData();
    if (resume) {
      formData.append("cv", resume);
    }
    if (selectedValues) {
      const docs = selectedValues?.map((key) => parseInt(key, 10));

      docs.forEach((doc) => {
        formData.append("documents_needed", doc);
      });
    }
    if (editedRow !== undefined) {
      formData.append("full_name", editedRow.full_name);
      formData.append("mobile", editedRow.mobile);
      formData.append("email", editedRow.email);
      formData.append("experience", editedRow.experience);
      formData.append("status", editedRow.status);
      formData.append("source", editedRow.source);

      editedRow?.job_ad != null &&  editedRow?.job_ad != undefined ? formData.append("job_ad", editedRow?.job_ad) :  null;

      formData.append("recruiter", parseInt(editedRow.recruiter));
    }

    const res = await ApiCalls.updateCandidate(id_, formData, true);
    if (res.success && res.code == 200) {
      setCandidateNameDialog(false);

      setData(editedData);
      getListCandidates();
      setEditableRowIndex(null);
    } else {
      setCandidateNameDialog(false);
    }
  };

  const handleInterivewCancelClick = () => {
    setEditableInterviewRowIndex(-1);
  };

  const handleCancelClick = () => {
    setEditableRowIndex(-1);
  };

  const [resume, setResume] = useState(null);
  const handleFileSelect = async (e) => {
    const file = e.target.files[0];
    let extension = file.name.split(".").pop();
    if (extension == "pdf" || extension == "docx") {
      setResume(file);
      setCVError("");
    } else {
      alert("Only PDF or DOCX Are Allowed ");
    }
  };
  // Pagination
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - candidate.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [data, setData] = useState([]);
  const [selectedInterview, setSelectedInterview] = useState(null);
  const [interviewDetailsDialog, setInterviewDetailsDialog] = useState(false);
  const handleOnInterviewerNameClick = (interview) => {
    setSelectedInterview(interview);
    setInterviewDetailsDialog(true);
  };
  const handleInterviewerNameClose = (interview) => {
    setInterviewDetailsDialog(false);
  };

  const employeesList = useSelector((state) => state.employee.employee);
  const employeeOptions = employeesList.map((employee) => ({
    value: `${employee.id}`,
    label: `${employee.user.firstname} ${employee.user.lastname}`,
  }));

  const getEmployeeNameById = (interviewerList) => {
    if (!interviewerList) {
      return "";
    }

    if (Array.isArray(interviewerList)) {
      var record = employeesList.filter((v) =>
        interviewerList.some((i) => i === v.id)
      );
      if (record.length > 0) {
        return record.reduce(
          (accumulator, name) =>
            accumulator + name.user.firstname + " " + name.user.lastname + " ",
          ""
        );
      }
    } else {
      var employee = employeesList.find((v) => v.id === interviewerList);
      if (employee) {
        return employee.user.firstname + " " + employee.user.lastname;
      }
    }

    return "";
  };
  const handleEmpSearch = (query) => {
    if (searchBy == "Name") {
      getListCandidates(`?full_name_starts_with=${query}`);
    } else if (searchBy == "Phone") {
      getListCandidates(`?mobile__icontains=${query}`);
    } else if (searchBy == "Email") {
      getListCandidates(`?email__icontains=${query}`);
    } else if (searchBy == "Status") {
      getListCandidates(`?status=${query}`);
    }
  };
  async function makeJobOffer(candidateId) {
    let data = {
      expected_doj: jobOfferDetail.expected_doj,
      status: 1,
      ctc: jobOfferDetail.ctc,
      active: true,
      valid_till: jobOfferDetail.valid_till,
      ip: "string",
      org: user.org_id,
      candidate: candidateId,
      created_by: user.employee_id,
    };
    var res = await ApiCalls.postJobOffer(data, false);
    if (res.success && res.code == 201) {
      setDocumentOpen(false);
    } else {
      setDocumentOpen(false);
    }
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox>
        <MDBox
          width="100%"
          display="flex"
          flexDirection={{ xs: "column", lg: "row" }}
          justifyContent="flex-end"
          alignItems="center"
          py={1.5}
          px={1.5}
        >
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            color="text"
            fontSize={size.sm}
            px={1.5}
          >
            <MDBox
              pr={1}
              mr={1}
              display="flex"
              alignItems="end"
              bgColor="info"
              sx={{ borderRadius: "20px" }}
            >
              <SearchBox
                width={350}
                placeholder={"Search Candidate"}
                onChange={(e) => {
                  if (e.length >= 3) {
                    handleEmpSearch(e);
                  }
                }}
                sort={
                  <Autocomplete
                    options={["Name", "Phone", "Email"]}
                    value={searchBy}
                    onChange={(event, newValue) => {
                      setSearchBy(newValue);
                    }}
                    sx={{
                      width: "155px",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                   
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                      />
                    )}
                  />
                }
              />
              <Autocomplete
                options={
                  candidateOptionValues?.status
                    ? Object.entries(candidateOptionValues?.status).map(
                        ([key, value]) => ({ value: key, label: value })
                      )
                    : []
                }
                onChange={(event, newValue) => {
                  if (newValue != undefined) {
                    getListCandidates(`?status=${newValue.value}`);
                  } else {
                    getListCandidates();
                  }
                }}
                sx={{
                  width: "110px",
                  paddingBottom: "2px",
                  "& .MuiAutocomplete-popupIndicator": {
                    backgroundColor: "#fff",
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                    sx={{
                      input: { color: "White" },
                      "& .MuiSvgIcon-root": {
                        color: "#000",
                      },
                    }}
                    placeholder="Sort By"
                    variant="standard"
                  />
                )}
              />
            </MDBox>
            {permissions?.includes(modulePermissions?.create_permission) && (
              <MDButton
                variant="gradient"
                color="dark"
                onClick={handleRecordsOpen}
                sx={{ borderRadius: "20px" }}
              >
                Add Records
              </MDButton>
            )}
            {/* )} */}
          </MDBox>
        </MDBox>
        <Card style={{ boxShadow: "0 8px 8px rgba(0, 0, 0, 0.50)" }}>
          <MDTypography variant="h5" p={2}>
            Candidates
          </MDTypography>
          <TableContainer components={Paper}>
            <Table aria-label="simple table">
              <TableBody data-testid="tBody">
                <TableRow style={{}}>
                  <TableCell variant="head" sx={{ color: "black" }}>
                    Name
                  </TableCell>
                  <TableCell variant="head" sx={{ color: "black" }}>
                    Number
                  </TableCell>
                  <TableCell variant="head" sx={{ color: "black" }}>
                    Email
                  </TableCell>
                  <TableCell variant="head" sx={{ color: "black" }}>
                    Experience
                  </TableCell>
                  <TableCell variant="head" sx={{ color: "black" }}>
                    Recruiter
                  </TableCell>
                  <TableCell variant="head" sx={{ color: "black" }}>
                    Job ID
                  </TableCell>
                  <TableCell variant="head" sx={{ color: "black" }}>
                    Source
                  </TableCell>
                  <TableCell variant="head" sx={{ color: "black" }}>
                    Status
                  </TableCell>
                  <TableCell variant="head" sx={{ color: "black" }}>
                    Resume
                  </TableCell>
                  {(permissions?.includes(
                    modulePermissions?.interview_list_permission
                  ) ||
                    permissions?.includes(
                      modulePermissions?.interview_create_permission
                    )) && (
                    <TableCell variant="head" sx={{ color: "black" }}>
                      Interview
                    </TableCell>
                  )}
                  {permissions?.includes(
                    modulePermissions?.delete_permission
                  ) && (
                    <TableCell variant="head" sx={{ color: "black" }}>
                      Actions
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>

              <TableBody>
                {(rowsPerPage > 0
                  ? candidate.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : candidate
                )?.map((row, index) => {
                  const actualIndex = index + page * rowsPerPage;
                  return (
                    <TableRow key={row.id}>
                      <TableCell
                        sx={{ cursor: "pointer" }}
                        style={{ fontWeight: "bold" }}
                        onClick={() => {
                          handleEditClick(row, actualIndex);
                          handleOnNameClick(actualIndex, row);
                        }}
                      >
                        {row.full_name}
                      </TableCell>
                      <TableCell>{row.mobile}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.experience}</TableCell>
                      <TableCell>
                        {getEmployeeNameById(row.recruiter)}
                      </TableCell>
                      <TableCell>
                        1
                      </TableCell>
                      <TableCell>{convertSource(row.source)}</TableCell>
                      <TableCell>{convertStatus(row.status)}</TableCell>

                      {row?.cv ? (
                        <TableCell>
                          <Tooltip title="Open">
                            <a
                              aria-label="open"
                              href={row.cv}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h5 style={{ color: "black" }}>
                                <Icon
                                  sx={{
                                    marginRight: "3px",
                                    marginBottom: "-2px",
                                  }}
                                >
                                  <PictureAsPdf />
                                </Icon>
                                Resume
                              </h5>
                            </a>
                          </Tooltip>
                        </TableCell>
                      ) : (
                        <TableCell>
                          <h5>Not found</h5>
                        </TableCell>
                      )}
                      {(permissions?.includes(
                        modulePermissions?.interview_list_permission
                      ) ||
                        permissions?.includes(
                          modulePermissions?.interview_create_permission
                        )) && (
                        <TableCell>
                          {permissions?.includes(
                            modulePermissions?.interview_list_permission
                          ) && (
                            <Tooltip title="View">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  handleOnInterviewOpen(actualIndex);
                                }}
                              >
                                <Visibility />
                              </IconButton>
                            </Tooltip>
                          )}
                          {permissions?.includes(
                            modulePermissions?.interview_create_permission
                          ) && (
                            <Tooltip title="Schedule">
                              <IconButton
                                size="small"
                                onClick={() => {

                                  if(user.employee_id == row.recruiter){
                                        handleOnScheduleInterview(row);
                                  }else {
                                    alert("Recruiter and Looged-In user should be same to schedule the interview")
                                  }
                              
                                }}
                              >
                                <CalendarTodayIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                      )}
                      {permissions?.includes(
                        modulePermissions?.delete_permission
                      ) && (
                        <TableCell>
                          {permissions?.includes(
                            modulePermissions?.delete_permission
                          ) && (
                            <Tooltip title="Delete">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  handleDeleteClick(row);
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={12}
                  count={candidate.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  slotProps={{
                    select: {
                      "aria-label": "rows per page",
                    },
                    actions: {
                      showFirstButton: true,
                      showLastButton: true,
                    },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableFooter>
            </Table>
          </TableContainer>
        </Card>
      </MDBox>
      <br />

      {/* Dialog Box for details */}
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "aliceBlue",
            width: "700px",
            height: "500px",
            maxWidth: "none",
            padding: "20px",
          },
        }}
        open={candidateNameDialog}
        onClose={() => {
          setCandidateNameDialog(false);
        }}
      >
        <DialogTitle>
          <MDTypography>Candidates Information</MDTypography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                // margin="dense"
                id="name"
                label="Name"
                type="text"
                fullWidth
                variant="standard"
                name="full_name"
                value={editedData[editableRowIndex]?.full_name}
                onChange={(event) => handleFieldChange(event, "full_name")}
                // inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                // margin="dense"
                id="mobile"
                label="Mobile"
                type="text"
                fullWidth
                variant="standard"
                name="mobile"
                value={editedData[editableRowIndex]?.mobile}
                onChange={(event) => handleFieldChange(event, "mobile")}
                // inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                // margin="dense"
                id="experience"
                label="Experience"
                type="text"
                fullWidth
                variant="standard"
                name="experience"
                value={editedData[editableRowIndex]?.experience}
                onChange={(event) => handleFieldChange(event, "experience")}

              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                // margin="dense"
                id="email"
                label="Email"
                type="text"
                fullWidth
                variant="standard"
                name="email"
                value={editedData[editableRowIndex]?.email}
                onChange={(event) => handleFieldChange(event, "email")}

              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={
                  candidateOptionValues?.status
                    ? Object.entries(candidateOptionValues?.status).map(
                        ([key, value]) => ({ value: key, label: value })
                      )
                    : []
                }
                value={convertStatus(editedData[editableRowIndex]?.status)}
                onChange={(event, newValue) => {
                  handleFieldChange(
                    { target: { value: newValue?.value } },
                    "status"
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    variant="standard"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={
                  candidateOptionValues?.status
                    ? Object.entries(candidateOptionValues?.source)?.map(
                        ([key, value]) => ({
                          value: key,
                          label: value,
                        })
                      )
                    : []
                }
                value={convertSource(editedData[editableRowIndex]?.source)}
                onChange={(event, newValue) => {
                  handleFieldChange(
                    { target: { value: newValue?.value } },
                    "source"
                  );
                }}
                // sx={{ marginTop: "-12px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Source"
                    variant="standard"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} alignSelf={"end"}>
              <Autocomplete
                id="recruiter"
                options={employeeOptions}
                getOptionLabel={(option) => option.label}
                value={
                  editedData[editableRowIndex]?.recruiter
                    ? employeeOptions.find(
                        (option) =>
                          option.value ==
                          editedData[editableRowIndex]?.recruiter
                      )
                    : null
                }
                fullWidth

                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    name="recruiter"
                    label="Recruiter"
                    variant="standard"

                  />
                )}
                onChange={(event, newValue) => {
                  if (newValue) {
                    const selectedValues = Array.isArray(newValue)
                      ? newValue.map((v) => v.value)
                      : newValue.value;
                    handleFieldChange(
                      { target: { value: selectedValues } },
                      "recruiter"
                    );
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                name="jd"
                options={advertisements}
                getOptionLabel={(e)=> e?.position}
                value={
                  editedData[editableRowIndex]?.job_ad
                    ? advertisements.find(
                        (option) =>
                          option.id ==
                          editedData[editableRowIndex]?.job_ad
                      )
                    : null
                }
                onChange={(event, value) => {
                    handleFieldChange(
                      { target: { value: value?.id ?? null } },
                      "job_ad"
                    );
                  
                  setJDError("");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    id="autocomplete"
                    label="Job Openings"
                    type="text"
                    variant="standard"
                    error={!!jdError}
                    helperText={jdError}
                  />
                )}
              />
            </Grid>

            
            <Grid item xs={6} alignSelf="end">
              <input
                type="file"
                onChange={handleFileSelect}
              ></input>
            
            </Grid>

            <Grid container item xs={12} sm={6} alignItems="center">
              <Grid item>
                <MDTypography variant="h8">Blacklisted</MDTypography>
              </Grid>
              <Grid item style={{ marginLeft: "10px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={detail.blacklisted}
                      disabled
                      color="primary"
                      inputProps={{ "aria-label": "blacklist-yes-checkbox" }}

                    />
                  }
                  label="Yes"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!detail.blacklisted}
                      disabled
                      color="primary"
                      inputProps={{ "aria-label": "blacklist-no-checkbox" }}
                      // style={{ color: '#000' }}
                    />
                  }
                  label="No"
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} sm={6} alignItems="center">
              <Grid item>
                <MDTypography variant="h8">Suspicious</MDTypography>
              </Grid>
              <Grid item style={{ marginLeft: "10px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={detail.suspicious}
                      disabled
                      color="primary"
                      inputProps={{ "aria-label": "suspicious-yes-checkbox" }}
                    />
                  }
                  label="Yes"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!detail.suspicious}
                      disabled
                      color="primary"
                      inputProps={{ "aria-label": "suspicious-no-checkbox" }}
                    />
                  }
                  label="No"
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} sm={10} alignItems="center">
              <Grid item>
                <MDTypography variant="h8">Blocked by Nascom</MDTypography>
              </Grid>
              <Grid item style={{ marginLeft: "10px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={detail.blocked}
                      disabled
                      color="primary"
                      inputProps={{ "aria-label": "blocked-yes-checkbox" }}
                    />
                  }
                  label="Yes"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!detail.blocked}
                      disabled
                      color="primary"
                      inputProps={{ "aria-label": "blocked-no-checkbox" }}
                    />
                  }
                  label="No"
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="gradient"
            color="dark"
            onClick={() => {
              handleCancelClick();
              setCandidateNameDialog(false);
            }}
          >
            Cancel
          </Button>
          {permissions?.includes(modulePermissions?.update_permission) && (
            <Button
              variant="gradient"
              color="dark"
              onClick={async () => {
                await handleSaveClick();
              }}
            >
              Update
            </Button>
          )}
          {detail.status == "4" &&
          permissions?.includes(modulePermissions?.update_permission) ? (
            <Button
              variant="gradient"
              color="dark"
              onClick={() => handleDocumentOpen()}
            >
              Make a Job Offer
            </Button>
          ) : (
            ""
          )}
        </DialogActions>
      </Dialog>

      {/* Dialog for interview */}
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "aliceBlue",
            boxShadow: "none",
            width: "70%",
            height: "600px",
            maxWidth: "none",
            borderRadius: "10px",
            padding: "20px",
          },
        }}
        open={interviewDialog}
        onClose={() => {
          handleInterviewClose();
          handleInterivewCancelClick();
        }}
      >
        <DialogTitle>
          <Typography variant="h5">Interview Details</Typography>
        </DialogTitle>
        <DialogContent style={{ padding: "30px" }}>
          <TableContainer component={Paper}>
            <Table>
              <TableRow>
                {/* <TableCell style={{ padding: "8px" }}>
                  <Typography variant="h6" style={{ color: "white" }}>
                    S.No
                  </Typography>
                </TableCell> */}
                <TableCell style={{ padding: "8px" }}>
                  <Typography variant="h6" style={{ color: "black" }}>
                    Interviewer Name
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: "8px" }}>
                  <Typography variant="h6" style={{ color: "black" }}>
                    Interview Time
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: "8px" }}>
                  <Typography variant="h6" style={{ color: "black" }}>
                    Interview Date
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: "8px" }}>
                  <Typography variant="h6" style={{ color: "black" }}>
                    Ratings
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: "8px" }}>
                  <Typography variant="h6" style={{ color: "black" }}>
                    Feedback
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: "8px" }}>
                  <Typography variant="h6" style={{ color: "black" }}>
                    Experience
                  </Typography>
                </TableCell>
                {(permissions?.includes(
                  modulePermissions?.interview_update_permission
                ) ||
                  permissions?.includes(
                    modulePermissions?.interview_delete_permission
                  )) && (
                  <TableCell style={{ padding: "8px" }}>
                    <Typography variant="h6" style={{ color: "black" }}>
                      Actions
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
              <TableBody>
                {candidate[currentCandidateIndex]?.interviews?.map(
                  (record, index) => (
                    // index === editableInterviewRowIndex ? (
                    //   <TableRow key={record.id}>

                    //     <TableCell>
                    //       <Autocomplete
                    //         id="interviewerName"
                    //         options={employeesList}
                    //         multiple
                    //         getOptionLabel={(option) =>
                    //           option.user.firstname + " " + option.user.lastname
                    //         }
                    //         value={employeesList.filter((v) =>
                    //           editedInterviewData?.interviewer?.some(
                    //             (a) => v.id == a
                    //           )
                    //         )}
                    //         renderInput={(params) => (
                    //           <TextField
                    //             {...params}
                    //             fullWidth
                    //             name="interviewerName"
                    //             label="Interviewer Name"
                    //             variant="standard"
                    //           />
                    //         )}
                    //         onChange={(event, newValue) => {
                    //           if (newValue) {
                    //             setEditedInterviewData((state) => ({
                    //               ...state,
                    //               interviewer: newValue?.map((v) => v.id),
                    //             }));
                    //           }
                    //         }}
                    //       />
                    //     </TableCell>
                    //     <TableCell>
                    //       <TextField
                    //         variant="standard"
                    //         InputLabelProps={{ shrink: true }}
                    //         margin="dense"
                    //         id="interviewTime"
                    //         type="time"
                    //         fullWidth
                    //         name="interviewTime"
                    //         value={moment(editedInterviewData.datetime).format(
                    //           "HH:mm"
                    //         )}
                    //         onChange={(event) => {
                    //           var dateTime = moment(
                    //             getDateFromDateTime(
                    //               editedInterviewData.datetime
                    //             ) + event.target.value,
                    //             +event.target.value,
                    //             "DD-MM-YYYY HH:mm:ss"
                    //           ).format();

                    //           setEditedInterviewData((state) => ({
                    //             ...state,
                    //             datetime: dateTime,
                    //           }));
                    //         }}
                    //         InputProps={{
                    //           classes: {
                    //             input: "input-field",
                    //           },
                    //           placeholder: "",
                    //         }}
                    //       />
                    //     </TableCell>
                    //     <TableCell>
                    //       <TextField
                    //         variant="standard"
                    //         InputLabelProps={{ shrink: true }}
                    //         margin="dense"
                    //         id="date"
                    //         type="date"
                    //         fullWidth
                    //         name="interviewDate"
                    //         value={moment(editedInterviewData.datetime).format(
                    //           "YYYY-MM-DD"
                    //         )}
                    //         onChange={(event) => {
                    //           var dateTime = moment(
                    //             event.target.value +
                    //               getTimeFromDateTime(
                    //                 editedInterviewData.datetime
                    //               ),
                    //             "YYYY-MM-DD HH:mm:ss"
                    //           ).format();
                    //           setEditedInterviewData((state) => ({
                    //             ...state,
                    //             datetime: dateTime,
                    //           }));
                    //         }}
                    //       />
                    //     </TableCell>
                    //     <TableCell>
                    //       <Input
                    //         value={editedInterviewData.ratings}
                    //         onChange={(event) => {
                    //           setEditedInterviewData((state) => ({
                    //             ...state,
                    //             ratings: event.target.value,
                    //           }));
                    //         }}
                    //       />
                    //     </TableCell>
                    //     <TableCell>
                    //       <Input
                    //         value={editedInterviewData.feedback}
                    //         onChange={(event) => {
                    //           setEditedInterviewData((state) => ({
                    //             ...state,
                    //             feedback: event.target.value,
                    //           }));
                    //         }}
                    //       />
                    //     </TableCell>
                    //     <TableCell>
                    //       <Input
                    //         value={editedInterviewData.experience}
                    //         onChange={(event) => {
                    //           setEditedInterviewData((state) => ({
                    //             ...state,
                    //             experience: event.target.value,
                    //           }));
                    //         }}
                    //       />
                    //     </TableCell>
                    //     {permissions?.includes(modulePermissions?.interview_update_permission) && (
                    //       <TableCell>
                    //         {permissions?.includes(modulePermissions?.interview_update_permission) && (
                    //           <Tooltip title="Save">
                    //             <IconButton
                    //               size="small"
                    //               aria-label="save"
                    //               onClick={() => {
                    //                 handleInterviewSaveClick();
                    //               }}
                    //             >
                    //               <Check />
                    //             </IconButton>
                    //           </Tooltip>
                    //         )}
                    //         <Tooltip title="Cancel">
                    //           <IconButton
                    //             size="small"
                    //             aria-label="cancel"
                    //             onClick={handleInterivewCancelClick}
                    //           >
                    //             <Cancel />
                    //           </IconButton>
                    //         </Tooltip>
                    //       </TableCell>
                    //     )}
                    //   </TableRow>
                    // ) :
                    <TableRow key={record.interviewerNo}>
                      {/* <TableCell style={{ padding: "10px" }}>
                          {index + 1}
                        </TableCell> */}
                      <TableCell
                        sx={{ cursor: "pointer" }}
                        style={{ padding: "10px", fontWeight: "bold" }}
                        onClick={() => handleOnInterviewerNameClick(record)}
                      >
                        {getEmployeeNameById(record.interviewer)}
                      </TableCell>
                      <TableCell style={{ padding: "10px" }}>
                        {getTimeFromDateTime(record.datetime)}
                      </TableCell>
                      <TableCell style={{ padding: "10px" }}>
                        {getDateFromDateTime(record.datetime)}
                      </TableCell>
                      <TableCell style={{ padding: "10px" }}>
                        {record.rating}
                      </TableCell>
                      <TableCell style={{ padding: "10px" }}>
                        <Input
                          value={record.feedback}
                          disableUnderline
                          inputProps={{ readOnly: true }}
                        />
                      </TableCell>
                      <TableCell style={{ padding: "10px" }}>
                        {record.recruiterName}
                      </TableCell>
                      {(permissions?.includes(
                        modulePermissions?.interview_update_permission
                      ) ||
                        permissions?.includes(
                          modulePermissions?.interview_delete_permission
                        )) && (
                        <TableCell>
                          {permissions?.includes(
                            modulePermissions?.interview_delete_permission
                          ) && (
                            <Tooltip title="Delete">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  // handleDeleteClick(row);
                                  handleDeleteInterviewRecord(record);
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          )}

                          {/* {permissions?.includes(modulePermissions?.interview_update_permission) && (
                              <Tooltip title="Update">
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    handleEditInterviewRecord(record, index);
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            )} */}
                        </TableCell>
                      )}
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
        </DialogContent>
        <DialogActions>
          <MDButton
            color="dark"
            variant="gradient"
            onClick={handleInterviewClose}
          >
            Cancel
          </MDButton>
        </DialogActions>
      </Dialog>

      {/* Dialog box for interview name click */}

      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "#f8f8f8",
            boxShadow: "none",
            width: "700px",
            height: "500px",
            maxWidth: "none",
            borderRadius: "10px",
          },
        }}
        open={interviewDetailsDialog}
        onClose={() => setInterviewDetailsDialog(false)}
      >
        {selectedInterview && (
          <React.Fragment>
            <DialogTitle
              style={{
                padding: "20px",
                borderBottom: "1px solid #ccc",
                background: "white",
                color: "white",
              }}
            >
              <Typography variant="h5" style={{ color: "black" }}>
                Interview Details
              </Typography>
            </DialogTitle>
            <DialogContent style={{ padding: "30px" }}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    id="interviewer"
                    label="Interviewer Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    name="interviewer"
                    value={getEmployeeNameById(selectedInterview.interviewer)}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    id="time"
                    label="Time"
                    type="text"
                    fullWidth
                    variant="standard"
                    name="time"
                    value={getTimeFromDateTime(selectedInterview.datetime)}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    id="date"
                    label="Date"
                    type="text"
                    fullWidth
                    variant="standard"
                    name="date"
                    value={getDateFromDateTime(selectedInterview.datetime)}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    id="rating"
                    label="Rating"
                    type="text"
                    fullWidth
                    variant="standard"
                    name="rating"
                    value={selectedInterview.rating}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    id="feedback"
                    label="Feedback"
                    type="text"
                    fullWidth
                    variant="standard"
                    name="feedback"
                    value={selectedInterview.feedback}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    id="expereince"
                    label="Experience"
                    type="text"
                    fullWidth
                    variant="standard"
                    name="experience"
                    value={selectedInterview.expereince}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <MDButton
                variant="gradient"
                color="dark"
                onClick={handleInterviewerNameClose}
              >
                Cancel
              </MDButton>
            </DialogActions>
          </React.Fragment>
        )}
      </Dialog>

      {/* Dialog Box for Schedule Interview */}

      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "white",
            borderRadius: "10px",
            width: "500px",
            height: "450px",
          },
        }}
        open={scheduleInterviewOpen}
        onClose={handleScheduleInterviewClose}
      >
        {showAlert && (
          <MDAlert
            color="primary"
            dismissible
            onClick={() => {
              setShowAlert(null);
            }}
          >
            {alertContent(showAlert)}
          </MDAlert>
        )}
        <DialogTitle
          style={{
            // padding: "20px",
            borderBottom: "50px solid",
            backgroundColor: "#007bed",
            color: "white",
          }}
        >
          <Typography variant="h5" style={{ color: "white" }}>
            Schedule Interview
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid item xs={22} sm={12}>
            <Autocomplete
              id="interviewerName"
              options={employeesList}
              getOptionLabel={(option) =>
                option.user.firstname + " " + option.user.lastname
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  name="interviewerName"
                  label="Interviewer Name"
                  required
                  error={!!interviewerNameError}
                  helperText={interviewerNameError}
                />
              )}
              onChange={(event, newValue) => {
                if (newValue) {

                  setScheduleInterviewRecord((state) => ({
                    ...state,
                    interviewer: newValue?.id
                  }));
                }
                setInterviewerNameError("");
              }}
            />
          </Grid>
          <Grid
            container
            spacing={4}
            sx={{ marginTop: -3 }}
            alignItems={"center"}
          >
            <Grid item xs={12} sm={6}>
              <TextField
                InputLabelProps={{ shrink: true }}
                margin="dense"
                id="interviewTime"
                label="Interview Time"
                type="time"
                fullWidth
                variant="outlined"
                name="interviewTime"
                required
                error={!!interviewTimeError}
                helperText={interviewTimeError}
                value={interviewTime.interviewTime}
                onChange={(event) => {
                  setInterviewTime(event.target.value);
                  setInterviewTimeError("");
                }}
                InputProps={{
                  classes: {
                    input: "input-field",
                  },
                  placeholder: "",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                InputLabelProps={{ shrink: true }}
                margin="dense"
                id="date"
                label="Interview Date"
                type="date"
                placeholder="Select a date"
                fullWidth
                variant="outlined"
                name="interviewDate"
                required
                error={!!interviewDateError}
                helperText={interviewDateError}
                value={interviewDate.interviewDate}
                onChange={(event) => {
                  setInterviewDate(event.target.value);
                  setInterviewDateError("");
                }}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <TextField
                InputLabelProps={{ shrink: true }}
                margin="dense"
                id="date"
                label="Description"
                // placeholder="Description"
                fullWidth
                variant="outlined"
                name="interviewDate"
                required
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography variant="h8">Final Interview</MDTypography>
              <Switch
                checked={finalInterview}
                onChange={() => {
                  setFinalInterview(!finalInterview);
                }}
              />
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <TextField
                InputLabelProps={{ shrink: true }}
                margin="dense"
                id="date"
                label="Description"
                // placeholder="Description"
                fullWidth
                variant="outlined"
                name="interviewDate"
                required
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography variant="h8">Final Interview</MDTypography>
              <Switch
                checked={finalInterview}
                onChange={() => {
                  setFinalInterview(!finalInterview);
                }}
              />
            </Grid>
          </Grid>
          <MDBox>
          {/* <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <MDBox display="flex" alignItems="center">
                  <MDBox ml={2} lineHeight={0}>
                    <MDTypography variant="h7" fontWeight="small">
                      GMeet
                    </MDTypography>
                  
                  </MDBox>
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  width={{ xs: "100%", sm: "auto" }}
                  mt={{ xs: 1, sm: 0 }}
                >
            
                  <MDBox mr={1} display="flex" justifyContent="center" alignItems="center" >
                    <Switch checked={gmeet} onChange={handleSetGmeet} />
                    {gmeet && (
                    gmeetAuthenticated ? (
                      <img src={verifiedIcon} height="24" width="24" />
                   )
                     : (
                      <MDButton
                      onClick={() => {
                             getAuthToken();
                      }}
                      sx={{ backgroundColor: "lightGrey",  }}
                    >
                      { "Authenticate"}
                    </MDButton>
                    ))}
                  </MDBox>
                </MDBox>
              </MDBox> */}
          </MDBox>
        </DialogContent>
        <DialogActions style={{ justifyContent: "flex-end" }}>
          <MDButton
            variant="gradient"
            color="dark"
            onClick={handleScheduleInterviewClose}
          >
            Cancel
          </MDButton>
          <MDButton
            variant="gradient"
            color="dark"
            onClick={() => {
              if(gmeetAuthenticated){
                handleScheduleInterview();
              }else {
                alert("You are not authenticated with Google Meet & Calendar Events. Please enable it from recruitment settings")
              }
            
            }}
          >
            Schedule
          </MDButton>
        </DialogActions>
      </Dialog>

      {/* Dialog Box for Document */}
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "white",
            boxShadow: "none",
            width: "500px",
            height: "450px",
            maxWidth: "none",
          },
        }}
        open={documentOpen}
        onClose={handleDocumentClose}
      >
        <DialogTitle
          style={{
            padding: "20px",
            borderBottom: "1px solid ",
            background: "#EC407A",
            color: "lavenderblush",
          }}
        >
          <Typography variant="h5" style={{ color: "white" }}>
            Required Document
          </Typography>
        </DialogTitle>
        <DialogContent>
          <MDBox py={3} pb={2}>
            <MDBox p={1}>
              <MDTypography variant="body2" color="text.secondary">
                {documents?.map((value) => {
                  const handleChange = () => handleDocumentChange(value.id);
                  const isChecked = selectedValues?.includes(value.id);
                  // const isChecked = selectedValues?.includes(key) || selectedDocs.includes(parseInt(key, 10));

                  return (
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems={{ xs: "center", sm: "center" }}
                      flexDirection={{ xs: "row", sm: "row" }}
                    >
                      <MDBox lineHeight={2}>
                        <MDTypography variant="button" color="text">
                          {value.name}
                        </MDTypography>
                      </MDBox>
                      <MDBox>
                        <input
                          key={value.id}
                          checked={isChecked}
                          onChange={() => {
                            handleChange();
                          }}
                          type="checkbox"
                        />
                      </MDBox>
                    </MDBox>
                  );
                })}
              </MDTypography>
            </MDBox>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  id="name"
                  label="Expected CTC"
                  type="text"
                  fullWidth
                  variant="standard"
                  name="ctc"
                  value={jobOfferDetail?.ctc}
                  onChange={(event) => {
                    setJobOfferDetail((state) => ({
                      ...state,
                      ctc: event.target.value,
                    }));
                  }}
                  inputProps={{}}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  id="e_doj"
                  label="Expected Date of Joining"
                  type="date"
                  fullWidth
                  variant="standard"
                  name="Expected Date of Joining"
                  placeholder=""
                  value={jobOfferDetail?.expected_doj}
                  onChange={(event) => {
                    setJobOfferDetail((state) => ({
                      ...state,
                      expected_doj: event.target.value,
                    }));
                  }}
                  inputProps={{}}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  id="validtill"
                  label="Valid till"
                  type="date"
                  fullWidth
                  variant="standard"
                  name="Valid till"
                  placeholder=""
                  value={jobOfferDetail?.valid_till}
                  onChange={(event) => {
                    setJobOfferDetail((state) => ({
                      ...state,
                      valid_till: event.target.value,
                    }));
                  }}
                  inputProps={{}}
                />
              </Grid>
            </Grid>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="contained"
            color="dark"
            onClick={handleDocumentClose}
          >
            Cancel
          </MDButton>
          <MDButton
            variant="contained"
            color="dark"
            onClick={async () => {
              await handleSaveClick(candData);
              await makeJobOffer(candData.id);
            }}
          >
            Done
          </MDButton>
        </DialogActions>
      </Dialog>

      {/* Dialog Box for Add Records */}
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "aliceBlue",
            boxShadow: "none",
            width: "700px",
            // height: "500px",
            maxWidth: "none",
            padding: "20px",
          },
        }}
        open={recordsOpen}
        onClose={handleRecordsClose}
      >
        {showAlert && (
          <MDAlert
            color="primary"
            dismissible
            onClick={() => {
              setShowAlert(null);
            }}
          >
            {alertContent(showAlert)}
          </MDAlert>
        )}
        <DialogTitle>Add Candidate</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="name"
                label="Name"
                type="text"
                fullWidth
                variant="standard"
                name="full_name"
                value={newRecord.full_name}
                onChange={handleInputChange}
                required
                error={!!fullNameError}
                helperText={fullNameError}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="number"
                label="Number"
                type="number"
                fullWidth
                variant="standard"
                name="mobile"
                value={newRecord.mobile}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="email"
                label="Email"
                type="email"
                fullWidth
                variant="standard"
                name="email"
                value={newRecord.email}
                onChange={handleInputChange}
                required
                error={!!emailError}
                helperText={emailError}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="experience"
                label="Experience"
                type="number"
                fullWidth
                variant="standard"
                name="experience"
                value={newRecord.experience}
                onChange={handleInputChange}
                required
                error={!!experienceError}
                helperText={experienceError}
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                options={
                  candidateOptionValues?.source
                    ? Object.entries(candidateOptionValues?.source).map(
                        ([key, value]) => ({ value: key, label: value })
                      )
                    : []
                }
                value={convertSource(newRecord.source)}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setNewRecord((state) => ({
                      ...state,
                      source: newValue?.value,
                    }));
                  }
                  setSourceError("");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Source"
                    variant="standard"
                    fullWidth
                    error={!!sourceError}
                    helperText={sourceError}
                  />
                )}
              />
              {/* <TextField
                disableRipple
                margin="dense"
                id="source"
                label="Source"
                type="text"
                fullWidth
                variant="standard"
                name="source"
                value={newRecord.source}
                onChange={handleInputChange}
                error={!!sourceError}
                helperText={sourceError}
              /> */}
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="recruiter"
                options={employeesList}
                value={editedData[editableRowIndex]?.recruiter}
                getOptionLabel={(option) =>
                  Array.isArray(option)
                    ? option.map(
                        (item) => item.user.firstname + " " + item.user.lastname
                      )
                    : option.user.firstname + " " + option.user.lastname
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    name="recruiter"
                    label="Recruiter"
                    variant="standard"
                  />
                )}
                onChange={(event, newValue) => {
                  if (newValue) {
                    const selectedValues = Array.isArray(newValue)
                      ? newValue.map((v) => v.id)
                      : [newValue.id];
                    setNewRecord((state) => ({
                      ...state,
                      recruiter: selectedValues,
                    }));
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                name="jd"
                options={advertisements}
                getOptionLabel={(e)=> e?.position}
                // value={jd.position}
                onChange={(event, value) => {
                  if (value) {
                    setNewRecord((state) => ({
                      ...state,
                      jd: value,
                    }));
                  }
                  setStatusError("");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    id="autocomplete"
                    label="Job Openings"
                    type="text"
                    variant="standard"
                    error={!!jdError}
                    helperText={jdError}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                options={
                  candidateOptionValues?.status
                    ? Object.entries(candidateOptionValues?.status).map(
                        ([key, value]) => ({ value: key, label: value })
                      )
                    : []
                }
                value={convertStatus(newRecord.status)}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setNewRecord((state) => ({
                      ...state,
                      status: newValue?.value,
                    }));
                  }
                  setStatusError("");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    variant="standard"
                    fullWidth
                    error={!!statusError}
                    helperText={statusError}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              {/* <label>Resume</label>
              <br /> */}
              <TextField
                InputLabelProps={{ shrink: true }}
                error={!!cvError}
                helperText={cvError}
                label="Resume"
                variant="standard"
                fullWidth
                type="file"
                onChange={handleFileSelect}
              />
              {/* <input
                // error={!!cvError}
                // helperText={cvError}
                // type="file"
                onChange={handleFileSelect}
              /> */}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            color="dark"
            onClick={handleRecordsClose}
          >
            Cancel
          </MDButton>
          <MDButton variant="gradient" color="dark" onClick={handleAddRecord}>
            Add Candidate
          </MDButton>
        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}
export default Candidates;
