import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
  TextField,
  Button,
  FormGroup,
  FormControl,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Tooltip,
  Icon,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import MDButton from "components/MDButton";

function WeeklyTimeRangePicker(props) {
  const {
    daysOfWeek,
    defaultTimeRange,
    defaultUnavailableTime,
    timeRanges,
    setTimeRanges,
    initialData,
    isEventEdit,
  } = props;

  const [isOpen, setOpen] = useState(false);

  const initialSelected = isEventEdit
    ? Object.keys(initialData)
    : ["Mon", "Tue", "Wed", "Thu", "Fri"];

  const [selectedDays, setSelectedDays] = useState(initialSelected);

  const handleAddSlot = (day) => {
    if (!timeRanges[day]) {
      setTimeRanges({ ...timeRanges, [day]: { ...defaultTimeRange } });
    }
  };

  const [hasInitialData, setHasInitialData] = useState(false);

  useEffect(() => {
    if (initialData && isEventEdit) {
      const selectedDays = Object.keys(initialData).filter(
        (day) =>
          initialData[day].start !== "Unavailable" &&
          initialData[day].end !== "Unavailable"
      );
      setSelectedDays(selectedDays);
      setHasInitialData(true);

      const updatedTimeRanges = { ...timeRanges };
      daysOfWeek.forEach((day) => {
        if (initialData[day]) {
          if (
            initialData[day].start !== "Unavailable" &&
            initialData[day].end !== "Unavailable"
          ) {
            updatedTimeRanges[day] = {
              start: initialData[day].start,
              end: initialData[day].end,
            };
          } else {
            updatedTimeRanges[day] = { ...defaultTimeRange };
          }
        } else {
          updatedTimeRanges[day] = { ...defaultTimeRange };
        }
      });

      setTimeRanges(updatedTimeRanges);
    }
  }, [initialData]);

  const handleDayChange = (day) => {
    const updatedSelectedDays = [...selectedDays];
    if (updatedSelectedDays.includes(day)) {
      updatedSelectedDays.splice(updatedSelectedDays.indexOf(day), 1);
      const newTimeRanges = { ...timeRanges };
      newTimeRanges[day] = defaultUnavailableTime;
      setTimeRanges(newTimeRanges);
    } else {
      updatedSelectedDays.push(day);
      const newTimeRanges = { ...timeRanges };
      newTimeRanges[day] = { ...defaultTimeRange };
      setTimeRanges(newTimeRanges);
    }
    setSelectedDays(updatedSelectedDays);
  };

  const handleRemoveSlot = (day) => {
    const updatedSelectedDays = [...selectedDays];
    if (updatedSelectedDays.includes(day)) {
      updatedSelectedDays.splice(updatedSelectedDays.indexOf(day), 1);
      const newTimeRanges = { ...timeRanges };
      newTimeRanges[day] = defaultUnavailableTime;
      setTimeRanges(newTimeRanges);
    }
    setSelectedDays(updatedSelectedDays);
  };

  return (
    <div style={{ marginBottom: "40px" }}>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            marginLeft: "2px",
            marginRight: "5px",
          }}
        >
          {selectedDays.includes("Sun") || selectedDays.includes("Sat")
            ? "Mon, Tue, Wed, Thu, Fri, Sat, Sun"
            : "Weekdays"}{" "}
          hours vary
        </Typography>
        <Tooltip title="Edit" placement="top">
          <MDButton
            variant="text"
            color="info"
            size="medium"
            iconOnly
            onClick={() => {
              setOpen(true);
            }}
          >
            <Icon>edit</Icon>
          </MDButton>
        </Tooltip>
      </div>

      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "aliceBlue",
            borderRadius: "8px",
            width: "700px",
            height: "500px",
            maxWidth: "none",
            padding: "20px",
          },
        }}
        open={isOpen}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>Manage Availability Hours</DialogTitle>
        <DialogContent>
          <div>
            <FormControl>
              <FormGroup>
                {daysOfWeek.map((day) => (
                  <div
                    key={day}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedDays.includes(day)}
                          onChange={() => handleDayChange(day)}
                        />
                      }
                      sx={{
                        width: "80px",
                      }}
                      label={day}
                    />
                    {selectedDays.includes(day) ? (
                      <Box display="flex" alignItems="center">
                        <TextField
                          type="time"
                          value={
                            timeRanges[day]?.start || defaultTimeRange.start
                          }
                          onChange={(e) => {
                            const newTimeRanges = { ...timeRanges };
                            newTimeRanges[day].start = e.target.value;
                            setTimeRanges(newTimeRanges);
                          }}
                        />
                        <span style={{ marginRight: 1, marginLeft: 1 }}>-</span>
                        <TextField
                          type="time"
                          value={timeRanges[day]?.end || defaultTimeRange.end}
                          onChange={(e) => {
                            const newTimeRanges = { ...timeRanges };
                            newTimeRanges[day].end = e.target.value;
                            setTimeRanges(newTimeRanges);
                          }}
                        />
                        <IconButton onClick={() => handleRemoveSlot(day)}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    ) : (
                      <Typography>Unavailable</Typography>
                    )}
                  </div>
                ))}
              </FormGroup>
            </FormControl>
          </div>
        </DialogContent>

        <DialogActions>
          <MDButton
            variant="gradient"
            color="dark"
            onClick={() => setOpen(false)}
          >
            Save and close
          </MDButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default WeeklyTimeRangePicker;
