import { createSlice } from '@reduxjs/toolkit';

const newEventSlice = createSlice({
  name: 'newEvents',
  initialState: {
    newMeetings: [], // Replace 'employee' with 'newMeetings'
    clickedNewMeeting: null, // Replace 'employee' with 'newMeetings'
  },
  reducers: {
    newMeetingList: (state, action) => {
      state.newMeetings = action.payload // Update to match your newMeeting data structure
    },
  },
});

export const {  newMeetingList } = newEventSlice.actions;
export default newEventSlice.reducer;

export const GetNewEventListing = (state) => state.newEvents.newMeetings;

