import { FileDrop } from "components/DragNDrop.js/drag_n_drop";
import MDBox from "components/MDBox";
import ApiCalls from "services/flick_erp_api_call";

import FormField from "layouts/pages/account/components/FormField";
import { setToastOpen } from "components/Toast/toastAlertSlice";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { useState } from "react";
import MDDropzone from "components/MDDropzone";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";

export function UploadFile() {
  const navigate = useNavigate() ;
  const [attendanceUploadPayload, setAttendanceUploadPayload] = useState();
  const dispatch = useDispatch();
  async function uploadAttendance() {
    let hasError = false ;

    if(attendanceUploadPayload?.start_date == undefined || attendanceUploadPayload?.start_date == ""   ) {

       hasError = true ;
       setAttendanceUploadPayload((state)=>({...state, start_date_error: true}))
    } else {

      setAttendanceUploadPayload((state)=>({...state, start_date_error: false}))
    }
    if(attendanceUploadPayload?.end_date == undefined || attendanceUploadPayload?.end_date == ""  ) {

      hasError = true ;
      setAttendanceUploadPayload((state)=>({...state, end_date_error: true}))
   }else {

    setAttendanceUploadPayload((state)=>({...state, end_date_error: false}))
  }
  if(attendanceUploadPayload?.file == undefined || attendanceUploadPayload?.file == ""  ) {

    hasError = true ;
    setAttendanceUploadPayload((state)=>({...state, file_error: true}))
 }else {

  setAttendanceUploadPayload((state)=>({...state, file_error: false}))
}
   if(!hasError) {
     var formData = new FormData();
    formData.append("start_date", attendanceUploadPayload.start_date);
    formData.append("end_date", attendanceUploadPayload.end_date);
    formData.append("file", attendanceUploadPayload.file);

    var res = await ApiCalls.uploadAttendance(formData, true);

    if (res.success && res.code === 201) {
      navigate("/Attendance")
      setAttendanceUploadPayload({})
    }
   }
   
  }
  return (
    <MDBox>
      <Grid container spacing={2} p={3}>
        <Grid item xs={3}>
          <FormField
            label="Start Date"
            variant="standard"
            InputProps={{}}
            id="start"
            type="date"
            name="start_date"
            error={attendanceUploadPayload?.start_date_error}
            onChange={(event) => {
              setAttendanceUploadPayload((state) => ({
                ...state,
                start_date: event.target.value,
                start_date_error: event.target.value == "",
              }));

            }}
            sx={{}}
          />
        </Grid>
        <Grid item xs={3}>
          <FormField
            label="End Date"
            variant="standard"
            InputProps={{}}
            id="end_date"
            type="date"
            name="end_date"
            error={attendanceUploadPayload?.end_date_error}
            onChange={(event) => {
              setAttendanceUploadPayload((state) => ({
                ...state,
                end_date: event.target.value,
                end_date_error: event.target.value == "",
              }));
            }}
          />
        </Grid>
      </Grid>

      {/* <MDDropzone options={{ addRemoveLinks: true, maxFiles:1 }}></MDDropzone> */}

    <MDBox align="center" p={3}>
    <FileDrop onDropped={(e) => {
        setAttendanceUploadPayload((state) => ({
          ...state,
          file: e,
          file_error:false
        }));
    }} acceptedFiles={[".dat"]} validationError={attendanceUploadPayload?.file_error}></FileDrop>
    </MDBox>
    <MDBox align="center" p={3}>
    <MDButton 
        onClick={() => {
          uploadAttendance();
        }}
        color="info"
      >
        Submit
      </MDButton>
    </MDBox>
    </MDBox>
  );
}
