import { Delete, Refresh } from "@mui/icons-material";
import {
  Autocomplete,
  Card,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { setToastOpen } from "components/Toast/toastAlertSlice";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormField from "layouts/applications/wizard/components/FormField";
import { useDebugValue, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ApiCalls from "services/flick_erp_api_call";

export function LeaveSettings({ permissions, modulePermissions }) {
  const [leavesTypes, setleavesTypes] = useState([]);
  const [leavesTypesPayload, setleavesTypesPayload] = useState({});
  const dispatch = useDispatch();
  const [CreateLeaveTypeDialog, setCreateLeaveTypeDialog] = useState(false);
  const leaveCateogry = [
    { id: "CL", category: "casual_leave", name: "Casual Leave" },
    { id: "SL", category: "sick_leave", name: "Sick Leave" },
    { id: "EL_PL", category: "earned_leave", name: "Earned Leave" },
    { id: "MATERNITY", category: "maternity_leave", name: "Maternity Leave" },
    { id: "PATERNITY", category: "paternity_leave", name: "Paternity Leave" },
    {
      id: "BEREAVEMENT",
      category: "bereavement_leave",
      name: "Bereavement Leave",
    },
    { id: "MEDICAL", category: "medical_leave", name: "Medical Leave" },
    { id: "UNPAID", category: "un_paid", name: "Unpaid" },
  ];

  useEffect(() => {
    getLeaveTypes();
  }, []);
  async function getLeaveTypes() {
    var res = await ApiCalls.getLeavesTypes();
    if (res.success && res.code == 200) {
      setleavesTypes(res.data);
    }
  }
  async function postLeaveTypes() {
    var res = await ApiCalls.postLeaveTypes(leavesTypesPayload, false);
    if (res.success && res.code == 201) {
      setCreateLeaveTypeDialog(false);
      getLeaveTypes();

    } else {
      setCreateLeaveTypeDialog(false);
     
    } setleavesTypesPayload((state) => ({}));
  }
  async function postLeaveRefresh() {

    var res = await ApiCalls.postLeaveRefresh({}, false);
    if (res.success && res.code == 201) {
      // setCreateLeaveTypeDialog(false);
      // getLeaveTypes();

    } else {
   
     
    }
  }
  async function deleteLeaveTypes(id) {
    var res = await ApiCalls.deleteLeaveType(id);
    if (res.success && res.code == 204) {
      getLeaveTypes();
    } else {
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar></DashboardNavbar>
      <MDBox p={5}>
        <Card sx={{ padding: "1px" }}>
          <MDBox p={2}>
            <MDBox display="flex" justifyContent="center">
              <MDTypography variant="h4">Leave Settings</MDTypography>
            </MDBox>

            <MDBox
              pt={4}
              pb={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDTypography variant="h6">Leave Types</MDTypography>
              { permissions?.includes(modulePermissions?.leave_type_create_permission) && (<Fab
                color="info"
                onClick={() => {
                  setCreateLeaveTypeDialog(true);
                }}
              >
                <MDTypography variant="h6" color="white">
                  +
                </MDTypography>
              </Fab>)}
            </MDBox>
            {permissions?.includes(
              modulePermissions?.leave_type_list_permission
            ) && (
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        variant="head"
                        color="black"
                        sx={{ color: "black" }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        variant="head"
                        color="black"
                        sx={{ color: "black" }}
                      >
                        Category
                      </TableCell>
                     
                      <TableCell
                        variant="head"
                        color="black"
                        sx={{ color: "black" }}
                      >
                        Hours
                      </TableCell>
                     { permissions?.includes(modulePermissions?.leave_type_delete_permission) && ( <TableCell
                        variant="head"
                        color="black"
                        sx={{ color: "black" }}
                      >
                        Action
                      </TableCell>)}
                    </TableRow>
                  </TableBody>
                  <TableBody>
                    {leavesTypes?.map((row, index) => {
                      return (
                        <TableRow key={row.id}>
                          {" "}
                          <TableCell>
                            <MDBox display="flex" flexDirection="column">
                              {row.name}
                              <MDTypography variant="caption" pt={-1} pl={0.1}>
                                {row.description}
                              </MDTypography>
                            </MDBox>
                          </TableCell>
                          <TableCell>
                            {
                              leaveCateogry.find(
                                (e) => e.category == row.category
                              ).name
                            }
                          </TableCell>
                          <TableCell>{row.max_hours_allowed}</TableCell>
                         { permissions?.includes(modulePermissions?.leave_type_delete_permission) &&( <TableCell>
                            <Delete
                              onClick={() => {
                                deleteLeaveTypes(row.id);
                              }}
                            />
                          </TableCell>)}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {/* <TableFooter>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={10}
                    count={jobOffers.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    slotProps={{
                      select: {
                        "aria-label": "rows per page",
                      },
                      actions: {
                        showFirstButton: true,
                        showLastButton: true,
                      },
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableFooter> */}
                </Table>
              </TableContainer>
            )}
            <MDBox pt={2}>
            <MDTypography variant="h6">Refresh Leave Types</MDTypography>
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <MDTypography variant ="body2">Refresh Employees Monthly Leaves</MDTypography>
              <IconButton
              onClick={()=>{
                postLeaveRefresh()
              }}
              >
                <Refresh></Refresh>
              </IconButton>
            </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
     
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "white",
            boxShadow: "none",
            width: "600px",
            height: "auto",
            maxWidth: "none",
            borderRadius: "10px",
            cursor: "pointer",
          },
        }}
        open={CreateLeaveTypeDialog}
        onClose={() => {
          setCreateLeaveTypeDialog(false);
          setleavesTypesPayload((state) => ({}));
        }}
      >
        <DialogTitle
          style={{
            width: "100%",
            padding: "20px",
            backgroundColor: "#4FA7F9",
            color: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "Medium" }}>
              Add Leave Type
            </Typography>
            <Icon
              fontSize="medium"
              onClick={() => {
                setCreateLeaveTypeDialog(false);
              }}
            >
              close
            </Icon>
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "15px",
          }}
        >
          <Card id="basic-info" sx={{ overflow: "visible", padding: "15px" }}>
            {/* <MDBox
                p={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <MDTypography variant="h5">Apply for leave</MDTypography>
              </MDBox> */}
            <MDBox component="form" pb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    // value={gender}
                    options={leaveCateogry}
                    getOptionLabel={(e) => e.name}
                    onChange={(event, values) => {
                      setleavesTypesPayload((state) => ({
                        ...state,
                        category: values.category,
                      }));
                    }}
                    renderInput={(params) => (
                      <FormField
                        label="Category"
                        {...params}
                        placeholder="Select Category"
                        InputLabelProps={{ shrink: true }}

                        // value={gender}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    label="Name"
                    // placeholder="Volkov"
                    value={leavesTypesPayload.name || ""}
                    onChange={(e) => {
                      setleavesTypesPayload((state) => ({
                        ...state,
                        name: e.target.value,
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        label="Description"
                        placeholder="Leave Type Description"
                        // value={email}
                        onChange={(e) => {
                          setleavesTypesPayload((state) => ({
                            ...state,
                            description: e.target.value,
                          }));
                          //   isCreateEmployee ? setEmail(e.target.value) : null;
                          //   setEmployee({ ...Employee, email: e.target.value });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormField
                        label="Allowed Hour"
                        placeholder=""
                        // value={email}
                        onChange={(e) => {
                          setleavesTypesPayload((state) => ({
                            ...state,
                            max_hours_allowed: e.target.value,
                            org: JSON.parse(localStorage.getItem("user"))
                              .org_id,
                          }));
                          //   isCreateEmployee ? setEmail(e.target.value) : null;
                          //   setEmployee({ ...Employee, email: e.target.value });
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* <Grid item xs={12} md={6}></Grid> */}
              </Grid>
              <MDBox pt={4} display="flex" justifyContent="center">
                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={() => {
                    postLeaveTypes();

                  }}
                >
                  Submit
                </MDButton>
              </MDBox>
            </MDBox>
          </Card>
        </DialogContent>
      </Dialog>
    </DashboardLayout>
  );
}
