/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import { AccountCircle, Edit } from "@mui/icons-material";
import { useRef } from "react";
import ApiCalls from "services/flick_erp_api_call";
import { base_url } from "services";
import { setToastOpen } from "components/Toast/toastAlertSlice";
import { useDispatch } from "react-redux";

function Header({ data, userId, permissions, modulePermissions ,is_manager, is_hr}) {
  // const [visible, setVisible] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState(null);

  const [profileImage, setProfileImage] = useState(null);

  const dispatch = useDispatch();
  // const handleSetVisible = () => setVisible(!visible);
  const location = useLocation();

  const user = JSON.parse(localStorage.getItem("user"));

  const fileInputRef = useRef(null);

  const handleFileSelect = async (e) => {
    const file = e.target.files[0];
    // const base64Data = await convertToBase64(file);
    setSelectedImage(URL.createObjectURL(file));
    updateProfile(file);
  };

  const navigate = useNavigate();

  const updateProfile = async (file) => {
    try {
      const formData = new FormData();
      formData.append("img", file);



      const res = await ApiCalls.editEmployee(userId, formData, true);

      if (res.success && res.code === 200) {
        setProfileImage(formData.img);

        // location.pathname == "/pages/profile/profile-overview"
        //   ? null
        //   : navigate("/dashboards/employees");
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditClick = () => {
    fileInputRef.current.click();

  };

  return (
    <Card id="profile" sx={{ boxShadow: "none" }}>
      <MDBox pl={2} pr={2} height="100%" width="100%">
        {(is_manager || is_hr) && (  <MDTypography pl={10} variant="h5" fontWeight="medium">
                {is_manager ? "Reporting Manager" : is_hr ? "HR Manager" : ""}
              </MDTypography>)}
        <Grid container p={0} alignItems="center">
          <Grid item pr={3} pt={0.5} pb={0.5}>
            { (
              <MDAvatar
                src={ (data?.img || selectedImage ) ? ( selectedImage
                  ? selectedImage
                  : data?.img.includes("http")
                  ? data?.img
                  : base_url + data?.img) : burceMars}
                alt="profile-image"
                size="xl"
                shadow="sm"
                sx={{  cursor: "pointer",
                position: "relative",
                transition: "transform 0.15s ease-in-out",
                "&:hover, &:focus": {
                  zIndex: 9999,
                  // ml: -1,
                  transform: "scale3d(2.5, 2.5, 2)",
                },}}
              />
            )}
          </Grid>
          <Grid item>
            <MDBox height="100%" width="100%" mt={0.5} lineHeight={1}>
              
              <MDTypography variant="h5" fontWeight="medium">
                {data?.user?.firstname || ""} {data?.user?.lastname || ""}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
            <MDBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileSelect}
              />
              {permissions?.includes(modulePermissions?.update_permission) && (
                <IconButton onClick={handleEditClick}>
                  <Edit />
                </IconButton>
              )}
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Header;
