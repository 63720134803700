import { Card } from "@mui/material";
import { Padding } from "@mui/icons-material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { DragEvent, useState } from "react";

export function FileDrop({ onDropped , acceptedFiles , validationError }) {
  const [isOver, setIsOver] = useState(false);
  const [files, setFiles] = useState([]);

  // Define the event handlers
  const handleDragOver = (event) => {
    event.preventDefault();
    setIsOver(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsOver(false);
    // Fetch the files
    const droppedFiles = Array.from(event.dataTransfer.files);

   if(acceptedFiles?.includes("."+droppedFiles[0].name.split('.').pop()) ) {
    onDropped(droppedFiles[0]);
    setFiles(droppedFiles);

    // Use FileReader to read file content
    droppedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {

      };
      reader.onerror = () => {
      };

      reader.readAsDataURL(file);

      return reader;
    });
   } else {
    alert("Only" + " "+ acceptedFiles.join(",") + " " + " Files are allowed.")
   }
  };

  return (
    // <Card fullWidth sx={{ height : '400px' , justifyContent:"center", alignItems:"center" ,  }}>
    <div
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        height: "300px",
        width: "45%",
        borderColor: validationError ? "red" : "grey",
        border: "1px dotted",
        borderRadius: "20px",
        overflow:"hidden",
        
        backgroundColor: isOver ? "lightgray" : "white",
      }}
    >
        <MDBox p={5} justifyContent="center" alignContent="center" >
      {files != undefined && files.length != 0 ? (
        <MDTypography>{files[0].name}</MDTypography>
      ) : (
        <>
        <MDTypography>Drag and drop some file here</MDTypography>
        <MDTypography>or</MDTypography>
       <input class="custom-file-input" type="file"
       accept={acceptedFiles?.join(",")}
       onChange={(e)=>{

        setFiles(e.target.files);

        onDropped(Array.from(e.target.files)[0]);
       }}
       >
</input>
        </>
      )}
     
      </MDBox>
    </div>
   
  );
}
