import React, { useEffect } from "react";
import NotFound from "./not_found";
import PermissionDenied from "permission_denied";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import { Navigate, Route, Link } from "react-router-dom";
import OrganisationSignUp from "layouts/authentication/org-sign-up";
import { logout } from "helpers/navigation";
function AuthGuard({ route }) {

  if (JSON.parse(localStorage.getItem("user")) !== null) {
    if (route.requireRole) {
      if (
        (JSON.parse(localStorage.getItem("user")) !== null) &&
        (JSON.parse(localStorage.getItem("user"))?.permissions_all[
          route.permissionKey
        ] != undefined) && (JSON.parse(localStorage.getItem("user"))?.permissions_all[
          route.permissionKey
        ].includes(route?.modulePermissions.list_permission) || JSON.parse(localStorage.getItem("user"))?.permissions_all[
          route.permissionKey
        ].includes(route?.modulePermissions.view_permission)      || JSON.parse(localStorage.getItem("user"))?.permissions_all[
          route.permissionKey
        ].includes(route?.modulePermissions.own_list_permission) )
      ) {
        return React.cloneElement(
          route.component,
          {
            userid: JSON.parse(localStorage.getItem("user"))?.employee_id,
            permissions: JSON.parse(localStorage.getItem("user"))
              ?.permissions_all[route.permissionKey],
            modulePermissions: route?.modulePermissions,
          },
          {
            state: {
              userid: JSON.parse(localStorage.getItem("user")).employee_id,
              permissions: JSON.parse(localStorage.getItem("user"))
                ?.permissions_all[route.permissionKey],
              modulePermissions: route?.modulePermissions,
            },
          }
        );
      } else {
        return route.permission_denied_component;
      }
    } else {
      return React.cloneElement(route.component, {
        permissions: Array.isArray(route.permissionKey)
          ? route.permissionKey
              .map(
                (e) =>
                  JSON.parse(localStorage.getItem("user"))?.permissions_all[e]
              )
              .flat(Infinity)
          : JSON.parse(localStorage.getItem("user"))?.permissions_all[
              route.permissionKey
            ],
        modulePermissions: route?.modulePermissions,
      });
    }
  } else if (route.name == "OrgSignUp") {
    return <OrganisationSignUp />;
  } else {
    return <SignInIllustration />;
  }
}

export default AuthGuard;
