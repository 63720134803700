/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useRef } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React components
import { useMaterialUIController } from "context";
import ApiCalls from "services/flick_erp_api_call";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";

function RolesPermissions({selectedPermissions,setSelectedPermissions}) {
  const navigate = useNavigate();

  const [permissions, setPermissions] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const initialRender = useRef(true);
const dispatch = useDispatch()
  useEffect( () => {
    getPermissions();
  }, []);
  useEffect(() => {
    // if (data?.permissions) {
    //   setSelectedPermissions(data?.permissions);
    // }
  }, []);
  function handleSwitchPermission(id) {
    if (selectedPermissions?.includes(id)) {
      setSelectedPermissions(
        selectedPermissions?.filter((item) => !(item == id))
      );
    } else {
      setSelectedPermissions([...selectedPermissions, id]);
    }
  }
  function handleModuleSwitchPermission(id) {
    var listOfPermissions = [];
    var p = permissions?.filter((val) => val.id == id)[0].permissions;
    p.forEach((e) => {
      listOfPermissions.push(e.id);
    });
    if (
      selectedPermissions.some((element) => {
        return listOfPermissions.indexOf(element) !== -1;
      })
    ) {
      setSelectedPermissions(
        selectedPermissions?.filter((item) => !listOfPermissions.includes(item))
      );
    } else {
      setSelectedPermissions([...selectedPermissions, ...listOfPermissions]);
    }
  }

  async function getPermissions() {
    var response = await ApiCalls.getGlobalPermissions();
    if (response.success) {
      setPermissions(response.data);
    }
  }
  // var groupByMenu = function (xs, key) {
  //   return xs.reduce(function (rv, x) {
  //     (rv[x[key]] = rv[x[key]] || []).push(x);
  //     return rv;
  //   }, {});

  // };
  useEffect(() => {
    // if(initialRender.current){
    //   initialRender.current = false ;
    // } else {
    //   if(isCreateEmployee){
    //     setEmployee({ ...Employee, permissions: selectedPermissions });
    //   } else {
    //     if(selectedPermissions != data?.permissions){
    //       setPermissionsApi();
    //     }
    //   }
    // }
    // if (  data !== null && selectedPermissions?.some((e)=> data?.permissions?.includes(e))
    // && !isCreateEmployee) {
    // } else {
    // }
  }, [selectedPermissions]);
  const setPermissionsApi = async () => {
    try {
      const updatedData = {
        permissions: selectedPermissions,
      };
    } catch (error) {
      console.error(error);


    }
  };
  return (
    <>
      {/* <MDBox p={3} pt={0} lineHeight={1}>
        <MDBox mb={0}>
          <MDTypography variant="h5">RolesPermissions</MDTypography>
        </MDBox>
        <MDTypography variant="button" color="text">
          Here you can select permission for the role.
        </MDTypography>
      </MDBox> */}

      <MDBox
        pt={2}
        pb={3}
        px={3}
        display="flex"
        flexDirection={{ xs: "column", sm: "column" }}
      >
        <MDBox
          display="flex"
          alignItems="flex-start"
          flexDirection={{ xs: "column", sm: "column" }}
        >
          {permissions.map((permission) => (
            <MDBox>
              <MDBox display="flex" justifyContent="start" alignItems="center">
                <MDTypography variant="h5" fontWeight="medium">
                  {permission.name}
                </MDTypography>
                <Switch
                    id={permission.id}
                  //   checked={selectedPermissions?.includes(object.id)}
                  onChange={() => handleModuleSwitchPermission(permission.id)}
                />
              </MDBox>
              <MDBox
                px={2}
                display="flex"
                flexWrap="wrap"
                // justifyContent="center"
                flexDirection={{ xs: "column", sm: "row" }}
              >
                {permission.permissions.map((object) => (
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems={{ xs: "center", sm: "center" }}
                    flexDirection={{ xs: "row", sm: "row" }}
                  >
                    <MDBox lineHeight={2}>
                      <MDTypography variant="button" color="text">
                        {object.name}
                      </MDTypography>
                    </MDBox>
                    <Switch
                      id={object.id}
                      checked={selectedPermissions?.includes(object.id)}
                      onChange={() => handleSwitchPermission(object.id)}
                    />
                  </MDBox>
                ))}
              </MDBox>
            </MDBox>
          ))}
        </MDBox>
      </MDBox>
    </>
  );
}

export default RolesPermissions;
