import { Staging_Base_Url } from "./apis";
import { Production_Base_Url } from "./apis";
import { Development_Base_Url } from "./apis";
import { store } from "../store";
import axios from "axios";
import history from "../helpers/navigation";
import { setToastOpen } from "components/Toast/toastAlertSlice";
import { NetworkError } from "./error_response_json";
import { processError } from "helpers/ServiceErrorUtility";
import { setLoaderOpen } from "components/Loader/loaderSlice";
import { setLoaderClose } from "components/Loader/loaderSlice";
import { setPromptOpen } from "components/Prompt/promptSlice";
const authConfigFunc = () => {
  var user = JSON.parse(localStorage.getItem("user"));
  return {
    headers: {
      "Content-Type": "application/json",
      "Org-Id": user?.org_id,
      Authorization: `Bearer ${user?.token}`,
    },
  };
};

const authConfigWithFormDataFunc = () => {
  var user = JSON.parse(localStorage.getItem("user"));

  return {
    headers: {
      "Content-Type": "multipart/form-data",
      "Org-Id": user?.org_id,
      Authorization: `Bearer ${user?.token}`,
    },
  };
};

const withoutAuthConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};

// const base_url =
//   process.env.REACT_APP_ENV == "development"
//     ? Development_Base_Url
//     : process.env.REACT_APP_ENV == "staging"
//     ? Staging_Base_Url
//     : process.env.REACT_APP_ENV == "production"
//     ? Production_Base_Url
//     : "";

export const base_url = "https://www.flickerp.com/";
// export const base_url = "http://hr.flickerp.com:8000/";
// export const base_url = "http://127.0.0.1:8000/"
// const base_url =  window.location.origin ;
const services = {
  get: async (url, isRequireAuth) => {
    try {
      store.dispatch(setLoaderOpen());
      const response = await axios.get(
        `${base_url}${url}`,
        isRequireAuth ? authConfigFunc() : withoutAuthConfig
      );
      store.dispatch(setLoaderClose());
      return successResponse(response);
    } catch (error) {
      store.dispatch(setLoaderClose());
      return errorResponse(error);
    }
  },
  post: async (url, data, isRequireAuth, isFormData) => {
    try {
      store.dispatch(setLoaderOpen());
      if (isRequireAuth) {
        if (isFormData) {
          data.append(
            "org",
            `${JSON.parse(localStorage.getItem("user")).org_id}`
          );
        } else {
          data.org = `${JSON.parse(localStorage.getItem("user")).org_id}`;
        }
      }
      const response = await axios.post(
        `${base_url}${url}`,
        data,
        isRequireAuth
          ? isFormData
            ? authConfigWithFormDataFunc()
            : authConfigFunc()
          : withoutAuthConfig
      );

      store.dispatch(setLoaderClose());
      return successResponse(response);
    } catch (error) {
      store.dispatch(setLoaderClose());
      return errorResponse(error);
    }
  },
  put: async (url, data, isRequireAuth) => {
    try {
      store.dispatch(setLoaderOpen());
      const response = await axios.put(
        `${base_url}${url}`,
        data,
        isRequireAuth ? authConfigFunc() : withoutAuthConfig
      );

      store.dispatch(setLoaderClose());
      return successResponse(response);
    } catch (error) {
      store.dispatch(setLoaderClose());
      return errorResponse(error);
    }
  },
  patch: async (url, data, isRequireAuth, isFormData) => {
    try {
      store.dispatch(setLoaderOpen());

      const response = await axios.patch(
        `${base_url}${url}`,
        data,
        isRequireAuth
          ? isFormData
            ? authConfigWithFormDataFunc()
            : authConfigFunc()
          : withoutAuthConfig
      );

      store.dispatch(setLoaderClose());
      return successResponse(response);
    } catch (error) {
      store.dispatch(setLoaderClose());
      return errorResponse(error);
    }
  },
  delete: async (url, isRequireAuth) => {
    try {

      const deleteConfirmation = confirm("Are you sure want to delete ?")
      if(deleteConfirmation){
        store.dispatch(setLoaderOpen());
      const response = await axios.delete(
        `${base_url}${url}`,
        isRequireAuth ? authConfigFunc() : withoutAuthConfig
      );

      store.dispatch(setLoaderClose());
      return successResponse(response);
      }
    } catch (error) {
      store.dispatch(setLoaderClose());
      return errorResponse(error);
    }
  },
};

const errorResponse = (error) => {
  const result = {
    success: false,
    data: null,
    code: 0,
  };

  if (error?.response?.status == 401) {
    store.dispatch(
      setToastOpen({ isOpen: true, message: "Authentication Error!" })
    );
    localStorage.clear();
    history.go("/authentication/sign-in/illustration");
  } else if (error?.response?.status == 500) {
    store.dispatch(
      setToastOpen({ isOpen: true, message: "Internal Server Error" })
    );
  } else if (error?.response?.status == 400) {
    if (error?.response?.data) {
      processError(error?.response?.data);
      store.dispatch(
        setToastOpen({
          isOpen: true,
          message: processError(error?.response?.data),
        })
      );
    } else {
      store.dispatch(setToastOpen({ isOpen: true, message: "Bad Request" }));
    }
  } else if (error?.response?.status == 403) {
    store.dispatch(setToastOpen({ isOpen: true, message:  processError(error?.response?.data) }));
  }
  
  else if (error?.response?.status == 404) {
    store.dispatch(setToastOpen({ isOpen: true, message: "Detail Not Found" }));
  } else {
    store.dispatch(
      setToastOpen({ isOpen: true, message: "An Error Occured!" })
    );
  }

  if (error.response) {
    result.data = error.response.data;
    result.code = error.response.status;
    return result;
  }
  if (error.message) {
    result.data = error.message;
    return result;
  }
};

const successResponse = (response) => {
  const result = {
    success: true,
    data: "null",
    code: 0,
  };

  if (response.data) {
    result.data = response.data;
    result.code = response.status;
    return result;
  } else {
    result.code = response.status;
    return result;
  }
};

export default services;
