import React, { useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

import CardContent from "@mui/material/CardContent";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
function Permission() {
  const checkList1 = ["Email ", "Manual ", "API "];
  const [manual, setManual] = useState(true);
  const [gmail, setGmail] = useState(false);
  const [api, setApi] = useState(false);

  const handleSetManual = () => setManual(!manual);
  const handleSetGmail = () => setGmail(false);
  const handleSetApi = () => setApi(false);

  return (
    <Card id="permissions">
      <CardContent>
        <MDTypography variant="h5" fontWeight="medium">
          Portal Pemissions for CV
        </MDTypography>
        <br />
        <MDTypography variant="body2" color="text.secondary">
          {/* {checkList1.map((item, index) => (
                        <Card variant="button" color ="text" key={index}>
                            <row>
                                {item}
                                <input value={item} type="checkbox" />
                            </row>
                        </Card>
                    ))} */}
          <Grid container spacing={3}>
            <Grid item xs={8} sm={5}>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <MDBox display="flex" alignItems="center">
                  <MDBox ml={2} lineHeight={0}>
                    <MDTypography variant="h7" fontWeight="small">
                      Manual
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  width={{ xs: "100%", sm: "auto" }}
                  mt={{ xs: 1, sm: 0 }}
                >
                  <MDBox lineHeight={0} mx={2}></MDBox>
                  <MDBox mr={1}>
                    <Switch checked={manual} onChange={handleSetManual} />
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
            <br />
            <Grid item xs={8} sm={5}>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <MDBox display="flex" alignItems="center">
                  <MDBox ml={2} lineHeight={0}>
                    <MDTypography variant="h7" fontWeight="small">
                      Gmail (Premium)
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  width={{ xs: "100%", sm: "auto" }}
                  mt={{ xs: 1, sm: 0 }}
                >
                  <MDBox lineHeight={0} mx={2}></MDBox>
                  <MDBox mr={1}>
                    <Switch checked={gmail} onChange={handleSetGmail} />
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>{" "}
            <br />
            <Grid item xs={8} sm={5}>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <MDBox display="flex" alignItems="center">
                  <MDBox ml={2} lineHeight={0}>
                    <MDTypography  variant="h7" fontWeight="small" >
                      API (Premium)
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  width={{ xs: "100%", sm: "auto" }}
                  mt={{ xs: 1, sm: 0 }}
                >
                  <MDBox lineHeight={0} mx={2}></MDBox>
                  <MDBox mr={1}>
                    <Switch checked={api} onChange={handleSetApi} />
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </MDTypography>
      </CardContent>
    </Card>
  );
}
export default Permission;
