import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { Card, Checkbox, Grid, Input, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import MDButton from "components/MDButton";
import Footer from "examples/Footer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ApiCalls from "services/flick_erp_api_call";
import { useSelector } from "react-redux";
import { advertisementLists } from "../currentOpenings/jobAdvertisementSlice";
import {
  Cancel,
  Check,
  Delete,
  Edit,
  HowToReg,
  NetworkCheckRounded,
  PictureAsPdf,
  Portrait,
} from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { optionValues, setScreeningLists } from "./screeningSlice";
import Autocomplete from "@mui/material/Autocomplete";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import { employeeList } from "layouts/employees/employeesSlice";
import { SearchBox } from "components/SearchBox";

function Screening({ permissions, modulePermissions }) {
  const user = JSON.parse(localStorage.getItem("user"));

  const [data, setData] = useState([]);
  const [editableRowIndex, setEditableRowIndex] = useState(-1);
  const [editedData, setEditedData] = useState([]);
  const [newRecord, setNewRecord] = useState({
    full_name: "",
    mobile: "",
    email: "",
    experience: "",
    recruiter: "",
    status: "",
    source: "",
    job_ad: "",
    cv: "",
  });
  const [id_, setID] = useState();
  const [recordsOpen, setRecordsOpen] = React.useState(false);
  const [nameOpen, setNameOpen] = React.useState(false);
  const [jd, setJD] = useState({ id: null, position: "" });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState();
  const [searchBy, setSearchBy] = useState("Name");

  const dispatch = useDispatch();

  useEffect(() => {
    getScreeningList();
    jodAdvertisement();
  }, []);

  //useSelector
  const advertisements = useSelector((state) =>
    state.jobAdvertisement.jobAdvertisement.map((advertisement) => ({
      id: advertisement.id,
      position: advertisement.position,
    }))
  );
  const advertisementList_ = useSelector(
    (state) => state.jobAdvertisement.jobAdvertisement
  );

 

  const getPositionLabel = (id) => {
    const ad = advertisementList_.find((ad) => ad.id === parseInt(id));
    return ad ? ad.position : "";
  };



  const screenings = useSelector((state) => state.screening.screening);

  useEffect(() => {
    getEmployee();
  }, []);

  // Get Employee List Api

  async function getEmployee() {
    var res = await ApiCalls.getEmployeesList();
    if (res.success && res.code === 200) {
      // var data = JSON.parse(JSON.stringify(res.data));
      dispatch(employeeList({ employee: res.data }));
    } else {
    }
  }
  const getEmployeeNameById = (interviewerList) => {
    if (!interviewerList) {
      return "";
    }

    if (Array.isArray(interviewerList)) {
      var record = employeesList?.filter((v) =>
        interviewerList.some((i) => i === v.id)
      );
      if (record.length > 0) {
        return record.reduce(
          (accumulator, name) =>
            accumulator + name.user.firstname + " " + name.user.lastname + " ",
          ""
        );
      }
    } else {
      var employee = employeesList?.find((v) => v.id === interviewerList);
      if (employee) {
        return employee.user.firstname + " " + employee.user.lastname;
      }
    }

    return "";
  };

  const employeesList = useSelector((state) => state.employee.employee);
  const employeeOptions = employeesList?.map((employee) => ({
    value: `${employee.id}`,
    label: `${employee.user.firstname} ${employee.user.lastname}`,
  }));

  // Api for get Job Advertisement List

  async function jodAdvertisement() {
    const res = await ApiCalls.getAdvertisementList();
    if (res.success && res.code === 200) {
      dispatch(advertisementLists({ jobAdvertisement: res.data }));
    } else {
    }
  }

  // get status and source api

  useEffect(() => {
    getOptionsValues();
  }, []);

  async function getOptionsValues() {
    const res = await ApiCalls.getScreeningStatusAndSource();
    if (res.success && res.code == 200) {
      dispatch(optionValues({ screening: res.data }));
    } else {
    }
  }

  const screeningOptionValues = useSelector(
    (state) => state.screening.options.screening
  );

  function convertSource(so) {
    const sourceValue = screeningOptionValues?.source[so] || "";
    return `${sourceValue}`;
  }
  function convertStatus(st) {
    const statusValue = screeningOptionValues?.status[st] || "";
    return `${statusValue}`;
  }

  //get Api for screening list
  async function getScreeningList(queryParams) {
    const res = await ApiCalls.getScreening(queryParams);
    if (res.success && res.code == 200) {
      setData(res.data);
      dispatch(setScreeningLists({ screening: res.data }));
    } else {
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRecord((prevState) => ({ ...prevState, [name]: value }));

    if (name === "full_name") {
      setFullNameError("");
    } else if (name === "email") {
      setEmailError("");
    } else if (name === "experience") {
      setExperienceError("");
    } else if (name === "mobile") {
      setNumberError("");
    }
  };

  const [resume, setResume] = useState(null);

  const handleFileSelect = async (e) => {
    const file = e.target.files[0];
  let extension =  file.name.split('.').pop();
  if(extension == "pdf" || extension == "docx" ) {
     setResume(file);
    setCVError("");
  }else {
    alert("Only PDF or DOCX Are Allowed ")
  }
  };

  // Api for create Screening
  const [showAlert, setShowAlert] = useState(null);
  const [fullNameError, setFullNameError] = useState("");
  const [experienceError, setExperienceError] = useState("");
  const [jdError, setJDError] = useState("");
  const [cvError, setCVError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [sourceError, setSourceError] = useState("");
  const [statusError, setStatusError] = useState("");
  const [numberError, setNumberError] = useState("");

  const handleAddRecords = async () => {
    let hasError = false;

    if (newRecord.full_name === undefined || newRecord.full_name === "") {
      setFullNameError("This field may not be blank.");
      hasError = true;
    } else {
      setFullNameError("");
    }
    if (newRecord.experience === undefined || newRecord.experience === "") {
      setExperienceError("A valid number is required");
      hasError = true;
    } else {
      setExperienceError("");
    }
    if (newRecord.email === undefined || newRecord.email === "") {
      setEmailError("This field may not be blank.");
      hasError = true;
    } else {
      setEmailError("");
    }
    if (jd.position === undefined || jd.position === "") {
      setJDError("please select job advertisement");
      hasError = true;
    } else {
      setJDError("");
    }
    if (resume === null) {
      setCVError("please select file");
      hasError = true;
    } else {
      setCVError("");
    }
    if (newRecord.source === undefined || newRecord.source === "") {
      setSourceError("please select source");
      hasError = true;
    } else {
      setSourceError("");
    }
    if (newRecord.mobile === undefined || newRecord.mobile === "") {
      setNumberError("please enter mobile");
      hasError = true;
    } else {
      setNumberError("");
    }

    if (
      newRecord.id &&
      newRecord.full_name &&
      newRecord.mobile &&
      newRecord.experience &&
      newRecord.email &&
      newRecord.status &&
      newRecord.source &&
      newRecord.job_ad &&
      newRecord.cv
    ) {
      const newDataRow = createData(
        newRecord.id,
        newRecord.full_name,
        newRecord.mobile,
        newRecord.experience,
        newRecord.email,
        newRecord.status,
        newRecord.source,
        newRecord.job_ad,
        newRecord.cv
      );
      setRows([...rows, newDataRow]);
      setNewRecord({
        id: "",
        full_name: "",
        mobile: "",
        email: "",
        experience: "",
        status: "",
        source: "",
        job_ad: "",
        cv: "",
      });
      setIsAdding(false);
    }

    const formData = new FormData();

    formData.append("full_name", newRecord.full_name);
    formData.append("mobile", newRecord.mobile);
    formData.append("email", newRecord.email);
    formData.append("experience", newRecord.experience);
    formData.append("recruiter", newRecord.recruiter);
    formData.append("source", newRecord.source);
    formData.append("job_ad", jd.id);
    formData.append("cv", resume);

    if (!hasError) {
      const res = await ApiCalls.postScreening(formData, true, true);
      if (res.success && res.code == 201) {
      
        getScreeningList();
      } else if (res.success == false && res.code == 400) {
        const errors = res.data;
        if (errors.full_name) {
          alert(errors.full_name);
        } else if (errors.experience) {
          alert(errors.experience);
        } else if (errors.email) {
          alert(errors.email);
        } else if (errors.job_ad) {
          alert(errors.job_ad);
        } else if (errors.cv) {
          alert(errors.cv);
        } else if (errors.source) {
          alert(errors.source);
        } else if (errors.status) {
          alert(errors.status);
        } else {
        }
      } else {
      }
      setNewRecord({});
      setRecordsOpen(false);
    }
  };

  const alertContent = (name) => (
    <MDTypography variant="body2" color="white">
      {name}
    </MDTypography>
  );

  const handleEditClick = (row, actualIndex) => {
    setEditableRowIndex(actualIndex);
    setEditedData([...data]);
    setID(row.id);
  };

  const handleFieldChange = (event, fieldName) => {
    const updatedData = [...editedData];
    const editedRecord = { ...updatedData[editableRowIndex] };
    editedRecord[fieldName] = event.target.value;
    updatedData[editableRowIndex] = editedRecord;
    setEditedData(updatedData);
  };

  // Api for Update Screening
  const handleSaveClick = async (file) => {
    const editedRow = editedData.find((row) => row.id === id_);
    const formData = new FormData();
    formData.append("full_name", editedRow.full_name);
    formData.append("mobile", editedRow.mobile);
    formData.append("email", editedRow.email);
    formData.append("experience", editedRow.experience);
    formData.append("status", editedRow.status);
    formData.append("source", editedRow.source);
    formData.append("recruiter", editedRow.recruiter);
    if (jd.id) {
      formData.append("job_ad", jd.id);
    }
    if (resume) {
      formData.append("cv", resume);
    }
    const res = await ApiCalls.partialUpdateScreening(id_, formData, true);
    if (res.success && res.code == 200) {
      handleNameClose();
      setEditableRowIndex(-1);
      getScreeningList();
    } else {

    }
   
  };

  const handleCancelClick = () => {
    setEditableRowIndex(-1);
  };

  // Api for Delete Screening
  const handleDeleteClick = async (row) => {
    const recordId = row.id;
    const res = await ApiCalls.deleteScreening(recordId);

    if (res.success && res.code === 204) {
      getScreeningList();
    } else {
    }
  };

  const handleRecordsOpen = (index) => {
    setRecordsOpen(true);
  };
  const handleRecordsClose = () => {
    setRecordsOpen(false);
  };

  //Dialog for Candidate information
  const [currentData, setCurrentData] = useState({});
  const handleNameOpen = async (index, row) => {
    const scr_id = row.id;
    const res = await ApiCalls.screening(scr_id, row);
    if (res.success && res.code == 200) {
      setCurrentData(res.data);
    } else {
    }
    setNameOpen(true);
  };
  const handleNameClose = () => {
    setNameOpen(false);
  };

  // upload CV
  const [uploadRecordsOpen, setUploadRecordsOpen] = useState(false);
  const handleUploadRecordsOpen = (index) => {
    setUploadRecordsOpen(true);
    // setCurrentIndex(index);
  };
  const handleUploadRecordsClose = () => {
    setUploadRecordsOpen(false);
  };
  const handleUploadCV = async () => {
    const formData = new FormData();
    formData.append("cv", resume);
    formData.append("jd", jd.id);

    const res = await ApiCalls.uploadCV(formData, true, true);

    if (res.success && res.code == 201) {
      getScreeningList();
    } else {
    }
  };

  //Convert To Candidate

  const handleConversion = async (currentData) => {
    if (currentData.note != null && currentData.note != undefined) {
      const res = await ApiCalls.convertToCandidate(
        currentData.id,
        currentData
      );
      if (res.success && res.code == 200) {
        handleNameClose();
        getScreeningList();
      } else {
        handleNameClose();
      }
    } else {
      alert("Note is required")
    }
  };

  //Reject  Candidate

  const handleRejectCandidate = async (currentData) => {
    var formData = new FormData();

    formData.append("status", 4);
    const res = await ApiCalls.partialUpdateScreening(
      currentData.id,
      formData,
      true
    );
    if (res.success && res.code == 200) {
      handleNameClose();
    } else {
    }
  };
  //Autocomplete

  const handleAutocomplete = (value) => {
    const selectedAdvertisement = advertisements.find(
      (advertisement) => advertisement.position === value
    );
    if (selectedAdvertisement) {
      setJD({
        id: selectedAdvertisement.id,
        position: selectedAdvertisement.position,
      });
    }
    setJDError("");
  };

  useEffect(() => {
    setJD;
  }, [screenings]);

  //Pagination

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - screenings.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleEmpSearch = (query) => {
    if (searchBy == "Name") {
      getScreeningList(`?full_name_starts_with=${query}`);
    } else if (searchBy == "Phone") {
      getScreeningList(`?mobile__icontains=${query}`);
    } else if (searchBy == "Email") {
      getScreeningList(`?email__icontains=${query}`);
    } else {
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <MDBox
          width="100%"
          display="flex"
          flexDirection={{ xs: "column", lg: "row" }}
          justifyContent="flex-end"
          alignItems="center"
          py={1.5}
          px={1.5}
        >
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            color="text"
            // fontSize={size.sm}
            px={1.5}
          >
              <SearchBox
                width={350}
                placeholder={"Search Candidate"}
                onChange={(e) => {
                  handleEmpSearch(e);
                }}
                sort={   <Autocomplete
                options={["Name", "Phone", "Email"]}
                value={searchBy}
                onChange={(event, newValue) => {
                  setSearchBy(newValue);
                }}
                sx={{ width: "155px",paddingLeft:"5px", paddingRight: "5px", alignSelf:"end" }}
                renderInput={(params) => (
                  <TextField {...params} variant="standard"   InputProps={{...params.InputProps, disableUnderline: true}}
                  />
                )}
              />}
              />
           
          
            {permissions?.includes(modulePermissions?.create_permission) && (
              <MDButton
                variant="gradient"
                color="info"
               sx={{borderRadius:"20px"}}
                onClick={handleRecordsOpen}
              >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                Add
              </MDButton>
            )}
            <MDButton
              sx={{ marginLeft: "5px",borderRadius:"20px" }}
              variant="gradient"
              color="dark"
              onClick={handleUploadRecordsOpen}
            >
              Upload by CV
            </MDButton>
          </MDBox>
        </MDBox>
        <Card style={{ boxShadow: "0 8px 8px rgba(0, 0, 0, 0.50)" }}>
          <MDTypography variant="h5" p={2}>
            Screening
          </MDTypography>{" "}
          <TableContainer  components={Paper}>
            <Table aria-label="simple table" >
              <TableBody data-testid="tBody">
                <TableRow style={{}}>
                  {/* <TableCell variant="head" color="white" sx={{ color: "White" }}>
                  Sno
                </TableCell> */}
                  <TableCell variant="head" color="black">
                    Name
                  </TableCell>
                  <TableCell variant="head" sx={{ color: "black" }}>
                    Number
                  </TableCell>
                  <TableCell variant="head" sx={{ color: "black" }}>
                    Experience
                  </TableCell>
                  <TableCell variant="head" sx={{ color: "black" }}>
                    Email
                  </TableCell>
                  <TableCell variant="head" sx={{ color: "black" }}>
                    Source
                  </TableCell>
                  <TableCell variant="head" sx={{ color: "black" }}>
                    Status
                  </TableCell>
                  <TableCell variant="head" sx={{ color: "black" }}>
                    Recruiter
                  </TableCell>
                  <TableCell variant="head" sx={{ color: "black" }}>
                    Job ID
                  </TableCell>
                  <TableCell variant="head" sx={{ color: "black" }}>
                    Resume
                  </TableCell>
                  {permissions?.includes(
                    modulePermissions?.delete_permission
                  ) && (
                    <TableCell variant="head" sx={{ color: "black" }}>
                      Actions
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>

              <TableBody>
                {(rowsPerPage > 0
                  ? screenings.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : screenings
                ).map((row, index) => {
                  const actualIndex = index + page * rowsPerPage;
                  return (
                    <TableRow key={row.id}>
                      {/* <TableCell>{actualIndex + 1}</TableCell> */}
                      <TableCell
                        sx={{ cursor: "pointer" }}
                        style={{ fontWeight: "bold" }}
                        onClick={() => {
                          handleNameOpen(index, row);
                          handleEditClick(row, actualIndex);
                        }}
                      >
                        {row.full_name}
                      </TableCell>
                      <TableCell>{row.mobile}</TableCell>
                      <TableCell>{row.experience}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{convertSource(row.source)}</TableCell>
                      <TableCell>{convertStatus(row.status)}</TableCell>
                      <TableCell>
                        {getEmployeeNameById(row.recruiter)}
                      </TableCell>
                      <TableCell>{getPositionLabel(row.job_ad)}</TableCell>
                      {row?.cv ? (
                        <TableCell>
                          <Tooltip title="View">
                            <a
                              aria-label="view"
                              href={row.cv}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {/* {row.cv.substring(0, 15)}... */}
                              <h5 style={{ color: "black" }}>
                                <Icon
                                  sx={{
                                    marginRight: "3px",
                                    marginBottom: "-2px",
                                  }}
                                >
                                  <PictureAsPdf />
                                </Icon>
                                Resume
                              </h5>
                            </a>
                          </Tooltip>
                        </TableCell>
                      ) : (
                        <TableCell>
                          <h5>Not found</h5>
                        </TableCell>
                      )}
                      {permissions?.includes(
                        modulePermissions?.delete_permission
                      ) && (
                        <TableCell>
                          {permissions?.includes(
                            modulePermissions?.delete_permission
                          ) && (
                            <Tooltip title="Delete">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  handleDeleteClick(row);
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          )}
                          {/* {permissions?.includes("update") && (
                          <Tooltip title="Update">
                            <IconButton
                              size="small"
                              onClick={() => {
                                handleEditClick(row, actualIndex);
                              }}
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                        )} */}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={12}
                  count={screenings.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  slotProps={{
                    select: {
                      "aria-label": "rows per page",
                    },
                    actions: {
                      showFirstButton: true,
                      showLastButton: true,
                    },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableFooter>
            </Table>
          </TableContainer>
        </Card>
      </MDBox>
      <br />

      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "aliceBlue",
            boxShadow: "none",
            width: "700px",
            height: "500px",
            maxWidth: "none",
            padding: "20px",
            border: "1px solid black",
          },
        }}
        open={recordsOpen}
        onClose={handleRecordsClose}
      >
        {showAlert && (
          <MDAlert
            color="primary"
            dismissible
            onClick={() => {
              setShowAlert(null);
            }}
          >
            {alertContent(showAlert)}
          </MDAlert>
        )}
        <DialogTitle>Add Candiate for Screening</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                required
                margin="dense"
                id="name"
                label="Name"
                type="text"
                fullWidth
                variant="standard"
                name="full_name"
                value={newRecord.full_name}
                onChange={handleInputChange}
                error={!!fullNameError}
                helperText={fullNameError}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="number"
                label="Number"
                type="number"
                fullWidth
                variant="standard"
                name="mobile"
                value={newRecord.mobile}
                onChange={handleInputChange}
                error={!!numberError}
                helperText={numberError}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                margin="dense"
                id="email"
                label="Email"
                type="text"
                fullWidth
                variant="standard"
                name="email"
                value={newRecord.email}
                onChange={handleInputChange}
                error={!!emailError}
                helperText={emailError}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                margin="dense"
                id="experience"
                label="Experience"
                type="number"
                fullWidth
                variant="standard"
                name="experience"
                value={newRecord.experience}
                onChange={handleInputChange}
                error={!!experienceError}
                helperText={experienceError}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={
                  screeningOptionValues?.source
                    ? Object.entries(screeningOptionValues?.source)?.map(
                        ([key, value]) => ({
                          value: key,
                          label: value,
                        })
                      )
                    : []
                }
                value={convertSource(newRecord?.source)}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setNewRecord((state) => ({
                      ...state,
                      source: newValue?.value,
                    }));
                  }
                  setSourceError("");
                }}
                // sx={{ marginTop: "-12px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Source"
                    variant="standard"
                    fullWidth
                    error={!!sourceError}
                    helperText={sourceError}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                id="recruiter"
                options={employeesList}
                // value={newRecord?.recruiter || ""}

                getOptionLabel={(option) =>
                  Array.isArray(option)
                    ? option.map(
                        (item) =>
                          item?.user?.firstname + " " + item?.user?.lastname
                      )
                    : option?.user?.firstname + " " + option?.user?.lastname
                }
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    name="recruiter"
                    label="Recruiter"
                    variant="standard"
                  />
                )}
                onChange={(event, newValue) => {
                  if (newValue) {
                    const selectedValues = Array.isArray(newValue)
                      ? newValue.map((v) => v.id)
                      : newValue?.id;

                    setNewRecord((state) => ({
                      ...state,
                      recruiter: selectedValues,
                    }));
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                name="jd"
                options={advertisements.map(
                  (advertisement) => advertisement.position
                )}
                value={jd.position}
                onChange={(event, value) => handleAutocomplete(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    id="autocomplete"
                    label="Job Openings"
                    type="text"
                    variant="standard"
                    error={!!jdError}
                    helperText={jdError}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              {/* <label>
                Resume<abbr>*</abbr>
              </label>
              <br />
              <input
                type="file"
                onChange={handleFileSelect}
                error={!!cvError}
                helperText={cvError}
              /> */}
              <TextField
                InputLabelProps={{ shrink: true }}
                error={!!cvError}
                helperText={cvError}
                label="Resume"
                required
                variant="standard"
                fullWidth
                type="file"

                onChange={handleFileSelect}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            color="dark"
            onClick={handleRecordsClose}
          >
            Cancel
          </MDButton>
          <MDButton variant="gradient" color="dark" onClick={handleAddRecords}>
            Add
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "aliceBlue",
            borderRadius: "8px",
            width: "700px",
            height: "500px",
            maxWidth: "none",
            padding: "20px",
          },
        }}
        open={nameOpen}
        onClose={handleNameClose}
      >
        <DialogTitle>Screening Information</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} alignItems={"flex-end"}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="standard-helperText"
                label="Full Name"
                defaultValue={currentData.full_name}
                variant="standard"
                sx={{
                  width: "100%",
                }}
                onChange={(event) => {
                  handleFieldChange(event, "full_name");
                }}
                // inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="standard-helperText"
                label="Mobile"
                defaultValue={currentData.mobile}
                variant="standard"
                onChange={(event) => handleFieldChange(event, "mobile")}
                // inputProps={{ readOnly: true }}
                sx={{
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="standard-helperText"
                label="Experience"
                defaultValue={currentData.experience}
                variant="standard"
                // inputProps={{ readOnly: true }}
                onChange={(event) => handleFieldChange(event, "experience")}
                sx={{
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="standard-helperText"
                label="Email"
                defaultValue={currentData.email}
                variant="standard"
                // inputProps={{ readOnly: true }}
                onChange={(event) => handleFieldChange(event, "email")}
                sx={{
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={
                  screeningOptionValues?.source
                    ? Object.entries(screeningOptionValues?.source).map(
                        ([key, value]) => ({ value: key, label: value })
                      )
                    : []
                }
                value={convertSource(editedData[editableRowIndex]?.source)}
                onChange={(event, newValue) => {
                  handleFieldChange(
                    { target: { value: newValue?.value } },
                    "source"
                  );
                }}
                // sx={{ marginTop: "-15px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Source"
                    variant="standard"
                    fullWidth
                    error={!!sourceError}
                    helperText={sourceError}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={
                  screeningOptionValues?.status
                    ? Object.entries(screeningOptionValues?.status).map(
                        ([key, value]) => ({ value: key, label: value })
                      )
                    : []
                }
                value={convertStatus(editedData[editableRowIndex]?.status)}
                onChange={(event, newValue) => {
                  handleFieldChange(
                    { target: { value: newValue?.value } },
                    "status"
                  );
                }}
                // sx={{ marginTop: "-15px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    variant="standard"
                    fullWidth
                    error={!!statusError}
                    helperText={statusError}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="recruiter"
                options={employeeOptions}
                getOptionLabel={(option) => option.label}
                value={
                  editedData[editableRowIndex]?.recruiter
                    ? employeeOptions.find(
                        (option) =>
                          option.value ==
                          editedData[editableRowIndex]?.recruiter
                      )
                    : null
                }
                fullWidth
                // sx={{ marginTop: "-15px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    name="recruiter"
                    label="Recruiter"
                    variant="standard"
                    sx={{ width: "100%" }}
                  />
                )}
                onChange={(event, newValue) => {
                  if (newValue) {
                    const selectedValues = Array.isArray(newValue)
                      ? newValue.map((v) => v.value)
                      : newValue.value;
                    handleFieldChange(
                      { target: { value: selectedValues } },
                      "recruiter"
                    );
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                name="jd"
                options={advertisements.map(
                  (advertisement) => advertisement.position
                )}
                value={getPositionLabel(editedData[editableRowIndex]?.job_ad)}
                fullWidth
                // value={jd.position }
                onChange={(event, value) => handleAutocomplete(value)}
                // sx={{ marginTop: "-15px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="autocomplete"
                    label="Job Openings"
                    type="text"
                    variant="standard"
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <TextField
                    id="standard-helperText"
                    label="Blacklist"
                    defaultValue={currentData.blacklist}
                    variant="standard"
                    inputProps={{ readOnly: true }}
                    fullwidth
                  /> */}
              <MDBox display="flex" alignItems="center">
                <MDTypography sx={{ fontSize: "14px" }} fontWeight="regular">
                  Blacklist
                </MDTypography>
                <Checkbox></Checkbox>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6} alignItems="center">
              <MDBox display="flex" alignItems="center">
                <MDTypography sx={{ fontSize: "14px" }} fontWeight="regular">
                  Suspicious
                </MDTypography>
                <Checkbox></Checkbox>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDBox display="flex" alignItems="center">
                <MDTypography sx={{ fontSize: "14px" }} fontWeight="regular">
                  Blocked by NASCOM
                </MDTypography>
                <Checkbox></Checkbox>
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="standard-helperText"
                label="Note*"
                defaultValue={currentData.note}
                variant="standard"
                rows={4}
                sx={{
                  width: "100%",
                }}
                onChange={(event) => {
                  setCurrentData((state) => ({
                    ...state,
                    note: event.target.value,
                  }));
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <MDButton variant="gradient" color="dark" onClick={handleNameClose}>
            Cancel
          </MDButton>
          {(permissions.includes(modulePermissions?.update_permission) ) && (
            <MDButton variant="gradient" color="dark" onClick={handleSaveClick}>
              Update
            </MDButton>
          )}
          {(currentData.status != "3" && (permissions.includes(modulePermissions?.update_permission) ) ) && (
            <>
              <MDButton
                variant="gradient"
                color="dark"
                onClick={() => {
                  handleConversion(currentData);
                }}
              >
                Convert to Candidate
              </MDButton>
            {(permissions.includes(modulePermissions?.update_permission) ) &&(  <MDButton
                variant="gradient"
                color="dark"
                onClick={() => {
                  handleRejectCandidate(currentData);
                  // handleConversion(currentData);
                }}
              >
                Reject Candidate
              </MDButton>)}
            </>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "linen",
            boxShadow: "none",
            width: "500px",
            height: "400px",
            maxWidth: "none",
          },
        }}
        open={uploadRecordsOpen}
        onClose={handleUploadRecordsClose}
      >
        <DialogTitle
          style={{
            padding: "20px",
            borderBottom: "20px solid ",
            background: "#EC407A",
            color: "lavenderblush",
          }}
        >
          <MDTypography sx={{ color: "white" }}> Upload by CV</MDTypography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Autocomplete
                name="jd"
                options={advertisements.map(
                  (advertisement) => advertisement.position
                )}
                value={jd.position}
                onChange={(event, value) => handleAutocomplete(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    id="autocomplete"
                    label="Job Openings"
                    type="text"
                    variant="standard"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <label>Resume</label>
              <br />
              <input type="file" onChange={handleFileSelect} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleUploadCV}>Add </MDButton>
          <MDButton onClick={handleUploadRecordsClose}>Cancel </MDButton>
        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}
export default Screening;
