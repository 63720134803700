import moment from "moment";

export const getTimeFromDateTime = (dateTime) => {
  var date = new Date(dateTime);
  return date.toLocaleString("en-US", {
    hour: "numeric",
    hour12: true,
    minute: "2-digit",
  });
};
export const getTimeFromDateTimeIn24Hours = (dateTime) => {
  var date = new Date(dateTime);
  return date.toLocaleString();
};

export const getDateFromDateTime = (dateTime) => {
  var date = new Date(dateTime);

  return (
    date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear()
  );
};
export const getNextDateFromCurrentDate = (nextDateNumber) => {
  return moment(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + nextDateNumber
    )
  ).format("YYYY-MM-DD");
};
export const getNextDate = (nextDateNumber, date, month, year) => {
  return moment(new Date(year, month, date + nextDateNumber)).format(
    "YYYY-MM-DD"
  );
};
export const getNextDay = (nextDateNumber, date, month, year) => {
  return new Date(year, month, date + nextDateNumber).toLocaleDateString(
    "en-US",
    { weekday: "long" }
  );
};
export const getNextDayFromCurrentDate = (nextDateNumber) => {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() + nextDateNumber
  ).toLocaleDateString("en-US", { weekday: "long" });
};
export const addTime = (time1, time2) => {
  time1 = time1.split(/[Hrs Min]+/);
  time2 = time2.split(/[Hrs Min]+/);
  return parseFloat(
    parseInt(time1[0]) +
      parseInt(time2[0]) +
      parseFloat(time1[1] / 60) +
      parseFloat(time2[1] / 60)
  ).toFixed(2);
};
export const timeStringToHour = (time) => {
  if (time.includes("Hrs")) {
    var timeList = time?.split(/[Hrs Min]+/);
    time =
      timeList?.length >= 2
        ? parseFloat(
            (parseInt(timeList[0]) + parseFloat(timeList[1] / 60)).toFixed(2)
          )
        : 0.0;
  }else {
    time = 0.0;
  }

  return time;
};
