import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components

// EmployeeView page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import { useEffect, useState } from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Contracts from "./ContractGeneral";
import Contractsline from "./Contractlines";
import ApiCalls from "services/flick_erp_api_call";
import MDTypography from "components/MDTypography";
import colors from "assets/theme/base/colors";
import { useForm} from 'react-hook-form'; 
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

function ContractView({ data }) {
  const { register, formState: { errors }, setValue , clearErrors  } = useForm();
  const [tabComponents, setTabComponents] = useState([]);
  const [employeeContract, setEmployeeContract] = useState({});
  const [addContractDialog, setAddContractDialog] = useState(false);
  const [contractPayload, setContractPayload] = useState({});
  const [bankDetails, setBankDetails] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    getEmployeeContract();
    getEmployeeBankDetials();
  }, []);
  const loggedUser = JSON.parse(localStorage.getItem("user"));
  async function getEmployeeContract() {
    var res = await ApiCalls.getEmployeeContract(
      `?employee_id=${data.id}&is_active=true`
    );
    if (res.success && res.code == 200) {
      if (res?.data[0]) {
        setEmployeeContract(res?.data[0]);
      }
    }
  }
  async function getEmployeeBankDetials() {
    var res = await ApiCalls.getEmployeeBankDetails(
      `?employee=${data.id}&is_active=true`
    );
    if (res.success && res.code == 200) {
      setBankDetails(res?.data);
    }
  }

  const validation = (payload) =>{
    const errors = {};
  
    Object.keys(payload).forEach((field) => {
      if (payload[field] === null || payload[field] === undefined || payload[field] === "") {
        errors[field] = "This field is required";
      }
    });

    if (payload.salary_details && payload.salary_details.length > 0) {
      payload.salary_details.forEach((detail, index) => {
        if (!detail.salary_head) {
          errors[`salary_head-${index}`] = "Salary Head is required";
        }
        if (!detail.amount || isNaN(parseFloat(detail.amount)) || parseFloat(detail.amount) === 0) {
          errors[`amount-${index}`] = "Valid Amount is required";
        }
      });
    }
  
    Object.keys(errors).forEach((field) => {
      setValue(field, '', { shouldValidate: true });
    });
  
    if (Object.keys(errors).length > 0) {

      setValidationErrors(errors);
      return false;
    } else {
      return true;
    }
  }

  async function updateContract() {
    var payload = {
      bank: employeeContract?.bank?.id,
      end_date: employeeContract.end_date,
      start_date: employeeContract.start_date,
      contract_type: employeeContract.contract_type,
      is_active: employeeContract.is_active,
      terms: employeeContract.terms,
      ctc: employeeContract.ctc,
      salary_details: employeeContract.salary_details.map((e) => ({
        ...e,
        salary_head: e.salary_head.id,
      })),
    };

    const isValid = validation(payload);
    if(isValid){
      setValidationErrors({});
      var res = await ApiCalls.patchEmployeeContract(
        employeeContract.id,
        payload,
        false
      );
      if (res.success && res.code == 200) {
        getEmployeeContract();
      }
    }

   
  }
  useEffect(() => {
    setTabComponents([
      {
        label: "General",
        component: (
          <Contracts
            isContractCreate={addContractDialog}
            contract={employeeContract}
            setEmployeeContract={setEmployeeContract}
            bankDetails={bankDetails}
            validationErrors={validationErrors} 
      setValidationErrors={setValidationErrors}
          />
        ),
      },
      {
        label: "Contract Lines",
        component: (
          <Contractsline
            isContractCreate={addContractDialog}
            contract={employeeContract}
            setEmployeeContract={setEmployeeContract}
            bankDetails={bankDetails}
            validationErrors={validationErrors} 
            setValidationErrors={setValidationErrors}
          />
        ),
      },
    ]);
  }, [employeeContract, bankDetails , validationErrors]);

  async function createEmployeeContract() {

    var payload = {
      bank: employeeContract.bank.id,
      salary_details: employeeContract?.salary_details?.map((e) => ({
        ...e,
        salary_head: e.salary_head.id,
      })) ?? [],
      terms: employeeContract.terms,
      start_date: employeeContract.start_date,
      end_date: employeeContract.end_date,
      contract_type: employeeContract.contract_type,
      ctc: employeeContract.ctc,
      is_active: employeeContract.is_active,
      org: loggedUser.org_id,
      employee: data.id,
    };

    const isValid = validation(payload);
    if(isValid){
    var res = await ApiCalls.createEmployeeContract(payload, false);
    if (res.success && res.code == 201) {
      getEmployeeContract();
    }
  }
  }

  return (
    <>
      <MDBox sx={{ padding: "10px" }}>
        {JSON.stringify(employeeContract) === "{}" ? (
          <MDBox
            style={{
              padding: "10px",
              height: "100px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid container spacing={1} justifyContent="center">
              <Grid item>
                <MDTypography variant="h5">No Contract Found!</MDTypography>
              </Grid>
              <Grid item>
                <MDTypography
                  variant="h5"
                  sx={{
                    color: colors.info.main,
                    cursor: "pointer",
                    "&:hover": { color: colors.info.focus },
                  }}
                  onClick={() => {
                    setEmployeeContract({ request_type: "create" });
                  }}
                >
                  Add New
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
        ) : (
          <>
            {" "}
            <BaseLayout tabComponents={tabComponents}></BaseLayout>
            <MDBox align="center">
              <MDButton
                variant="gradient"
                color="info"
                onClick={() => {

                  employeeContract?.request_type == "create"
                    ? createEmployeeContract()
                    : updateContract();
                }}
              >
                Submit
              </MDButton>
            </MDBox>
          </>
        )}
        <Dialog
          PaperProps={{
            style: {
              backgroundColor: "aliceBlue",
              boxShadow: "none",
              width: "80%",
              // height: "500px",
              maxWidth: "none",
              padding: "20px",
            },
          }}
          open={addContractDialog}
          onClose={() => {
            setAddContractDialog(false);
          }}
        >
          <DialogTitle>Add Contract</DialogTitle>
          <DialogContent>
            <Contracts
              isContractCreate={addContractDialog}
              contract={employeeContract}
              setEmployeeContract={setEmployeeContract}
              bankDetails={bankDetails}
            />

            <Contractsline
              isContractCreate={addContractDialog}
              contract={employeeContract}
              setEmployeeContract={setEmployeeContract}
              bankDetails={bankDetails}
            />
          </DialogContent>
          <DialogActions>
            <MDButton
              onClick={() => {
                createEmployeeContract();
              }}
            >
              Create Contract
            </MDButton>
            <MDButton onClick={() => {}}>Cancel</MDButton>
          </DialogActions>
        </Dialog>
      </MDBox>
    </>
  );
}

export default ContractView;
