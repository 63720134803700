// /**
// =========================================================
// * Material Dashboard 2 PRO React - v2.1.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
// * Copyright 2022 Creative Tim (https://www.creative-tim.com)

// Coded by www.creative-tim.com

//  =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
// */

// import { useState, useEffect } from "react";

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MDBox from "components/MDBox";
import breakpoints from "assets/theme/base/breakpoints";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useNavigate } from "react-router-dom";

import { Person, Lock, Badge, Comment } from "@mui/icons-material";



function BaseLayout({ stickyNavbar, tabComponents }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, []);

  const navigate = useNavigate();

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <MDBox>
      {/* <DashboardNavbar absolute={!stickyNavbar} isMini /> */}
      <MDBox mt={3}>
        <Grid container>
          <Grid item xs={12} sm={12} lg={12}>
            <AppBar position="static" sx={{padding:"10px"}}>
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue} >
                {tabComponents.filter((e)=> (e?.visible == undefined ? true : e?.visible ) )?.map(({ label,icon,visible }, index) =>
                // visible != undefined && visible == false? (<></>) :
                
                  <Tab key={label} icon={icon} label={label}  />
                )}
              </Tabs>
            </AppBar><br />
          </Grid>
        </Grid>
        {tabComponents.filter((e)=> (e?.visible == undefined ? true : e?.visible) )[tabValue]?.component}
      </MDBox>
      {/* <Footer /> */}
    </MDBox>
  );
}

BaseLayout.defaultProps = {
  stickyNavbar: false,
};

BaseLayout.propTypes = {
  stickyNavbar: PropTypes.bool,
};

export default BaseLayout;



