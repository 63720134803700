import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import {
  Autocomplete,
  Card,
  Grid,
  Icon,
  Input,
  Tooltip,
  Typography,
} from "@mui/material";
import { TextareaAutosize } from "@mui/base";

// import {Table, TableBody, TableContainer, TableRow, Paper, TableFooter, TablePagination} from '@mui/material';
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Footer from "examples/Footer";
import typography from "assets/theme/base/typography";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
// icons
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Add from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";

//Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ApiCalls from "services/flick_erp_api_call";
import MDInput from "components/MDInput";
import { Cancel, Check, PictureAsPdf } from "@mui/icons-material";
import * as moment from "moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { interviewsList } from "./interviewsSlice";
import { setToastOpen } from "components/Toast/toastAlertSlice";
import colors from "assets/theme/base/colors";
import MDEditor from "components/MDEditor";
import { SearchBox } from "components/SearchBox";

function Interviews({ permissions, modulePermissions }) {
  const { size } = typography;
  // const [inputs, setInput] = useState();
  var newIndex;
  //useState hooks

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [editableRowIndex, setEditableRowIndex] = useState(null);
  const [feedbackDialog, setFeedbackDialog] = useState(false);
  const [editedInterviewData, setEditedInterviewData] = useState({});
  const [recordsOpen, setRecordsOpen] = React.useState(false);
  const [employees, setEmployees] = useState([]);
  const [interviewList, setInterviewList] = useState([]);

  const [sortStatus, setSortStatus] = useState({
    value: 1,
    label: "Scheduled",
  });
  const [searchBy, setSearchBy] = useState("Name");

  const statuses = {
    1: "Scheduled",
    2: "Selected",
    3: "Rejected",
    4: "Rescheduled",
    5: "Lost",
  };
  const dispatch = useDispatch();

  useEffect(() => {
    sortStatus == undefined
      ? getInterviewsList()
      : getInterviewsList(`?status=${sortStatus.value}`);
  }, [sortStatus]);
  useEffect(() => {
    getEmployee();
  }, []);

  async function getEmployee() {
    const user = JSON.parse(localStorage.getItem("user"));
    var res = await ApiCalls.getEmployeesList();
    if (res.success && res.code === 200) {
      setEmployees(res.data);
    } else {
    }
  }

  const user = JSON.parse(localStorage.getItem("user"));
  const baseURL = "http://api.flickerp.com/";

  // Api for get Interviews  List
  async function getInterviewsList(queryParams) {
    const res = await ApiCalls.getInterviewsList(queryParams);
    if (res.success && res.code === 200) {
      dispatch(interviewsList({ interviews: res.data }));
      setInterviewList(res.data);
    } else {
    }
  }

  // update interview record

  const handleInterviewSaveClick = async () => {
    var data = {
      rating: editedInterviewData.rating,
      evaluated_exp: editedInterviewData.evaluated_exp,
      feedback: editedInterviewData.feedback,
      status: editedInterviewData.status,
    };
    const res = await ApiCalls.patchInterview(
      data,
      editedInterviewData.id,
      false
    );

    if (res.success && res.code == 200) {
      setRecordsOpen(false);
    } else {
    }
    setRecordsOpen(false);
    getInterviewsList(`?status=${sortStatus.value}`);
    setEditedInterviewData({});
    setEditableRowIndex(null);
  };

  const handleEditClick = (row, actualIndex) => {
    setEditableRowIndex(actualIndex);
    setEditedInterviewData(row);
  };

  const handleCancelClick = () => {
    setEditableRowIndex(null);
  };

  // Delete Interview Api

  const handleDeleteClick = async (row) => {
    const res = await ApiCalls.deleteInterview(row.id);
    if (res.success && res.code === 204) {
    } else {
    }
    getInterviewsList(`?status=${sortStatus.value}`);
  };

  const handleSearch = (query) => {
    setInterviewList(
      interviews.filter((e) => e.candidate_name.toLowerCase().startsWith(query))
    );
  };

  //Pagination

  const interviews = useSelector((state) => state.interviews.interviews);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // const interviews = useSelector((state)=> state.interviews.interviews);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox>
        <MDBox
          width="100%"
          display="flex"
          flexDirection={{ xs: "column", lg: "row" }}
          justifyContent="flex-end"
          alignItems="center"
          py={1.5}
          px={1.5}
        >
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            color="text"
            fontSize={size.sm}
            px={1.5}
          >
            <MDBox pl={1} pr={1} display="flex" alignItems="end">
              <SearchBox
                width={350}
                placeholder={"Search Candidate"}
                onChange={(e) => {
                  handleSearch(e);
                }}
                sort={  <Autocomplete
                  value={sortStatus}
                  options={Object.entries(statuses).map(([key, value]) => ({
                    value: key,
                    label: value,
                  }))}
                  onChange={(event, newValue) => {
                    setSortStatus(newValue ?? undefined);
                  }}
               
                  sx={{ width: "210px",paddingLeft:"5px", paddingRight: "5px" }}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard"   InputProps={{...params.InputProps, disableUnderline: true}}
                    />
                  )}
                />}
              />

           
            </MDBox>
          </MDBox>
        </MDBox>
        <Card style={{ boxShadow: "0 8px 8px rgba(0, 0, 0, 0.50)" }}>
          <MDTypography variant="h5" p={2}>
            Interviews
          </MDTypography>
          <TableContainer components={Paper}>
            <Table aria-label="simple table">
              <TableBody data-testid="tBody">
                <TableRow style={{}}>
                  {/* <TableCell variant="head" color="white" sx={{ color: "White" }}>
                  Sno
                </TableCell> */}
                  <TableCell
                    variant="head"
                    color="black"
                    sx={{ color: "black" }}
                  >
                    Candidate Name
                  </TableCell>
                  <TableCell
                    variant="head"
                    color="black"
                    sx={{ color: "black" }}
                  >
                    Interviewer
                  </TableCell>
                  <TableCell
                    variant="head"
                    color="black"
                    sx={{ color: "black" }}
                  >
                    Date
                  </TableCell>
                  <TableCell variant="head" sx={{ color: "black" }}>
                    Time
                  </TableCell>
                  <TableCell variant="head" sx={{ color: "black" }}>
                    Status
                  </TableCell>
                  <TableCell variant="head" sx={{ color: "black" }}>
                    Rating
                  </TableCell>
                  <TableCell variant="head" sx={{ color: "black" }}>
                    Experience
                  </TableCell>
                  <TableCell variant="head" sx={{ color: "black" }}>
                    Feedback
                  </TableCell>
                  <TableCell variant="head" sx={{ color: "black" }}>
                    Resume
                  </TableCell>
                  {permissions?.includes(
                    modulePermissions?.delete_permission
                  ) && (
                    <TableCell variant="head" sx={{ color: "black" }}>
                      Action
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
              <TableBody>
                {(rowsPerPage > 0
                  ? interviewList.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : interviewList
                )?.map((row, index) => {
                  const actualIndex = index + page * rowsPerPage;
                  newIndex = actualIndex;
                  return (
                    <TableRow key={row.id}>
                      {/* <TableCell>{actualIndex + 1}</TableCell> */}
                      <TableCell
                        sx={{ cursor: "pointer" }}
                        style={{ fontWeight: "bold" }}
                        onClick={() => {
                          setRecordsOpen(true);

                          handleEditClick(row, actualIndex);
                        }}
                      >
                        {row.candidate_name}
                      </TableCell>
                      <TableCell>
                        {
                          employees?.find((e) => e?.id == row?.interviewer)
                            ?.user?.firstname
                        }
                      </TableCell>
                      <TableCell>
                        {moment(row.datetime).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell>
                        {moment(row.datetime).format("hh:mm A")}
                      </TableCell>
                      <TableCell>{statuses[row.status]}</TableCell>
                      <TableCell>{row.rating}</TableCell>
                      <TableCell>{row.evaluated_exp}</TableCell>
                      <TableCell>
                        <TextField
                          variant="standard"
                          disabled
                          InputProps={{
                            disableUnderline: true,
                          }}
                          value={row.feedback}
                          sx={{}}
                        ></TextField>
                      </TableCell>
                      {row?.cv ? (
                        <TableCell>
                          <Tooltip title="Open">
                            <a
                              aria-label="open"
                              href={`${baseURL}${row.cv}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {/* {row.cv.substring(0, 15)}... */}
                              <h5 style={{ color: "black" }}>
                                <Icon
                                  sx={{
                                    marginRight: "3px",
                                    marginBottom: "-2px",
                                  }}
                                >
                                  <PictureAsPdf />
                                </Icon>
                                Resume
                              </h5>
                            </a>
                          </Tooltip>
                        </TableCell>
                      ) : (
                        <TableCell>
                          <h5>Not found</h5>
                        </TableCell>
                      )}
                      {permissions?.includes(
                        modulePermissions?.delete_permission
                      ) && (
                        <TableCell>
                          <Tooltip title="Delete">
                            <IconButton
                              size="small"
                              aria-label="delete"
                              onClick={() =>
                                handleDeleteClick(row, actualIndex)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={10}
                  count={interviewList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  slotProps={{
                    select: {
                      "aria-label": "rows per page",
                    },
                    actions: {
                      showFirstButton: true,
                      showLastButton: true,
                    },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableFooter>
            </Table>
          </TableContainer>
          {/* <br /> */}
        </Card>
      </MDBox>

      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "#f8f8f8",
            boxShadow: "none",
            width: "600px",
            height: "400px",
            maxWidth: "none",
            borderRadius: "10px",
          },
        }}
        open={feedbackDialog}
        onClose={() => setFeedbackDialog(false)}
      >
        <React.Fragment>
          <DialogTitle
            style={{
              padding: "20px",
              borderBottom: "1px solid #ccc",
              background: "deepskyblue",
              color: "white",
            }}
          >
            <Typography variant="h5" style={{ color: "aliceblue" }}>
              Feedback
            </Typography>
          </DialogTitle>
          <DialogContent style={{ padding: "30px" }}>
            <TextField
              fullWidth
              placeholder="Feedback"
              value={editedInterviewData.feedback}
              multiline
              rows={6}
              maxRows={10}
              onChange={(event) => {
                setEditedInterviewData((state) => ({
                  ...state,
                  feedback: event.target.value,
                }));
              }}
            />
          </DialogContent>
          <DialogActions>
            <MDButton
              variant="gradient"
              color="dark"
              onClick={() => {
                setFeedbackDialog(false);
              }}
            >
              Done
            </MDButton>
          </DialogActions>
        </React.Fragment>
      </Dialog>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "aliceBlue",
            boxShadow: "none",
            width: "700px",
            height: "500px",
            maxWidth: "none",
            padding: "20px",
          },
        }}
        open={recordsOpen}
        onClose={() => {
          setRecordsOpen(false);
          setEditedInterviewData({});
        }}
      >
        {/* {showAlert && (
          <MDAlert
            color="primary"
            dismissible
            onClick={() => {
              setShowAlert(null);
            }}
          >
            {alertContent(showAlert)}
          </MDAlert>
        )} */}
        <DialogTitle>Interview Information</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <MDInput
                fullWidth
                label="Name"
                variant="standard"
                value={editedInterviewData?.candidate_name}
                inputProps={{ readOnly: "true" }}
              ></MDInput>
            </Grid>
            <Grid item xs={6}>
              <MDInput
                fullWidth
                variant="standard"
                label="Experience"
                type="number"
                value={editedInterviewData.evaluated_exp}
                onChange={() => {
                  setEditedInterviewData((state) => ({
                    ...state,
                    evaluated_exp: event.target.value,
                  }));
                }}
              ></MDInput>
            </Grid>
            <Grid item xs={6}>
              <MDInput
                fullWidth
                variant="standard"
                label="Date"
                value={moment(editedInterviewData.date).format("YYYY-MM-DD")}
              ></MDInput>
            </Grid>
            <Grid item xs={6}>
              <MDInput
                fullWidth
                variant="standard"
                label="Time"
                value={moment(editedInterviewData.date).format("hh:mm A")}
              ></MDInput>
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={Object.entries(statuses).map(([key, value]) => ({
                  value: key,
                  label: value,
                }))}
                value={statuses[editedInterviewData?.status]}
                onChange={(event, newValue) => {
                  setEditedInterviewData((state) => ({
                    ...state,
                    status: newValue?.value,
                  }));
                }}
                fullWidth
                sx={{}}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    variant="standard"
                    fullWidth
                    // error={!!sourceError}
                    // helperText={sourceError}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} alignSelf={"end"}>
              <Tooltip title="Open">
                <a
                  aria-label="open"
                  href={`${baseURL}${editedInterviewData.cv}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {/* {row.cv.substring(0, 15)}... */}
                  <h5 style={{ color: "black" }}>
                    <Icon
                      sx={{
                        marginRight: "3px",
                        marginBottom: "-2px",
                      }}
                    >
                      <PictureAsPdf />
                    </Icon>
                    Resume
                  </h5>
                </a>
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <MDInput
                fullWidth
                variant="standard"
                type="number"
                label="Rating"
                value={editedInterviewData.rating}
                onChange={(event) => {
                  setEditedInterviewData((state) => ({
                    ...state,
                    rating: event.target.value,
                  }));
                }}
              ></MDInput>
            </Grid>
            <Grid item xs={6}>
              <MDEditor
                value={editedInterviewData.feedback || ""}
                onChange={(val) => {
                  setEditedInterviewData((state) => ({
                    ...state,
                    feedback: val,
                  }));
                }}
              />
              {/* <MDInput
                fullWidth
                label="Feedback"
                variant="standard"
                value={editedInterviewData.feedback}
                onChange={() => {
                  setEditedInterviewData((state) => ({
                    ...state,
                    feedback: event.target.value,
                  }));
                }}
                InputProps={{
                  inputComponent: TextareaAutosize,
                  rows: 3,
                }}
              ></MDInput> */}
              {/* <TextareaAutosize
              style={{
                background: transparent
              }}
              ></TextareaAutosize> */}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            color="dark"
            onClick={() => {
              setRecordsOpen(false);
              setEditedInterviewData({});
            }}
          >
            Cancel
          </MDButton>
          {permissions?.includes(modulePermissions?.update_permission) && (
            <MDButton
              variant="gradient"
              color="dark"
              onClick={() => {
                handleInterviewSaveClick();
              }}
            >
              Update
            </MDButton>
          )}
        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}

export default Interviews;
