import { createSlice } from "@reduxjs/toolkit";

const candidatesSlice = createSlice({
    name : 'candidates',
    initialState : {
        candidates : [],
        details : [],
        options : []
    },
    reducers : {
        candidatesList : (state,action) => {
            state.candidates = action.payload.candidates;
        },
        candidateDetails : (state,action) => {
            state.details = action.payload;
        },
        optionValues : (state,action) => {
            state.options = action.payload;
        }
    }
});

export const {candidatesList,candidateDetails,optionValues} = candidatesSlice.actions;
export default candidatesSlice.reducer;