import { Add, Delete, Info, ViewArray, ViewCompact } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Icon,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import { useEffect, useState } from "react";
import ApiCalls from "services/flick_erp_api_call";
import Permissions from "./components/Permissions";
import RolesPermissions from "./components/Permissions";
import history from "../../../helpers/navigation";
import { useDispatch } from "react-redux";
import { setToastOpen } from "components/Toast/toastAlertSlice";
export function OrganisationSettings({permissions,modulePermissions}) {

  const dispatch = useDispatch();
  const [tabComponents, setTabComponents] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [
    createDepartmentAndDesignationAndDocumentTypeData,
    setDepartmentAndDesignationAndDocumentTypeData,
  ] = useState({});
  const [designationsDialog, setDesignationsDialog] = useState(false);
  const [createRolesDialog, setCreateRolesDialog] = useState(false);
  const [createPermissiosDialog, setCreatePermissiosDialog] = useState(false);
  const [rolesPayload, setRolesPayload] = useState({});
  const user = JSON.parse(localStorage.getItem("user"));
  const [
    createDepartmentORDesignationDialog,
    setCreateDepartmentORDesignationDialog,
  ] = useState(false);

  useEffect(() => {
    getDepartment();
    getDocumentType();
    getRoles();
    getTempCards()
  }, []);

  const [departmentData, setDepartmentData] = useState({
    columns: [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* <Avatar src={row.original.profilePhoto} alt="Profile Photo" /> */}
            <span style={{ marginLeft: "0.5rem" }}>{row.original.name}</span>
            {/* <span style={{ marginLeft: "0.2rem" }}>
              {row.original.lastname}
            </span> */}
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "add",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
           {permissions?.includes(modulePermissions.designation_create_permission) && ( <Tooltip title="Add">
              <IconButton
                size="small"
                onClick={() => {
                  setCreateDepartmentORDesignationDialog(true);
                  setDepartmentAndDesignationAndDocumentTypeData((state) => ({
                    ...state,
                    type: "designation",
                    departmentId: row.original.id,
                  }));
                }}
              >
                <Add />
              </IconButton>
            </Tooltip>)}
            <Tooltip title="View Designation">
              <IconButton
                size="small"
                onClick={() => {
                  setDesignations(row.original.designations);
                  setDesignationsDialog(true);
                  //   handleEditClick(row.original);
                }}
              >
                <Info />
              </IconButton>
            </Tooltip>
           {permissions?.includes(modulePermissions.department_delete_permission) &&( <Tooltip title="Delete Designation">
              <IconButton
                size="small"
                onClick={() => {
                  deleteDepartment(row.original.id);
                 
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>)}
          </div>
        ),
      },
    ],
    rows: [],
  });
  const [documentTypes, setDocumentTypes] = useState({
    columns: [
      {
        Header: "Document Name",
        accessor: "name",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* <Avatar src={row.original.profilePhoto} alt="Profile Photo" /> */}
            <span style={{ marginLeft: "0.5rem" }}>{row.original.name}</span>
            {/* <span style={{ marginLeft: "0.2rem" }}>
              {row.original.lastname}
            </span> */}
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "edit_delete",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Delete">
              <IconButton
                size="small"
                onClick={() => {
                  deleteDocumentType(row.original.id);
                  //   handleDeleteClick(row.original.id);
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
    ],
    rows: [],
  });

  const [rolesData, setRolesData] = useState({
    columns: [
      {
        Header: "Name",
        accessor: "role_name",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* <Avatar src={row.original.profilePhoto} alt="Profile Photo" /> */}
            <span style={{ marginLeft: "0.5rem" }}>{row.original.name}</span>
            {/* <span style={{ marginLeft: "0.2rem" }}>
              {row.original.lastname}
            </span> */}
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "add",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* <Tooltip title="Add Permission">
              <IconButton
                size="small"
                onClick={() => {
                  setCreatePermissiosDialog(true);
                }}
              >
                <Add />
              </IconButton>
            </Tooltip> */}
            <Tooltip title="View Permissions">
              <IconButton size="small" onClick={() => {
                  setRolesPayload({type:"update",name:row.original.name, id:row.original.id})
                setSelectedPermissions(row.original.permissions)
                setCreateRolesDialog(true)
              }}>
                <Info />
              </IconButton>
            </Tooltip>
            {permissions?.includes(modulePermissions.role_delete_permission) &&(<Tooltip title="Delete Role">
              <IconButton size="small" onClick={() => {
                deleteRole(row.original.id)
              }}>
                <Delete />
              </IconButton>
            </Tooltip>)}
          </div>
        ),
      },
    ],
    rows: [],
  });
  const [tempCardData, setTempCardData] = useState({
    columns: [
      {
        Header: "Name",
        accessor: "card",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* <Avatar src={row.original.profilePhoto} alt="Profile Photo" /> */}
            <span style={{ marginLeft: "0.5rem" }}>{row.original.card}</span>
            {/* <span style={{ marginLeft: "0.2rem" }}>
              {row.original.lastname}
            </span> */}
          </div>
        ),
      },
      {
        Header:"COMMENT",
        accessor:"comment"
      },
      {
        Header: "Action",
        accessor: "add",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
          
            {/* <Tooltip title="View Card">
              <IconButton size="small" onClick={() => {
              
              }}>
                <Info />
              </IconButton>
            </Tooltip> */}
            {
            // permissions?.includes(modulePermissions.role_delete_permission) &&
            (<Tooltip title="Delete Role">
              <IconButton size="small" onClick={() => {
                deleteTempCard(row.original.id)
              }}>
                <Delete />
              </IconButton>
            </Tooltip>)}
          </div>
        ),
      },
    ],
    rows: [],
  });
  async function getDepartment() {
    var res = await ApiCalls.getDepartment();
    if (res.success && res.code == 200) {
      departmentData.rows = res.data;
      setDepartmentData({ ...departmentData });
    } else {
    
    }
  }
  async function getDocumentType() {
    var res = await ApiCalls.getDocuments();
    if (res.success && res.code == 200) {
      // documentTypes.rows =   Object.entries(res.data).map(([key,value])=> ({id:key,documentName:value}))
      documentTypes.rows = res.data;
      setDocumentTypes({ ...documentTypes });
    } else {
      
    }
  }
  async function getTempCards() {
    var res = await ApiCalls.getTempCards();
    if (res.success && res.code == 200) {
      tempCardData.rows = res.data;
      setTempCardData({ ...tempCardData });
    } else {
    
    }
  }
  async function deleteDepartment(id) {
    var res = await ApiCalls.deleteDepartment(id);
    if (res.success && res.code == 204) {
      getDepartment();
    

    } else {
     
    }
  }
  async function deleteDesignation(id) {
    var res = await ApiCalls.deleteDesignation(id);
    if (res.success && res.code == 204) {
      getDepartment();
      setDesignationsDialog(false);

     
    } else {
      setDesignationsDialog(false)(false);
      
    }
  }
  async function deleteDocumentType(id) {
    var res = await ApiCalls.deleteDocumentType(id);
    if (res.success && res.code == 204) {
      getDocumentType();
      

    } else {
      
    }
  }
  async function deleteTempCard(id) {
    var res = await ApiCalls.deleteTempCard(id);
    if (res.success ) {
      getTempCards();
    

    } else {
     
    }
  }
  async function createDepartmentORDesignationORDocumentType() {
    var data = {};
    
    if (
      createDepartmentAndDesignationAndDocumentTypeData.type == "department"
    ) {
      data = {
        name: createDepartmentAndDesignationAndDocumentTypeData.name,
        org: user.org_id,
        designations: [],
      };
    } else if (
      createDepartmentAndDesignationAndDocumentTypeData.type == "designation"
    ) {
      data = {
        name: createDepartmentAndDesignationAndDocumentTypeData.name,
        org: user.org_id,
        department:
          createDepartmentAndDesignationAndDocumentTypeData.departmentId,
      };
    } else if (
      createDepartmentAndDesignationAndDocumentTypeData.type == "documentType"
    ) {
      data = {
        name: createDepartmentAndDesignationAndDocumentTypeData.name,
        org: user.org_id,
      };
    } else if (createDepartmentAndDesignationAndDocumentTypeData.type == "tempCard") {
      data = {
        card: createDepartmentAndDesignationAndDocumentTypeData.name,
        comment: createDepartmentAndDesignationAndDocumentTypeData.comment,
        org: user.org_id,
      };
    }
    debugger
    var res =
      createDepartmentAndDesignationAndDocumentTypeData.type == "department"
        ? await ApiCalls.postDepartment(data, false)
        : createDepartmentAndDesignationAndDocumentTypeData.type ==
          "designation"
        ? await ApiCalls.postDesignation(data, false)
        : createDepartmentAndDesignationAndDocumentTypeData.type ==
          "documentType"
        ? await ApiCalls.postDocumentTypes(data, false)
        : createDepartmentAndDesignationAndDocumentTypeData.type == "tempCard" ?  await ApiCalls.postTempCards(data, false) : null;
    if (res.success && res.code == 201) {
      getDepartment();
      getDocumentType();
      getTempCards()
      setCreateDepartmentORDesignationDialog(false);

      
    } else {
      setCreateDepartmentORDesignationDialog(false);
      

    }
  }
  async function getRoles() {
    var res = await ApiCalls.getRoles();
    if (res.success && res.code == 200) {
      rolesData.rows = res.data;
      setRolesData({ ...rolesData });
    } else {
    
    }
  }
   async function createRoles(){
    if(rolesPayload.name != undefined && rolesPayload.name != "") {
var payload = {
      ...rolesPayload,
      "permissions": selectedPermissions,
      org: user.org_id
    };

    var res = await ApiCalls.postRoles(payload,false);
    if(res.success && res.code == 201) {
      getRoles()
      setCreateRolesDialog(false);

      
    }else {
      setCreateRolesDialog(false);

     
    }
    }else {


    }
    
   }
   async function deleteRole(id) {
    var res = await ApiCalls.deleteRole(id);
    if (res.success && res.code == 204) {
      getRoles();


    } else {

    }
  }
  async function updateRoles(){
    if(rolesPayload.name != undefined && rolesPayload.name != "") {
var payload = {

      name: rolesPayload.name,
      "permissions": selectedPermissions,
      org: user.org_id
    };

    var res = await ApiCalls.updateRoles(rolesPayload.id,payload,false);
    if(res.success && res.code == 200) {
      getRoles()
      setCreateRolesDialog(false);
      setRolesPayload({})
   

    }else {
      setCreateRolesDialog(false);
      setRolesPayload({})
   

    }
    }else {


    }
    
   }

  useEffect(() => {
    setTabComponents([
      {
        label: "Department",
        component: (
          <MDBox>
            <MDBox display="flex" justifyContent="end">
            {permissions?.includes(modulePermissions.department_create_permission) &&(  <Fab
                color="info"
                onClick={() => {
                  setCreateDepartmentORDesignationDialog(true);
                  setDepartmentAndDesignationAndDocumentTypeData((state) => ({
                    ...state,
                    type: "department",
                  }));
                }}
              >
                <MDTypography variant="h6" color="white">
                  +
                </MDTypography>
              </Fab>)}
            </MDBox>
            <DataTable table={departmentData}></DataTable>
          </MDBox>
        ),
        icon: <Icon>settings</Icon>,
        visible: permissions?.includes(modulePermissions?.department_list_permission) ?? false
      },
      {
        label: "Document Types",
        component: (
          <MDBox>
            <MDBox display="flex" justifyContent="end">
              <Fab
                color="info"
                onClick={() => {
                  setCreateDepartmentORDesignationDialog(true);
                  setDepartmentAndDesignationAndDocumentTypeData((state) => ({
                    ...state,
                    type: "documentType",
                  }));
                }}
              >
                <MDTypography variant="h6" color="white">
                  +
                </MDTypography>
              </Fab>
            </MDBox>
            <DataTable table={documentTypes}></DataTable>
          </MDBox>
        ),
        icon: <Icon>settings</Icon>,
        visible: permissions?.includes(modulePermissions?.documentType_list_permission) ?? false
      },
      {
        label: "Roles",
        component: (
          <MDBox>
            <MDBox display="flex" justifyContent="end">
            {permissions?.includes(modulePermissions.role_create_permission) &&(  <Fab
                color="info"
                onClick={() => {
                  setRolesPayload({type:"create"})
                  setCreateRolesDialog(true);
                }}
              >
                <MDTypography variant="h6" color="white">
                  +
                </MDTypography>
              </Fab>)}
            </MDBox>
            <DataTable table={rolesData}></DataTable>
          </MDBox>
        ),
        icon: <Icon>settings</Icon>,
        visible: permissions?.includes(modulePermissions?.role_list_permission) ?? false
      },
      {
        label: "Temp Card",
        component: (
          <MDBox>
            <MDBox display="flex" justifyContent="end">
            {
            // permissions?.includes(modulePermissions.role_create_permission)
            // &&
            (  <Fab
                color="info"
                onClick={() => {
                  setCreateDepartmentORDesignationDialog(true);
                  setDepartmentAndDesignationAndDocumentTypeData((state) => ({
                    ...state,
                    type: "tempCard",
                  }));
                }}
              >
                <MDTypography variant="h6" color="white">
                  +
                </MDTypography>
              </Fab>)}
            </MDBox>
            <DataTable table={tempCardData}></DataTable>
          </MDBox>
        ),
        icon: <Icon>settings</Icon>,
        visible: ((permissions?.includes(modulePermissions?.tempcard_list_permission))|| (permissions?.includes(modulePermissions?.tempcard_owner_list_permission))) ?? false
      },
    ]);
  }, [departmentData, documentTypes,rolesData,tempCardData]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ padding: "10px" }}>
        <MDBox p={2}>
          <MDBox justifyContent="center">
            <MDBox display="flex" flexDirection="row" justifyContent="center">
              <MDTypography alignItems="center" variant="h4">
                Organisation Settings
              </MDTypography>
            </MDBox>
            <BaseLayout tabComponents={tabComponents}></BaseLayout>
          </MDBox>
        </MDBox>
      </Card>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "white",
            // backgroundColor: '#F5F5F5',
            width: "700px",
            height: "500px",
            maxWidth: "none",
            padding: "20px",
          },
        }}
        open={designationsDialog}
        onClose={() => {
          setDesignationsDialog(false);
        }}
      >
        <DialogTitle>
          <MDTypography>Designations</MDTypography>
        </DialogTitle>
        <DialogContent>
          {designations.map(function (e) {
            return (
              <MDBox p={1}>
                <Card>
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={2}
                  >
                    {e.name}

                   {permissions?.includes(modulePermissions.designation_delete_permission) &&( <Delete
                      onClick={() => {
                        deleteDesignation(e.id);
                      }}
                    ></Delete>)}
                  </MDBox>
                </Card>
              </MDBox>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button
            variant="gradient"
            color="dark"
            onClick={() => {
              setDesignationsDialog(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "white",
            // backgroundColor: '#F5F5F5',
            width: "500px",
            height: "200px",
            maxWidth: "none",
            padding: "20px",
          },
        }}
        open={createDepartmentORDesignationDialog}
        onClose={() => {
          setCreateDepartmentORDesignationDialog(false);
        }}
      >
        {/* <DialogTitle style={{ padding: '20px', marginLeft: '30px', fontWeight: 'bold', fontSize: '24px' }}> */}
        <DialogTitle>
          <MDTypography>
            Create{" "}
            {createDepartmentAndDesignationAndDocumentTypeData.type ==
            "department"
              ? "Department"
              : createDepartmentAndDesignationAndDocumentTypeData.type ==
                "designation"
              ? "Designation"
              : createDepartmentAndDesignationAndDocumentTypeData.type ==
              "documentType"? "Document Type" :  createDepartmentAndDesignationAndDocumentTypeData.type ==
              "tempCard"? "Temp Card": ""}
          </MDTypography>
        </DialogTitle>
        <DialogContent>
          <MDBox display="flex" justifyContent="center" p={2}>
            <TextField
              label={`${
                createDepartmentAndDesignationAndDocumentTypeData.type ==
                "department"
                  ? "Department Name*"
                  : createDepartmentAndDesignationAndDocumentTypeData.type ==
                    "designation"
                  ? "Designation Name*"
                  : createDepartmentAndDesignationAndDocumentTypeData.type ==
                  "documentType"? "Document Type*" :  createDepartmentAndDesignationAndDocumentTypeData.type ==
                  "tempCard"? "Temp Card*" : ""
              }`}
              error={
                createDepartmentAndDesignationAndDocumentTypeData?.name == ""
              }
              sx={{ paddingRight: 1 }}
              onChange={(event) => {
                setDepartmentAndDesignationAndDocumentTypeData((state) => ({
                  ...state,
                  name: event.target.value,
                }));
              }}
            />
              <TextField
              label={`${
                createDepartmentAndDesignationAndDocumentTypeData.type ==
                  "tempCard"? "Comment*" : ""
              }`}
              // error={
              //   createDepartmentAndDesignationAndDocumentTypeData?.name == ""
              // }
              sx={{ paddingRight: 1 }}
              onChange={(event) => {
                setDepartmentAndDesignationAndDocumentTypeData((state) => ({
                  ...state,
                  comment: event.target.value,
                }));
              }}
            />
            <Button
              variant="gradient"
              color="dark"
              onClick={() => {
                createDepartmentORDesignationORDocumentType(
                  createDepartmentAndDesignationAndDocumentTypeData.name
                );
              }}
            >
              Create
            </Button>
            <Button
              variant="gradient"
              color="dark"
              onClick={() => {
                setCreateDepartmentORDesignationDialog(false);
              }}
            >
              Cancel
            </Button>
          </MDBox>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "white",

            width: "80%",

            maxWidth: "none",
            padding: "20px",
          },
        }}
        open={createRolesDialog}
        onClose={() => {
          setCreateRolesDialog(false);
        }}
      >
        {/* <DialogTitle style={{ padding: '20px', marginLeft: '30px', fontWeight: 'bold', fontSize: '24px' }}> */}
        <DialogTitle>
          <MDTypography variant="h5">{rolesPayload.type == "create" ? "Create Role" : "Update Roles"}</MDTypography>
        </DialogTitle>
        <DialogContent>
          <MDBox display="flex" justifyContent="start" p={2}>
            <TextField
              label={"Role"}
              placeholder="Enter Role"
              fullWidth
              variant="standard"
              sx={{ paddingRight: 1 }}
              value={rolesPayload.name}
              onChange={(event) => {
                setRolesPayload((state) => ({
                  ...state,
                  name: event.target.value,
                }));
              }}
            />
          </MDBox>
          <RolesPermissions
            selectedPermissions={selectedPermissions}
            setSelectedPermissions={setSelectedPermissions}
          ></RolesPermissions>
        </DialogContent>
        <DialogActions>
        {(permissions?.includes(modulePermissions.role_create_permission) || permissions?.includes(modulePermissions.role_update_permission)) &&(  <Button
            variant="gradient"
            color="dark"
            onClick={() => {
              rolesPayload.type == "create" ?
               createRoles()  :  updateRoles()

            }}
          >
          {rolesPayload.type == "create" ?  "Create" : "Update"}
          </Button>)}
          <Button
            variant="gradient"
            color="dark"
            onClick={() => {
              setCreateRolesDialog(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "white",
            // backgroundColor: '#F5F5F5',
            width: "80%",
            // height: "200px",
            maxWidth: "none",
            padding: "20px",
          },
        }}
        open={createPermissiosDialog}
        onClose={() => {
          setCreatePermissiosDialog(false);
        }}
      >
        {/* <DialogTitle style={{ padding: '20px', marginLeft: '30px', fontWeight: 'bold', fontSize: '24px' }}> */}
        <DialogTitle>
          <MDTypography>Add permissions for this role</MDTypography>
        </DialogTitle>
        <DialogContent>
          <MDBox display="flex" justifyContent="center" p={2}></MDBox>
        </DialogContent>
        <DialogActions>
          <Button
            variant="gradient"
            color="dark"
            onClick={() => {
              // createDepartmentORDesignationORDocumentType(
              //   createDepartmentAndDesignationAndDocumentTypeData.name
              // );
            }}
          >
            Create
          </Button>
          <Button
            variant="gradient"
            color="dark"
            onClick={() => {
              setCreatePermissiosDialog(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}
export function DocumentTypes() {
  return (
    <>
      <MDBox></MDBox>
    </>
  );
}
