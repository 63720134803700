/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "layouts/marketing/newEvent/components/FormField";
import { ColorPicker } from "material-ui-color";
import { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

function EventInfo({
  formData,
  color,
  setColor,
  duration,
  setDuration,
  selectedLocation,
  setSelectedLocation,
  selectedLocationError,
  custom,
  setCustom,
  getAuthToken,
}) {
  const { formField, values, errors, touched } = formData;
  const { eventName, eventColor, eventDuration, email } = formField;
  const {
    eventName: eventNameV,
    eventColor: eventColorV,
    eventDuration: eventDurationV,
    email: emailV,
  } = values;

  const handleColorChange = (newColor) => {
    setColor(newColor.css.backgroundColor);
  };

  const buttonStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#344767",
    margin: 1,
    wordWrap: "wrap",
    justifyContent: "center",
    borderColor: "#B2B2B2",
    "&:hover": {
      backgroundColor: "#f3f6f9", // Change background color on hover
      borderColor: "#344767", // Change border color on hover
    },
    gap: "4px", // Adjust the gap between icon and text
  };

  const locationTypes = [
    {
      label: "Meet",
      value: "meet",
      icon: <VideocamOutlinedIcon color="#344767" />,
    },
    {
      label: "Phone Call",
      value: "phone_call",
      icon: <PhoneOutlinedIcon color="#344767" />,
    },
    {
      label: "In-person Meeting",
      value: "in_person",
      icon: <LocationOnOutlinedIcon color="#344767" />,
    },
  ];

  const LongBoxWithIcons = () => {
    return (
      <Grid container alignItems="center" spacing={1}>
        {/* Left Box containing Icon and Label */}
        <Box
          display="flex"
          alignItems="center"
          border="1px solid #ccc"
          padding={1}
          borderRadius={2}
          sx={{
            margin: 2,
            backgroundColor: "#f3f6f9",
          }}
        >
          <Box marginRight={1}>{selectedLocation?.icon}</Box>
          <Typography variant="body2">{selectedLocation?.label}</Typography>
        </Box>
        <IconButton
          color="secondary"
          aria-label="close"
          onClick={() => {
            setSelectedLocation(null);
          }}
        >
          <CloseOutlinedIcon />
        </IconButton>
      </Grid>
    );
  };

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h4">New Event</MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={eventName?.type} // Use the new field type
              label={eventName?.label} // Use the new field label
              name={eventName?.name} // Use the new field name
              value={eventNameV}
              placeholder={eventName?.placeholder}
              error={errors?.eventName && touched?.eventName}
              success={eventNameV?.length > 0 && !errors?.eventName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div
              style={{
                marginTop: "13px",
              }}
            >
              <ColorPicker value={color} onChange={handleColorChange} />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={email.type}
              label={email.label}
              name={email.name}
              value={emailV}
              placeholder={email.placeholder}
              error={errors.email && touched.email}
              success={emailV?.length > 0 && !errors.email}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Autocomplete
                options={["15", "30", "45", "Custom"]}
                value={duration}
                onChange={(event, newValue) => {
                  setDuration(newValue);
                }}
                sx={{ width: "110px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Duration"
                    // size="small"
                    variant="standard"
                    // fullWidth
                    // error={!!sourceError}
                    // helperText={sourceError}
                  />
                )}
              />
              {duration === "Custom" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",

                    alignItems: "center",
                  }}
                >
                  <TextField
                    variant="outlined"
                    type="number"
                    size="small"
                    sx={{
                      width: 50,
                      marginRight: 1,
                      marginLeft: 1,
                    }}
                    value={custom.customDuration}
                    onChange={(event) => {
                      setCustom({
                        ...custom,
                        customDuration: event.target.value,
                      });
                    }}
                    InputProps={{
                      inputProps: { min: 0 }, // Set a minimum value if needed
                    }}
                  />
                  <Autocomplete
                    options={["min", "hrs"]}
                    value={custom.customTimeIn}
                    onChange={(event, newValue) => {
                      setCustom({
                        ...custom,
                        customTimeIn: newValue,
                      });
                    }}
                    sx={{ width: "110px" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Duration"
                        // size="small"
                        variant="standard"
                        // fullWidth
                        // error={!!sourceError}
                        // helperText={sourceError}
                      />
                    )}
                  />
                </div>
              )}
            </div>
          </Grid> */}
        </Grid>
        <MDBox lineHeight={0}>
          <MDTypography variant="h6">Location</MDTypography>
          {selectedLocation ? (
            <LongBoxWithIcons />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {locationTypes?.map((item, index) => {
                return (
                  <Button
                    variant="outlined"
                    sx={buttonStyle}
                    onClick={() => {
                      if (item.value === "meet") {
                        getAuthToken(item);
                      } else {
                        setSelectedLocation(item);
                      }
                    }}
                  >
                    {item?.icon}
                    {item?.label}
                  </Button>
                );
              })}
            </div>
          )}

          {selectedLocationError && (
            <MDTypography variant="body2" color="error" textGradient>
              Please select location type.
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for UserInfo
EventInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default EventInfo;
