/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MDDatePicker from "components/MDDatePicker";
// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
// import { Link } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDBadgeDot from "components/MDBadgeDot";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Link, useNavigate } from "react-router-dom";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";
import SalesTable from "examples/Tables/SalesTable";
import DataTable from "examples/Tables/DataTable";
import { Pie } from "react-chartjs-2";
// Sales dashboard components
import ChannelsChart from "layouts/recruitement/components/ChannelsChart";
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Data
import joineeData from "layouts/recruitement/data/joineeData";
import defaultLineChartData from "layouts/dashboards/sales/data/defaultLineChartData";

import assignedJobData from "layouts/recruitement/data/assignedJobData";

import MDInput from "components/MDInput";
import ApiCalls from "services/flick_erp_api_call";
import PieChart from "examples/Charts/PieChart";

function Recruitement() {
  // DefaultStatisticsCard state for the dropdown value

  const [salesDropdownValue, setSalesDropdownValue] = useState("3 Months");
  const [customersDropdownValue, setCustomersDropdownValue] =
    useState("3 Months");
  const [revenueDropdownValue, setRevenueDropdownValue] = useState("3 Months");
  const [acquisitionDropdownValue, setAcquisitionDropdownValue] =
    useState("3 Months");
  const [controller, dispatch] = useMaterialUIController();
  // DefaultStatisticsCard state for the dropdown action
  const [salesDropdown, setSalesDropdown] = useState(null);
  const [customersDropdown, setCustomersDropdown] = useState(null);
  const [revenueDropdown, setRevenueDropdown] = useState(null);
  const [acquisitionDropdown, setAcquisitionDropdown] = useState(null);
  const [dashboardCounts, setDashboardCounts] = useState({});
  const [pieChartData, setPieChartData] = useState({});
  const [candidateStatusOptions, setCandidateStatusOptions] = useState({});

  const [candidateByRecruiterPieData, setCandidateByRecruiterPieData] = useState( {
    labels: [],
    datasets: {
      label: "Candidate By Recruiter",
      backgroundColors: ["info", "primary", "dark", "error", "primary"],
      data: [],
    },
  });
  const [candidateByInterviewerPieData, setCandidateByInterviewerPieData] = useState( {
    labels: [],
    datasets: {
      label: "Candidate By Interviewer",
      backgroundColors: ["info", "primary", "dark", "error", "primary"],
      data: [],
    },
  });
  const [candidateByStatusPieData, setCandidateByStatusPieData] = useState( {
    labels: [],
    datasets: {
      label: "Candidate By Status",
      backgroundColors: ["info", "primary", "dark", "error", "primary"],
      data: [],
    },
  });
  const [interviewsNoFeedbackPieData, setInterviewsNoFeedbackPieData] = useState( {
    labels: [],
    datasets: {
      label: "Candidate By Status",
      backgroundColors: ["info", "primary", "dark", "error", "primary"],
      data: [],
    },
  });

  const [horizontalBarChartData, setHorizontalBarChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Candidates",
        color: "info",
        data: [],
      },
    ],
  });

  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
  } = controller;

  // DefaultStatisticsCard handler for the dropdown action
  const openSalesDropdown = ({ currentTarget }) =>
    setSalesDropdown(currentTarget);
  const closeSalesDropdown = ({ currentTarget }) => {
    setSalesDropdown(null);
    setSalesDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  const openCustomersDropdown = ({ currentTarget }) =>
    setCustomersDropdown(currentTarget);
  const closeCustomersDropdown = ({ currentTarget }) => {
    setCustomersDropdown(null);
    setCustomersDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  const openRevenueDropdown = ({ currentTarget }) =>
    setRevenueDropdown(currentTarget);
  const closeRevenueDropdown = ({ currentTarget }) => {
    setRevenueDropdown(null);
    setRevenueDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  const openAcquisitionDropdown = ({ currentTarget }) =>
    setAcquisitionDropdown(currentTarget);
  const closeAcquisitionDropdown = ({ currentTarget }) => {
    setAcquisitionDropdown(null);
    setAcquisitionDropdownValue(currentTarget.innerText || salesDropdownValue);
  };

  const [date, setDate] = useState("");
  const dateInputRef = useRef(null);

  const [value, onChange] = useState(new Date());
  useEffect(  () =>{
     getOptionValue().then(()=>{
      recruitmentDashboardCounts();
      recruitmentDashboardPieData();
     });

  
  }, []);
  async function getOptionValue() {
    const res = await ApiCalls.getCandiateStatusAndSource();
    if (res.success && res.code == 200) {
      // setOption(res.data);
      setCandidateStatusOptions(  res.data.status );
    } else {
    }
  }

  async function recruitmentDashboardCounts() {
    var res = await ApiCalls.getRecruitmentDashboardCounts();
    if (res.success && res.code === 200) {
      setDashboardCounts(res?.data);
      horizontalBarChartData.labels = Object.keys(res?.data?.candidate).map(
        (e) => ({ label: e.replaceAll("_"," ").toUpperCase() })
      );
      horizontalBarChartData.datasets[0].data = Object.values(
        res?.data?.candidate
      ).map((e) => e);
      setHorizontalBarChartData({...horizontalBarChartData})
    }
  }
  async function recruitmentDashboardPieData() {
    var res = await ApiCalls.getRecruitmentDashboardPieData();
    if (res.success && res.code === 200) {
      setPieChartData(res.data);
      candidateByRecruiterPieData.labels = res.data.candidate_by_recruiter.map((e)=> e.recruiter__user__firstname )
      candidateByRecruiterPieData.datasets.data = res.data.candidate_by_recruiter.map((e)=> e.count )
      candidateByInterviewerPieData.labels = res.data.by_interviewer.map((e)=> e.interviewer__user__firstname )
      candidateByInterviewerPieData.datasets.data = res.data.by_interviewer.map((e)=> e.count )

      candidateByStatusPieData.labels = res.data.by_status.map((e)=> candidateStatusOptions[e.status] )
      candidateByStatusPieData.datasets.data = res.data.by_status.map((e)=> e.count )
      interviewsNoFeedbackPieData.labels = res.data.interviews_no_feedback.map((e)=> e.interviewer__user__firstname )
      interviewsNoFeedbackPieData.datasets.data = res.data.interviews_no_feedback.map((e)=> e.no_feedback_count )
      setCandidateByRecruiterPieData({...candidateByRecruiterPieData})
      setCandidateByInterviewerPieData({...candidateByInterviewerPieData})
      setCandidateByStatusPieData({...candidateByStatusPieData})
      setInterviewsNoFeedbackPieData({...interviewsNoFeedbackPieData})

    }
  }


  // Dropdown menu template for the DefaultStatisticsCard
  const renderMenu = (state, close) => {
    const [value, onChange] = useState(new Date());
    const handleClick = () => {
      return (
        <div>
          <MDDatePicker
            label="Custom"
            onChange={(newValue) => setNestedObjectValues(newValue)}
            value={value}
          />
        </div>
      );
    };

    return (
      <>
        <Menu
          anchorEl={state}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          open={Boolean(state)}
          onClose={close}
          keepMounted
          disableAutoFocusItem
        >
          <MenuItem onClick={close}>3 Months</MenuItem>
          <MenuItem onClick={close}>6 Months</MenuItem>
          <MenuItem onClick={close}>1 year</MenuItem>
          <MenuItem
            disableRipple
            aria-haspopup="true"
            variant="contained"
            onClick={handleClick}
          >
            Custom
          </MenuItem>
          {/* <label>custom</label> */}
          <MenuItem label="Set startDate">
            <DatePicker
              selected={date}
              onChange={(date) => {
                setDate(date);
              }}
            />
            <br />
            {/* <DatePicker selected={date} onChange={(date) => {setDate(date)}} /> */}
          </MenuItem>
          <MenuItem label="Set endDate">
            <DatePicker
              selected={date}
              onChange={(date) => {
                setDate(date);
              }}
            />
            <br />
            {/* <DatePicker selected={date} onChange={(date) => {setDate(date)}} /> */}
          </MenuItem>
        </Menu>
      </>
    );
  };
  const navigate = useNavigate();

  // const [date, setDate] = useState(new Date());

  const handleApplication = () => {
    navigate("/dashboards/recruitement/shortlisted");
  };
  const handleShortListed = () => {
    navigate("/dashboards/recruitement/shortlisted");
  };
  const handleRejected = () => {
    navigate("/dashboards/recruitement/rejected");
  };
  const handleConfigurator = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  return (
    <>
      {/* <DashboardNavbar  /> */}
      <MDBox py={3}>
        {/* <Grid container spacing={3}>
          <Grid item xs={8} sm={4}>
            <MDButton>+ Add Candidate</MDButton>
          </Grid>
        </Grid> */}
        {/* <br /> */}
        <MDBox mb={3}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} sm={4}>
          

              <DefaultStatisticsCard
                title="Waiting"
                count={dashboardCounts?.screening?.waiting ?? 0}
                onClick = {handleApplication}
                percentage={{
                  color: "success",
                  value: "+55%",
                  label: "since last month",
                }}
                dropdown={{
                  action: openSalesDropdown,
                  menu: renderMenu(salesDropdown, closeSalesDropdown),
                  value: salesDropdownValue,
                }}
              />
            </Grid> */}
            {/* <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="Screened"
                count={dashboardCounts?.screening?.screened ?? 0}
                percentage={{
                  color: "success",
                  // value: "+12%",
                  // label: "since last month",
                }}

                dropdown={{
                  action: openCustomersDropdown,
                  menu: renderMenu(customersDropdown, closeCustomersDropdown),
                  value: customersDropdownValue,
                }}
              />
            </Grid> */}
            {/* <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="Rejected"
                count={dashboardCounts?.screening?.rejected_cv ?? 0}
                percentage={{
                  color: "secondary",
                  // value: "+$213",
                  // label: "since last month",
                }}
                dropdown={{
                  action: openRevenueDropdown,
                  menu: renderMenu(revenueDropdown, closeRevenueDropdown),
                  value: revenueDropdownValue,
                }}
              />
            </Grid> */}
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3} lg={3}>
           <Card style={{padding:"10px"}}>
           <MDTypography
                variant="button"
                fontWeight="medium"
                color="text"
                textTransform="capitalize"
              >
                Candidate By Recruiter
              </MDTypography>
           <PieChart chart={candidateByRecruiterPieData} height="9.0rem" />
           </Card>


              {/* <ChannelsChart
                title="Pie"
                dropdown={{
                  action: openRevenueDropdown,
                  menu: renderMenu(revenueDropdown, closeRevenueDropdown),
                  value: revenueDropdownValue,
                }}
              /> */}
            </Grid>
            <Grid item xs={12} sm={3} lg={3}>
           <Card style={{padding:"10px"}}>
           <MDTypography
                variant="button"
                fontWeight="medium"
                color="text"
                textTransform="capitalize"
              >
                Candidate By Interviewer
              </MDTypography>
           <PieChart chart={candidateByInterviewerPieData} height="9.0rem" />
           </Card>


             
            </Grid>
            <Grid item xs={12} sm={3} lg={3}>
           <Card style={{padding:"10px"}}>
           <MDTypography
                variant="button"
                fontWeight="medium"
                color="text"
                textTransform="capitalize"
              >
                Candidate By Status
              </MDTypography>
           <PieChart chart={candidateByStatusPieData} height="9.0rem" />
           </Card>


              {/* <ChannelsChart
                title="Pie"
                dropdown={{
                  action: openRevenueDropdown,
                  menu: renderMenu(revenueDropdown, closeRevenueDropdown),
                  value: revenueDropdownValue,
                }}
              /> */}
            </Grid>
            <Grid item xs={12} sm={3} lg={3}>
           <Card style={{padding:"10px"}}>
           <MDTypography
                variant="button"
                fontWeight="medium"
                color="text"
                textTransform="capitalize"
              >
                No Feedback Interviews
              </MDTypography>
           <PieChart chart={interviewsNoFeedbackPieData} height="9.0rem" />
           </Card>


              {/* <ChannelsChart
                title="Pie"
                dropdown={{
                  action: openRevenueDropdown,
                  menu: renderMenu(revenueDropdown, closeRevenueDropdown),
                  value: revenueDropdownValue,
                }}
              /> */}
            </Grid>
            {/* <Grid > */}
              {/* <DefaultLineChart
                title="Revenue"
                description={
                  <MDBox display="flex" justifyContent="space-between">
                    <MDBox display="flex" ml={-1}>
                      <MDBadgeDot color="info" size="sm" badgeContent="Facebook Ads" />
                      <MDBadgeDot color="dark" size="sm" badgeContent="Google Ads" />
                    </MDBox>
                    <MDBox mt={-4} mr={-1} position="absolute" right="1.5rem">
                      <Tooltip title="See which ads perform better" placement="left" arrow>
                        <MDButton
                          variant="outlined"
                          color="secondary"
                          size="small"
                          circular
                          iconOnly
                        >
                          <Icon>priority_high</Icon>
                        </MDButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                }
                chart={defaultLineChartData}
              /> */}
              

              {/* <DatePicker selected={date} onChange={(date) => {setDate(date)}} /> */}
            {/* </Grid> */}
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <SalesTable title="Current Job Openings" rows={dashboardCounts.open_jobs}               ignore={["id", "created_at","updated_at","description","show_salary","deadline","is_active","org","post_date"]}
 />
            </Grid>
            <Grid item xs={12} lg={6}>
            <HorizontalBarChart
                title="Candidate Acquisition"
                chart={horizontalBarChartData}
              />
              {/* <SalesTable title="Upcoming Joining" rows={joineeData} /> */}
            </Grid>
          </Grid>
        </MDBox>
        {/* <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} px={3}>
                <MDTypography variant="h6" fontWeight="medium">
                  Top Selling Products
                </MDTypography>
              </MDBox>
              <MDBox py={1}>
                <DataTable
                  table={dataTableData}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  isSorted={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid> */}
      </MDBox>
      <Footer />
    </>
  );
}

export default Recruitement;
