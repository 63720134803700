/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable no-dupe-keys */
// Material Dashboard 2 PRO React base styles
import colors from "assets/theme/base/colors";

const { gradients, dark } = colors;

function configs(labels, datasets, cutout = 65) {
  const backgroundColors = [];

  if (datasets.backgroundColors) {
    datasets.backgroundColors.forEach((color) => {
      if (gradients[color]) {
        if (color === "info") {
          backgroundColors.push(gradients.info.main);
        } else {
          backgroundColors.push(gradients[color].state);
        }
      } else {
        backgroundColors.push(dark.main);
      }
    });
  } else {
    backgroundColors.push(dark.main);
  }

  return {
    data: {
      labels,
      datasets: [
        {
          label: datasets.label,
          weight: 9,
          cutout,
          tension: 0.9,
          pointRadius: 2,
          borderWidth: 2,
          backgroundColor: backgroundColors,
          fill: false,
          data: datasets.data,
        },
      ],
    },
    // options: {
    //   // responsive: true,
    //   // maintainAspectRatio: true,
    //   // aspectRatio: 1,
    
     
    //   plugins: {
    //     legend: {
    //       paddingBelowLegends: false,

    //       display: false,
    //     },
    //   },
    //   radius:"10%",
    //   // interaction: {
    //   //   intersect: false,
    //   //   mode: "index",
    //   // },
    //   scales: {
    //     y: {
    //       grid: {
    //         drawBorder: false,
    //         display: false,
    //         drawOnChartArea: false,
    //         drawTicks: false,
    //       },
    //       ticks: {
    //         display: false,
    //       },
    //     },
    //     x: {
    //       grid: {
    //         drawBorder: false,
    //         display: false,
    //         drawOnChartArea: false,
    //         drawTicks: false,
    //       },
    //       ticks: {
    //         display: false,
    //       },
    //     },
    //   },
    // },
    options: {
      cutout: "15%",
    //   borderRadius: 3,
    //   responsive: true,
    // maintainAspectRatio: false,
    radius:"10%",
      
      
      layout: {
        padding: 0
    },
      plugins: {
          legend:{display: false},
          title: {display: false,}
      }
  },
  };
}

export default configs;
