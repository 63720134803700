import MDBox from "components/MDBox";
import { SearchBox } from "components/SearchBox";
import DataTable from "examples/Tables/DataTable";
import moment from "moment";
import { useEffect, useState } from "react";
import ApiCalls from "services/flick_erp_api_call";

export function LeaveBalanceComponent({reload}) {
    useEffect(() => {
        getEmployee();
      }, [reload]);
      const user_id = JSON.parse(localStorage.getItem("user")).employee_id;
      const org_id = JSON.parse(localStorage.getItem("user")).org_id;
    const [employee, setEmployee] = useState({});
    async function getEmployee() {
        var res = await ApiCalls.getEmployee(user_id);
        if (res.success && res.code == 200) {
          setEmployee(res.data);
        //   setLeaveBalance(res.data.leave_balances);
          leaveBalanceData.rows = res.data.leave_balances;
          setLeaveBalanceData({ ...leaveBalanceData });
        } else {
        }
      }
    const [leaveBalanceData, setLeaveBalanceData] = useState({
        columns: [
          {
            Header: "Added Date",
            accessor: "created_at",
            Cell: ({ row }) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                {moment(row.original.created_at).format("YYYY-MM-DD")}
              </div>
            ),
          },
          {
            Header: "Leave Type",
            accessor: "leave",
            Cell: ({ row }) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                {row.original.leave_type.name}
              </div>
            ),
          },
          { Header: "Balance", accessor: "balance" },
          {
            Header: "Allowed hours",
            accessor: "allowed_hours",
            Cell: ({ row }) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                {row.original.leave_type.max_hours_allowed}
              </div>
            ),
          },
          // { Header: "Leave Remark", accessor: "leave_remark" },
          // { Header: "Hours", accessor: "hours" },
          // { Header: "Note", accessor: "note" },
        ],
        rows: [],
      });
    return (
      <MDBox>
           <SearchBox
                width={350}
                placeholder={"Search Candidate"}
                onChange={(e) => {
                  // handleSearch(e);
                }}
               
              />
         <DataTable table={leaveBalanceData}></DataTable></MDBox>
     );
}