/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import * as React from "react";

import parse from "html-react-parser";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";

import ApiCalls from "services/flick_erp_api_call";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MDAvatar from "components/MDAvatar";
import burceMars from "assets/images/bruce-mars.jpg";
import divyanshu from "assets/divyanshu.png";

import {
  Autocomplete,
  Badge,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  Grid,
  Icon,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import MDButton from "components/MDButton";
import { Box } from "@mui/system";
import MDEditor from "components/MDEditor";

// image
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

import moment from "moment";

import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";

import ProgressLine from "components/ProgressBar";
import { FlickPopover } from "components/FlickPopover";
import { EmojiPeople, Groups3 } from "@mui/icons-material";
import colors from "assets/theme/base/colors";
import Calendar from "components/Calendar/calendar";
import { base_url } from "services";

const avatarStyles = {
  border: ({ borders: { borderWidth }, palette: { white } }) =>
    `${borderWidth[1]} solid ${white.main}`,
  cursor: "pointer",
  position: "relative",
  ml: -0.4,
  transition: "transform 0.15s ease-in-out",
  "&:hover, &:focus": {
    zIndex: "10",
    ml: -0.4,
    transform: "scale3d(1.1, 1.1, 1)",
  },
};
function EmployeeDashboard({ permissions, modulePermissions }) {
  const loggedUser = JSON.parse(localStorage.getItem("user"));
  const [noticeBoardList, setNoticeBoardList] = useState([]);
  const [noticeDialogOpen, setNoticeDialogOpen] = useState(false);
  const [addNoticeDialogOpen, setAddNoticeDialogOpen] = useState(false);
  const [addNoticePayload, setAddNoticePayload] = useState({});
  const [employees, setEmployees] = useState([]);
  const [orgHierarchy, setOrgHierarchy] = useState([]);
  const [userDashboard, setUserDashboard] = useState({});

  const [wfhAnchorEl, setWFHAnchorEl] = React.useState(null);
  const [leaveAnchorEl, setLeaveAnchorEl] = React.useState(null);

  const [noticeDialogData, setNoticeDialogData] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const [data, setData] = useState(null);
  useEffect(() => {
    getEmployee();
    getNoticeBoards();
    getOrganisationHierarchy();
    getUserDashboard();
  }, []);

  const upcomingBirthday = [
    { name: "Divyanshu", date: "22-Oct", img: divyanshu },
    { name: "Adbhut", date: "29-Oct", img: team2 },
  ];
  const upcomingAnniversary = [
    { name: "Riya", date: "05-Nov", img: team3 },
    { name: "Ajay", date: "14-Nov", img: team4 },
  ];
  const upcomingHoliday = [{ name: "Christmas", date: "25-Dec", img: team3 }];
  const channelChartData = {
    labels: ["Employees"],
    datasets: {
      label: "Employees",
      backgroundColors: ["info", "primary", "dark", "error", "primary"],
      data: ["57"],
    },
  };

  // get Notice Board list
  async function getNoticeBoards() {
    var res = await ApiCalls.getNoticeBoards();
    if (res.success && res.code == 200) {
      setNoticeBoardList(res.data);
    }
  }
  // get employees list
  async function getEmployee() {
    const user = JSON.parse(localStorage.getItem("user"));
    var res = await ApiCalls.getEmployeesList();
    if (res.success && res.code === 200) {
      setEmployees(res.data);
    } else {
    }
  }
  // get organisation hierarchy
  async function getOrganisationHierarchy() {
    const user = JSON.parse(localStorage.getItem("user"));
    var res = await ApiCalls.getOrganisationHierarchyList();
    if (res.success && res.code === 200) {
      setOrgHierarchy(res.data);
    } else {
    }
  }
  // get user dashboard
  async function getUserDashboard() {
    const user = JSON.parse(localStorage.getItem("user"));
    var res = await ApiCalls.getUserDashboard();
    if (res.success && res.code === 200) {
      setUserDashboard(res.data);
    } else {
    }
  }
  async function postReadBy(id) {
    const user = JSON.parse(localStorage.getItem("user"));
    var data = {
      org: user.org_id,
      notishboard: id,
      reads_by: user.employee_id,
    };
    var res = await ApiCalls.postNoticeBoardRead(data, false);

    getNoticeBoards();
  }
  async function postNotice() {
    const user = JSON.parse(localStorage.getItem("user"));
    var data = {
      ...addNoticePayload,
      org: user.org_id,
      added_by: user.employee_id,
    };
    var res = await ApiCalls.postNoticeBoard(data, false);
    if (res.success && res.code == 201) {
    }
    setAddNoticePayload({});
    getNoticeBoards();
    setAddNoticeDialogOpen(false);
  }
  const filterUserProfilePhoto = (id) => {
    return employees.find((e) => e.id == id);
  };
  // popover handlers
  const handleWFHPopoverOpen = (event) => {
    setWFHAnchorEl(event.currentTarget);
  };
  const handleLeavePopoverOpen = (event) => {
    setLeaveAnchorEl(event.currentTarget);
  };

  const handleWFHPopoverClose = () => {
    setWFHAnchorEl(null);
  };
  const handleLeavePopoverClose = () => {
    setLeaveAnchorEl(null);

  }
  const renderWFHComponent = () => {
    return (
      <MDBox display="flex">
        {userDashboard?.wfh_employees?.map((e) => {
          var user = employees.find(
            (emp) => emp?.user?.id == e?.employee__user
          );
          return (
            <Tooltip title={user?.user?.firstname}>
              <MDAvatar
                src={user?.img}
                alt="team-1"
                size="xs"
                title="Smiley face"
                sx={avatarStyles}
              />
            </Tooltip>
          );
        })}
      </MDBox>
    );
  };
  const renderLeaveComponent = () => {
    return (
      <MDBox display="flex">
        {userDashboard?.current_leaves?.map((e) => {
          var user = employees.find(
            (emp) => emp?.user?.id == e?.employee__user
          );
          return (
            <Tooltip title={user?.user?.firstname}>
              <MDAvatar
                src={user?.img}
                alt="team-1"
                size="xs"
                title="Smiley face"
                sx={avatarStyles}
              />
            </Tooltip>
          );
        })}
      </MDBox>
    );
  };
  const colorCodeComponent = (status) => {
    return (
      <MDBox display="flex" alignItems="center">
        <MDBox
          height={8}
          width={8}
          style={{
            borderRadius: "2px",
            backgroundColor:
              status == "pending"
                ? "#ffde05"
                : status == "approved"
                ? "green"
                : "red",
          }}
        ></MDBox>
        <MDTypography
          textTransform="capitalize"
          style={{
            fontSize: "10px",
            fontWeight: "bold",
          }}
        >
          &nbsp; {status}
        </MDTypography>
      </MDBox>
    );
  };
  const showDetailsHandle = (dayStr) => {
    setData(dayStr);
    setShowDetails(true);
  };
  const EmployeeHierarchyTree = ({ employee }) => {
    return (
      <TreeItem
        key={employee.id} // Place the key prop here
        nodeId={employee.id}
        label={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "5px",
            }}
          >
            <MDAvatar
              src={`${base_url}/${employee.img}`}
              alt="profile-image"
              size="sm"
              shadow="sm"
            />
            <Typography
              variant="body2"
              sx={{ fontWeight: "medium", marginLeft: "5px" }}
            >
              {employee.fullname}
            </Typography>
          </Box>
        }
      >
        {employee.children && employee.children.length > 0 && (
          <div style={{ marginLeft: "20px" }}>
            {employee.children.map((child) => (
              <EmployeeHierarchyTree key={child.id} employee={child} />
            ))}
          </div>
        )}
      </TreeItem>
    );
  };
  const calendarComponent = (startDate, endDate) => {
    return (
      <MDBox
        sx={{
          height: "45px",
          width: "45px",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        <MDBox
          sx={{
            height: "15px",
            width: "100%",
            backgroundColor: "#f77272",
          }}
        >
          <MDTypography
            style={{
              fontSize: "12px",
              color: "white",
              textAlign: "center",
            }}
          >
            {moment(startDate, "YYYY-MM-DD").format("MMM")}
          </MDTypography>
        </MDBox>
        <MDTypography
          style={{
            fontSize: "14px",
            color: "black",
            textAlign: "center",
          }}
        >
          {moment(startDate, "YYYY-MM-DD").format("DD")}{" "}
          {startDate != endDate && "-"}{" "}
          {startDate != endDate && moment(endDate, "YYYY-MM-DD").format("DD")}
        </MDTypography>
      </MDBox>
    );
  };

  return (
    <>
      {/* <DashboardNavbar /> */}
      <MDBox pt={2} pb={2} width="100%">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Grid container spacing={1.5}>
              <Grid item xs={12} sm={12}>
                <Card>
                  <MDBox
                    pt={2}
                    pl={2}
                    display="flex"
                    pr={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <MDTypography variant="h5">
                      Announcement/Notification
                    </MDTypography>
                 {permissions?.includes(modulePermissions?.notification_create_permission) && (   <Fab
                      size="small"
                      color="info"
                      onClick={() => {
                        setAddNoticeDialogOpen(true);
                      }}
                    >
                      <MDTypography color="white">+</MDTypography>
                    </Fab>)}
                  </MDBox>
                  <MDBox pl={2} pr={2} pt={4} lineHeight={0}>
                    {noticeBoardList.length != 0 ? (
                      noticeBoardList?.map((val, index) => {
                        return (
                          <MDBox>
                            <MDBox display="flex" flexDirection="row" p={1}>
                              <MDBox
                                display="flex"
                                flexDirection="column"
                                pl={1}
                                pr={1}
                              >
                                <Typography variant="button">
                                  {moment(val.created_at).format("DD-MMM")}
                                </Typography>
                                <Typography variant="button">
                                  {moment(val.created_at).format("hh:mm A")}
                                </Typography>
                              </MDBox>
                              <span
                                style={{
                                  display: "inline-block",
                                  width: "1px",
                                  backgroundColor: "grey",
                                  height: "2em",
                                }}
                              ></span>
                              <MDBox
                                display="flex"
                                flexDirection="column"
                                pl={1}
                                pr={1}
                              >
                                <Typography
                                  variant="button"
                                  style={{
                                    fontWeight: "600",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setNoticeDialogOpen(true);
                                    setNoticeDialogData(val);

                                    if (
                                      !val.read_by?.includes(
                                        loggedUser.employee_id
                                      )
                                    ) {
                                      postReadBy(val.id);
                                    }
                                  }}
                                >
                                  {val.titile}
                                </Typography>
                                <Grid container spacing={1} alignItems={"end"}>
                                  <Grid item>
                                    <MDTypography
                                      sx={{
                                        fontWeight: "regular",
                                        fontSize: 12,
                                      }}
                                    >
                                      {val?.body
                                        ?.replace(/<\/?[^>]+(>|$)/g, "")
                                        .substring(0, 120) + "..."}
                                    </MDTypography>
                                  </Grid>
                                  <Grid item>
                                    <MDBox
                                      display="flex"
                                      pl={1}
                                      width="96.5px"
                                      sx={{
                                        overflow: "auto",
                                        transition:
                                          "transform 0.15s ease-in-out",
                                        "&:hover, &:focus": {
                                          zIndex: "10000",
                                          transform: "scale3d(2.5, 2.5, 2)",
                                          width: "96.5px",
                                        },
                                        scrollbarWidth: "none",
                                        "&::-webkit-scrollbar": {
                                          display: "none",
                                        },
                                        "&-ms-overflow-style:": {
                                          display: "none",
                                        },
                                      }}
                                    >
                                      {Array.from(new Set(val.read_by))?.map(
                                        (e) => (
                                          <Tooltip
                                            title={
                                              filterUserProfilePhoto(e)?.user
                                                .firstname
                                            }
                                            placement="top"
                                          >
                                            <MDAvatar
                                              src={
                                                filterUserProfilePhoto(e)
                                                  ?.img ?? team1
                                              }
                                              alt="team-1"
                                              size="xxs"
                                              title="Smiley face"
                                              sx={avatarStyles}
                                            />
                                          </Tooltip>
                                        )
                                      )}
                                    </MDBox>
                                  </Grid>
                                </Grid>
                              </MDBox>
                            </MDBox>
                          </MDBox>
                        );
                      })
                    ) : (
                      <MDBox align="center">No Data Found!</MDBox>
                    )}
                  </MDBox>
                  <MDBox p={2}></MDBox>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card style={{ overflow: "hidden" }}>
                  <MDBox bgColor="white">
                    <MDBox
                      display="flex"
                      p={2}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MDBox
                        height={60}
                        width={60}
                        mr={1}
                        // mt={-1}
                        display="flex"
                        style={{
                          borderRadius: "10px",
                          boxShadow:
                            "rgba(17, 17, 26, 0.15) 0px 4px 16px 0px inset, rgba(17, 17, 26, 0.05) 0px 8px 32px 0px",
                        }}
                        bgColor="white"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Groups3 style={{ color: colors.info.main }} />
                      </MDBox>
                      <MDBox display="flex" flexDirection="column">
                        <MDTypography mt={-1.2} color="black" variant="body2">
                          Employees
                        </MDTypography>
                        <MDTypography
                          mt={-0.5}
                          textAlign="end"
                          color="black"
                          variant="h4"
                        >
                          {employees.length}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox px={2}>
                      <ProgressLine
                        visualParts={[
                          {
                            percentage: `${employees.length}%`,
                            color: colors.info.main,
                          },
                        ]}
                      />
                    </MDBox>
                  </MDBox>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card style={{ overflow: "hidden" }}>
                  <MDBox bgColor="#656EF1">
                    <MDBox display="flex" p={3} alignItems="center">
                      <MDBox
                        height={45}
                        width={45}
                        mr={1}
                        display="flex"
                        sx={{
                          borderRadius: "10px",
                          boxShadow:
                            "rgba(17, 17, 26, 0.2) 0px 4px 16px 0px inset, rgba(17, 17, 26, 0.05) 0px 8px 32px 0px",
                          transition:
                            "transform 0.15s ease-in-out, box-shadow .15s",
                          "&:hover": {
                            boxShadow:
                              "rgba(18, 18, 27, 0.3) 0px 4px 16px 0px inset, rgba(17, 17, 26, 0.05) 0px 8px 32px 0px",
                            transform: "scale3d(1.2, 1.2, 1)",
                            cursor: "pointer",
                          },
                        }}
                        bgColor="#656EF1"
                        justifyContent="center"
                        alignItems="center"
                        onClick={handleLeavePopoverOpen}
                      >
                        <Groups3 style={{ color: "white" }} />
                      </MDBox>
                      <MDBox>
                        <Badge
                          // badgeContent={4}
                          color="white"
                        >
                          <MDTypography color="white" variant="h5">
                            On Leave
                          </MDTypography>
                        </Badge>
                      </MDBox>
                    </MDBox>
                    <MDBox px={2}>
                      <ProgressLine
                        visualParts={[
                          {
                            percentage: `${userDashboard?.current_leaves?.length}%`,
                            color: "#5660F2",
                          },
                        ]}
                      />
                    </MDBox>
                  </MDBox>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card style={{ overflow: "hidden" }}>
                  <MDBox bgColor="#4285F4">
                    <MDBox display="flex" p={3} alignItems="center">
                      <MDBox
                        height={45}
                        width={45}
                        mr={1}
                        display="flex"
                        sx={{
                          borderRadius: "10px",
                          boxShadow:
                            "rgba(17, 17, 26, 0.2) 0px 4px 16px 0px inset, rgba(17, 17, 26, 0.05) 0px 8px 32px 0px",
                          transition:
                            "transform 0.15s ease-in-out, box-shadow .15s",
                          "&:hover": {
                            boxShadow:
                              "rgba(18, 18, 27, 0.3) 0px 4px 16px 0px inset, rgba(17, 17, 26, 0.05) 0px 8px 32px 0px",
                            transform: "scale3d(1.2, 1.2, 1)",
                            cursor: "pointer",
                          },
                        }}
                        // style={{
                        //   borderRadius: "10px",
                        //   boxShadow:
                        //     "rgba(17, 17, 26, 0.2) 0px 4px 16px 0px inset, rgba(17, 17, 26, 0.05) 0px 8px 32px 0px",
                        // }}
                        bgColor="#4285F4"
                        justifyContent="center"
                        alignItems="center"
                        onClick={handleWFHPopoverOpen}
                      >
                        <Groups3 style={{ color: "white" }} />
                      </MDBox>
                      <MDBox>
                        <Badge
                          // badgeContent={4}
                          color="white"
                          style={{ cursor: "pointer" }}
                          onClick={handleWFHPopoverOpen}
                        >
                          <MDTypography color="white" variant="h5">
                            On WFH
                          </MDTypography>
                        </Badge>
                      </MDBox>
                    </MDBox>
                    <MDBox px={2}>
                      <ProgressLine
                        visualParts={[
                          {
                            percentage: `${userDashboard?.wfh_employees?.length}%`,
                            color: "#2173F6",
                          },
                        ]}
                      />
                    </MDBox>
                  </MDBox>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Card style={{ overflow: "hidden" }}>
                  <Calendar showDetailsHandle={showDetailsHandle} />
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <Card sx={{ paddingLeft: "15px", paddingRight: "15px" }}>
                  <MDBox p={2} pb={0}>
                    <MDTypography variant="h5">Upcoming Leaves</MDTypography>
                  </MDBox>
                  <MDBox p={1} pb={0}>
                    <MDTypography variant="h6" p={1}></MDTypography>
                    {userDashboard?.upcoming_leaves_next_8_days?.map((e) => {
                      return (
                        <MDBox
                          display="flex"
                          alignItems="center"
                          p={0.5}
                          pb={0}
                        >
                          {calendarComponent(e.start_date, e.end_date)}
                          <MDBox display="flex" pl={2}>
                            <MDTypography pl={1} variant="h6">
                              {e?.employee__user__firstname +
                                " " +
                                e?.employee__user__lastname}
                            </MDTypography>
                            <MDBox
                              height={8}
                              width={8}
                              style={{
                                borderRadius: "2px",
                                backgroundColor:
                                  e.status == "pending"
                                    ? "#ffde05"
                                    : e.status == "approved"
                                    ? "green"
                                    : "red",
                              }}
                            ></MDBox>
                          </MDBox>
                        </MDBox>
                      );
                    })}

                    {userDashboard?.upcoming_leaves_next_8_days?.length ==
                      0 && (
                      <MDBox pb={2}>
                        <MDTypography textAlign="center">
                          No Upcoming Leaves!
                        </MDTypography>
                      </MDBox>
                    )}
                    <MDBox p={1}>
                      <Grid container spacing={1.2}>
                        <Grid item>{colorCodeComponent("approved")}</Grid>
                        <Grid item>{colorCodeComponent("pending")}</Grid>
                        <Grid item>{colorCodeComponent("rejected")}</Grid>
                      </Grid>
                    </MDBox>
                  </MDBox>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid item xs={12} sm={12}>
                  <Card
                    sx={{
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      paddingBottom: "10px",
                    }}
                  >
                    <MDBox>
                      <MDBox p={3}>
                        <MDTypography variant="h5">Hierarchy</MDTypography>
                      </MDBox>
                      <TreeView
                        aria-label="file system navigator"
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        defaultExpanded={["1", "2"]}
                      >
                        {orgHierarchy?.map((employee) => (
                          <EmployeeHierarchyTree
                            key={employee.id}
                            employee={employee}
                          />
                        ))}
                      </TreeView>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>

      <div></div>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "aliceBlue",
            boxShadow: "none",
            width: "60%",
            maxWidth: "none",
            padding: "20px",
            borderRadius: "10px",
          },
        }}
        open={noticeDialogOpen}
        onClose={() => setNoticeDialogOpen(false)}
      >
        <DialogTitle align="center">Notice</DialogTitle>
        <DialogContent>
          <MDTypography>{parse(noticeDialogData?.body || "")}</MDTypography>
        </DialogContent>
      </Dialog>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "aliceBlue",
            boxShadow: "none",
            width: "60%",
            height: "60%",
            maxWidth: "none",
            padding: "20px",
            borderRadius: "10px",
          },
        }}
        open={addNoticeDialogOpen}
        onClose={() => setAddNoticeDialogOpen(false)}
      >
        <DialogTitle align="center">Add Notice</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems={"end"}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                variant="standard"
                label="Title"
                onChange={(event) => {
                  setAddNoticePayload((state) => ({
                    ...state,
                    titile: event.target.value,
                  }));
                }}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                placeholder="Select Status"
                options={["draft", "publish", "deactivated"]}
                onChange={(event, newValue) => {
                  setAddNoticePayload((state) => ({
                    ...state,
                    status: newValue,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Status"
                    variant="standard"
                    required
                  />
                )}
              />
            </Grid>
          </Grid>
          <br></br>
          <MDEditor
            onChange={(value) => {
              setAddNoticePayload((state) => ({ ...state, body: value }));
            }}
          ></MDEditor>
        </DialogContent>
        <DialogActions>
          <MDButton
            onClick={() => {
              setAddNoticeDialogOpen(false);
            }}
          >
            Cancel
          </MDButton>
          <MDButton
            onClick={() => {
              postNotice();
            }}
          >
            Create
          </MDButton>
        </DialogActions>
      </Dialog>
      <FlickPopover
        open={Boolean(wfhAnchorEl)}
        anchorEl={wfhAnchorEl}
        children={renderWFHComponent()}
        close={handleWFHPopoverClose}
      />
       <FlickPopover
        open={Boolean(leaveAnchorEl)}
        anchorEl={leaveAnchorEl}
        children={renderLeaveComponent()}
        close={handleLeavePopoverClose}
      />

      {/* <Footer /> */}
    </>
  );
}

export default EmployeeDashboard;
