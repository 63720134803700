import { createSlice } from "@reduxjs/toolkit";

const toastAlertSlice = createSlice({
    name : 'toastAlert',
    initialState : {
        isOpen : false,
        message  : "",
    },
    reducers : {
        setToastOpen : (state,action) => {
            state.isOpen = action.payload.isOpen;
            state.message = action.payload.message;

        },
        setToastClose:(state,action) => {
            state.isOpen= false;
            state.message=false;
        }
       
    }
});

export const {setToastOpen,setToastClose} = toastAlertSlice.actions;
export default toastAlertSlice.reducer;