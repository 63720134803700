import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import dataOffered from "./data";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import SalesTable from "examples/Tables/SalesTable";
import Footer from "examples/Footer";
import * as moment from "moment";

import {
  Autocomplete,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import ApiCalls from "services/flick_erp_api_call";
import React, { useDebugValue, useEffect, useState } from "react";
import MDTypography from "components/MDTypography";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MDInput from "components/MDInput";
import { useDispatch } from "react-redux";
import { setToastOpen } from "components/Toast/toastAlertSlice";
export default function JobOffer({ permissions, modulePermissions }) {
  const statuses = {
    1: "Created",
    2: "Sent",
    3: "Rejected ",
    4: "Accepted",
    5: "Joined",
  };
  var newIndex;
  const dispatch = useDispatch();
  //useState hooks

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [editableRowIndex, setEditableRowIndex] = useState(null);
  const [editedJobOfferData, setEditedJobOfferData] = useState({});
  const [jobOffersDialog, setJobOffersDialog] = useState(false);
  const [jobOffers, setJobOffers] = useState([]);
  const [jobOfferDetail, setJobOffersDetail] = useState({});

  useEffect(() => {
    getJobOffers();
  }, []);
  async function getJobOffers() {
    var res = await ApiCalls.getJobOffers();
    if (res.success && res.code == 200) {
      setJobOffers(res.data);
    }
  }
  async function acceptOffer(id, data) {
    var res = await ApiCalls.patchJobOffer(id, data, false);

    if (res.success && res.code == 200) {
      getJobOffers();
      setJobOffersDialog(false);
    } else {
      setJobOffersDialog(false);
    }
  }
  async function deleteJobOffer(id) {
    var res = await ApiCalls.deleteJobOffer(id);

    if (res.success && res.code == 204) {
      getJobOffers();
    } else {
    }
  }
  // pagination

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox>
          <Card style={{ boxShadow: "0 8px 8px rgba(0, 0, 0, 0.50)" }}>
            <MDTypography variant="h5" p={2}>
              Job Offers
            </MDTypography>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      variant="head"
                      color="black"
                      sx={{ color: "black" }}
                    >
                      Candidate Name
                    </TableCell>
                    <TableCell
                      variant="head"
                      color="black"
                      sx={{ color: "black" }}
                    >
                      Expected Date of joining
                    </TableCell>
                    <TableCell
                      variant="head"
                      color="black"
                      sx={{ color: "black" }}
                    >
                      Approval
                    </TableCell>
                    <TableCell
                      variant="head"
                      color="black"
                      sx={{ color: "black" }}
                    >
                      Status
                    </TableCell>
                    { permissions?.includes(
                    modulePermissions?.delete_permission
                  ) &&(<TableCell
                      variant="head"
                      color="black"
                      sx={{ color: "black" }}
                    >
                      Action
                    </TableCell>)}
                  </TableRow>
                </TableBody>
                <TableBody>
                  {(rowsPerPage > 0
                    ? jobOffers.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : jobOffers
                  )?.map((row, index) => {
                    const actualIndex = index + page * rowsPerPage;
                    newIndex = actualIndex;
                    return (
                      <TableRow key={row.id}>
                        {" "}
                        <TableCell
                          sx={{ cursor: "pointer" }}
                          style={{ fontWeight: "bold" }}
                          onClick={
                            () => {
                              setJobOffersDetail(row);
                              setJobOffersDialog(true);
                            }
                            // handleOnNameClick(actualIndex, row)
                          }
                        >
                          {row.candidate.full_name}
                        </TableCell>
                        <TableCell>
                          {moment(row.expected_doj).format("YYYY-MM-DD")}
                        </TableCell>
                        <TableCell>
                          {row.approve_by == null
                            ? "Waiting for Approval"
                            : "Approved"}
                        </TableCell>
                        <TableCell>{statuses[row.status]}</TableCell>
                        {permissions?.includes(
                          modulePermissions?.delete_permission
                        ) && (
                          <TableCell>
                            <Tooltip title="Delete">
                              <IconButton
                                size="small"
                                aria-label="delete"
                                onClick={() => {
                                  deleteJobOffer(row.uuid);
                                  //  handleDeleteClick(row, actualIndex)
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={10}
                    count={jobOffers.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    slotProps={{
                      select: {
                        "aria-label": "rows per page",
                      },
                      actions: {
                        showFirstButton: true,
                        showLastButton: true,
                      },
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableFooter>
              </Table>
            </TableContainer>
          </Card>
        </MDBox>
        {/* <Footer /> */}
      </DashboardLayout>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "white",
            // backgroundColor: '#F5F5F5',
            width: "50%",
            height: "400px",
            maxWidth: "none",
            padding: "20px",
          },
        }}
        open={jobOffersDialog}
        onClose={() => {
          setJobOffersDialog(false);
        }}
      >
        {/* <DialogTitle style={{ padding: '20px', marginLeft: '30px', fontWeight: 'bold', fontSize: '24px' }}> */}
        <DialogTitle>
          <MDTypography>Job Offer</MDTypography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="name"
                label="Name"
                type="text"
                fullWidth
                variant="standard"
                name="full_name"
                value={jobOfferDetail?.candidate?.full_name}
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="e_doj"
                label="Expected Date of Joining"
                type="text"
                fullWidth
                variant="standard"
                name="Expected Date of Joining"
                value={moment(jobOfferDetail.expected_doj).format("YYYY-MM-DD")}
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="experience"
                label="Experience"
                type="text"
                fullWidth
                variant="standard"
                name="experience"
                value={jobOfferDetail?.candidate?.experience}
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="email"
                label="Email"
                type="text"
                fullWidth
                variant="standard"
                name="email"
                value={jobOfferDetail?.candidate?.email}
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="ctc"
                label="CTC"
                type="text"
                fullWidth
                variant="standard"
                name="ctc"
                value={jobOfferDetail?.ctc}
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="active"
                label="Active"
                type="text"
                fullWidth
                variant="standard"
                name="actice"
                value={jobOfferDetail?.active}
                inputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {(permissions?.includes(modulePermissions?.update_permission) && jobOfferDetail.status == 1) &&  (
            <Button
              variant="gradient"
              color="dark"
              onClick={() => {
                acceptOffer(jobOfferDetail.uuid, { status: 2 });
              }}
            >
              Verify & Send
            </Button>
          )}
          <Button
            variant="gradient"
            color="dark"
            onClick={() => {
              setJobOffersDialog(false);
              // setDesignationsDialog(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
