import MDSnackbar from "components/MDSnackbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToastClose, setToastOpen } from "./toastAlertSlice";
import { Snackbar, SnackbarContent } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { NotificationAddRounded, NotificationImportant } from "@mui/icons-material";

export function ToastMessageAlert() {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState(false);

  const isOpen = useSelector((state) => state?.toastAlert?.isOpen) ?? false;
  const message = useSelector((state) => state?.toastAlert?.message) ?? "";

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  useEffect(() => {
setContent(message)
  }, [message]);

  const dispatch = useDispatch();

  return (
    <Snackbar
    //   icon="notifications"
    //   title="Flick ERP"
    anchorOrigin={{ vertical: "top", horizontal: "center" }}
    //   message={content}
      open={open}
      autoHideDuration={3000}
    //   sx={{backgroundColor:"red", color:"red", background:"red"}}
      onClose={() => {
        dispatch(setToastClose())
      }}

      close={() => {
        dispatch(setToastClose())
      }}
    >
 <SnackbarContent style={{
      backgroundColor:'#007bed',
    //   height:"50px",
      justifyContent:"flex-start",
      alignItems:"flex-start"
    }}
    message={
     <MDBox display="flex" alignItems="center">
          <NotificationImportant
        
          color="white"
          ></NotificationImportant>
        <MDTypography sx={{padding:"10px"}} variant="h6" color="white">
        {message}
    </MDTypography>
     </MDBox> 
    }
  />
    </Snackbar>
  );
}
