/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { ThemeProvider } from "@mui/material/styles";


// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";
import NotFound from "not_found";
import SignInIllustration from "layouts/authentication/sign-in/illustration";

import theme from "assets/theme";
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";
import { Provider } from "react-redux";
import {store} from "./store"
import { TextViewEditorBox } from "components/TextViewEditorBox";
import history from "./helpers/navigation" ;
ReactDOM.render(
  <BrowserRouter  history={history}>
  <Provider store={store}>
    <MaterialUIControllerProvider>    
      < App />
    </MaterialUIControllerProvider>
  </Provider>  
  </BrowserRouter>,
  document.getElementById("root")
);
