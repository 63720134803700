import React, { useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

import Autocomplete from "@mui/material/Autocomplete";
import { Grid, TextareaAutosize, Tooltip, Typography } from "@mui/material";
import { TextField } from "@mui/material";
import MDButton from "components/MDButton";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import AddIcon from "@mui/icons-material/Add";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ApiCalls from "services/flick_erp_api_call";
import { setToastOpen } from "components/Toast/toastAlertSlice";
import { useDispatch } from "react-redux";
import { developmentMessage } from "services/apis";
import { SelectHierarchicalEmployee } from "components/SelectHierarchicalEmployee";
import moment from "moment";
import SalesTable from "examples/Tables/SalesTable";
import { addTime } from "helpers/DateTime";
import { timeStringToHour } from "helpers/DateTime";
import { CrisisAlert, Edit, PunchClock } from "@mui/icons-material";
import FormField from "layouts/applications/wizard/components/FormField";

// import DatePicker from "react-flatpickr";
function Attendance({ permissions, modulePermissions }) {
  const loggedUser = JSON.parse(localStorage.getItem("user"));
  const [selectedType, setSelectedType] = useState();
  const [selectedMonth, setSelectedMonth] = useState(
    `${new Date().getFullYear()}-${new Date().toLocaleString("en-GB", {
      month: "2-digit",
    })}`
  );

  const [totalAttendance, setTotalAttendance] = useState();
  const [editAttendancePayload, setEditAttendancePayload] = useState({});

  const [selectedEmp, setSelectedEmp] = useState(
    JSON.parse(localStorage.getItem("user")).employee_id
  );
  const [inoutDialogOpen, setInoutDialog] = useState(false);
  const [editAttendanceDialogOpen, setEditAttendanceDialogOpen] =
    useState(false);
  const [missPunchDialogOpen, setMissPunchDialogOpen] = useState(false);
  const [missPunchDetailsPayload, setMissPunchDetailsPayload] = useState({});
  const [statusDialogOpen, setStatusDialog] = useState(false);
  const [SelectHierarchicalEmployeError, setSelectHierarchicalEmployeError] =
    useState("");
  const [mispunchCommentError, setMispunchCommentError] = useState("");
  const [inOutData, setInoutData] = useState([]);
  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const handleView = (data) => {
    setInoutData(
      data.map((e) => ({
        id: e.id,
        type: e.type,
        in_time: moment(e.in_time, "HH:mm:ss").format("HH:mm A"),
        out_time: moment(e.out_time, "HH:mm:ss").format("HH:mm A"),
        duration: moment(e.duration, "HH:mm:ss").format("HH:mm:ss"),
      }))
    );
    setInoutDialog(true);
  };

  const handleSubmit = () => {
    fetchAttendanceLists(
      `?employee__id=${selectedEmp}&year=${selectedMonth.split("-")[0]}&month=${
        selectedMonth.split("-")[1]
      }`
    );
  };
  const [attendanceData, setAttendanceData] = useState({
    columns: [
      {
        Header: "DATE",
        accessor: "date",
        Cell: ({ row }) => (
          <div
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
            }}
          >
            <span style={{}}>{row?.original?.date}</span>
            <span>{days[new Date(row?.original?.date).getDay()]}</span>
          </div>
        ),
      },
      {
        Header: "NAME",
        accessor: "name",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{}}>{row.original.employee.user.firstname}</span>
            <span style={{ marginLeft: "0.2rem" }}>
              {row.original.employee.user.lastname}
            </span>
          </div>
        ),
      },
      {
        Header: "CLOCKED IN/OUT",
        accessor: "time",
        Cell: ({ row }) =>
          row.original?.data?.holiday ? (
            <>{row.original?.data?.holiday}</>
          ) : row.original?.shift?.is_wfh ? (
            <>WFH</>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {row.original?.data?.leave && <span style={{}}>On Leave</span>}
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{}}>
                  {row?.original?.data?.in_time != undefined
                    ? moment(row?.original?.data?.in_time, "hh:mm:ss").format(
                        "hh:mm A"
                      ) + " -"
                    : ""}
                </span>
                <span style={{ marginLeft: "0.2rem" }}>
                  {row?.original?.data?.in_time != undefined
                    ? moment(row?.original?.data?.out_time, "hh:mm:ss").format(
                        "hh:mm A"
                      )
                    : ""}
                </span>
              </div>
            </div>
          ),
      },
      {
        Header: "BREAK",
        accessor: "break",
        Cell: ({ row }) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {row?.original?.data?.leave?.status && (
              <span
                style={{
                  color:
                    row?.original?.data?.leave?.status == "approved"
                      ? "green"
                      : row?.original?.data?.leave?.status == "pending"
                      ? "yellow"
                      : "red",
                }}
              >
                {row?.original?.data?.leave?.status}
              </span>
            )}
            <span style={{}}>{row?.original?.data?.in_break}</span>
          </div>
        ),
      },
      {
        Header: "DURATION",
        accessor: "duration",
        Cell: ({ row }) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {row?.original?.data?.leave?.number_of_hours && (
              <span style={{ color: "red" }}>
                {"Leave " +
                  row?.original?.data?.leave?.number_of_hours +
                  " Hours"}
              </span>
            )}
            <span style={{}}>{row?.original?.data?.duration}</span>
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "inout",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Info">
              <IconButton
                aria-label="info"
                size="small"
                onClick={() => {
                  handleView(row.original.attendance_lists);
                }}
              >
                <InfoIcon />
              </IconButton>
            </Tooltip>
            {permissions.includes(modulePermissions.update_permission) &&
              parseInt(row?.original?.data?.duration?.split("Hrs")[0]) < 8 &&
              row?.original?.data?.leave == undefined && (
                <Tooltip title="Take Action">
                  <IconButton
                    aria-label="info"
                    size="small"
                    onClick={() => {
                      setEditAttendancePayload({
                        attendance_log: row?.original?.id,
                        type: "in",
                      });
                      setEditAttendanceDialogOpen(true);
                    }}
                  >
                    <CrisisAlert />
                  </IconButton>
                </Tooltip>
              )}
            {/* <Tooltip title="Add Status">
              <IconButton
                aria-label="Add Status"
                size="small"
                onClick={() => {
                  setStatusDialog(true);
                }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip> */}
          </div>
        ),
      },
    ],
    rows: [],
  });

  //--------------------------------------------------------
  React.useEffect(() => {
    fetchAttendanceLists(
      `?employee__id=${
        loggedUser.employee_id
      }&year=${new Date().getFullYear()}&month=${new Date().getMonth() + 1}`
    );
  }, []);

  const fetchAttendanceLists = async (queryParams) => {
    const res = await ApiCalls.getAttendanceList(queryParams);
    if (res.success && res.code === 200) {
      attendanceData.rows = res.data;
      var attendance = attendanceData.rows.reduce(
        (a, b) =>
          a +
          (b.shift?.is_wfh ? 8.65 : timeStringToHour(b?.data?.duration ?? "")),
        0
      );
      setTotalAttendance(parseFloat(attendance.toFixed(2)));
      setAttendanceData({ ...attendanceData });
    } else {
    }
  };
  async function handleSubmitAttendance() {
    var res = await ApiCalls.uploadAttendanceCorrection(
      { ...editAttendancePayload, org: loggedUser.org_id },
      false
    );

    if (res.success) {
      setEditAttendanceDialogOpen(false);
      handleSubmit();
    }
  }
  async function handleSubmitMisspunch() {
    debugger
    var hasError = false ;
    if(missPunchDetailsPayload.comment == undefined || missPunchDetailsPayload.comment == "") {
   setMispunchCommentError("Comment is required");
   hasError = true ;
    } else {
      setMispunchCommentError("");
    }
    if(!hasError) {
      var res = await  ApiCalls.attendanceConsiderRequest({
        attendance_list: missPunchDetailsPayload.id,
        comment: missPunchDetailsPayload.comment
      },false);
      if(res.success ){

      }
      setMissPunchDetailsPayload({});
      setMissPunchDialogOpen(false);
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={1}>
        <MDBox>
          <Card sx={{ overflow: "visible" }}>
            <MDBox
              mt={-3}
              p={3}
              lineHeight={1}
              bgColor="#007bed"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                color: " white",
                borderRadius: 2,
                marginLeft: "0.9rem",
                marginRight: "0.9rem",
              }}
            >
              Filter By Employee & Month
            </MDBox>

            <MDBox
              p={3}
              lineHeight={1}
              sx={{
                display: "flex",
                justifyContent: "end",
                borderRadius: 3,
              }}
            >
              <Grid
                sx={{ justifyContent: "flex-end" }}
                container
                spacing={3}
                alignItems={"flex-end"}
              >
                <Grid item>
                  <TextField
                    variant="standard"
                    InputLabelProps={{}}
                    id="month"
                    type="month"
                    value={selectedMonth}
                    defaultValue={selectedMonth}
                    name="Date"
                    label="Select Month"
                    onChange={(event) => {
                      setSelectedMonth(event.target.value);
                    }}
                    InputProps={{}}
                  />
                </Grid>
                <Grid item>
                  <SelectHierarchicalEmployee
                    label="Select Employee"
                    onSelected={(e) => {
                      setSelectedEmp(e ?? loggedUser.employee_id);
                    }}
                  ></SelectHierarchicalEmployee>
                </Grid>
                <Grid item>
                  <MDButton
                    variant="gradient"
                    color="dark"
                    onClick={handleSubmit}
                  >
                    Submit
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </MDBox>
      </MDBox>

      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox
              mt={-3}
              p={3}
              lineHeight={1}
              bgColor="#007bed"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                color: "white",
                borderRadius: 2,
                marginLeft: "0.9rem",
                marginRight: "0.9rem",
              }}
            >
              <MDTypography color="white">Attendance</MDTypography>
            </MDBox>
            <MDBox
              p={2}
              lineHeight={1}
              sx={{ display: "flex", justifyContent: "space-between" }}
            ></MDBox>
            <DataTable
              table={attendanceData}
              entriesPerPage={{
                defaultValue: 30,
                entries: [5, 10, 15, 20, 25, 30],
              }}
              showTotalEntries={false}
              isSorted={false}
            ></DataTable>
            <MDBox
              p={1}
              display="flex"
              flexDirection="row"
              justifyContent="space-around"
            >
              <MDTypography pl={2} variant="h6">
                Total{" "}
              </MDTypography>
              <MDTypography pl={2} variant="h6" fontWeight={"regular"}>
                {parseInt(
                  attendanceData.rows.reduce(
                    (a, b) => a + (b?.data?.holiday ? 0 : 8.65),
                    0
                  )
                )}{" "}
                Hours
              </MDTypography>
              <MDTypography pl={2} variant="h6">
                Duration
              </MDTypography>
              <MDTypography pl={2} variant="h6" fontWeight={"regular"}>
                {parseInt(Number(totalAttendance)) +
                  " Hours" +
                  ` ${
                    totalAttendance?.toString()?.split(".").length == 2
                      ? `${parseInt(
                          `${
                            (totalAttendance?.toString()?.split(".")[1] * 60) /
                            100
                          }`.slice(0, 2)
                        )}`
                      : "0"
                  } Minutes`}
              </MDTypography>
            </MDBox>
          </Card>
        </MDBox>
      </MDBox>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "white",
            boxShadow: "none",
            width: "auto",
            maxWidth: "none",
            borderRadius: "10px",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        open={inoutDialogOpen}
        onClose={() => setInoutDialog(false)}
      >
        <DialogTitle
          style={{
            width: "100%",
            padding: "20px",
            borderBottom: "10px solid ",
            backgroundColor: "#007bed",
            color: "white",
          }}
        >
          <Typography sx={{ color: "white", fontWeight: "Medium" }}>
            In Out Details
          </Typography>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "4px",
          }}
        >
          <MDBox align="center" p={2}>
            <SalesTable
              title=""
              rows={inOutData}
              action={{
                action: [
                  {
                    icon: <PunchClock />,
                    key: "edit",
                    idKey: "id",
                    presenseKey: ["mis_punch", "out"],
                    presenseTypeKey: "type",
                  },
                ],
              }}
              onActionClick={(v) => {
                if (v.action == "edit") {
                  setInoutDialog(false);
                  setMissPunchDialogOpen(true);
                  setMissPunchDetailsPayload((state) => ({
                    ...state,
                    id: v.id,
                  }));
                }
              }}
              ignore={["id"]}
            />
          </MDBox>
        </DialogContent>
      </Dialog>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "white",
            boxShadow: "none",
            // height: "500px",
            // width: "500px",
            maxWidth: "none",
            borderRadius: "10px",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        open={missPunchDialogOpen}
        onClose={() => {
          setMissPunchDialogOpen(false);
        }}
      >
        <DialogTitle
          style={{
            width: "100%",
            padding: "20px",
            borderBottom: "10px solid ",
            backgroundColor: "#007bed",
            color: "white",
          }}
        >
          <Typography sx={{ color: "white", fontWeight: "Medium" }}>
            MissPunch Details
          </Typography>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "4px",
          }}
        >
          <MDBox align="end" p={2}>
            <TextField
              label="Add Comment"
              required
              fullWidth
              onChange={(e) => {
                setMissPunchDetailsPayload((state) => ({
                  ...state,
                  comment: e.target.value,
                }));
              }}
              error={!!mispunchCommentError}
              helperText={mispunchCommentError}
              style={{ paddingRight: "10px", paddingBottom: "10px" }}
            />
            <MDButton color="info" onClick={() => {
handleSubmitMisspunch()
            }}>
              Submit
            </MDButton>
          </MDBox>
        </DialogContent>
      </Dialog>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "white",
            boxShadow: "none",
            maxWidth: "none",
            borderRadius: "10px",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        open={editAttendanceDialogOpen}
        onClose={() => setEditAttendanceDialogOpen(false)}
      >
        <DialogTitle
          style={{
            width: "100%",
            padding: "20px",
            borderBottom: "10px solid ",
            backgroundColor: "#007bed",
            color: "white",
          }}
        >
          <Typography sx={{ color: "white", fontWeight: "Medium" }}>
            Edit Attendance Details
          </Typography>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "4px",
          }}
        >
          <MDBox align="center" p={2}>
            <Grid container spacing={1} alignItems={"flex-end"}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={[
                    { value: "5 mins", duration: "00:05:00" },
                    { value: "10 mins", duration: "00:10:00" },
                    { value: "20 mins", duration: "00:20:00" },
                    { value: "30 mins", duration: "00:30:00" },
                  ]}
                  getOptionLabel={(v) => v.value}
                  onChange={(event, values) => {
                    let [hour, minute] = values?.duration?.split(":");
                    setEditAttendancePayload((state) => ({
                      ...state,
                      duration: values?.duration,
                      out_time: moment(editAttendancePayload?.in_time, "HH:mm")
                        .add(hour, "hours")
                        .add(minute, "minutes")
                        .format("HH:mm"),
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Time Difference" />
                  )}
                ></Autocomplete>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      label="Start Time"
                      fullWidth
                      inputProps={{ type: "time" }}
                      variant="standard"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => {
                        let [hour, minute] =
                          editAttendancePayload?.duration?.split(":") ?? [
                            "0",
                            "0",
                          ];
                        setEditAttendancePayload((state) => ({
                          ...state,
                          in_time: e.target.value,
                          out_time: moment(e.target.value, "HH:mm")
                            .add(hour, "hours")
                            .add(minute, "minutes")
                            .format("HH:mm"),
                        }));
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      label="End Time"
                      value={editAttendancePayload?.out_time}
                      fullWidth
                      inputProps={{ type: "time", readOnly: true }}
                      variant="standard"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => {}}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  variant="standard"
                  label="Note"
                  fullWidth
                  placeholder="Attendance Correction Note"
                  // value={leaveRequestPayload.reason}
                  onChange={(e) => {
                    setEditAttendancePayload((state) => ({
                      ...state,
                      comment: e.target.value,
                    }));
                    //   isCreateEmployee ? setEmail(e.target.value) : null;
                    //   setEmployee({ ...Employee, email: e.target.value });
                  }}
                  required
                />
              </Grid>
            </Grid>
            <MDBox p={2} align="center">
              <MDButton
                variant="gradient"
                color="info"
                onClick={() => handleSubmitAttendance()}
              >
                Submit
              </MDButton>
            </MDBox>
          </MDBox>
        </DialogContent>
      </Dialog>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "white",
            boxShadow: "none",
            width: "500px",
            height: "auto",
            maxWidth: "none",
            borderRadius: "10px",
          },
        }}
        open={statusDialogOpen}
        onClose={() => setStatusDialog(false)}
      >
        <DialogTitle
          style={{
            width: "100%",
            padding: "20px",
            backgroundColor: "#007bed",
            color: "white",
          }}
        >
          <Typography sx={{ color: "white", fontWeight: "Medium" }}>
            Add Status
          </Typography>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "4px",
          }}
        >
          <MDBox>
            <MDBox py={3} px={2}>
              <TextField
                fullWidth
                id="outlined-multiline-flexible"
                label="What you have done today?"
                multiline
                maxRows={4}
                rows={10}
              />
            </MDBox>
            <MDBox pb={3} px={2}>
              <TextField
                fullWidth
                id="outlined-multiline-flexible"
                label="Any blockers?"
                multiline
                rows={3}
              />
            </MDBox>
            <MDBox align="end" px={3} pb={1}>
              <MDButton
                variant="gradient"
                color="info"
                size="large"
                fullWidth
                onClick={() => {}}
              >
                Submit
              </MDButton>
            </MDBox>
          </MDBox>
        </DialogContent>
      </Dialog>

      <Footer />
    </DashboardLayout>
  );
}
export default Attendance;
