import { Add, Delete, Edit } from "@mui/icons-material";
import {
  Autocomplete,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  Icon,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import FormField from "layouts/applications/wizard/components/FormField";
import moment from "moment";
import { useEffect, useState } from "react";
import ApiCalls from "services/flick_erp_api_call";

export function ShiftSetting({ permissions, modulePermissions}) {

  const [officeShiftDialog, setOfficeShiftDialog] = useState(false);
  const [officeShiftPayload, setOfficeShiftPayload] = useState({
    time_from: "10:00",
  });
 

  useEffect(() => {
    getOfficeShifts();
  }, [officeShifts]);
  // get office Shifts
  async function getOfficeShifts() {
    const res = await ApiCalls.getOfficeShifts();
    if (res.success && res.code === 200) {
      officeShifts.rows = res.data;
      setOfficeShifts({ ...officeShifts });
    } else {
    }
  }
  async function deleteOfficeShift(id) {
    const res = await ApiCalls.deleteOfficeShift(id);
    if (res.success && res.code === 200) {
      getOfficeShifts()
    } else {
    }
  }
  async function createOfficeShifts() {
    var data = {org:JSON.parse(localStorage.getItem("user")).org_id,...officeShiftPayload}
    const res = await ApiCalls.createOfficeShifts(data,false);
    if (res.success && res.code === 201) {
    getOfficeShifts()
    } else {
        
    }setOfficeShiftDialog(false)
    setOfficeShiftPayload({
      time_from: "10:00",
    });
  }
  async function updateOfficeShifts() {
    var data = {org:JSON.parse(localStorage.getItem("user")).org_id,...officeShiftPayload}
    const res = await ApiCalls.patchOfficeShifts(data,officeShiftPayload.id,false);
    if (res.success && res.code === 200) {
    getOfficeShifts()
    } else {
        
    }setOfficeShiftDialog(false)
    setOfficeShiftPayload({
      time_from: "10:00",
    });
  }
  var [officeShifts, setOfficeShifts] = useState({
    columns: [
      {
        Header: "Created Date",
        accessor: "created_at",
        Cell: ({ row }) => moment(row.original.created_at).format("YYYY-MM-DD"),
      },
      {
        Header: "Shift Name",
        accessor: "name",
      },
      {
        Header: "Time From",
        accessor: "time_from",
      },
      {
        Header: "Shift hours",
        accessor: "shift_hours",
      },
      {
        Header: "Break Time",
        accessor: "break_time",
      },
      {
        Header: "Action",
        accessor: "edit_delete",
        Cell: ({ row }) =>
        
      (permissions?.includes(modulePermissions?.office_shift_delete_permission) || permissions?.includes(modulePermissions?.office_shift_update_permission)) ?  (
          <> 
        { permissions?.includes(modulePermissions?.office_shift_update_permission) && (  <Tooltip title="Edit">
          <IconButton size="small" onClick={() => {
            setOfficeShiftDialog(true)
            setOfficeShiftPayload((state)=>({...state,...row.original, requestType:"Edit", }))


          }}>
            <Edit />
          </IconButton>
        </Tooltip>)}
          
          
         { permissions?.includes(modulePermissions?.office_shift_delete_permission) &&(  <Tooltip title="Delete">
              <IconButton size="small" onClick={() => {

                deleteOfficeShift(row.original.id)
              }}>
                <Delete />
              </IconButton>
            </Tooltip>)}
</>
        ) : (
            <></>
        ),
      },
    ],
    rows: [],
  });
  return (
    <MDBox>
      {permissions?.includes(modulePermissions?.office_shift_create_permission) &&(<MDBox align="end">
        <Fab
          color="info"
          onClick={() => {
            setOfficeShiftPayload((state)=>({ time_from: "10:00", requestType:"create"}))
            setOfficeShiftDialog(true);
          }}
        >
          <Add></Add>
        </Fab>
        </MDBox>)}
        <DataTable table={officeShifts} />

      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "white",
            boxShadow: "none",
            width: "600px",
            height: "auto",
            maxWidth: "none",
            borderRadius: "10px",
            cursor: "pointer",
          },
        }}
        open={officeShiftDialog}
        onClose={() => {
          setOfficeShiftDialog(false);
        }}
      >
        <DialogTitle
          style={{
            width: "100%",
            padding: "20px",
            backgroundColor: "#4FA7F9",
            color: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "Medium" }}>
              Create Office Shifts
            </Typography>
            <Icon
              fontSize="medium"
              onClick={() => {
                setOfficeShiftDialog(false);
              }}
            >
              close
            </Icon>
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "15px",
          }}
        >
          <Card id="basic-info" sx={{ overflow: "visible", padding: "15px" }}>
            <MDBox component="form" pb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Name"
                    value={officeShiftPayload.name}
                    onChange={(e) => {
                      setOfficeShiftPayload((state) => ({
                        ...state,
                        name: e.target.value,
                      }));
                    }}
                    inputProps={{}}
                    fullWidth
                    required
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Time From"
                    value={moment(officeShiftPayload.time_from, [
                      "h:mm A",
                    ]).format("HH:mm")}
                    inputProps={{ type: "time" }}
                    onChange={(e) => {
                      setOfficeShiftPayload((state) => ({
                        ...state,
                        time_from: e.target.value,
                      }));
                    }}
                    fullWidth
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        options={[7, 8, 9, 10]}
                        value={officeShiftPayload.shift_hours}

                        onChange={(event, newValue) => {
                          if (newValue) {
                            setOfficeShiftPayload((state) => ({
                              ...state,
                              shift_hours: newValue,
                            }));
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Shift Hour"
                            variant="standard"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        options={[20, 30, 45, 60]}
                        value={officeShiftPayload.break_time}

                        onChange={(event, newValue) => {
                          if (newValue) {
                            setOfficeShiftPayload((state) => ({
                              ...state,
                              break_time: newValue,
                            }));
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Break Time"
                            variant="standard"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* <Grid item xs={12} md={6}></Grid> */}
              </Grid>
              <MDBox pt={4} display="flex" justifyContent="center">
                <MDButton variant="gradient" color="dark" onClick={() => {

officeShiftPayload.requestType == "create" ?
                    createOfficeShifts() : updateOfficeShifts()
                }}>
                  Submit
                </MDButton>
              </MDBox>
            </MDBox>
          </Card>
        </DialogContent>
      </Dialog>
    </MDBox>
  );
}
