import { Info, Margin } from "@mui/icons-material";
import { Card, IconButton, Tooltip, Select, MenuItem } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useState } from "react";

export function Batch() {
  const [filter, setFilter] = useState({
    month: "",
    year: "",
  });
  const [batch, setBatch] = useState({
    columns: [
      {
        Header: "BATCH",
        accessor: "batch",
      },
      {
        Header: "Year",
        accessor: "year",
      },
      {
        Header: "Action",
        accessor: "inout",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Info">
              <IconButton aria-label="info" size="small" onClick={() => {}}>
                <Info></Info>
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
    ],
    rows: [
      { batch: "January", year: "2024" },
      { batch: "February", year: "2024" },
      { batch: "March", year: "2024" },
      { batch: "April", year: "2024" },
      { batch: "May", year: "2024" },
      { batch: "June", year: "2024" },
      { batch: "July", year: "2024" },
    ],
  });

  const filteredRows = batch.rows.filter((row) => {
    if (filter.month && filter.year) {
      return row.batch === filter.month && row.year === filter.year;
    } else if (filter.month) {
      return row.batch === filter.month;
    } else if (filter.year) {
      return row.year === filter.year;
    } else {
      return true;
    }
  });
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <br />
      <Card>
        <MDBox pt={3} pl={3} pb={4}sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: 3,
                '@media (max-width: 600px)': {
                  flexWrap: "wrap",
                },
              }}>
                <MDBox>
          <MDTypography variant="h4">Batch</MDTypography></MDBox>
          <MDBox sx={{
                display: "flex",
                justifyContent: "end",
                
              }}>
          <MDBox sx={{
                marginRight: '10px',
                marginLeft: '10px',
                '@media (max-width: 600px)': {
                  marginLeft: '0px',
                },
              }}>
          <label style={{ fontSize: 14, display:"block",  color: "#7b809a" }}>Select Month:</label>
          <Select
            value={filter.month}
            onChange={(e) => setFilter({ ...filter, month: e.target.value })}
            style={{width: "150px", padding: "10px" }}
          >
            <MenuItem value="">All Months</MenuItem>
            <MenuItem value="January">January</MenuItem>
            <MenuItem value="February">February</MenuItem>
            <MenuItem value="March">March</MenuItem>
            <MenuItem value="April">April</MenuItem>
            <MenuItem value="May">May</MenuItem>
            <MenuItem value="June">June</MenuItem>
            <MenuItem value="July">July</MenuItem>
          </Select>
          </MDBox>
          <MDBox
          sx={{
            marginRight: '15px',
            marginLeft: '10px',
          }}
          >
          <label style={{ fontSize: 14, display:"block", color: "#7b809a" }}>Select Year:</label>
          <Select
            value={filter.year}
            onChange={(e) => setFilter({ ...filter, year: e.target.value })}
            style={{width: "150px", padding: "10px" }}
          >
            <MenuItem value="">All Years</MenuItem>
            <MenuItem value="2024">2024</MenuItem>
            {/* Add other years as needed */}
          </Select>
          </MDBox>
          </MDBox>
        </MDBox>
        <MDBox>
          <DataTable table={{ ...batch, rows: filteredRows }} />
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}
