import {
  Autocomplete,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  Icon,
  TextField,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Header from "layouts/employees/employee_view/components/Header";
import { useEffect, useState } from "react";
import ApiCalls from "services/flick_erp_api_call";
import FormField from "layouts/pages/account/components/FormField";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { FileUpload } from "@mui/icons-material";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import Footer from "examples/Footer";
import moment from "moment";
import { LeaveBalanceComponent } from "./Components/LeaveBalance";
import { LeaveHistoryComponent } from "./Components/LeaveHistory";
import { LeaveTransactionComponent } from "./Components/LeaveTransaction";

export function Leaves({ permissions, modulePermissions }) {
  const [employee, setEmployee] = useState({});
  const [employees, setEmployees] = useState([]);
  const [reload, setReload] = useState();
  const [employeeLeaveBalance, setEmployeeLeaveBalance] = useState([]);
  const [createDialog, setCreateDialog] = useState(false);
  const [leaveRequestPayload, setLeaveRequestPayload] = useState({
    start_time: moment(new Date()).format("HH:mm"),
    start_date: moment(new Date()).format("YYYY-MM-DD"),
    end_date: moment(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      )
    ).format("YYYY-MM-DD"),
    number_of_days: 1,
  });
  const [RemarkError, setRemarkError] = useState("");
  const [LeaveHourError, setLeaveHourError] = useState("");
  const [LeaveBalanceError, setLeaveBalanceError] = useState("");
  const [startTimeError, setStartTimeError] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");

  const user_id = JSON.parse(localStorage.getItem("user")).employee_id;
  const org_id = JSON.parse(localStorage.getItem("user")).org_id;
  useEffect(() => {
    getEmployee();
    getEmployees();
    getEmployeeLeaveBalance();
  }, []);
  useEffect(() => {
    getEmployeeLeaveBalance();
  }, [reload]);
  async function getEmployee() {
    var res = await ApiCalls.getEmployee(user_id);
    if (res.success && res.code == 200) {
      setEmployee(res.data);
    } else {
    }
  }
  async function getEmployees() {
    var res = await ApiCalls.getEmployeesList();
    if (res.success && res.code == 200) {
      setEmployees(res.data);
    } else {
    }
  }
  // get leave balance
  async function getEmployeeLeaveBalance() {
    var res = await ApiCalls.getEmployeeLeaveBalance(user_id);
    if (res.success && res.code == 200) {
      setEmployeeLeaveBalance(res.data);
    }
  }

  async function createLeaveRequest() {
    var hasError = false ;

    if(leaveRequestPayload.reason == undefined || leaveRequestPayload.reason.length < 99) {
      hasError = true
      setRemarkError("Remark needs to be have atleast 100 characters.")
    }else {
      setRemarkError("")
    }

    if(leaveRequestPayload.leave_category_name == undefined) {
      hasError=true;
      setLeaveBalanceError("Leave Balance is required")
    }else {
      setLeaveBalanceError("")
    }
    if(leaveRequestPayload.number_of_hours == undefined) {
      hasError=true;
      setLeaveHourError("Hours is required")
    }else { 
      setLeaveHourError("")
    }
    if(!hasError) {
      var numberOfHours;
      if (leaveRequestPayload.number_of_hours >= 8) {
        var res = await ApiCalls.getShiftHours(
          `?start_date=${leaveRequestPayload.start_date}&end_date=${leaveRequestPayload.end_date}&employee_id=${user_id}`
        );
        if (res.success) {
          numberOfHours = res.data.total_shift_hours;
        }
      } else {
        numberOfHours = leaveRequestPayload.number_of_hours;
      }
      if (numberOfHours != undefined) {
        var data = {
          start_date: leaveRequestPayload.start_date,
          end_date: leaveRequestPayload.number_of_hours >= 8 ? leaveRequestPayload.end_date :  leaveRequestPayload.start_date,
          number_of_hours: numberOfHours,
          status: "pending",
          reason: leaveRequestPayload.reason,
          org: org_id,
          employee: user_id,
          leave_category_name: leaveRequestPayload.leave_category_name,
        };
  
        var res = await ApiCalls.postLeaveRequest(data, false);
        if (res.success && res.code == 201) {
          setCreateDialog(false);
          setReload(Math.random().toString())
          getEmployeeLeaveBalance();
        } else {
          setCreateDialog(false);
        }
      }
    }
   
  }

  const [tabComponents, setTabComponents] = useState([]);

  useEffect(() => {
    setTabComponents([
      {
        label: "Leave History",
        component: (
          <LeaveHistoryComponent
            permissions={permissions}
            modulePermissions={modulePermissions}
            setReload={setReload}
            reload={reload}
          ></LeaveHistoryComponent>
        ),
        icon: <FileUpload />,
        visible:
          permissions?.includes(modulePermissions?.list_permission) ||
          permissions?.includes(modulePermissions?.own_list_permission),
      },
      {
        label: "Leave  Transaction",
        component: (
          <LeaveTransactionComponent
            employees={employees}
            setReload={setReload}
            reload={reload}
          ></LeaveTransactionComponent>
        ),
        icon: <FileUpload />,
        visible:
        permissions?.includes(modulePermissions?.transaction_list_permission) ||
        permissions?.includes(modulePermissions?.transaction_owner_list_permission),
      },
      {
        label: "Leave  Balance",
        component: <LeaveBalanceComponent
        setReload={setReload}
        reload={reload}
        ></LeaveBalanceComponent>,
        icon: <FileUpload />,
        visible: permissions?.includes(
          modulePermissions?.balance_list_permission
        ),
      },
    ]);
  }, [employees,reload]);

  const handleSubmit = async () => {
    createLeaveRequest();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <MDBox>
          <Card sx={{ padding: 2 }}>
            <MDBox display="flex">
              <Grid
                container
                spacing={3}
                alignContent="center"
                alignItems="center"
              >
                <Grid item xl={5}>
                  <Card>
                    <Header data={employee}></Header>
                  </Card>
                </Grid>
                {Object.keys(employeeLeaveBalance)?.map((e) => (
                  <Grid item>
                    <Card sx={{ height: "100%", padding: 2 }}>
                      <MDBox sx={{ justifyContent: "center", width: "110px" }}>
                        <MDTypography variant="h6" textTransform="capitalize">
                          {e.replaceAll("_", " ")}
                        </MDTypography>
                        <MDTypography variant="h6" textTransform="capitalize">
                          {employeeLeaveBalance[e] + " Hours"}
                        </MDTypography>
                      </MDBox>
                    </Card>
                  </Grid>
                ))}
              </Grid>
              {permissions?.includes(modulePermissions?.create_permission) && (
                <Fab
                  color="info"
                  sx={{ padding: "30px" }}
                  onClick={() => {
                    setCreateDialog(true);
                  }}
                >
                  <MDTypography variant="h6" color="white">
                    +
                  </MDTypography>
                </Fab>
              )}
            </MDBox>
          </Card>
        </MDBox>
        <MDBox pt={3} justifyContent="center">
          <Card sx={{ paddingLeft: 2 }}>
            <MDBox>
              <BaseLayout tabComponents={tabComponents}></BaseLayout>
            </MDBox>
          </Card>
        </MDBox>
        <Dialog
          PaperProps={{
            style: {
              backgroundColor: "white",
              boxShadow: "none",
              width: "600px",
              height: "auto",
              maxWidth: "none",
              borderRadius: "10px",
              cursor: "pointer",
            },
          }}
          open={createDialog}
          onClose={() => {
            setCreateDialog(false);
          }}
        >
          <DialogTitle
            style={{
              width: "100%",
              padding: "20px",
              backgroundColor: "#4FA7F9",
              color: "white",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ color: "white", fontWeight: "Medium" }}>
                Apply Leave
              </Typography>
              <Icon
                fontSize="medium"
                onClick={() => {
                  setCreateDialog(false);
                }}
              >
                close
              </Icon>
            </div>
          </DialogTitle>
          <DialogContent
            style={{
              padding: "15px",
            }}
          >
            <Card id="basic-info" sx={{ overflow: "visible", padding: "15px" }}>
              {/* <MDBox
                p={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <MDTypography variant="h5">Apply for leave</MDTypography>
              </MDBox> */}
              <MDBox component="form" pb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      // value={gender}
                      options={Object.keys(employeeLeaveBalance)}
                      onChange={(event, values) => {
                        setLeaveRequestPayload((state) => ({
                          ...state,
                          leave_category_name: values,
                        }));
                      }}
                      getOptionLabel={(v) =>
                        v?.replaceAll("_", " ") +
                        " (" +
                        employeeLeaveBalance[v] +
                        " hours)"
                      }
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          label="Leave Balance"
                          {...params}
                          placeholder="Select Leave Balance"
                          InputLabelProps={{ shrink: true }}
                          required
                          error={!!LeaveBalanceError}
                          helperText={LeaveBalanceError}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      // value={gender}
                      options={[
                        { name: "Full Day", hour: 8 },
                        { name: "Half Day", hour: 4 },
                        { name: "2 Hours", hour: 2 },
                      ]}
                      onChange={(event, values) => {
                        setLeaveRequestPayload((state) => ({
                          ...state,
                          number_of_hours: values?.hour,
                        }));
                      }}
                      getOptionLabel={(v) => v.name}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          label="Hours"
                          {...params}
                          placeholder="Select Hours"
                          InputLabelProps={{ shrink: true }}
                          required
                          error={!!LeaveHourError}
                          helperText={LeaveHourError}
                        />
                      )}
                    />
                    {/* <FormField
                      label="Number Of Days"
                      // placeholder="Volkov"
                      value={
                        leaveRequestPayload.number_of_days
                      }
                      onChange={(e) => {
                        setLeaveRequestPayload((state) => ({
                          ...state,
                          number_of_days: e.target.value,
                        }));
                      }}
                      inputProps={{  }}
                    /> */}
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          label="Start Date"
                          inputProps={{ type: "date" }}
                          placeholder="DD/MM/YY"
                          value={leaveRequestPayload.start_date}
                          onChange={(e) => {
                            setLeaveRequestPayload((state) => ({
                              ...state,
                              start_date: e.target.value,
                              // number_of_days: parseInt(
                              //   (new Date(leaveRequestPayload.end_date) -
                              //     new Date(e.target.value)) /
                              //     (1000 * 60 * 60 * 24),
                              //   10
                              // ),
                            }));
                            //   setEmployee({ ...Employee, dob: e.target.value });
                            //   setDob(e.target.value);
                          }}
                          error={!!startDateError}
                          helperText={startDateError}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {leaveRequestPayload?.number_of_hours == 8 ? (
                          <FormField
                            label="End Date"
                            inputProps={{ type: "date" }}
                            placeholder="DD/MM/YY"
                            value={leaveRequestPayload.end_date}
                            onChange={(e) => {
                              setLeaveRequestPayload((state) => ({
                                ...state,
                                end_date: e.target.value, 
                              }));
                            }}
                         
                          />
                        ) : (
                          <FormField
                            label="Start Time"
                            inputProps={{ type: "time" }}
                            value={leaveRequestPayload.start_time}
                            onChange={(e) => {
                              setLeaveRequestPayload((state) => ({
                                ...state,
                                end_date: state.start_date,
                                start_time: e.target.value,
                              }));
                            }}
                          />
                        )}
                      </Grid>
                      {/* <Grid item xs={12} sm={6}>
                        <Grid container spacing={3}>
                          
                        </Grid>
                      </Grid> */}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      variant="standard"
                      label="Remark"
                      fullWidth
                      placeholder="Leave Remark"
                      value={leaveRequestPayload.reason}
                      onChange={(e) => {
                        setLeaveRequestPayload((state) => ({
                          ...state,
                          reason: e.target.value,
                        }));
                        //   isCreateEmployee ? setEmail(e.target.value) : null;
                        //   setEmployee({ ...Employee, email: e.target.value });
                      }}
                      required
                      error={!!RemarkError}
                      helperText={RemarkError}
                    />
                  </Grid>

                  {/* <Grid item xs={12} md={6}></Grid> */}
                </Grid>
                <MDBox pt={4} display="flex" justifyContent="center">
                  <MDButton
                    variant="gradient"
                    color="dark"
                    onClick={() => handleSubmit()}
                  >
                    Submit
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </DialogContent>
        </Dialog>
      </MDBox>
      <Footer></Footer>
    </DashboardLayout>
  );
}
