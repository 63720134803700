/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
// Settings page components



import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Permission from "./components/Permissions";
import Verification from "./components/Verificatiom";
import Sources from "./components/source";

import Notifications from "./components/Notifications";
import Sidenav from "./components/Sidenav";
import ApiCalls from "services/flick_erp_api_call";
import { useEffect, useState } from "react";

function SettingsRec() {
  const [settingsData, setSettingsData] = useState();
  useEffect(()=>{
    getSettings()
  },[])
  const getSettings = async() =>{
    var org_data = JSON.parse(localStorage.getItem("user"));
    var res = await ApiCalls.getOrganisationSetting() ;
    if(res.success && res.code == 200) {
      var currentOrgData = res.data.filter((data)=> data.id == org_data.org_id)[0] ;
      setSettingsData(currentOrgData);

    }
 
  }
  return (
    <DashboardLayout>
      {/* <DashboardNavbar/> */}
      {/* <Card > */}
        <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3} >
            <Sidenav/>
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} >
                  <Permission />
                </Grid>
                <Grid item xs={12}>
                  <Verification />
                </Grid>
                <Grid item xs={12}>
                  <Sources />
                </Grid>
                
                <Grid item xs={12}>
                  <Notifications settingsData={settingsData}/>
                </Grid>
               
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      
      <Footer/>
    </DashboardLayout>
  );
}

export default SettingsRec;
