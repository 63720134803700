/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, Link } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React routes
import routes from "routes";

// Material Dashboard 2 PRO React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import AuthGuard from "./AuthGuard";
import NotFound from "./not_found";
import PermissionDenied from "permission_denied";
import { useSelector } from "react-redux";
import {
  selectSignIn,
  signIn,
} from "layouts/authentication/sign-in/illustration/signInSlice";
import { TextViewEditorBox } from "components/TextViewEditorBox";
import { ToastMessageAlert } from "components/Toast";
import { Backdrop } from "@mui/material";
import { Loader } from "components/Loader";
import { PromptBox } from "components/Prompt";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };
  var v = 0;

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement != undefined
      ? (document.scrollingElement.scrollTop = 0)
      : null;
  }, [pathname]);

  // const user_ = useSelector((state) => state.user);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return (
          <Route
            path={route.route}
            rol={route.roles}
            element={<AuthGuard route={route} />}
            key={route.key}
          />
        );
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  const isDialogOpen = useSelector((state) => state?.textViewBox?.isOpen);
  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
      {useMemo(
        () => (
          <Loader />
        ),
        []
      )}
      {useMemo(
        () => (
          <ToastMessageAlert />
        ),
        []
      )}
      {useMemo(
        () => (
          <TextViewEditorBox dialogOpen={isDialogOpen}></TextViewEditorBox>
        ),
        []
      )}
      {useMemo(
        () => (
          <PromptBox />
        ),
        []
      )}

      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={
              (transparentSidenav && !darkMode) || whiteSidenav
                ? brandDark
                : brandWhite
            }
            brandName="Flick ERP"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {/* {configsButton} */}
        </>
      )}
      <Routes>
        {getRoutes(routes)}
        <Route
          path="/"
          element={
            <Navigate
              to={
                localStorage.getItem("user") != ""
                  ? "/dashboards/dashboard/"
                  : "/authentication/sign-in/illustration/"
              }
            />
          }
        />
        <Route path="*" element={<Navigate to ="/not_found"/>} />
        <Route path="/not_found" element={<NotFound></NotFound>} />
        <Route
          path="/permission_denied"
          element={<PermissionDenied></PermissionDenied>}
        />
      </Routes>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
