import { createSlice } from "@reduxjs/toolkit";

const jobAdvertisementSlice = createSlice({
    name: 'jobAdvertisement',
    initialState: {
        jobAdvertisement: [],
    },
    reducers: {
        advertisementLists: (state, action) => {
            state.jobAdvertisement = action.payload.jobAdvertisement;
        },
        
    }
});

export const { advertisementLists } = jobAdvertisementSlice.actions;
export default jobAdvertisementSlice.reducer;
export const selectJobAdvertisement = (state) => state.jobAdvertisement;