/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Footer from "examples/Footer";
// EmployeeView page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import Sidenav from "layouts/pages/account/settings/components/Sidenav";
import { useEffect, useState } from "react";
import ApiCalls from "services/flick_erp_api_call";
import { useLocation, useNavigate } from "react-router-dom";
import { Person, Lock, Badge, Comment, AccountBalance, Assignment, Paid, Security } from "@mui/icons-material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Documents from "./components/Documents";
import BasicInfo from "./components/BasicInfo";
import ChangePassword from "./components/ChangePassword";
import Permissions from "./components/Permissions";
import { ReportingManager } from "./components/ReportingManager";
import { LeaveBalance } from "./components/LeaveBalance";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Card } from "@mui/material";
import Contracts from "./components/Contract/ContractGeneral";
import ContractView from "./components/Contract";
import { BankDetails } from "./components/BankDetails";

function EmployeeView({ userid, permissions, modulePermissions }) {

  const location = useLocation();
  const [isReloadRequest, setIsReloadRequest] = useState(false);

  const loggedUser = JSON.parse(localStorage.getItem("user"));
  const user_id = location.state?.userid ?? loggedUser.employee_id;
  const permission = location.state?.permissions ?? permissions;
  const isCreateEmployee = location.state?.isCreateEmployee;

  const navigate = useNavigate();
  useEffect(() => {
    isCreateEmployee == undefined ? getEmployee() : null;
  }, [user_id,isReloadRequest]);
  const [data, setData] = useState(null);

  const [tabComponents, setTabComponents] = useState([]);
  const [employee, setEmployee] = useState({
    gender: { id: "m", name: "Male" },
  });

  const user = JSON.parse(localStorage.getItem("user"));

  async function getEmployee() {
    var res = await ApiCalls.getEmployee(user_id);
    if (res.success && res.code == 200) {
      setData(res.data);
    }
  }


  useEffect(() => {
    setTabComponents([
      {
        label: "Profile",
        component: (
          <BasicInfo
            data={data}
            isCreateEmployee={isCreateEmployee}
            Employee={employee}
            setEmployee={setEmployee}
            permissions={permission}
            modulePermissions={modulePermissions}
            setIsReloadRequest={setIsReloadRequest}
            reloadCallback = {()=>{
              getEmployee()
            }}
          />
        ),
        icon: <Person />,
        visible: true,
      },
      {
        label: "Password",
        component: (
          <ChangePassword
            data={data}
            isCreateEmployee={isCreateEmployee}
            Employee={employee}
            setEmployee={setEmployee}
            permissions={permission}
            modulePermissions={modulePermissions}
          />
        ),
        icon: <Lock />,
        visible: permissions?.includes(modulePermissions.update_permission)
          ? true
          : user_id == user.employee_id
          ? true
          : false ?? false,
      },
      {
        label: "Permissions",
        component: (
          <Permissions
            data={data}
            isCreateEmployee={isCreateEmployee}
            Employee={employee}
            setEmployee={setEmployee}
            permissions={permission}
            modulePermissions={modulePermissions}
          />
        ),
        icon: <Security />,
        visible:
          permissions?.includes(modulePermissions.update_permission) ?? false,
      },
      {
        label: "Managers",
        component: (
          <ReportingManager
            data={data}
            isCreateEmployee={isCreateEmployee}
            Employee={employee}
            setEmployee={setEmployee}
            permissions={permission}
            modulePermissions={modulePermissions}
            reloadCallback = {(v)=>{
              getEmployee()
            }}
          />
        ),
        icon: <Badge />,
        visible: (permissions?.includes(modulePermissions.reporting_structure_owner_list_permission) || permissions?.includes(modulePermissions.reporting_structure_list_permission)) ?? false,
      },
      {
        label: "Leave Balance",
        component: (
          <LeaveBalance

            permissionKey="leave"
            data={data}
            isCreateEmployee={isCreateEmployee}
            Employee={employee}
            setEmployee={setEmployee}
            permissions={permission}
            modulePermissions={modulePermissions}
            setIsReloadRequest={setIsReloadRequest}
          />
        ),
        icon: <Comment />,
        visible:
          permissions?.includes(modulePermissions.update_permission) ?? false,
      },
      {
        label: "Documents",
        component: (
          <Documents
            data={data}
            isCreateEmployee={isCreateEmployee}
            Employee={employee}
            setEmployee={setEmployee}
            permissions={permission}
            modulePermissions={modulePermissions}
          />
        ),
        icon: <Assignment />,
        visible:
          permissions?.includes(modulePermissions.update_permission) ?? false,
      },
      {
        label: "Contracts",
        component: (
          <ContractView
            data={data}
            isCreateEmployee={isCreateEmployee}
            Employee={employee}
            setEmployee={setEmployee}
            permissions={permission}
            modulePermissions={modulePermissions}
          />
        ),
        icon: <Paid />,
        visible:
          permissions?.includes(modulePermissions.update_permission) ?? false,
      },
      {
        label: "Bank Details",
        component: (
          <BankDetails
            data={data}
            isCreateEmployee={isCreateEmployee}
            Employee={employee}
            setEmployee={setEmployee}
            permissions={permission}
            modulePermissions={modulePermissions}
          />
        ),
        icon: <AccountBalance />,
        visible:
          permissions?.includes(modulePermissions.update_permission) ?? false,
      },
    ]);
  }, [data]);
  const handleButtonClick = async () => {
    try {
      const payload = {
        user: {
          email: employee.email,
          password: employee.password,
          firstname: employee.firstName,
          lastname: employee.lastName,
          phone: employee.phone,
        },
        doj: employee.doj,
        dob: employee.dob,
        gender: employee.gender.id,
        emp_id: employee.emp_id,
        role: employee.role,
        designation: employee.designation,
        permissions: employee.permissions,
        org: user.org_id,
      };

      const res = await ApiCalls.createEmployee(payload, false);
      if (res.success && res.code === 201) {
        navigate("/employee/employees");
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {!isCreateEmployee ? (
        <DashboardLayout>
          <DashboardNavbar></DashboardNavbar>
          <Card>
            <BaseLayout data={data} tabComponents={tabComponents}>
              <Footer />
            </BaseLayout>
          </Card>
        </DashboardLayout>
      ) : (
        <DashboardLayout>
          <DashboardNavbar></DashboardNavbar>
          <MDBox mt={5}>
            <MDBox mb={3}>
              <MDBox mb={3}>
                <BasicInfo
                  data={data}
                  isCreateEmployee={isCreateEmployee}
                  Employee={employee}
                  setEmployee={setEmployee}
                  reloadCallback = {()=>{
                    getEmployee()
                  }}
                />
              </MDBox>
              {!isCreateEmployee && <ChangePassword data={data} />}
              <Permissions
                data={data}
                isCreateEmployee={isCreateEmployee}
                Employee={employee}
                setEmployee={setEmployee}
              />

              {isCreateEmployee && (
                <MDBox display="flex" justifyContent="center" p={2}>
                  <MDButton
                    variant="gradient"
                    color="dark"
                    onClick={() => {
                      handleButtonClick();
                    }}
                  >
                    Create Employee
                  </MDButton>
                </MDBox>
              )}
            </MDBox>
          </MDBox>
        </DashboardLayout>
      )}
    </>
  );
}

export default EmployeeView;
