import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import CircleIcon from "@mui/icons-material/Circle";
import { CirclePicker } from "react-color";
import { Popover, IconButton } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import {
  Autocomplete,
  Grid,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fieldStylesList } from "../StyleSlice";
// import {fieldStylesList} from '../StyleSlice';

function StyleComponent(props) {
  const dispatch = useDispatch();
  const [font, setFont] = useState("Arial");
  const fontFamilies = ["Arial", "cursive", "Times New Roman", "Courier New", "Verdana"];
  const [labelColor, setLabelColor] = useState("#344767");
  const [labelSize, setLabelSize] = useState(16);
  const [buttonColorPickerAnchor, setButtonColorPickerAnchor] = useState(null);
  const [labelColorPickerAnchor, setLabelColorPickerAnchor] = useState(null);
  const [fieldWidth, setFieldWidth] = useState(80);
  const [unit, setUnit] = useState("%");
  const [buttonColor, setbuttonColor] = useState("#2977ff");
  const [buttonAlignment, setAlignment] = useState("left");

  const Alignment = [
    { name: "Left" },
    { name: "Right" },
    { name: "Center" }
  ];

  useEffect(() =>{
    const data = {
      width:fieldWidth,
      unit:unit,
      font:font,
      labelColor:labelColor,
      labelSize:labelSize,
      buttonColor:buttonColor,
      buttonAlignment:buttonAlignment
    }
    dispatch(fieldStylesList({ fieldStyles: data }));

  },[fieldWidth , unit , font , labelColor , labelSize ,buttonColor , buttonAlignment]);

  useEffect(() => {
    if (props.props.response !== null) {
        setLabelColor(props.props.response?.config?.fieldStyles?.labelColor);
        setLabelSize(props.props.response?.config?.fieldStyles?.labelSize);
        setFieldWidth(props.props.response?.config?.fieldStyles?.width);
        setUnit(props.props.response?.config?.fieldStyles?.unit);
        setAlignment(props.props.response?.config?.fieldStyles?.buttonAlignment);
        setFont(props.props.response?.config?.fieldStyles?.font);
        setbuttonColor(props.props.response?.config?.fieldStyles?.buttonColor);
    }

}, [props])

  const handleUnitChange = (newUnit) => {
    setUnit(newUnit);
  };

  const handleChange = (event) => {
    setFont(event.target.value);
  };

  const handleColorPickerOpen = (event, type) => {
    if (type === 'button') {
      setButtonColorPickerAnchor(event.currentTarget);
    } else if (type === 'label') {
      setLabelColorPickerAnchor(event.currentTarget);
    }
  };

  const handleColorPickerClose = (type) => {
    if (type === 'button') {
      setButtonColorPickerAnchor(null);
    } else if (type === 'label') {
      setLabelColorPickerAnchor(null);
    }
  };

  const handleColorChange = (selectedColor) => {
    setLabelColor(selectedColor.hex);
    handleColorPickerClose('label');
  };

  const handleButtonColorChange = (selectedColor) => {
    setbuttonColor(selectedColor.hex);
    handleColorPickerClose('button');
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <div style={{ fontWeight: "bold" }}>General</div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div>Width</div>
            <TextField
              id="outlined-basic"
              label="Label"
              type="number"
              variant="outlined"
              defaultValue={fieldWidth}
              onChange={(e) => {
                const newValue = e.target.value;
                if ((unit === "%" && newValue <= 100) || (unit === "px" && newValue >= 0)) {
                  setFieldWidth(newValue);
                }
              }}
              style={{ width: "100%", height: "58%" }}
            />
          </Grid>
          <Grid item xs={6}>
            <div>Unit</div>
            <Select
              style={{ width: "100%", height: "58%" }}
              value={unit}
              onChange={(e) => handleUnitChange(e.target.value)}
            >
              <MenuItem value="%">%</MenuItem>
              <MenuItem value="px">px</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <div style={{ fontWeight: "bold" }}>Text</div>
        <Grid container spacing={2} style={{marginBottom:"5%"}}>
          <Grid item xs={6}>
            <div>Font Family</div>
            <FormControl style={{ width: "100%", height: "100%" }}>
              <InputLabel>Font Family</InputLabel>
              <Select
                style={{ width: "100%", height: "70%" }}
                fullWidth
                value={font}
                onChange={handleChange}
              >
                {fontFamilies.map((font, index) => (
                  <MenuItem key={index} value={font}>
                    {font}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item xs={6}>
            <div>Sample Text</div>
            <div style={{ fontFamily: font }}>
              The quick brown fox jumps over the lazy dog
            </div>
          </Grid> */}
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div>Label Color</div>
              <Box display="flex" alignItems="center">
                <TextField
                  label="#RRGGBB"
                  value={labelColor}
                  onChange={(e) => setLabelColor(e.target.value)}
                />
                <div onClick={(event) => handleColorPickerOpen(event, 'label')}>
                  <IconButton edge="end" size="large">
                    <CircleIcon style={{ color: labelColor }} />
                  </IconButton>
                </div>
                <Popover
                  open={Boolean(labelColorPickerAnchor)}
                  anchorEl={labelColorPickerAnchor}
                  onClose={() => handleColorPickerClose('label')}
                >
                  <CirclePicker onChange={handleColorChange} color={labelColor} />
                </Popover>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <div>Label Size</div>
              <TextField
                type="number"
                value={labelSize}
                onChange={(e) => setLabelSize(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <div style={{ fontWeight: "bold" }}>Button</div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div>Button Color</div>
            <Box display="flex" alignItems="center">
              <TextField
                label="#RRGGBB"
                value={buttonColor}
                onChange={(e) => setbuttonColor(e.target.value)}
              />
              <div onClick={(event) => handleColorPickerOpen(event, 'button')}>
                <IconButton edge="end" size="large">
                  <CircleIcon style={{ color: buttonColor }} />
                </IconButton>
              </div>
              <Popover
                open={Boolean(buttonColorPickerAnchor)}
                anchorEl={buttonColorPickerAnchor}
                onClose={() => handleColorPickerClose('button')}
              >
                <CirclePicker onChange={handleButtonColorChange} color={buttonColor} />
              </Popover>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <div>Alignment</div>
            <Autocomplete
              id="Alignment-select"
              options={Alignment}
              value={buttonAlignment}
              onChange={(event, newValue) => setAlignment(newValue.name)}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                } else if (option.name) {
                  return option.name;
                } else {
                  return '';
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  name="Alignment"
                  label="Select alignment"
                  variant="outlined"
                  required
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default StyleComponent;
