import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import { useEffect, useState } from "react";
import Options from "./options";
import StyleAndPreview from "./styleAndPreview";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ApiCalls from "services/flick_erp_api_call";
import DataTable from "examples/Tables/DataTable";
import IconButton from "@mui/material/IconButton";
import { Edit, Delete } from "@mui/icons-material";
import { DataList } from '../FormBuilder/createForm/formFieldsSlice';
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { fieldsList } from './createForm/formFieldsSlice';
import {
  Grid,
  Tooltip,
} from "@mui/material";
import MDButton from "components/MDButton";
import CreateForm from "./createForm";

function FormBuilder({permissions,modulePermissions}) {

  const dispatch = useDispatch();
  const data = useSelector((state) => state.fieldsArray.FormData);
  const [tabComponents, setTabComponents] = useState([]);
  const [showTab, setShowTab] = useState(false);
  const [showList, setShowList] = useState(true);
  const [tabValue, setTabValue] = useState(0);

  const [FormsRecord, setFormsRecord] = useState({
    columns: [
      {
        Header: "NAME",
        accessor: "name",
      },
      {
        Header: "DATE",
        accessor: "created_at",
      },
      {
        Header: "Action",
        accessor: "edit_delete",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Delete">
              <IconButton
                size="small"
                onClick={() => deleteForm(row.original.id)}
              >
                <Delete />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton
                size="small"
                onClick={() => {
                  switchToEditMode(row.original)
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
    ],
    rows: [],
  });

  useEffect(() =>{
    getList(); //getList
  },[showList])

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    ApiCalls.getFormData()
      .then((res) => {
        if (res.success && res.code === 200) {
          dispatch(DataList({ FormData: res.data }));
          setFormsRecord((prevFormsRecord) => ({
            ...prevFormsRecord,
            rows: res.data,
          }));
        } else {
       
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        alert("An error occurred while fetching data.");
      });
  }

  const switchToEditMode = (row) => {

    setTabValue(0);
    setShowList(false);
    setShowTab(true);
    setTabComponents([
      {
        label: "Form",
        component: (
          <DndProvider backend={HTML5Backend}>
            <CreateForm selectedData={row}/>
          </DndProvider>
        ),
      },
      {
        label: "Options",
        component: (
          <Options response={row} />
        )
      },
      {
        label: "Style & preview",
        component: (
          <StyleAndPreview response={row} setShowList={setShowList} setShowTab={setShowTab}/>
        )
      },
    ]);
  };
  const switchToCreateMode = () => {
    setShowList(false);
    setShowTab(true);
    setTabValue(0);
    dispatch(fieldsList({ fieldsArray: [] }));
    setTabComponents([
      {
        label: "Form",
        component: (
          <DndProvider backend={HTML5Backend}>
            <CreateForm selectedData={null}/>
          </DndProvider>
        ),
      },
      {
        label: "Options",
        component: (
          <Options response={null} />
        )
      },
      {
        label: "Style & preview",
        component: (
          <StyleAndPreview response={null} setShowList={setShowList} setShowTab={setShowTab}/>
        )
      },
    ]);
  };

  const deleteForm = async (id) => {
    const res = await ApiCalls.deleteForm(id);

    if (res.success && res.code === 204) {


      setFormsRecord((prevFormsRecord) => ({
        ...prevFormsRecord,
        rows: prevFormsRecord.rows.filter((row) => row.id !== id),
      }));

    } else {

    }

  }

  const handleBack = () => {
    setShowList(true);
    setShowTab(false);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {showTab ? (
        <MDBox pt={6} pb={3}>
          <MDBox>
            <Card sx={{ padding: 2 }}>
              <IconButton color="secondary" aria-label="add an alarm" onClick={handleBack} style={{ width: "6%", padding: "10px" }}>
                <ArrowBackIcon />
              </IconButton>
              <BaseLayout tabComponents={tabComponents} tabValue={tabValue}>
              </BaseLayout>
            </Card>
          </MDBox>
        </MDBox>
      ) : (null)}

      {showList ? (
        <div>
          <MDBox
            p={2}
            lineHeight={1}
            sx={{
              display: "flex",
              justifyContent: "end",
              borderRadius: 3,
            }}
          >
           {permissions?.includes(modulePermissions?.create_permission) &&( <Grid item>
              <MDButton
                color="white"
                onClick={switchToCreateMode}
                sx={{
                  border: "1px solid black",
                  "&:hover": { backgroundColor: " black", color: " white" },
                }}
              >
                Create Forms
              </MDButton>
            </Grid>)}
          </MDBox>

          <DataTable
            table={FormsRecord}
            entriesPerPage={false}
            showTotalEntries={false}
            isSorted={false}
          ></DataTable>
        </div>
      ) : (null)}
    </DashboardLayout>
  );
}

export default FormBuilder;