import { Autocomplete, Card, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TripleToggleSwitch from "components/ThreeWaySwitch";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import moment from "moment";
import { useEffect, useState } from "react";
import ApiCalls from "services/flick_erp_api_call";

export function EmployeeEvents({ permissions, modulePermissions }) {
  const [employeeEvent, setEmployeeEvent] = useState({
    columns: [
      {
        Header: "Added Date",
        accessor: "created_at",
        Cell: (row) => (
          <span>{moment(row?.original?.created_at).format("YYYY-MM-DD")}</span>
        ),
      },
      {
        Header: "Event Date",
        accessor: "date",
      },
      {
        Header: "Event Time",
        accessor: "time",
      },
      {
        Header: "Event Config",
        accessor: "event_config",
      },
      {
        Header: "Time Zone",
        accessor: "timezone",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (row) => (
            // permissions?.includes(modulePermissions?.update_permission) ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Autocomplete
                    blurOnSelect
                    underlineShow={false}
                    options={["pending", "approved", "rejected"]}
                    onChange={(event, newValue) => {
                    }}
                    // inputProps={{ disableUnderline: true}}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        sx={{ width: "120px" }}
                      />
                    )}
                  ></Autocomplete>
                </div>
            //   ) : (
            //     <div style={{ display: "flex", alignItems: "center" }}>
            //       {row.original.status}
            //     </div>
            //   ),
        ),
      },
    ],
    rows: [],
  });
  useEffect(() => {
    getEmployeeEvents();
  }, []);
  async function getEmployeeEvents() {
    var res = await ApiCalls.getEmployeeEvents();
    if (res.success && res.code == 200) {
      employeeEvent.rows = res.data;
      setEmployeeEvent({ ...employeeEvent });
    }
  }

 
  return (
    <DashboardLayout>
      <Card>
        <MDBox>
          <MDBox>
            <MDTypography p={3} variant="h5">
              Employee Events
            </MDTypography>
          </MDBox>
          <DataTable table={employeeEvent}></DataTable>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}
