import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

import CardContent from "@mui/material/CardContent";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { Autocomplete, Icon, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import ApiCalls from "services/flick_erp_api_call";
import verifiedIcon from "../../../../../assets/verified.png";
function Sources() {
  const checkList1 = ["Email ", "Manual ", "API "];
  const [gmeetAuthenticated, setGmeetAuthenticated] = useState(true);

  const [gmeet, setGmeet] = useState(true);
  const [manual, setManual] = useState(true);

  const handleSetGmeet = () => setGmeet(!gmeet);
  const handleSetManual = () => setManual(!manual);

  useEffect(() => {
    getGmeetAuthenticateStatus();

    if (window.location.href.includes("code")) {
      const query = new URLSearchParams(window.location.search);
      const code = query.get("code");
      postGoogleResponseCode(code);
    }
  }, []);
  async function postGoogleResponseCode(code) {
    var data = {
      code: code,
      redirect_uri:`${window.location.origin}/recruitement/settings`
    };
    var res = ApiCalls.postResponseCode(data, false);
  }
  const getAuthToken = () => {
    const googleOAuthURL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=938201927476-qdqsjasno8319mui54olm6mj7gn8r75m.apps.googleusercontent.com&redirect_uri=${window.location.origin}/recruitement/settings&response_type=code&scope=https://www.googleapis.com/auth/calendar&access_type=offline&prompt=consent`;
    console.log(googleOAuthURL)
    window.location = googleOAuthURL;
  };

  const getGmeetAuthenticateStatus = async () => {
    var res = await ApiCalls.getStatusOfGoogleAuthenticate();
    setGmeetAuthenticated(res.success ? true : false);
  };

  return (
    <Card id="sources">
      <CardContent>
        <MDTypography variant="h5" fontWeight="medium">
          Source
        </MDTypography>

        <br />
        <MDTypography variant="body2" color="text.secondary">
          {/* {checkList1.map((item, index) => (
                        <Card variant="button" color ="text" key={index}>
                            <row>
                                {item}
                                <input value={item} type="checkbox" />
                            </row>
                        </Card>
                    ))} */}
          <Grid container spacing={3}>
            <Grid item xs={8} sm={5}>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <MDBox display="flex" alignItems="center">
                  <MDBox ml={2} lineHeight={0}>
                    <MDTypography variant="h7" fontWeight="small">
                      GMeet
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  width={{ xs: "100%", sm: "auto" }}
                  mt={{ xs: 1, sm: 0 }}
                >
                  <MDBox
                    mr={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Switch checked={gmeet} onChange={handleSetGmeet} />
                    {gmeet && (
                    gmeetAuthenticated ? (
                      <img src={verifiedIcon} height="24" width="24" />
                   )
                     : (
                      <MDButton
                      onClick={() => {
                             getAuthToken();
                      }}
                      sx={{ backgroundColor: "lightGrey",  }}
                    >
                      { "Authenticate"}
                    </MDButton>
                    ))}
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
            <br />

            <Grid item xs={8} sm={5}>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <MDBox display="flex" alignItems="center">
                  <MDBox ml={2} lineHeight={0}>
                    <MDTypography variant="h7" fontWeight="small">
                      Manual
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  width={{ xs: "100%", sm: "auto" }}
                  mt={{ xs: 1, sm: 0 }}
                >
                  <MDBox lineHeight={0} mx={2}></MDBox>
                  <MDBox mr={1}>
                    <Switch checked={manual} onChange={handleSetManual} />
                  </MDBox>
                </MDBox>
                <Autocomplete
                  options={["1", "2", "3"]}
                  // value={searchBy}
                  onChange={(event, newValue) => {}}
                  sx={{ width: "130px" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="No. of Interview"
                      // size="small"
                      variant="standard"
                      // fullWidth
                      // error={!!sourceError}
                      // helperText={sourceError}
                    />
                  )}
                />
              </MDBox>
            </Grid>
            <br />
          </Grid>
        </MDTypography>
      </CardContent>
    </Card>
  );
}
export default Sources;
