/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import SettingsRec from "layouts/recruitement/settings";
import Candidates from "layouts/recruitement/candidates";
import Screening from "layouts/recruitement/screening";
import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Settings from "layouts/pages/account/settings";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import ResetCover from "layouts/authentication/reset-password/cover";
// import Attendance from "layouts/dashboards/attendance";
import Attendance from "layouts/attendance";
import Employees from "layouts//employees";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";
import OTP from "layouts/authentication/otp";

// @mui icons
import Icon from "@mui/material/Icon";
import Recruitement from "layouts/recruitement";
// Images
import profilePicture from "assets/images/team-3.jpg";
import { Navigate } from "react-router-dom";
import OrganisationSignUp from "layouts/authentication/org-sign-up";
import BasicInfo from "layouts/pages/account/settings/components/BasicInfo";

import Interviews from "layouts/recruitement/interview";
import Shifts from "layouts/attendance/shifts";
import ApproveLeave from "layouts/managerdesk/approveLeave";
import DailyStatus from "layouts/managerdesk/dailyStatus";
import Teamfeedback from "layouts/managerdesk/teamFeedback";
// import Invoice from "layouts/pages/account/invoice";
import Billing from "layouts/invoicing/billing";
import Invoice from "layouts/invoicing/invoice";
import PricingPage from "layouts/invoicing/pricing_page";
import UploadAttendance from "layouts/attendance/uploadAttendance";
import EmployeeView from "layouts/employees/employee_view";
import { Leaves } from "layouts/leaves";
import { AttendanceSettings } from "layouts/attendance/settings";
import { OrganisationSettings } from "layouts/settings/organisation_settings";
import { LeaveSettings } from "layouts/leaves/settings";
import SubscriptionPayment from "layouts/invoicing/pricing_page/components/SubscriptonPayment";
import JobOffer from "layouts/recruitement/jobOffer";
import EmployeeDashboard from "layouts/dashboards/employee_dashboard";
import NewEvent from "layouts/marketing/newEvent";
import { Payrole } from "layouts/payrole";
import Holidays from "layouts/holidays";

import list from "assets/theme/components/list";

import FormBuilder from "layouts/marketing/FormBuilder";
import { PayroleSettings } from "layouts/payrole/settings";
import { GeneratePayrole } from "layouts/payrole/generatePayrole";
import CurrentOpenings from "layouts/recruitement/currentOpenings";
import { Dashboard } from "layouts/dashboards";
import { EmployeeEvents } from "layouts/marketing/employeeEvents";
import { AllAttendance } from "layouts/attendance/allAttendance";
import { Contracts } from "layouts/payrole/contracts";
import { Salary } from "layouts/payrole/salary";
import { Expenses } from "layouts/payrole/expenses";
import { Batch } from "layouts/payrole/batch";
import { TempCard } from "layouts/employees/temp_card";
import { ApproveMissPunch } from "layouts/attendance/approveMissPunch";

const routes = [
  {
    type: "collapse",
    name: "Current User",
    key: "currentUser",

    collapse: [
      {
        name: "My Profile",
        key: "Employee",
        permissionKey: "employee",
        route: "/pages/profile/profile-overview",
        icon: <Icon fontSize="medium">account_circle</Icon>,
        component: <EmployeeView />,
        not_found_component: <Navigate to="/not_found" />,
        permission_denied_component: <Navigate to="/permission_denied" />,
        requireRole: false,
        modulePermissions: {
          owner_list_permission: "employee | employee|owner_list",
          list_permission: "employee | employee|list",
          create_permission: "employee | employee|create",
          delete_permission: "employee | employee|delete",
          update_permission: "employee | employee|update",
          reporting_structure_owner_list_permission : "employee | reporting structure|owner_list",
          reporting_structure_list_permission : "employee | reporting structure|list",
          reporting_structure_update_permission : "employee | reporting structure|update",
          reporting_structure_create_permission : "employee | reporting structure|create",
          reporting_structure_delete_permission : "employee | reporting structure|delete",
          leave_balance_list_permission: "leave | leave balance|list",
          leave_balance_owner_list_permission: "leave | leave balance|owner_list",
          leave_balance_create_permission: "leave | leave balance|create",
          leave_balance_udpate_permission: "leave | leave balance|update",


        },
      },
      // {
      //   name: "Settings",
      //   key: "profile-settings",
      //   route: "/pages/account/settings",
      //   component: <Settings />,
      //   not_found_component: <Navigate to="/not_found" />,
      //   permission_denied_component: <Navigate to="/permission_denied" />,
      //   requireRole: false,
      // },
      {
        name: "Logout",
        key: "logout",
        route: "/authentication/sign-in/illustration",
        component: <SignInIllustration />,
        icon: <Icon fontSize="medium">logout</Icon>,

        not_found_component: <Navigate to="/not_found" />,
        permission_denied_component: <Navigate to="/permission_denied" />,
        requireRole: false,
      },
    ],
  },
  // { type: "divider", key: "divider-0" },
  {
    type: "open",
    name: "Settings",
    key: "settings",
    collapse: [
      {
        name: "Basic Info",
        key: "BasicInfo",
        route: "/pages/account/settings/components/BasicInfo",
        component: <BasicInfo />,
        not_found_component: <Navigate to="/not_found" />,
        permission_denied_component: <Navigate to="/permission_denied" />,
        requireRole: false,
      },
    ],
  },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Dashboard",
        key: "dashboard",
        permissionKey: "notifications",
        route: "/dashboards/dashboard",
        icon: <Icon fontSize="medium">groups</Icon>,
        requireRole: false,
        modulePermissions: {
         notification_create_permission : "notifications | notishboard|create"
        },
        component: <Dashboard />,
        not_found_component: <Navigate to="/not_found" />,
        permission_denied_component: <Navigate to="/permission_denied" />,
      },
     
      // {
      //   name: "Employees Dashboard",
      //   key: "Employee",
      //   route: "/dashboards/employeesDashboard",
      //   icon: <Icon fontSize="medium">groups</Icon>,
      //   requireRole: false,

      //   component: <EmployeeDashboard />,
      //   not_found_component: <Navigate to="/not_found" />,
      //   permission_denied_component: <Navigate to="/permission_denied" />,
      // },

    
     
    ],
  },
  
  {type:"collapse",
   name:"Employee",
   key:"employee",
  icon:<Icon fontSize="medium">corporate_fare</Icon>, 
collapse:[
  {
    name: "Employees",
    key: "employees",
    permissionKey: "employee",
    route: "/employee/employees",
    icon: <Icon fontSize="medium">groups</Icon>,
    requireRole: true,
    modulePermissions: {
      list_permission: "employee | employee|list",
      own_list_permission: "employee | employee|owner_list" ,
      create_permission: "employee | employee|create",
      delete_permission: "employee | employee|delete",
      update_permission: "employee | employee|update",
    },
    component: <Employees />,
    not_found_component: <Navigate to="/not_found" />,
    permission_denied_component: <Navigate to="/permission_denied" />,
  },
  {
    name: "Temp Card",
    key: "temp_card",
    permissionKey: "employee",
    route: "/employee/temp_card",
    icon: <Icon fontSize="medium">groups</Icon>,
    requireRole: false,
    // modulePermissions: {
    //   list_permission: "employee | employee|list",
    //   own_list_permission: "employee | employee|owner_list" ,
    //   create_permission: "employee | employee|create",
    //   delete_permission: "employee | employee|delete",
    //   update_permission: "employee | employee|update",
    // },
    component: <TempCard />,
    not_found_component: <Navigate to="/not_found" />,
    permission_denied_component: <Navigate to="/permission_denied" />,
  },
]
},
  {
    type: "collapse",
    name: "Recruitement",
    key: "recruitement",
    icon: <Icon fontSize="medium">corporate_fare</Icon>,
    collapse: [
      // {
      //   name: "Dashboard",
      //   key: "Dashboard",
      //   permissionKey: "recruitment",
      //   icon: <Icon fontSize="medium">space_dashboard</Icon>,
      //   route: "/recruitement/Dashboard",
      //   requireRole: false,

      //   component: <Recruitement />,
      //   not_found_component: <Navigate to="/not_found" />,
      //   permission_denied_component: <Navigate to="/permission_denied" />,
      // },
      {
        name: "Current Openings",
        key: "Job_Advertisement",
        permissionKey: "recruitment",
        route: "/recruitement/Job_Advertisement",
        icon: <Icon fontSize="medium">featured_video</Icon>,
        requireRole: true,
        modulePermissions: {
          own_list_permission: "recruitment | job advertisement|owner_list",
          list_permission: "recruitment | job advertisement|list",
          view_permission: "recruitment | job advertisement|view",
          create_permission: "recruitment | job advertisement|create",
          update_permission: "recruitment | job advertisement|update",
          delete_permission: "recruitment | job advertisement|delete",
        },
        component: <CurrentOpenings />,
        not_found_component: <Navigate to="/not_found" />,
        permission_denied_component: <Navigate to="/permission_denied" />,
      },
      {
        name: "Screening",
        key: "Screening",
        permissionKey: "recruitment",
        route: "/recruitement/Screening",
        icon: <Icon fontSize="medium">portrait</Icon>,
        requireRole: true,
        modulePermissions: {
          own_list_permission: "recruitment | screening|owner_list",
          list_permission: "recruitment | screening|list",
          view_permission: "recruitment | screening|view",
          create_permission: "recruitment | screening|create",
          update_permission: "recruitment | screening|update",
          delete_permission: "recruitment | screening|delete",
        },
        component: <Screening />,
        not_found_component: <Navigate to="/not_found" />,
        permission_denied_component: <Navigate to="/permission_denied" />,
      },
      // {
      //   name: "Settings",
      //   key: "RecSettings",
      //   route: "/dashboards/recruitement/recsettings",

      //   component: <RecSettings />,
      //   not_found_component: <Navigate to="/not_found" />,
      //   permission_denied_component: <Navigate to="/permission_denied"/>

      // },
      {
        name: "Candidate",
        key: "Candidate",
        permissionKey: "recruitment",
        route: "/recruitement/Candidate",
        icon: <Icon fontSize="medium">group</Icon>,
        requireRole: true,
        component: <Candidates />,
        modulePermissions: {
          own_list_permission: "recruitment | candidate|owner_list",
          list_permission: "recruitment | candidate|list",
          view_permission: "recruitment | candidate|view",
          create_permission: "recruitment | candidate|create",
          update_permission: "recruitment | candidate|update",
          delete_permission: "recruitment | candidate|delete",
          owner_interview_list_permission: "recruitment | interview|owner_list",
          interview_list_permission: "recruitment | interview|list",
          interview_create_permission: "recruitment | interview|create",
          interview_update_permission: "recruitment | interview|update",
          interview_delete_permission: "recruitment | interview|delete",
        },
        not_found_component: <Navigate to="/not_found" />,
        permission_denied_component: <Navigate to="/permission_denied" />,
      },
      {
        name: "Interviews",
        key: "Interview",
        permissionKey: "recruitment",
        route: "/recruitement/Interview",
        icon: <Icon fontSize="medium">portrait</Icon>,
        requireRole: true,
        modulePermissions: {
          list_permission: "recruitment | interview|list",
          view_permission: "recruitment | interview|view",
          own_list_permission: "recruitment | interview|owner_list",
          create_permission: "recruitment | interview|create",
          update_permission: "recruitment | interview|update",
          delete_permission: "recruitment | interview|delete",
        },
        component: <Interviews />,
        not_found_component: <Navigate to="/not_found" />,
        permission_denied_component: <Navigate to="/permission_denied" />,
      },
      {
        name: "Job Offers",
        key: "JobOffers",
        permissionKey: "recruitment",
        route: "/recruitement/JobOffers",
        icon: <Icon fontSize="medium">work</Icon>,
        requireRole: true,
        modulePermissions: {
          list_permission: "recruitment | job offer|list",
          view_permission: "recruitment | job offer|view",
          own_list_permission: "recruitment | job offer|owner_list",
          create_permission: "recruitment | job offer|create",
          update_permission: "recruitment | job offer|update",
          delete_permission: "recruitment | job offer|delete",
        },
        component: <JobOffer />,
        not_found_component: <Navigate to="/not_found" />,
        permission_denied_component: <Navigate to="/permission_denied" />,
      },
      // {
      //   name: "Onboarded",
      //   key: "onboarded",
      //   route: "/dashboards/recruitement/onboarded",

      //   component: <Onboarded />,
      //   not_found_component: <Navigate to="/not_found" />,
      //   permission_denied_component: <Navigate to="/permission_denied"/>

      // },
      // {
      //   name: "Rejected",
      //   key: "rejected",
      //   route: "/dashboards/recruitement/rejected",

      //   component: <Rejected />,
      //   not_found_component: <Navigate to="/not_found" />,
      //   permission_denied_component: <Navigate to="/permission_denied"/>

      // },
      // {
      //   name: "Reject Offer",
      //   key: "Reject Offer",
      //   route: "/dashboards/recruitement/rejectOffer",

      //   component: <RejectedOffer />,
      //   not_found_component: <Navigate to="/not_found" />,
      //   permission_denied_component: <Navigate to="/permission_denied"/>

      // },
      // {
      //   name: "Shortlisted",
      //   key: "shortlisted",
      //   route: "/dashboards/recruitement/shortlisted",

      //   component: <Shortlisted />,
      //   not_found_component: <Navigate to="/not_found" />,
      //   permission_denied_component: <Navigate to="/permission_denied"/>

      // },
    ],
  },
  {
    type: "collapse",
    name: "Marketing",
    key: "marketing",
    icon: <Icon fontSize="medium">file_copy</Icon>,
    collapse: [
      {
        name: "Form Builder",
        key: "formBuilder",
        permissionKey: "marketing",
        route: "/marketing/formBuilder",
        requireRole: true,
        icon: <Icon fontSize="medium">receipt_long</Icon>,
        modulePermissions: {
          list_permission: "marketing | marketing forms|list",
          own_list_permission: "marketing | marketing forms|owner_list",
          create_permission: "marketing | marketing forms|create",
          update_permission: "marketing | marketing forms|update",
          delete_permission: "marketing | marketing forms|delete",
        },
        component: <FormBuilder />,
        not_found_component: <Navigate to="/not_found" />,
        permission_denied_component: <Navigate to="/permission_denied" />,
      },
      {
        name: "Calender Meetings",
        key: "newEvent",
        permissionKey: "marketing",
        route: "/marketing/newEvent",
        requireRole: true,
        icon: <Icon fontSize="medium">receipt_long</Icon>,
        modulePermissions: {
          list_permission: "marketing | Event Configuration|list",
          own_list_permission: "marketing | Event Configuration|owner_list",
          // create_permission: "marketing | Employee Event|create",
          // update_permission: "marketing | Employee Event|update",
          // delete_permission: "marketing | Employee Event|delete",
        },
        component: <NewEvent />,
        not_found_component: <Navigate to="/not_found" />,
        permission_denied_component: <Navigate to="/permission_denied" />,
      },
      {
        name: "Employee Events",
        key: "employeeEvents",
        permissionKey: "marketing",
        route: "/marketing/employeeEvents",
        requireRole: false,
        icon: <Icon fontSize="medium">receipt_long</Icon>,
        modulePermissions: {
          list_permission: "marketing | Employee Event|list",
          own_list_permission: "marketing | Employee Event|owner_list",
          // create_permission: "marketing | Employee Event|create",
          // update_permission: "marketing | Employee Event|update",
          // delete_permission: "marketing | Employee Event|delete",
        },
        component: <EmployeeEvents />,
        not_found_component: <Navigate to="/not_found" />,
        permission_denied_component: <Navigate to="/permission_denied" />,
      },
    ],
  },

  {
    type: "open",
    name: "Invoice",
    key: "invoice",
    route: "/invoicing/invoice",
    // icon: <Icon fontSize="medium">description</Icon>,
    component: <Invoice />,
    requireRole: false,

    not_found_component: <Navigate to="/not_found" />,
    permission_denied_component: <Navigate to="/permission_denied" />,
  },
  {
    type: "open",
    name: "Recruitement Settings",
    key: "settings",
    route: "/recruitement/settings",
    requireRole: false,
    component: <SettingsRec />,
    not_found_component: <Navigate to="/not_found" />,
    permission_denied_component: <Navigate to="/permission_denied" />,
  },
  {
    type: "open",
    name: "Subscription payment",
    key: "SubscriptionPayment",
    route: "/invoicing/pricing_page/components/SubscriptionPayment",
    requireRole: false,
    component: <SubscriptionPayment />,
    not_found_component: <Navigate to="/not_found" />,
    permission_denied_component: <Navigate to="/permission_denied" />,
  },
  {
    type: "open",
    name: "Attendance Settings",
    key: "attendance_settings",
    permissionKey: "attendance",
    route: "/attendace/settings",
    requireRole: false,
    component: <AttendanceSettings />,
    modulePermissions: {
      office_shift_list_permission: "attendance | office shift|list",
      office_shift_create_permission: "attendance | office shift|create",
      office_shift_update_permission: "attendance | office shift|update",
      office_shift_delete_permission: "attendance | office shift|delete",
    },
    not_found_component: <Navigate to="/not_found" />,
    permission_denied_component: <Navigate to="/permission_denied" />,
  },
  {
    type: "open",
    name: "Leave Settings",
    key: "Leave_settings",
    permissionKey: "leave",
    route: "/leave/settings",
    requireRole: false,
    modulePermissions: {
      leave_type_list_permission: "leave | leave type|list",
      leave_type_create_permission: "leave | leave type|create",
      leave_type_update_permission: "leave | leave type|update",
      leave_type_delete_permission: "leave | leave type|delete",
    },
    component: <LeaveSettings />,
    not_found_component: <Navigate to="/not_found" />,
    permission_denied_component: <Navigate to="/permission_denied" />,
  },
  {
    type: "open",
    name: "Organisation Settings",
    permissionKey: ["organization","attendance"],
    key: "organisation_settings",
    route: "/organisationSettings",
    requireRole: false,
    modulePermissions: {
      list_permission: "organization | organization|list",
      create_permission: "organization | organization|create",
      update_permission: "organization | organisation|update",
      delete_permission: "organization | organization|delete",
      department_list_permission: "organization | department|list",
      department_create_permission: "organization | department|create",
      department_update_permission: "organization | department|update",
      department_delete_permission: "organization | department|delete",
      designation_list_permission: "organization | designation|list",
      designation_create_permission: "organization | designation|create",
      designation_update_permission: "organization | designation|update",
      designation_delete_permission: "organization | designation|delete",
      role_list_permission: "organization | role|list",
      role_create_permission: "organization | role|create",
      role_delete_permission: "organization | role|delete",
      role_update_permission: "organization | role|update",
      documentType_list_permission: "organization | documentType|list",
      documentTyp__create_permission: "organization | documentType|create",
      documentType_update_permission: "organization | documentType|update",
      documentType_delete_permission: "organization | documentType|delete",
      tempcard_list_permission: "attendance | tem card|list",
      tempcard_owner_list_permission: "attendance | tem card|owner_list",
      tempcard_create_permission: "attendance | tem card|create",
      tempcard_update_permission: "attendance | tem card|update",
      tempcard_delete_permission: "attendance | tem card|delete",
    },
    component: <OrganisationSettings />,
    not_found_component: <Navigate to="/not_found" />,
    permission_denied_component: <Navigate to="/permission_denied" />,
  },
  {
    type: "open",
    name: "Payrole Settings",
    permissionKey: "payrole",
    key: "payrole_setting",
    route: "/payrolesetting",
    requireRole: false,
    modulePermissions: {
      list_permission: "payrole | payrole|list",
      create_permission: "payrole | payrole|create",
      update_permission: "payrole | payrole|update",
      delete_permission: "payrole | payrole|delete",
      salary_head_list_permission: "payrole | salary head|list",
      salary_head_create_permission: "payrole | salary head|create",
      salary_head_update_permission: "payrole | salary head|update",
      salary_head_delete_permission: "payrole | salary head|delete",
    },
    component: <PayroleSettings />,
    not_found_component: <Navigate to="/not_found" />,
    permission_denied_component: <Navigate to="/permission_denied" />,
  },
  {
    type: "open",
    name: "Employee Details",
    permissionKey: "employee",
    key: "Employee",
    route: "/dashboards/employees/employee_view",
    requireRole: false,
    modulePermissions: {
      owner_list_permission: "employee | employee|owner_list",
      list_permission: "employee | employee|list",
      create_permission: "employee | employee|create",
      delete_permission: "employee | employee|delete",
      update_permission: "employee | employee|update",
      reporting_structure_owner_list_permission : "employee | reporting structure|owner_list",
      reporting_structure_list_permission : "employee | reporting structure|list",
      reporting_structure_update_permission : "employee | reporting structure|update",
      reporting_structure_create_permission : "employee | reporting structure|create",
      reporting_structure_delete_permission : "employee | reporting structure|delete",
      leave_balance_list_permission: "leave | leave balance|list",
          leave_balance_owner_list_permission: "leave | leave balance|owner_list",
          leave_balance_create_permission: "leave | leave balance|create",
          leave_balance_udpate_permission: "leave | leave balance|update",
    },
    component: <EmployeeView />,
    not_found_component: <Navigate to="/not_found" />,
    permission_denied_component: <Navigate to="/permission_denied" />,
  },
  // {
  //   type: "open",
  //   name: "Employee View",
  //   key: "employee_view",
  //   route: "/dashboards/employees/employee_view",
  //   requireRole: false,
  //   component: <EmployeeView />,
  //   not_found_component: <Navigate to="/not_found" />,
  //   permission_denied_component: <Navigate to="/permission_denied" />,
  // },
  // { type: "title", title: "Pages", key: "title-pages" },
  // {
  //   type: "collapse",
  //   name: "Pages",
  //   key: "pages",
  //   icon: <Icon fontSize="medium">image</Icon>,
  //   collapse: [
  //     {
  //       name: "Profile",
  //       key: "profile",
  //       collapse: [
  //         {
  //           name: "Profile Overview",
  //           key: "profile-overview",
  //           route: "/pages/profile/profile-overview",
  //           component: <ProfileOverview />,
  //         },
  //         {
  //           name: "All Projects",
  //           key: "all-projects",
  //           route: "/pages/profile/all-projects",
  //           component: <AllProjects />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Users",
  //       key: "users",
  //       collapse: [
  //         {
  //           name: "New User",
  //           key: "new-user",
  //           route: "/pages/users/new-user",
  //           component: <NewUser />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Account",
  //       key: "account",
  //       collapse: [
  //         {
  //           name: "Settings",
  //           key: "settings",
  //           route: "/pages/account/settings",
  //           component: <Settings />,
  //         },
  //         {
  //           name: "Billing",
  //           key: "billing",
  //           route: "/pages/account/billing",
  //           component: <Billing />,
  //         },
  //         {
  //           name: "Invoice",
  //           key: "invoice",
  //           route: "/pages/account/invoice",
  //           component: <Invoice />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Projects",
  //       key: "projects",
  //       collapse: [
  //         {
  //           name: "Timeline",
  //           key: "timeline",
  //           route: "/pages/projects/timeline",
  //           component: <Timeline />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Pricing Page",
  //       key: "pricing-page",
  //       route: "/pages/pricing-page",
  //       component: <PricingPage />,
  //     },
  //     { name: "RTL", key: "rtl", route: "/pages/rtl", component: <RTL /> },
  //     { name: "Widgets", key: "widgets", route: "/pages/widgets", component: <Widgets /> },
  //     { name: "Charts", key: "charts", route: "/pages/charts", component: <Charts /> },
  //     {
  //       name: "Notfications",
  //       key: "notifications",
  //       route: "/pages/notifications",
  //       component: <Notifications />,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Applications",
  //   key: "applications",
  //   icon: <Icon fontSize="medium">apps</Icon>,
  //   collapse: [
  //     {
  //       name: "Kanban",
  //       key: "kanban",
  //       route: "/applications/kanban",
  //       component: <Kanban />,
  //     },
  //     {
  //       name: "Wizard",
  //       key: "wizard",
  //       route: "/applications/wizard",
  //       component: <Wizard />,
  //     },
  //     {
  //       name: "Data Tables",
  //       key: "data-tables",
  //       route: "/applications/data-tables",
  //       component: <DataTables />,
  //     },
  //     {
  //       name: "Calendar",
  //       key: "calendar",
  //       route: "/applications/calendar",
  //       component: <Calendar />,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Ecommerce",
  //   key: "ecommerce",
  //   icon: <Icon fontSize="medium">shopping_basket</Icon>,
  //   collapse: [
  //     {
  //       name: "Products",
  //       key: "products",
  //       collapse: [
  //         {
  //           name: "New Product",
  //           key: "new-product",
  //           route: "/ecommerce/products/new-product",
  //           component: <NewProduct />,
  //         },
  //         {
  //           name: "Edit Product",
  //           key: "edit-product",
  //           route: "/ecommerce/products/edit-product",
  //           component: <EditProduct />,
  //         },
  //         {
  //           name: "Product Page",
  //           key: "product-page",
  //           route: "/ecommerce/products/product-page",
  //           component: <ProductPage />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Orders",
  //       key: "orders",
  //       collapse: [
  //         {
  //           name: "Order List",
  //           key: "order-list",
  //           route: "/ecommerce/orders/order-list",
  //           component: <OrderList />,
  //         },
  //         {
  //           name: "Order Details",
  //           key: "order-details",
  //           route: "/ecommerce/orders/order-details",
  //           component: <OrderDetails />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    type: "sign",
    name: "Authentication",
    key: "authentication",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/sign-in/basic",
            component: <SignInBasic />,
          },
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/sign-in/cover",
            component: <SignInCover />,
          },
          {
            name: "Illustration",
            key: "illustration",
            route: "/authentication/sign-in/illustration",
            component: <SignInIllustration />,

            requireRole: false,
            not_found_component: <Navigate to="/not_found" />,
            permission_denied_component: <Navigate to="/permission_denied" />,
          },
        ],
      },
      {
        name: "Sign Up",
        key: "sign-up",
        collapse: [
          // {
          //   name: "Cover",
          //   key: "cover",
          //   route: "/authentication/sign-up/cover",
          //   component: <SignUpCover />,
          // },
          {
            name: "OrgSignUp",
            key: "orgsignup",
            route: "/authentication/sign-up/organisation",

            requireRole: false,
            component: <OrganisationSignUp />,
            not_found_component: <Navigate to="/not_found" />,
            permission_denied_component: <Navigate to="/permission_denied" />,
          },
        ],
      },
      {
        name: "OTP",
        key: "otp",
        route: "/authentication/otp",
        component: <OTP />,
        // component: <SignUpIllustration />,
        not_found_component: <Navigate to="/not_found" />,
        permission_denied_component: <Navigate to="/permission_denied" />,
      },
      {
        name: "Reset Password",
        key: "reset-password",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/reset-password/cover",
            component: <ResetCover />,
          },
        ],
      },
    ],
  },

  // {
  //   type: "collapse",
  //   name: "Marketing",
  //   key: "marketing",
  //   icon: <Icon fontSize="medium">file_copy</Icon>,
  //   collapse: [
  //     {
  //       name: "New Event",
  //       key: "newEvent",
  //       route: "/marketing/newEvent",
  //       requireRole: false,
  //       icon: <Icon fontSize="medium">receipt_long</Icon>,
  //       component: <NewEvent />,
  //       not_found_component: <Navigate to="/not_found" />,
  //       permission_denied_component: <Navigate to="/permission_denied" />,
  //     },
  //   ],
  // },
  {
    type: "collapse",
    name: "Attendance",
    key: "Attendance",
    icon: <Icon fontSize="medium">fingerprint_sharp</Icon>,
    collapse: [
      {
        name: "Attendance",
        key: "Attendance",
        permissionKey: "attendance",
        icon: <Icon fontSize="medium">browse_gallery</Icon>,
        route: "/Attendance",
        requireRole: true,
        modulePermissions: {
          own_list_permission: "attendance | Employee Attendence|owner_list",
          list_permission: "attendance | Employee Attendence|list",
          create_permission: "attendance | Employee Attendence|create",
          update_permission: "attendance | Employee Attendence|update",
          delete_permission: "attendance | Employee Attendence|delete",
        },
        component: <Attendance />,
        not_found_component: <Navigate to="/not_found" />,
        permission_denied_component: <Navigate to="/permission_denied" />,
      },
      {
        name: "Shifts",
        key: "Shifts",
        permissionKey: "attendance",
        icon: <Icon fontSize="medium">access_time</Icon>,
        route: "/Attendance/Shifts",
        requireRole: true,
        modulePermissions: {
          own_list_permission: "attendance | employee shift|owner_list",
          list_permission: "attendance | employee shift|list",
          create_permission: "attendance | employee shift|create",
          update_permission: "attendance | employee shift|update",
          delete_permission: "attendance | employee shift|delete",
        },
        component: <Shifts />,
        not_found_component: <Navigate to="/not_found" />,
        permission_denied_component: <Navigate to="/permission_denied" />,
      },
      {
        name: "Upload Attendance",
        key: "UploadAttendance",
        permissionKey: "attendance",
        icon: <Icon fontSize="medium">access_time</Icon>,
        route: "/Attendance/UploadAttendance",
        requireRole: true,
        modulePermissions: {
          own_list_permission: "attendance | Employee Attendence|create",
          list_permission: "attendance | Employee Attendence|create",
          create_permission: "attendance | Employee Attendence|create",
          update_permission: "attendance | Employee Attendence|update",
          delete_permission: "attendance | Employee Attendence|delete",
        },
        component: <UploadAttendance />,
        not_found_component: <Navigate to="/not_found" />,
        permission_denied_component: <Navigate to="/permission_denied" />,
      },
      {
        name: "Approve MissPunch",
        key: "ApproveMissPunch",
        permissionKey: "attendance",
        icon: <Icon fontSize="medium">access_time</Icon>,
        route: "/Attendance/ApproveMissPunch",
        requireRole: true,
        modulePermissions: {
          own_list_permission: "attendance | Employee Attendence|update",
          list_permission: "attendance | Employee Attendence|update",

        },
        component: <ApproveMissPunch />,
        not_found_component: <Navigate to="/not_found" />,
        permission_denied_component: <Navigate to="/permission_denied" />,
      },
      // {
      //   name: "All Attendance",
      //   key: "AllAttendance",
      //   permissionKey: "attendance",
      //   icon: <Icon fontSize="medium">browse_gallery</Icon>,
      //   route: "/AllAttendance",
      //   requireRole: true,
      //   modulePermissions: {
      //     own_list_permission: "attendance | Employee Attendence|owner_list",
      //     list_permission: "attendance | Employee Attendence|list",
      //     create_permission: "attendance | Employee Attendence|create",
      //     update_permission: "attendance | Employee Attendence|update",
      //     delete_permission: "attendance | Employee Attendence|delete",
      //   },
      //   component: <AllAttendance/>,
      //   not_found_component: <Navigate to="/not_found" />,
      //   permission_denied_component: <Navigate to="/permission_denied" />,
      // },
    ],
  },
  {
    type: "collapse",
    name: "Leaves",
    key: "Leaves",
    icon: <Icon fontSize="medium">house_boat</Icon>,
    collapse: [
      {
        name: "Leaves",
        key: "Leaves",
        permissionKey: "leave",
        modulePermissions: {
          own_list_permission: "leave | leave request|owner_list",
          list_permission: "leave | leave request|list",
          create_permission: "leave | leave request|create",
          update_permission: "leave | leave request|update",
          delete_permission: "leave | leave request|delete",
          balance_list_permission: "leave | leave balance|list",
          balance_create_permission: "leave | leave balance|create",
          balance_update_permission: "leave | leave balance|update",
          balance_delete_permission: "leave | leave balance|delete",
          transaction_owner_list_permission : "leave | leave transaction|owner_list",
          transaction_list_permission: "leave | leave transaction|list",
          transaction_create_permission: "leave | leave transaction|create",
          transaction_update_permission: "leave | leave transaction|update",
          transaction_delete_permission: "leave | leave transaction|delete",
        },
        icon: <Icon fontSize="medium">house_boat</Icon>,
        route: "/Leaves",
        requireRole: true,
        component: <Leaves />,
        not_found_component: <Navigate to="/not_found" />,
        permission_denied_component: <Navigate to="/permission_denied" />,
      },
      {
        name: "Approve Leave",
        key: "ApproveLeave",
        permissionKey: "leave",
        modulePermissions: {
          own_list_permission: "leave | leave request approval|owner_list",
          list_permission: "leave | leave request approval|list",
          create_permission: "leave | leave request approval|create",
          update_permission: "leave | leave request approval|update",
          delete_permission: "leave | leave request approval|delete",
        },
        icon: <Icon fontSize="medium">check_circle</Icon>,
        route: "/Leaves/ApproveLeave",
        requireRole: true,
        component: <ApproveLeave />,
        not_found_component: <Navigate to="/not_found" />,
        permission_denied_component: <Navigate to="/permission_denied" />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Holidays",
    key: "Holidays",
    icon: <Icon fontSize="medium">assignment</Icon>,
    collapse: [
      {
        name: "Holidays",
        key: "Holidays",
        permissionKey: "holiday",
        icon: <Icon fontSize="medium">assignment</Icon>,
        route: "/Holidays",
        requireRole: true,
        modulePermissions: {
          list_permission: "holiday | holiday|list",
          create_permission: "holiday | holiday|create",
          delete_permission: "holiday | holiday|delete",
          update_permission: "holiday | holiday|update",
        },
        component: <Holidays />,

        not_found_component: <Navigate to="/not_found" />,
        permission_denied_component: <Navigate to="/permission_denied" />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Payrole",
    key: "Payrole",
    icon: <Icon fontSize="medium">assignment</Icon>,
    collapse: [
      {
        name: "Payrole",
        key: "Payrole",
        permissionKey: "payrole",
        icon: <Icon fontSize="medium">assignment</Icon>,
        route: "/Payrole",
        requireRole: false,
        modulePermissions: {
          list_permission: "payrole | payrole|list",
          create_permission: "payrole | payrole|create",
          delete_permission: "payrole | payrole|delete",
          update_permission: "payrole | payrole|update",
        },
        component: <Payrole />,

        not_found_component: <Navigate to="/not_found" />,
        permission_denied_component: <Navigate to="/permission_denied" />,
      },
      {
        name: "Contracts",
        key: "Contracts",
        permissionKey: "payrole",
        icon: <Icon fontSize="medium">assignment</Icon>,
        route: "/Payrole/Contracts",
        requireRole: true,
        modulePermissions: {
          list_permission: "payrole | employee contract|list",
          // create_permission: "payrole | payrole|create",
          // delete_permission: "payrole | payrole|delete",
          // update_permission: "payrole | payrole|update",
        },
        component: <Contracts />,

        not_found_component: <Navigate to="/not_found" />,
        permission_denied_component: <Navigate to="/permission_denied" />,
      },
      {
        name: "Expenses",
        key: "Expenses",
        permissionKey: "payrole",
        icon: <Icon fontSize="medium">assignment</Icon>,
        route: "/Payrole/Expenses",
        requireRole: true,
        modulePermissions: {
          list_permission: "payrole | expense or incentive or deduction|list",
          // create_permission: "payrole | payrole|create",
          // delete_permission: "payrole | payrole|delete",
          // update_permission: "payrole | payrole|update",
        },
        component: <Expenses />,

        not_found_component: <Navigate to="/not_found" />,
        permission_denied_component: <Navigate to="/permission_denied" />,
      },
      {
        name: "Salary",
        key: "Salary",
        permissionKey: "payrole",
        icon: <Icon fontSize="medium">assignment</Icon>,
        route: "/Payrole/Salary",
        requireRole: true,
        modulePermissions: {
          list_permission: "payrole | employee salary|list",
          // create_permission: "payrole | payrole|create",
          // delete_permission: "payrole | payrole|delete",
          // update_permission: "payrole | payrole|update",
        },
        component: <Salary />,

        not_found_component: <Navigate to="/not_found" />,
        permission_denied_component: <Navigate to="/permission_denied" />,
      },
      {
        name: "Batch",
        key: "Batch",
        permissionKey: "payrole",
        icon: <Icon fontSize="medium">assignment</Icon>,
        route: "/Payrole/Batch",
        requireRole: false,
        modulePermissions: {
          list_permission: "payrole | employee salary|list",
          // create_permission: "payrole | payrole|create",
          // delete_permission: "payrole | payrole|delete",
          // update_permission: "payrole | payrole|update",
        },
        component: <Batch />,

        not_found_component: <Navigate to="/not_found" />,
        permission_denied_component: <Navigate to="/permission_denied" />,
      },
      // {
      //   name: "Generate Payrole",
      //   key: "generatePayrole",
      //   permissionKey:"payrole",
      //   icon: <Icon fontSize="medium">assignment</Icon>,
      //   route: "Payrole/generatePayrole",
      //   requireRole: false,
      //   modulePermissions: {
      //     list_permission: "payrole | payrole|list",
      //     create_permission: "payrole | payrole|create",
      //     delete_permission: "payrole | payrole|delete",
      //     update_permission: "payrole | payrole|update",
      //   },
      //   component: <GeneratePayrole />,
      //   not_found_component: <Navigate to="/not_found" />,
      //   permission_denied_component: <Navigate to="/permission_denied" />,
      // },
    ],
  },
 
  // {
  //   type: "collapse",
  //   name: "Invoicing",
  //   key: "invoicing",
  //   icon: <Icon fontSize="medium">file_copy</Icon>,
  //   collapse: [
  //     {
  //       name: "Billing",
  //       key: "billing",
  //       route: "/invoicing/billing",
  //       requireRole: false,
  //       icon: <Icon fontSize="medium">receipt_long</Icon>,

  //       component: <Billing />,
  //       not_found_component: <Navigate to="/not_found" />,
  //       permission_denied_component: <Navigate to="/permission_denied" />,
  //     },

  //     {
  //       name: "Pricing Page",
  //       key: "pricing_page",
  //       route: "/invoicing/pricing_page",
  //       icon: <Icon fontSize="medium">attach_money</Icon>,
  //       requireRole: false,

  //       component: <PricingPage />,
  //       not_found_component: <Navigate to="/not_found" />,
  //       permission_denied_component: <Navigate to="/permission_denied" />,
  //     },
  //   ],
  // },
  
  // {
  //   type: "collapse",
  //   name: "Manager Desk",
  //   key: "manager-desk",
  //   icon: <Icon fontSize="medium">info</Icon>,
  //   collapse: [
  //     {
  //       name: "Daily Status",
  //       key: "daily-status",
  //       icon: <Icon fontSize="medium">pix_sharp</Icon>,
  //       route: "/layouts/managerdesk/dailyStatus",
  //       requireRole: false,
  //       component:<DailyStatus/>,
  //       not_found_component: <Navigate to="/not_found" />,
  //       permission_denied_component: <Navigate to="/permission_denied" />,
  //     },
  //     {
  //       name: "Approve Leave",
  //       key: "approve-leave",
  //       icon: <Icon fontSize="medium">check_circle</Icon>,
  //       route: "/layouts/managerdesk/approveLeave",
  //       requireRole: false,
  //       component:<ApproveLeave/>,
  //       not_found_component: <Navigate to="/not_found" />,
  //       permission_denied_component: <Navigate to="/permission_denied" />,
  //     },
  //     {
  //       name: "Team Feedback",
  //       key: "team-feedback",
  //       icon: <Icon fontSize="medium">feedback</Icon>,
  //       route: "/layouts/managerdesk/teamFeedback",
  //       requireRole: false,
  //       component:<Teamfeedback/>,
  //       not_found_component: <Navigate to="/not_found" />,
  //       permission_denied_component: <Navigate to="/permission_denied" />,
  //     },

  //   ],
  // },

  // { type: "title", title: "Docs", key: "title-docs" },
  // {
  //   type: "collapse",
  //   name: "Basic",
  //   key: "basic",
  //   icon: <Icon fontSize="medium">upcoming</Icon>,
  //   collapse: [
  //     {
  //       name: "Getting Started",
  //       key: "getting-started",
  //       collapse: [
  //         {
  //           name: "Overview",
  //           key: "overview",
  //           href: "https://www.creative-tim.com/learning-lab/react/overview/material-dashboard/",
  //         },
  //         {
  //           name: "License",
  //           key: "license",
  //           href: "https://www.creative-tim.com/learning-lab/react/license/material-dashboard/",
  //         },
  //         {
  //           name: "Quick Start",
  //           key: "quick-start",
  //           href: "https://www.creative-tim.com/learning-lab/react/quick-start/material-dashboard/",
  //         },
  //         {
  //           name: "Build Tools",
  //           key: "build-tools",
  //           href: "https://www.creative-tim.com/learning-lab/react/build-tools/material-dashboard/",
  //         },
  //       ],
  //     },
  //     {
  //       name: "Foundation",
  //       key: "foundation",
  //       collapse: [
  //         {
  //           name: "Colors",
  //           key: "colors",
  //           href: "https://www.creative-tim.com/learning-lab/react/colors/material-dashboard/",
  //         },
  //         {
  //           name: "Grid",
  //           key: "grid",
  //           href: "https://www.creative-tim.com/learning-lab/react/grid/material-dashboard/",
  //         },
  //         {
  //           name: "Typography",
  //           key: "base-typography",
  //           href: "https://www.creative-tim.com/learning-lab/react/base-typography/material-dashboard/",
  //         },
  //         {
  //           name: "Borders",
  //           key: "borders",
  //           href: "https://www.creative-tim.com/learning-lab/react/borders/material-dashboard/",
  //         },
  //         {
  //           name: "Box Shadows",
  //           key: "box-shadows",
  //           href: "https://www.creative-tim.com/learning-lab/react/box-shadows/material-dashboard/",
  //         },
  //         {
  //           name: "Functions",
  //           key: "functions",
  //           href: "https://www.creative-tim.com/learning-lab/react/functions/material-dashboard/",
  //         },
  //         {
  //           name: "Routing System",
  //           key: "routing-system",
  //           href: "https://www.creative-tim.com/learning-lab/react/routing-system/material-dashboard/",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Components",
  //   key: "components",
  //   icon: <Icon fontSize="medium">view_in_ar</Icon>,
  //   collapse: [
  //     {
  //       name: "Alerts",
  //       key: "alerts",
  //       href: "https://www.creative-tim.com/learning-lab/react/alerts/material-dashboard/",
  //     },
  //     {
  //       name: "Avatar",
  //       key: "avatar",
  //       href: "https://www.creative-tim.com/learning-lab/react/avatar/material-dashboard/",
  //     },
  //     {
  //       name: "Badge",
  //       key: "badge",
  //       href: "https://www.creative-tim.com/learning-lab/react/badge/material-dashboard/",
  //     },
  //     {
  //       name: "Badge Dot",
  //       key: "badge-dot",
  //       href: "https://www.creative-tim.com/learning-lab/react/badge-dot/material-dashboard/",
  //     },
  //     {
  //       name: "Box",
  //       key: "box",
  //       href: "https://www.creative-tim.com/learning-lab/react/box/material-dashboard/",
  //     },
  //     {
  //       name: "Buttons",
  //       key: "buttons",
  //       href: "https://www.creative-tim.com/learning-lab/react/buttons/material-dashboard/",
  //     },
  //     {
  //       name: "Date Picker",
  //       key: "date-picker",
  //       href: "https://www.creative-tim.com/learning-lab/react/datepicker/material-dashboard/",
  //     },
  //     {
  //       name: "Dropzone",
  //       key: "dropzone",
  //       href: "https://www.creative-tim.com/learning-lab/react/dropzone/material-dashboard/",
  //     },
  //     {
  //       name: "Editor",
  //       key: "editor",
  //       href: "https://www.creative-tim.com/learning-lab/react/quill/material-dashboard/",
  //     },
  //     {
  //       name: "Input",
  //       key: "input",
  //       href: "https://www.creative-tim.com/learning-lab/react/input/material-dashboard/",
  //     },
  //     {
  //       name: "Pagination",
  //       key: "pagination",
  //       href: "https://www.creative-tim.com/learning-lab/react/pagination/material-dashboard/",
  //     },
  //     {
  //       name: "Progress",
  //       key: "progress",
  //       href: "https://www.creative-tim.com/learning-lab/react/progress/material-dashboard/",
  //     },
  //     {
  //       name: "Snackbar",
  //       key: "snackbar",
  //       href: "https://www.creative-tim.com/learning-lab/react/snackbar/material-dashboard/",
  //     },
  //     {
  //       name: "Social Button",
  //       key: "social-button",
  //       href: "https://www.creative-tim.com/learning-lab/react/social-buttons/material-dashboard/",
  //     },
  //     {
  //       name: "Typography",
  //       key: "typography",
  //       href: "https://www.creative-tim.com/learning-lab/react/typography/material-dashboard/",
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Change Log",
  //   key: "changelog",
  //   href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
  //   icon: <Icon fontSize="medium">receipt_long</Icon>,
  //   noCollapse: true,
  // },
];

export default routes;
