/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import "./js.css";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDBadge from "components/MDBadge";

// Material Dashboard 2 PRO React examples
// import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
import DefaultItem from "examples/Items/DefaultItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import { developmentMessage } from "services/apis";
import { Grid } from "@mui/material";

function DashboardNavbar({ absolute, light, isMini, debugBanner }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const [openSettingsMenu, setOpenSettingsMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);
    
    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleOpenProfileMenu = (event) =>
    setOpenProfileMenu(event.currentTarget);
  const handleOpenSettingsMenu = (event) =>
    setOpenSettingsMenu(event.currentTarget);
  const handleCloseSettingsMenu = () => setOpenSettingsMenu(false);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleCloseProfileMenu = () => setOpenProfileMenu(false);
  const handleRecDashboard = () => {
    navigate("/recruitement/settings");
  };
  const handleAttendanceSetting = () => {
    navigate("/attendace/settings");
  };
  const handleOrganisationSetting = () => {
    navigate("/organisationSettings");
  };
  const handleLeaveSetting = () => {
    navigate("/leave/settings");
  };
  const handlePayroleSetting = () => {
    navigate("/payrolesetting");
  };

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem
        icon={<Icon>podcasts</Icon>}
        title="Manage Podcast sessions"
      />
      <NotificationItem
        icon={<Icon>shopping_cart</Icon>}
        title="Payment successfully completed"
      />
    </Menu>
  );
  const renderProfileMenu = () => (
    <Menu
      anchorEl={openProfileMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openProfileMenu)}
      onClose={handleCloseProfileMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        onClick={handleEditProfile}
        icon={<Icon>account_box</Icon>}
        title="Edit Profile"
      />
      <NotificationItem
        onClick={handleProfile}
        icon={<Icon>workspace</Icon>}
        title="Workspace"
      />
      <NotificationItem
        onClick={handleConfiguratorOpen}
        icon={<Icon>settings</Icon>}
        title="Settings"
      />
      <NotificationItem
        onClick={handleLogout}
        icon={<Icon>logout</Icon>}
        title="Logout"
      />
      {/* <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" /> */}
    </Menu>
  );
  const renderSettingsMenu = () => (
    <Menu
      anchorEl={openSettingsMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openSettingsMenu)}
      onClose={handleCloseSettingsMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        onClick={handleRecDashboard}
        icon={<Icon>settings</Icon>}
        title="Recruitement Settings"
      />
      {/* <NotificationItem onClick= {handleConfiguratorOpen} icon={<Icon>dashboard_customize</Icon>} title="Dashboard Settings" /> */}
      <NotificationItem
        onClick={handleAttendanceSetting}
        icon={<Icon>dashboard_customize</Icon>}
        title="Attendance Settings"
      />
      <NotificationItem
        onClick={handleOrganisationSetting}
        icon={<Icon>dashboard_customize</Icon>}
        title="Organisation Settings"
      />
      <NotificationItem
        onClick={handleLeaveSetting}
        icon={<Icon>dashboard_customize</Icon>}
        title="Leave Settings"
      />
      <NotificationItem
        onClick={handlePayroleSetting}
        icon={<Icon>dashboard_customize</Icon>}
        title="Payrole Settings"
      />

      {/* <NotificationItem onClick= {handleLogout} icon={<Icon>logout</Icon>} title="Logout" /> */}
      {/* <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" /> */}
    </Menu>
  );

  const navigate = useNavigate();
  const handleLogout = () => {
    navigate("/authentication/sign-in/illustration");
  };
  const handleProfile = () => {
    navigate("/pages/profile/profile-overview");
  };
  const user = JSON.parse(localStorage.getItem("user"));
  const empID = user?.employee_id;
  const handleEditProfile = () => {
    navigate("/pages/account/settings", {
      state: {
        userid: empID,
      },
    });
    // navigate(`/pages/account/settings?empID=${empID}`);
  };

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <>
      <AppBar
        position={absolute ? "absolute" : navbarType}
        color="inherit"
        sx={(theme) =>
          navbar(theme, { transparentNavbar, absolute, light, darkMode })
        }
      >
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <MDBox
            color="inherit"
            mb={{ xs: 1, md: 0 }}
            sx={(theme) => navbarRow(theme, { isMini })}
          >
            {/* <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} /> */}
            <Grid container>
              <Grid item>
                {" "}
                <IconButton
                  sx={navbarDesktopMenu}
                  onClick={handleMiniSidenav}
                  size="small"
                  disableRipple
                >
                  <Icon fontSize="medium" sx={iconsStyle}>
                    {miniSidenav ? "menu_open" : "menu"}
                  </Icon>
                </IconButton>
              </Grid>
              {debugBanner && (
                <Grid item>
                  <div id="breaking-news-container">
                    <div
                      id="breaking-news-colour"
                      class="slideup animated"
                    ></div>
                    <span class="breaking-news-title delay-animated slidein">
                      // UPDATE //
                    </span>
                    <a class="breaking-news-headline delay-animated2 fadein marquee">
                      {developmentMessage}
                    </a>
                  </div>
                </Grid>
              )}
            </Grid>
          </MDBox>
          {isMini ? null : (
            <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
              <MDBox pr={1}>{/* <MDInput label="Search here" /> */}</MDBox>
              <MDBox color={light ? "white" : "inherit"}>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="profile-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenSettingsMenu}
                >
                  <MDBadge color="error" size="xs" circular>
                    <Icon sx={iconsStyle}>settings</Icon>
                  </MDBadge>
                </IconButton>
                {renderSettingsMenu()}

                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarMobileMenu}
                  onClick={handleMiniSidenav}
                >
                  <Icon sx={iconsStyle} fontSize="medium">
                    {miniSidenav ? "menu_open" : "menu"}
                  </Icon>
                </IconButton>
              </MDBox>
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <MDBadge badgeContent={9} color="error" size="xs" circular>
                  <Icon sx={iconsStyle}>notifications</Icon>
                </MDBadge>
              </IconButton> */}
              {/* {renderMenu()} */}
            </MDBox>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
