import {
  Autocomplete,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import ApiCalls from "services/flick_erp_api_call";
import InfoIcon from "@mui/icons-material/Info";
import MDBox from "components/MDBox";
import SalesTable from "examples/Tables/SalesTable";
import MDTypography from "components/MDTypography";
import FormField from "layouts/applications/wizard/components/FormField";
import MDButton from "components/MDButton";
import { Edit } from "@mui/icons-material";

export function Expenses() {
  const [createExpenseDialog, setCreateExpenseDialog] = useState(false);
  const [salaryHeadDetails, setSalaryHeadDetails] = useState([]);
  const [contractDetails, setContractDetails] = useState([]);
  const [expensePayload, setExpensePayload] = useState({});
  const [idAndName, setIdAndName] = useState([]);
  let ArrayData = [];

  useEffect(() => {
    getContracts();
  },[]);

  async function getContracts() {
    var res = await ApiCalls.getEmployeeContract();
    if (res.success && res.code == 200) {
      setContractDetails(res.data);
    }
  }

  useEffect(() => {
    let idAndName_ = contractDetails?.map(contracts => ({id: contracts.id, name: contracts.bank.name_in_account}));
      setIdAndName(idAndName_)
      ArrayData = idAndName_;
      getExpenses();
  },[contractDetails])

  async function getExpenses() {
    var res = await ApiCalls.getExpenseIncentiveDeductions();
    if (res.success && res.code == 200) {
      // expensesIncentivesDeductions.rows = res.data;
      let data = res.data;

      data.map((dataa) => {
        const contractInfo = ArrayData.find(item => item.id === dataa.contract);
        if (contractInfo) {
          dataa.contract = contractInfo.name;

          // return { ...dataa, contract: contractInfo.name };
          
        } else {
          console.log("Contract ID not found in idAndName array");
          return dataa;
        }
        
      })
      expensesIncentivesDeductions.rows = data;
      setExpensesIncentivesDeductions({ ...expensesIncentivesDeductions });
    }
  }
  
  const [expensesIncentivesDeductions, setExpensesIncentivesDeductions] =
    useState({
      columns: [
        {
          Header: "NAME",
          accessor: "name",
        },
        {
          Header: "CONTRACT",
          accessor: "contract",
        },
        {
          Header: "START DATE",
          accessor: "start_date",
        },
        {
          Header: "END DATE",
          accessor: "end_date",
        },
        {
          Header: "LINE TYPE",
          accessor: "line_type",
        },
        {
          Header: "Amount",
          accessor: "amount",
        },

        {
          Header: "Action",
          accessor: "inout",
          Cell: ({ row }) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Tooltip title="Info">
                <IconButton
                  aria-label="info"
                  size="small"
                  onClick={() => {
                    setExpensePayload({ type: "edit", ...row.original });
                    setCreateExpenseDialog(true);
                    // handleView(row.original.salary_lines);
                  }}
                >
                  <Edit></Edit>
                </IconButton>
              </Tooltip>
            </div>
          ),
        },
      ],
      rows: [],
    });
  //   const handleView = (data) => {
  //     setSalaryHeadDetails(
  //         data?.map((e) => ({
  //             description : e?.description,
  //           amount:e?.amount,

  //           }))
  //     );
  //     setCreateExpenseDialog(true);
  //   };
  async function createExpense() {
    var res = await ApiCalls.createExpenseIncentiveDeductions(
      expensePayload,
      false
    );
    if (res.success) {
        getExpenses()
      setExpensePayload({});
      setCreateExpenseDialog(false);
    }
  }
  async function editExpense() {
    var res = await ApiCalls.editExpenseIncentiveDeductions(
      expensePayload.id,
      expensePayload,
      false
    );
    if (res.success) {
        getExpenses()
      setExpensePayload({});
      setCreateExpenseDialog(false);
    }
  }

  return (
    <DashboardLayout>
      <Card>
        <MDBox
          pt={3}
          pl={3}
          pb={4}
          pr={3}
          display="flex"
          justifyContent="space-between"
        >
          <MDTypography variant="h4">Expenses</MDTypography>
          <Fab
            color="info"
            onClick={() => {
              setExpensePayload({ type: "create" });
              setCreateExpenseDialog(true);
              //   setAddManagerDialog(true);
            }}
          >
            <MDTypography color="white">+</MDTypography>
          </Fab>
        </MDBox>
        <MDBox>
          <DataTable
            table={expensesIncentivesDeductions}
            entriesPerPage={{
              defaultValue: 100,
              entries: [5, 10, 15, 20, 25, 30],
            }}
            showTotalEntries={true}
            isSorted={false}
          ></DataTable>
        </MDBox>
      </Card>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "white",
            boxShadow: "none",
            width: "auto",
            maxWidth: "none",
            borderRadius: "10px",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        open={createExpenseDialog}
        onClose={() => {
          setExpensePayload({});
          setCreateExpenseDialog(false);
        }}
      >
        <DialogTitle
          style={{
            width: "100%",
            padding: "20px",
            borderBottom: "10px solid ",
            backgroundColor: "#007bed",
            color: "white",
          }}
        >
          <Typography sx={{ color: "white", fontWeight: "Medium" }}>
            Create Expenses
          </Typography>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "4px",
          }}
        >
          <MDBox align="center">
            <MDBox p={3} lineHeight={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    fullWidth
                    value={contractDetails.find(
                      (e) => e.id == expensePayload.contract
                    )}
                    options={contractDetails}
                    getOptionLabel={(e) => e?.bank?.name_in_account}
                    onChange={(event, value) => {

                      setExpensePayload((state) => ({
                        ...state,
                        contract: value?.id,

                      }));
                      
                    }}
                    renderInput={(params) => (
                      <FormField label="Contract" {...params} />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    fullWidth
                    value={["allowance", "deduction"].find(
                      (e) => e == expensePayload.line_type
                    )}
                    options={["allowance", "deduction"]}
                    onChange={(event, value) => {
                      setExpensePayload((state) => ({
                        ...state,
                        line_type: value,
                      
                      }));
                    }}
                    renderInput={(params) => (
                      <FormField label="Line Type" {...params} />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    label="Name"
                    value={expensePayload.name}
                    onChange={(e) => {
                      setExpensePayload((state) => ({
                        ...state,
                        name: e.target.value,
                       
                      }));
                    }}
                    placeholder="Name"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    label="Amount"
                    value={expensePayload.amount}
                    onChange={(e) => {
                      setExpensePayload((state) => ({
                        ...state,
                        amount: e.target.value,
                       
                      }));
                    }}
                    inputProps={{ type: "number" }}

                    // placeholder="DD/MM/YY"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    label="Start Date"
                    InputLabelProps={{ shrink: true }}
                    value={expensePayload.start_date}
                    // value={contract?.start_date || ""}
                    onChange={(e) => {

                      setExpensePayload((state) => ({
                        ...state,
                        start_date: e.target.value,

                      }));
                    }}
                    inputProps={{ type: "date" }}
                    placeholder="DD/MM/YY"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormField
                    label="End Date"
                    value={expensePayload.end_date}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      setExpensePayload((state) => ({
                        ...state,
                        end_date: e.target.value,

                      }));
                    }}
                    inputProps={{ type: "date", shrink: true }}
                    placeholder="DD/MM/YY"
                  />
                </Grid>
              </Grid>
              <MDBox p={2}>
                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={() =>
                  { 

                    expensePayload.type == "create"
                      ? createExpense()
                      : editExpense()}
                  }
                >
                  Submit
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </DialogContent>
      </Dialog>
    </DashboardLayout>
  );
}
