import { Edit } from "@mui/icons-material";
import { Dialog, DialogTitle, Fab } from "@mui/material";
import {
  Autocomplete,
  Card,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import ApiCalls from "services/flick_erp_api_call";

export function LeaveBalance({ data,permissionKey, setIsReloadRequest ,modulePermissions }) {
  const [leavesTypes, setLeavesTypes] = useState([]);
  const [leaveBalance, setLeaveBalance] = useState([]);

  const [addLeaveBalanceDialog, setAddLeaveBalanceDialog] = useState(false);
  const [leaveBalancePayload, setLeaveBalancePayload] = useState({});

  const [leaveTypeValidationError, setLeaveTypeValidationError] = useState('');
  const [balanceValidationError, setBalanceValidationError] = useState('');

  const loggedUser = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    getLeaveTypes();
    getLeaveBalance();
  }, [data]);
    // get leave Balance
    async function getLeaveBalance() {
      var res = await ApiCalls.getEmployeeLeaveBalance(data.id);
      if (res.success && res.code == 200) {
        setLeaveBalance(res.data);
      }
    }
  

  // get leave Types
  async function getLeaveTypes() {
    var res = await ApiCalls.getLeavesTypes();
    if (res.success && res.code == 200) {
      setLeavesTypes(res.data);
    }
  }

  async function postLeaveBalance() {
    setLeaveTypeValidationError('');
    setBalanceValidationError('');

    let isValid = true;

    if (!leaveBalancePayload.leave_type) {
      setLeaveTypeValidationError('Leave Type is required');
      isValid = false;
    }

    if (!leaveBalancePayload.balance || isNaN(leaveBalancePayload.balance)) {
      setBalanceValidationError('Balance must be a valid number');
      isValid = false;
    }

    if (isValid) {
      var payload = {
        balance: leaveBalancePayload?.balance,
        org: loggedUser?.org_id,
        employee: data?.id,
        leave_type: leaveBalancePayload?.leave_type,
      };
      var res = await ApiCalls.postLeaveBalance(payload, false);
      if (res.success && res.code == 201) {
        setAddLeaveBalanceDialog(false);
        getLeaveBalance()
      } else {
        setAddLeaveBalanceDialog(false)

      }
    }


  }

  async function patchLeaveBalance() {
    var payload = {
      ...leaveBalancePayload,
      leave_type: leaveBalancePayload.leave_type.id
    };
    var res = await ApiCalls.patchLeaveBalance(
      payload,
      leaveBalancePayload.id,
      false
    );
    if (res.success && res.code == 200) {
      setIsReloadRequest(true)

      setAddLeaveBalanceDialog(false);
    } else {
      setAddLeaveBalanceDialog(false);
    }
  }
  async function resetLeaveBalancePayload() {
    setLeaveBalancePayload({});
  }
  return (
    <>
      <Card>
        <MDBox p={3} display="flex" justifyContent="space-between">
          <MDTypography variant="h4">Leave Balance</MDTypography>
        {loggedUser?.permissions_all[permissionKey]?.includes(modulePermissions?.leave_balance_create_permission) &&(  <Fab
            color="info"
            onClick={() => {
              setLeaveTypeValidationError('');
              setBalanceValidationError('');
              resetLeaveBalancePayload();
              setLeaveBalancePayload({ request: "create" });
              setAddLeaveBalanceDialog(true);
            }}
          >
            <MDTypography color="white">+</MDTypography>
          </Fab>)}
        </MDBox>
      {(loggedUser?.permissions_all[permissionKey]?.includes(modulePermissions?.leave_balance_list_permission) || loggedUser?.permissions_all[permissionKey]?.includes(modulePermissions?.leave_balance_owner_list_permission)) ?(  <MDBox component="form" pb={3} px={3}>
          {Object.keys(leaveBalance)?.map(function (e) {
            return (
              <>
                {
                  <MDBox p={2} width="80%">
                    <Card sx={{ padding: "12px" }}>
                      <MDBox
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <MDBox>
                          <MDTypography textTransform="capitalize">{e.replaceAll("_", " ")}</MDTypography>
                          <MDTypography variant="h5" >
                            Balance: {leaveBalance[e]} Hours
                          </MDTypography>
                        </MDBox>
{/* { loggedUser?.permissions_all[permissionKey]?.includes(modulePermissions?.leave_balance_udpate_permission) &&(
                        <Edit
                          onClick={() => {
                            setLeaveBalancePayload({ ...e, request: "Edit" });
                            setAddLeaveBalanceDialog(true);
                          }}
                        ></Edit>)} */}
                      </MDBox>
                    </Card>
                  </MDBox>
                }
              </>
            );
          })}
        </MDBox>) : (
         <MDBox p={3} align="center">
           <MDTypography>Not Permitted To View Leave Balance List!</MDTypography>
         </MDBox>
        )}
        <Dialog
          PaperProps={{
            style: {
              backgroundColor: "aliceBlue",
              boxShadow: "none",
              width: "500px",
              paddingBottom: "20px",
              maxWidth: "none",
            },
          }}
          open={addLeaveBalanceDialog}
          onClose={() => setAddLeaveBalanceDialog(false)}
        >
          <DialogTitle
            style={{
              backgroundColor: "#007bed",
              color: "white",
            }}
          >
            <Typography sx={{ color: "white", fontWeight: "Medium" }}>
              Add Leave Balance
            </Typography>
          </DialogTitle>
          <DialogContent
            style={{
              padding: "4px",
            }}
          >
            <MDBox
              display="flex"
              flexDirection="column"
              alignItems="center"
              pt={4}
            >
              <Grid container spacing={3} alignItems="flex-end" p={2}>
                <Grid item xs={6}>
                  <Autocomplete
                    fullWidth
                    options={leavesTypes}
                    value={leavesTypes.find(
                      (e) => e?.id == leaveBalancePayload?.leave_type?.id
                    )}
                    onChange={(event, values) => {
                      setLeaveBalancePayload((state) => ({
                        ...state,
                        leave_type: values.id,
                      }));
                      setLeaveTypeValidationError('');
                    }}
                    getOptionLabel={(v) => v.name}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        variant="standard"
                        label="Leave Type"
                        {...params}
                        placeholder="Select Leave Type"
                        InputLabelProps={{ shrink: true }}
                        required
                        error={!!leaveTypeValidationError}
                        helperText={leaveTypeValidationError}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Balance"
                    fullWidth
                    variant="standard"
                    value={leaveBalancePayload?.balance}
                    onChange={(e) => {
                      setLeaveBalancePayload((state) => ({
                        ...state,
                        balance: e.target.value,
                      }));
                      setBalanceValidationError('');
                    }}
                    error={!!balanceValidationError}
                    helperText={balanceValidationError}
                  ></TextField>
                </Grid>
              </Grid>
              <MDButton
                variant="gradient"
                color="info"
                sx={{ height: "40px", width: "0px" }}
                onClick={() => {
                  leaveBalancePayload.request == "create"
                    ? postLeaveBalance()
                    : patchLeaveBalance();
                  // setAddManager(true)
                  // setCreateDialog(true);
                }}
              >
                <MDTypography variant="h6" color="white">
                  {leaveBalancePayload.request == "create" ? "Add" : "Edit"}
                </MDTypography>
              </MDButton>
            </MDBox>
          </DialogContent>
        </Dialog>
      </Card>
    </>
  );
}
