import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import {
  Autocomplete,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Input,
  Switch,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Footer from "examples/Footer";
import typography from "assets/theme/base/typography";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import ApiCalls from "services/flick_erp_api_call";
import { Cancel, Check, Delete, DocumentScanner } from "@mui/icons-material";
import { advertisementLists } from "./jobAdvertisementSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import MDAlert from "components/MDAlert";
import { TextField } from "@mui/material";
import FormField from "layouts/pages/account/components/FormField";
import { TextViewEditorBox } from "components/TextViewEditorBox";
import { setIsOpen } from "components/TextViewEditorBox/textViewEditorBoxSlice";
import moment from "moment";
import MDEditor from "components/MDEditor";
import parse from "html-react-parser";
import { promptConfirmation } from "components/Prompt";
import { PromptBox } from "components/Prompt";
import { setPromptOpen } from "components/Prompt/promptSlice";

function CurrentOpenings(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const [descriptionDialog, setDescriptionDialog] = useState(false);
  const [
    createORUpdateJobAdvertisementDialog,
    setCreateORUpdateJobAdvertisementDialog,
  ] = useState(false);

  // const { size } = typography;
  function createData(
    id,
    position,
    education,
    experience,
    vacancy,
    description,
    deadline,
    post_date
  ) {
    return {
      id,
      position,
      education,
      experience,
      vacancy,
      description,
      deadline,
      post_date,
    };
  }

  //useState hooks
  var newIndex;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [data, setData] = useState([]);

  const [editableRowIndex, setEditableRowIndex] = useState(null);
  const [editedData, setEditedData] = useState([]);
  const [id_, setID] = useState();
  const date = new Date();

  const [positionError, setPositionError] = useState("");
  const [educationError, setEducationError] = useState("");
  const [vacancyError, setVacancyError] = useState("");
  const [experienceError, setExperienceError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [deadlineError, setDeadlineError] = useState("");

  const initialFormattedDate = date;
  const [jobAdvertisementPayload, setJobAdvertisementPayload] = useState({
    id: "",
    position: "",
    education: "",
    experience: "",
    vacancy: "",
    description: "",
    deadline: new Date(),
    post_date: new Date(),
    type: "",
  });

  const dispatch = useDispatch();

  //api for delete job advertisement

  const handleDeleteClick = async (row) => {
    const recordId = row.id;
    const res = await ApiCalls.deleteAdvertisement(recordId);

    if (res.success && res.code === 204) {
      jobAdvertisement();
    } else {
    }
  };

  useEffect(() => {
    jobAdvertisement();
  }, []);

  // Api for get Job Advertisement List
  async function jobAdvertisement() {
    const res = await ApiCalls.getAdvertisementList();
    if (res.success && res.code === 200) {
      setData(res.data);
      dispatch(advertisementLists({ jobAdvertisement: res.data }));
    } else {
    }
  }

  // Api for create job advertisement
  const [showAlert, setShowAlert] = useState(null);
  const alertContent = (name) => (
    <MDTypography variant="body2" color="white">
      {name}
    </MDTypography>
  );
  const clearValidation = () => {
    setPositionError("");
    setVacancyError("");
    setDeadlineError("");
    setEducationError("");
    setDescriptionError("");
    setExperienceError("");
  };

  const handleAddRecord = async () => {
    let hasError = false;
    let error = "This field may not be blank.";

    if (
      jobAdvertisementPayload.position === undefined ||
      jobAdvertisementPayload.position === ""
    ) {
      setPositionError(error);
      hasError = true;
    } else {
      setPositionError("");
    }
    if (
      jobAdvertisementPayload.vacancy === undefined ||
      jobAdvertisementPayload.vacancy === ""
    ) {
      setVacancyError(error);
      hasError = true;
    } else {
      setVacancyError("");
    }

    if (
      jobAdvertisementPayload.education === undefined ||
      jobAdvertisementPayload.education === ""
    ) {
      setEducationError(error);
      hasError = true;
    } else {
      setEducationError("");
    }
    if (
      jobAdvertisementPayload.description === undefined ||
      jobAdvertisementPayload.description === ""
    ) {
      setDescriptionError(error);
      hasError = true;
    } else {
      setDescriptionError("");
    }
    if (
      jobAdvertisementPayload.experience === undefined ||
      jobAdvertisementPayload.experience === ""
    ) {
      setExperienceError(error);
      hasError = true;
    } else {
      setExperienceError("");
    }

    if (!hasError) {
      let data = {
        position: jobAdvertisementPayload.position,
        education: jobAdvertisementPayload.education,
        experience: jobAdvertisementPayload.experience,
        vacancy: jobAdvertisementPayload.vacancy,
        description: jobAdvertisementPayload.description,
        deadline: moment(jobAdvertisementPayload.deadline).format("YYYY-MM-DD"),
        post_date: moment(jobAdvertisementPayload.post_date).format(
          "YYYY-MM-DD"
        ),
      };
      const res = await ApiCalls.createAdvertisement(data);
      if (res.success && res.code == 201) {
        jobAdvertisement();
        setCreateORUpdateJobAdvertisementDialog(false);
      }
    }
  };

  const handleCancelRecord = () => {
    setShowAlert(null);
  };

  // Api for update job advertisement

  // const handleEditClick = (row, actualIndex) => {
  //   setEditableRowIndex(actualIndex);
  //   setEditedData([...data]);
  //   setID(row.id);
  // };

  const handleSaveClick = async () => {
    let data = {
      position: jobAdvertisementPayload.position,
      education: jobAdvertisementPayload.education,
      experience: jobAdvertisementPayload.experience,
      vacancy: jobAdvertisementPayload.vacancy,
      description: jobAdvertisementPayload.description,
      deadline: jobAdvertisementPayload.deadline,
      post_date: jobAdvertisementPayload.post_date,
    };
    const res = await ApiCalls.updateJobAdvertisement(
      jobAdvertisementPayload.id,
      data
    );
    if (res.success && res.code == 200) {
      jobAdvertisement();
      setCreateORUpdateJobAdvertisementDialog(false);
    } else {
    }
  };

  const handleFieldChange = (event, fieldName) => {
    const updatedData = [...editedData];
    const editedRecord = { ...updatedData[editableRowIndex] };
    editedRecord[fieldName] = event.target.value;
    updatedData[editableRowIndex] = editedRecord;
    setEditedData(updatedData);
  };

  // useSelector Hook
  const advertisements = useSelector(
    (state) => state.jobAdvertisement.jobAdvertisement
  );
  const description = useSelector((state) => state.textViewBox.value);
  useEffect(() => {
    if (description != "") {
      const updatedData = [...editedData];

      const editedRecord = { ...updatedData[editableRowIndex] };
      editedRecord["description"] = description;
      updatedData[editableRowIndex] = editedRecord;
      setEditedData(updatedData);
    }
  }, [description]);

  //Pagination

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - advertisements.length)
      : 0;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <MDBox>
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
            }}
          >
            {props?.permissions?.includes(
              props?.modulePermissions?.create_permission
            ) && (
              <MDButton
                variant="gradient"
                color="dark"
                sx={{borderRadius:"20px"}}
                onClick={() => {
                  setJobAdvertisementPayload((state) => ({
                    ...state,
                    deadline: new Date(),
                    post_date: new Date(),
                    type: "create",
                  }));
                  setCreateORUpdateJobAdvertisementDialog(true);
                }}
              >
                Add Records
              </MDButton>
            )}
          </MDBox>
          <MDBox sx={{}}>
            {showAlert && (
              <MDAlert
                color="primary"
                dismissible
                onClick={() => {
                  setShowAlert(null);
                }}
              >
                {alertContent(showAlert)}
              </MDAlert>
            )}
          </MDBox>
        </MDBox>
        <Card style={{ boxShadow: "0 8px 8px rgba(0, 0, 0, 0.50)" }}>
          <MDTypography variant="h5" p={2}>
            Current Openings
          </MDTypography>
          <TableContainer components={Paper}>
            <Table aria-label="simple table">
              <TableBody data-testid="tBody">
                <TableRow>
                  <TableCell variant="head" style={{ color: "black" }}>
                    Position
                  </TableCell>
                  <TableCell variant="head" style={{ color: "black" }}>
                    Education
                  </TableCell>
                  <TableCell variant="head" style={{ color: "black" }}>
                    Vacancy
                  </TableCell>
                  <TableCell variant="head" style={{ color: "black" }}>
                    Experience
                  </TableCell>
                  <TableCell variant="head" style={{ color: "black" }}>
                    Description
                  </TableCell>
                  <TableCell variant="head" style={{ color: "black" }}>
                    Deadline
                  </TableCell>
                  <TableCell variant="head" style={{ color: "black" }}>
                    Post Date
                  </TableCell>
                  {props?.permissions.includes(
                    props?.modulePermissions?.delete_permission
                  ) && (
                    <TableCell variant="head" style={{ color: "black" }}>
                      Action
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
              <TableBody>
                {(rowsPerPage > 0
                  ? advertisements.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : advertisements
                ).map((row, index) => {
                  const actualIndex = index + page * rowsPerPage;
                  newIndex = actualIndex;
                  return (
                    <TableRow key={row.id}>
                      {/* <TableCell>{actualIndex + 1}</TableCell> */}
                      <TableCell
                        variant="head"
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setJobAdvertisementPayload((state) => ({
                            ...state,
                            ...row,
                            type: "update",
                          }));
                          clearValidation();
                          setCreateORUpdateJobAdvertisementDialog(true);
                        }}
                      >
                        {row.position}
                      </TableCell>
                      <TableCell>{row.education}</TableCell>
                      <TableCell>{row.vacancy}</TableCell>
                      <TableCell>{row.experience}</TableCell>
                      <TableCell
                        onClick={() => {
                          dispatch(
                            setIsOpen({
                              isOpen: true,
                              isReadOnly: true,
                              value: row.description,
                            })
                          );
                        }}
                      >
                        <Typography
                          sx={{
                            width: "90px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "1",
                            WebkitBoxOrient: "vertical",
                            fontWeight: "lighter",
                            fontSize: "16px",
                          }}
                        >
                          {parse(row.description)}
                        </Typography>
                        {/* <TextField
                          variant="standard"
                          InputProps={{
                            disableUnderline: true,
                          }}

                          value={new DOMParser().parseFromString(row.description, 'text/html')}

                        ></TextField> */}
                      </TableCell>
                      <TableCell>{row.deadline}</TableCell>
                      <TableCell>{row.post_date}</TableCell>
                      {props.permissions.includes(
                        props?.modulePermissions?.delete_permission
                      ) && (
                        <TableCell>
                          {props.permissions.includes(
                            props?.modulePermissions?.delete_permission
                          ) && (
                            <>
                            
                              <Tooltip title="Scan">
                                <IconButton
                                  size="small"
                                  aria-label="scan"
                                  onClick={() => {
                               
                                
                                  }}
                                >
                                  <DocumentScanner />
                                </IconButton>
                              </Tooltip>
                             
                              <Tooltip title="Delete">
                                <IconButton
                                  size="small"
                                  aria-label="delete"
                                  onClick={() =>
                                   {
                                    handleDeleteClick(row, actualIndex)
                                    
                                   }
                                  }
                                >
                                  <Delete />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Active">
                               <Switch/>
                              </Tooltip>
                            </>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={10}
                  count={advertisements.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  slotProps={{
                    select: {
                      "aria-label": "rows per page",
                    },
                    actions: {
                      showFirstButton: true,
                      showLastButton: true,
                    },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableFooter>
            </Table>
          </TableContainer>
        </Card>

        {/* <br /> */}
      </MDBox>
      <br />
      <Footer />
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "#f8f8f8",
            boxShadow: "none",
            width: "600px",
            height: "400px",
            maxWidth: "none",
            borderRadius: "10px",
          },
        }}
        open={descriptionDialog}
        onClose={() => setDescriptionDialog(false)}
      >
        <React.Fragment>
          <DialogTitle
            style={{
              padding: "20px",
              borderBottom: "1px solid #ccc",
              background: "deepskyblue",
              color: "white",
            }}
          >
            <Typography variant="h5" style={{ color: "aliceblue" }}>
              Description
            </Typography>
          </DialogTitle>
          <DialogContent style={{ padding: "30px" }}>
            <TextField
              fullWidth
              placeholder="Descriptipn"
              value={editedData[editableRowIndex]?.description}
              multiline
              rows={6}
              maxRows={10}
              onChange={(event) => {
                handleFieldChange(event, "description");
              }}
            />
          </DialogContent>
          <DialogActions>
            <MDButton
              variant="gradient"
              color="dark"
              onClick={() => {
                setDescriptionDialog(false);
              }}
            >
              Done
            </MDButton>
          </DialogActions>
        </React.Fragment>
      </Dialog>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "white",
            boxShadow: "none",
            width: "600px",
            maxWidth: "none",
            padding: "20px",
            borderRadius: "10px",
          },
        }}
        open={createORUpdateJobAdvertisementDialog}
        onClose={() => {
          setJobAdvertisementPayload({});
          setCreateORUpdateJobAdvertisementDialog(false);
          clearValidation();
        }}
      >
        <React.Fragment>
          <DialogTitle>
            <Typography variant="h5">Current Opening</Typography>
          </DialogTitle>
          <DialogContent style={{}}>
            <MDBox>
              <Grid container spacing={3} alignItems={"flex-end"}>
                <Grid item xs={12} sm ={6}>
                  <TextField
                    type="text"
                    variant="standard"
                    name="position"
                    value={jobAdvertisementPayload.position}
                    onChange={(event) => {
                      setJobAdvertisementPayload((state) => ({
                        ...state,
                        position: event.target.value,
                      }));
                      setPositionError("");
                    }}
                    style={{ border: "none", outline: "none", padding: 0 }}
                    required
                    fullWidth
                    label="Position"
                    error={!!positionError}
                    helperText={positionError}
                  />
                </Grid>
                <Grid item xs={12} sm ={6}>
                  <TextField
                    variant="standard"
                    name="education"
                    value={jobAdvertisementPayload.education}
                    onChange={(event) => {
                      setJobAdvertisementPayload((state) => ({
                        ...state,
                        education: event.target.value,
                      }));
                      setEducationError("");
                    }}
                    required
                    fullWidth
                    label="Education"
                    error={!!educationError}
                    helperText={educationError}
                  />
                </Grid>
                <Grid item xs={12} sm ={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextField
                        variant="standard"
                        name="vacancy"
                        value={jobAdvertisementPayload.vacancy}
                        onChange={(event) => {
                          setJobAdvertisementPayload((state) => ({
                            ...state,
                            vacancy: event.target.value,
                          }));
                          setVacancyError("");
                        }}
                        type="number"
                        required
                        fullWidth
                        label="Vacancy"
                        error={!!vacancyError}
                        helperText={vacancyError}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="standard"
                        name="experience"
                        value={jobAdvertisementPayload.experience}
                        onChange={(event) => {
                          setJobAdvertisementPayload((state) => ({
                            ...state,
                            experience: event.target.value,
                          }));
                          setExperienceError("");
                        }}
                        type="number"
                        required
                        label="Experience"
                        fullWidth
                        error={!!experienceError}
                        helperText={experienceError}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm ={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="standard"
                        id="deadline"
                        type="date"
                        label="Deadline"
                        value={moment(jobAdvertisementPayload.deadline).format(
                          "YYYY-MM-DD"
                        )}
                        name="deadline"
                        placeholder=""
                        fullWidth
                        required
                        // error={!!deadlineError}
                        // helperText={deadlineError}
                        onChange={(event) => {
                          setJobAdvertisementPayload((state) => ({
                            ...state,
                            deadline: event.target.value,
                          }));
                          deadlineError("");
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="standard"
                        InputLabelProps={{}}
                        id="post_date"
                        type="date"
                        label="Post Date"
                        fullWidth
                        value={moment(jobAdvertisementPayload.post_date).format(
                          "YYYY-MM-DD"
                        )}
                        name="post_date"
                        onChange={(event) => {
                          setJobAdvertisementPayload((state) => ({
                            ...state,
                            post_date: event.target.value,
                          }));
                        }}
                        InputProps={{}}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm ={6}>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    value={jobAdvertisementPayload.skills || []}
                    options={[]}
                    sx={{
                      "&.MuiAutocomplete-noOptions": {
                        display: "none",
                      },
                    }}
                    onChange={(event, values)=> {
                      setJobAdvertisementPayload((state) => ({
                        ...state,
                        skills: values,
                      }));
                    }}
                    // renderOption={(props, option) => {
                    //   return (
                    //     <div></div>
                    //   );
                    // }}
                    // defaultValue={[top100Films[13].title]}
                    freeSolo={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Skills"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm ={6}>
                  <TextField
                    type="file"
                    label="Image"
                    variant="standard"
                  ></TextField>
                </Grid>

                <Grid item xs={12} >
                  <MDEditor
                    value={jobAdvertisementPayload.description || ""}
                    onChange={(val) => {
                      const inputValue = val;
                      setJobAdvertisementPayload((state) => ({
                        ...state,
                        description: val,
                      }));
                      setDescriptionError("");
                    }}
                  />
                  <p
                    style={{
                      color: "red",
                      fontSize: 13,
                      fontWeight: "lighter",
                    }}
                  >
                    {" "}
                    {descriptionError ? "This field may not be blank*" : ""}
                  </p>
                  {/* <TextField
                    variant="standard"
                    name="description"
                    value={jobAdvertisementPayload.description}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      if (/^\d+$/.test(inputValue)) {
                        return;
                      }

                      setJobAdvertisementPayload((state) => ({
                        ...state,
                        description: event.target.value,
                      }));
                    }}
                    required
                    label="Description"
                    error={!!descriptionError}
                    helperText={descriptionError}
                    fullWidth
                    InputProps={{
                      inputComponent: TextareaAutosize,
                      rows: 3,
                    }}
                  /> */}
                </Grid>
              </Grid>
            </MDBox>
          </DialogContent>
          <DialogActions>
            <MDButton
              variant="gradient"
              color="dark"
              onClick={() => {
                setJobAdvertisementPayload({});
                setCreateORUpdateJobAdvertisementDialog(false);
                clearValidation();
              }}
            >
              Cancel
            </MDButton>
            {(props.permissions.includes(
              props?.modulePermissions?.update_permission
            ) ||
              props?.permissions?.includes(
                props?.modulePermissions?.create_permission
              )) && (
              <MDButton
                variant="gradient"
                color="dark"
                onClick={() => {
                  jobAdvertisementPayload.type == "create"
                    ? handleAddRecord()
                    : handleSaveClick();
                }}
              >
                {jobAdvertisementPayload.type == "create" ? "Create" : "Update"}
              </MDButton>
            )}
          </DialogActions>
        </React.Fragment>
      </Dialog>
      <TextViewEditorBox></TextViewEditorBox>
    </DashboardLayout>
  );
}
export default CurrentOpenings;
